import styled, { css } from 'styled-components';

import SystemRoundedIcon from 'components/shared/core/roundedIcon';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.md};
`;

export const FlexGrow = styled(Container)`
  gap: 9px;
  flex-grow: 1;
`;

export const ColumnHeader = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small1};
  line-height: 1.5rem;
  color: ${({ theme }) => theme.secondary.dark};
  text-transform: uppercase;
  font-weight: 600;
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    line-height: ${theme.fontSizes.medium2};
    color: ${theme.texts.darkSecondary};
    font-weight: ${theme.new.typography.weight.semibold};
  `}
  padding-block: 2vh;
`;

export const Fields = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.md};

  @media screen and (max-width: 950px) {
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing.xs} 0;
  }
`;

export const FieldsUnorderedList = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.md};
  font-weight: ${({ theme }) => theme.new.typography.weight.semibold};
  ::before {
    content: '•'; /* Adiciona um ponto antes do conteúdo */
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing.xs} 0;
  }
`;

export const FieldsBold = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.md};
  font-weight: ${({ theme }) => theme.new.typography.weight.semibold};

  @media screen and (max-width: 950px) {
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing.xs} 0;
  }
`;
export const Figure = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium2};
    color: ${theme.texts.lightSecondary};
  `}
`;

export const ContainerGrow = styled.div`
  display: flex;
  align-items: baseline;
  align-self: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.xs};
`;
export const ContainerSubTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.texts.lightSecondary};
  gap: ${({ theme }) => theme.spacing.xs};
  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

export const ContainerButton = styled.div`   
  margin-left: auto;n;
 margin-top: -3vh;
  }
`;

export const RoundedIcon = styled(SystemRoundedIcon)`
  flex-shrink: 0;
  color: ${({ theme }) => theme.texts.darkSecondary};
  background-color: #fff;
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    margin-inline-start: ${theme.fontSizes.xs};
  `}
`;

export const FieldsGrow = styled(Fields)`
  & > div {
    flex-grow: 1;
    background-color: #fff;
    padding: 2vw;
  }
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    span {
      font-size: ${theme.new.typography.size.sm};
      font-weight: ${theme.new.typography.weight.semibold};
      line-height: ${theme.new.typography.lineHeight.sm};
      color: ${theme.new.colors.primary.main};
    }
  `}
`;
export const SectionContent = styled.div`
  ${({ theme }) => css`
    color: ${theme.texts.lightSecondary};
    font-size: ${theme.fontSizes.small1};
    border-radius: 20px;
    padding-block: 2vh;
    width: -webkit-fill-available;
  `}
`;

export const Title = styled.h1`
  font-weight: 600;
  text-transform: uppercase;
  ${({ theme }) => css`
    color: ${theme.texts.lightSecondary};
    margin-top: ${theme.spacing.lg};
    margin-bottom: ${theme.spacing.md};
  `}
`;
