import styled, { css } from 'styled-components';

import Heading from 'components/shared/core/heading';

import { device } from 'styles/tokens/devices';

export const Title = styled(Heading)`
  ${({ theme }) => css`
    color: ${theme.texts.lightSecondary};
    font-size: ${theme.fontSizes.large};
    font-weight: 600;
    line-height: 32px;
  `}

  @media ${device.tabletL} {
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.medium2};
    `}
  }
`;

export const Header = styled.div`
  margin: ${({ theme }) => theme.spacing.md} 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media ${device.laptopS} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 0;
  }
`;

export const QueryWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  @media screen and (max-width: 1000px) {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;
export const InputWrapper = styled.div`
  width: 300px;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const InputWrapperFile = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xs1};
  width: 300px;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  ${({ theme }) => css`
    color: ${theme.texts.darkSecondary};
    font-weight: 600;
    font-size: ${theme.fontSizes.small1};
  `}
`;

export const LabelSpan = styled.label`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: 23px;
    gap: 3px;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.input.outlined.label};
    color: ${theme.input.primary.label};
    font-weight: 600;
    height: fit-content;
    text-transform: uppercase;
    & span {
      color: ${theme.contextual.error};
    }
  `}
`;

export const RadioButtomWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
`;

export const Span = styled.span``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 600;
`;

export const Form = styled.form`
  width: 100%;
`;

export const ButtonStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`;
