import axios from 'axios';
import { useAlert } from 'contexts';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { MutateListener } from 'types';

import { orthopedicLoan } from 'services/orthopedic';

export const useUpdateLoan = ({ onSuccess }: MutateListener) => {
  const { updateOrthopedicEquipmentLoan } = orthopedicLoan();
  const intl = useIntl();
  const { showAlert } = useAlert();

  const handleErrorValidation = (status: number) => {
    switch (status) {
      case 422:
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'form.error.devolutionError',
          }),
        });
        break;
      case 404:
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'request.error.loan_not_found',
          }),
        });
        break;
      case 400:
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'request.error.loan_closed',
          }),
        });
        break;
      default:
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'failureToSend',
          }),
        });
        break;
    }
  };

  return useMutation(updateOrthopedicEquipmentLoan, {
    onSuccess: onSuccess,
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        const response = error.response;
        handleErrorValidation(response?.status as number);
        return;
      }
    },
  });
};
