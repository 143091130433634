import {
  DataPaymentMethodPIX,
  DataPaymentMethodSLIP,
  DataPaymentMethodURL,
  DataSendSlipByEmail,
  NewSchedule,
} from 'types';

import { ASSOCIATES, BILLINGS } from 'constants/endpoints';

import api from './api';

export const getAllInstaments = async (subscription: string) => {
  const url = `${ASSOCIATES.GET_ALL_INSTALLMENTS(subscription)}`;
  const allInstamennts = await api.getRequest(url);

  return allInstamennts.data;
};

export const getCallStatuses = async () => {
  const url = `${BILLINGS.GET_CALL_STATUSES()}`;
  const allStatues = await api.getRequest(url);

  return allStatues.data;
};

export const getPaymentMethod = async (
  subscription: string,
  type: 'url' | 'slip' | 'pix',
  dataPaymentMethod:
    | DataPaymentMethodPIX
    | DataPaymentMethodSLIP
    | DataPaymentMethodURL
) => {
  try {
    const response = await api.postRequest(
      BILLINGS.PAYMENTS_METHOD(subscription, type),
      dataPaymentMethod
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const sendSlipByEmail = async ({
  subscription,
  dataSendSlip,
}: {
  subscription: string;
  dataSendSlip: DataSendSlipByEmail;
}) => {
  try {
    await api.postRequest(
      BILLINGS.SEND_SLIP_BY_EMAIL(subscription),
      dataSendSlip
    );

    return { isSuccess: true };
  } catch (err) {
    throw err;
  }
};

export const newSchedule = async (
  subscription: string,
  dataNewSchedule: NewSchedule
) => {
  try {
    const data = await api.postRequest(
      BILLINGS.BILLING_SCHEDULING(subscription),
      dataNewSchedule
    );
    return data;
  } catch (err) {
    throw err;
  }
};
