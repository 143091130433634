import CryptoJS from 'crypto-js';

const secret = process.env.REACT_APP_STORAGE_SECRET || 'test-secret';

export const encrypt = (text: string) =>
  CryptoJS.AES.encrypt(JSON.stringify(text), secret).toString();

export const setItem = (key: string, value: string) => {
  localStorage.setItem(key, encrypt(value));
};

export const getItem = (key: string) => {
  const ciphertext = localStorage.getItem(key);
  if (!ciphertext) {
    return;
  }
  const bytes = CryptoJS.AES.decrypt(ciphertext, secret);

  const bytesString = bytes.toString(CryptoJS.enc.Utf8);
  return bytesString ? JSON.parse(bytesString) : '';
};

export const clear = () => {
  localStorage.clear();
};

const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

const validate = () => {
  for (const item of Object.keys(localStorage)) {
    const content = localStorage.getItem(item) || '';
    const bytes = CryptoJS.AES.decrypt(content, secret);
    try {
      JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (err) {
      setItem(item, content);
    }
  }
};

validate();

export const useLocalStorage = () => ({
  setItem,
  getItem,
  removeItem,
  clear,
});
