import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { getPaginatedCollectors } from 'useCases/deliveryManager/api';

export const useFindAllCollectors = (
  filterName: string,
  whenEnabled = true,
  fetchAll = false
) => {
  const { data, ...rest } = useInfiniteQuery(
    ['findAllCollectors', filterName],
    ({ pageParam }) => getPaginatedCollectors({ pageParam, filterName }),
    {
      enabled: whenEnabled,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage < lastPage.meta.totalPages
          ? lastPage.meta.currentPage + 1
          : undefined,
      cacheTime: 1000 * 60 * 30,
      staleTime: 1000 * 60 * 30, // 30 minutes
    }
  );

  if (
    !rest.isFetchingNextPage &&
    !rest.isError &&
    rest.hasNextPage &&
    fetchAll
  ) {
    rest.fetchNextPage();
  }

  const allValues = useMemo(
    () => data?.pages.flatMap(({ data }) => data),
    [data]
  );

  return { ...rest, allCollectors: allValues };
};
