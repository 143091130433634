import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaChevronDown, FaPlus } from 'react-icons/fa';
import { useIntl } from 'react-intl';

import { useOutsideClick } from 'hooks/useOutsideClick';

import InfiniteScroll from 'components/shared/core/infiniteScroll';
import LoadingSpinner from 'components/shared/core/loadingSpinner';
import Tooltip from 'components/shared/newCore/Tooltip';

import * as S from './SearchSelect.styles';
import { TextVariantKeys } from './SearchSelect.styles';

type ErrorPosition = 'top' | 'bottom';

export type OptionValueGeneric<T> = T | T[] | undefined;
export type Value = OptionValueGeneric<string | number>;

export type SelectOption = {
  label: string;
  value: string | number;
  data?: OptionValueGeneric<unknown>;
};

type SearchSelectProps = {
  onSelect?: (value: SelectOption | SelectOption[]) => void;
  onBlur?: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  onSearchChange?: (value: string | number) => void;
  cancelSearch?: () => void;
  setValue?: (label: string, value: Value) => void;
  valueLabel?: string | string[] | undefined;
  value?: Value;
  options: SelectOption[];
  id: string;
  label?: string;
  description?: string;
  placeholder?: string;
  required?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  isAccordionForm?: boolean;
  multiple?: boolean;
  hasErrors?: boolean;
  errorMessage?: string;
  errorMessagePosition?: ErrorPosition;
  handlePagination?: () => void;
  hasPagination?: boolean;
  hasErrorPagination?: boolean;
  multipleProps?: {
    flexColumn?: boolean;
  };
  characterRestrictionMessage?: boolean;
  hasDarkBackground?: boolean;
  OptionIconButton?: React.ReactNode;
  handleOptionIconButton?: (value: string | number) => void;
  asyncFilteredOptions?: boolean;
  onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  hasBorder?: boolean;
  fieldType?: string;
  onlyNumbers?: boolean;
  citySearch?: string;
  refetchCitiesByName?: () => void;
  setValueFilter?: (value: string) => void;
  emptyStateMessage?: string;
};

const SearchSelect = ({
  onSelect,
  onBlur,
  onSearchChange,
  cancelSearch,
  setValue,
  valueLabel,
  value,
  id,
  options,
  placeholder = 'Selecione',
  label,
  description,
  required = false,
  isLoading = false,
  disabled = false,
  readOnly = false,
  fullWidth = false,
  isAccordionForm = false,
  multiple = false,
  hasErrors = false,
  errorMessage,
  errorMessagePosition = 'bottom',
  handlePagination,
  hasPagination,
  hasErrorPagination,
  multipleProps,
  characterRestrictionMessage,
  hasDarkBackground = false,
  OptionIconButton,
  handleOptionIconButton,
  asyncFilteredOptions,
  onPressEnter,
  hasBorder,
  fieldType = 'text',
  onlyNumbers,
  citySearch,
  refetchCitiesByName,
  setValueFilter,
  emptyStateMessage = '',
}: SearchSelectProps) => {
  const intl = useIntl();

  const [selected, setSelected] = useState<SelectOption | undefined>();
  const [multipleSelection, setMultipleSelection] = useState<SelectOption[]>(
    []
  );

  const [listItemIndex, setListItemIndex] = useState<number | null>(null);
  const [showOption, setShowOption] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');

  const wrapperRef = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const optionsRef = useRef<HTMLUListElement | null>(null);

  const getSingleSelectedOption = (value: Value) => {
    const selectedOption = options.find((option) => option.value === value);

    if (selectedOption && selectedOption === selected) {
      return;
    }

    if (selectedOption) {
      setSelected(selectedOption);
    }

    if (!selectedOption && valueLabel) {
      setSelected({
        label: (valueLabel ?? value) as string,
        value: value as string | number,
      });
    }
  };

  const getMultipleSelectedOptions = (value: Value) => {
    const values = Array.isArray(value) ? value : [value];
    let selectedOptions: SelectOption[] = [];

    values.forEach((value, index) => {
      const selectedOption = options.find((option) => option.value === value);

      if (selectedOption) {
        selectedOptions.push(selectedOption);
      }

      if (!selectedOption && valueLabel) {
        selectedOptions.push({
          label: valueLabel[index] as string,
          value: value as string | number,
        });
      }
    });
    setMultipleSelection(selectedOptions);
  };

  const getSelected = (value: Value) => {
    if (!value) {
      return;
    }

    multiple
      ? getMultipleSelectedOptions(value)
      : getSingleSelectedOption(value);
  };

  const offFocusHandler = () => {
    setShowOption(false);
    setListItemIndex(null);

    if (selected) {
      setSearchInput(selected.label ?? '');
    }
  };

  const focusHandler = () => {
    setShowOption(true);
    setSearchInput('');
  };

  useOutsideClick(wrapperRef, offFocusHandler);

  const clearSelection = () => {
    setSelected(undefined);
    setMultipleSelection([]);
  };

  const clearAll = (
    event?:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    event?.stopPropagation();
    event?.preventDefault();
    clearSelection();
    setSearchInput('');
    setListItemIndex(null);
    setValue && setValue(id, '');

    if (cancelSearch) {
      cancelSearch();
    }
  };

  const isOptionSelected = (option: SelectOption) => {
    if (multiple) {
      return (
        multipleSelection.findIndex(
          (selectedOption) => selectedOption.value === option.value
        ) !== -1
      );
    }

    return selected?.value === option.value;
  };

  const selectMultipleOption = (selectedOption: SelectOption) => {
    const alreadySelected = multipleSelection.some(
      (option) => option.value === selectedOption.value
    );

    const newMultipleSelection = alreadySelected
      ? multipleSelection.filter((option) => option !== selectedOption)
      : [...multipleSelection, selectedOption];

    setMultipleSelection(newMultipleSelection);
    onSelect && onSelect(newMultipleSelection);
    setValue &&
      setValue(id, newMultipleSelection.map((option) => option.value) as Value);

    setSearchInput('');
  };

  const selectSingleOption = (selectedOption: SelectOption) => {
    setSelected(selectedOption);

    if (setValue && selectedOption !== selected) {
      setValue(id, selectedOption.value);
    }

    if (onSelect && selectedOption !== selected) {
      onSelect(selectedOption);
    }
  };

  const handleSelection = (selectedOption: SelectOption) => {
    if (multiple) {
      selectMultipleOption(selectedOption);
    } else {
      setShowOption(false);
      selectSingleOption(selectedOption);
      setSearchInput(selectedOption.label ?? '');
    }
  };

  const filteredOptions = !asyncFilteredOptions
    ? options.filter((option) =>
        option.label
          .toLowerCase()
          .includes(searchInput.toString().toLowerCase())
      )
    : options;

  function validateInput(input: string) {
    return input.replace(/[^0-9]/g, '');
  }

  const handleSearch = (searchValue: string) => {
    const value = onlyNumbers ? validateInput(searchValue) : searchValue;
    setSearchInput(value);

    if (onSearchChange) {
      onSearchChange(value);
    }

    if (id === 'city-for-neighborhood' && setValueFilter) {
      setValueFilter(searchValue);
    }
  };

  const handleSelectButtonItem = (item: SelectOption) => {
    handleOptionIconButton && handleOptionIconButton(item.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const listItemMenuHeight = 38;
    let activeIdx = null;

    if (filteredOptions?.length === 0) {
      setListItemIndex(null);
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        activeIdx =
          listItemIndex === null
            ? 0
            : Math.min(filteredOptions.length - 1, listItemIndex + 1);
        break;
      case 'ArrowUp':
        activeIdx =
          listItemIndex !== null
            ? Math.max(0, listItemIndex - 1)
            : filteredOptions.length - 1;
        break;
      case 'Escape':
        setShowOption(false);
        break;
      case 'Enter':
        e?.preventDefault();

        if (listItemIndex !== null) {
          const selected = filteredOptions[listItemIndex];
          if (inputRef && inputRef.current) {
            inputRef.current.blur();
          }

          handleSelection(selected);
        }

        if (onPressEnter && !activeIdx && listItemIndex) {
          onPressEnter && onPressEnter(e);
        }

        if (
          id === 'city-for-neighborhood' &&
          refetchCitiesByName &&
          citySearch &&
          citySearch.length >= 3
        ) {
          refetchCitiesByName();
        }

        break;
      case 'Backspace':
        if (searchInput === '') {
          cancelSearch && cancelSearch();
          if (multiple && multipleSelection.length > 0) {
            const lastSelected =
              multipleSelection[multipleSelection.length - 1];
            const newMultipleSelection = multipleSelection.filter(
              (option) => option !== lastSelected
            );

            setMultipleSelection(newMultipleSelection);
            onSelect && onSelect(newMultipleSelection);
            setValue &&
              setValue(
                id,
                newMultipleSelection.map((option) => option.value) as Value
              );
          } else if (selected) {
            setSelected(undefined);
          }
        }
        break;
      case 'Tab':
        offFocusHandler();
        break;
      default:
        break;
    }

    setListItemIndex(activeIdx);

    if (activeIdx && optionsRef && optionsRef.current && activeIdx - 4 > -1) {
      optionsRef.current.scrollTop = listItemMenuHeight * (activeIdx - 4);
    }
  };

  const errorElement = (position: ErrorPosition) =>
    hasErrors && errorMessage ? (
      <Tooltip position={position} type="error" message={errorMessage} />
    ) : null;

  useEffect(() => {
    if (value && options) {
      getSelected(value);
    }
  }, [value, valueLabel, options]);

  useEffect(() => {
    if (selected) {
      setSearchInput(selected.label ?? '');
    }
  }, [selected?.label]);

  useEffect(() => {
    if (value === '') {
      clearSelection();
      setSearchInput('');
      setListItemIndex(null);
    }
  }, [value]);

  const RenderOption = useMemo(() => {
    if (isLoading)
      return (
        <S.Span>
          <LoadingSpinner width={20} height={20} borderSize={3} />
        </S.Span>
      );

    if (searchInput.length < 3 && characterRestrictionMessage)
      return (
        <S.Span>
          {intl.formatMessage({
            id: 'autocomplete.characterRestriction',
          })}
        </S.Span>
      );

    if (options && filteredOptions.length > 0)
      return (
        <S.OptionsList ref={optionsRef}>
          {filteredOptions.map((option, index) => (
            <S.Option
              key={`${index}-${option.value}`}
              onClick={() => handleSelection(option)}
              isSelected={isOptionSelected(option)}
              isActive={listItemIndex !== null && listItemIndex === index}
            >
              <S.OptionLabel>{option.label}</S.OptionLabel>
              {handleOptionIconButton ? (
                <button
                  type="button"
                  onClick={() => handleSelectButtonItem(option)}
                >
                  {OptionIconButton}
                </button>
              ) : null}
            </S.Option>
          ))}
          {!hasErrorPagination && handlePagination && hasPagination ? (
            <>
              <InfiniteScroll
                loadMore={() => handlePagination()}
                refresh={options.length}
              />
              <LoadingSpinner width={20} height={20} borderSize={4} />
            </>
          ) : null}
        </S.OptionsList>
      );
    else
      return (
        <S.Span>
          {(!isLoading && emptyStateMessage) ||
            intl.formatMessage({ id: 'emptyState.title' })}
        </S.Span>
      );
  }, [isLoading, options, filteredOptions, listItemIndex]);

  return (
    <S.Wrapper
      key={id}
      id={id}
      disabled={disabled}
      readOnly={readOnly}
      hasErrors={hasErrors}
      fullWidth={fullWidth}
      isAccordionForm={isAccordionForm}
    >
      {errorMessagePosition === 'top'
        ? errorElement(errorMessagePosition)
        : null}
      <S.LabelWrapper>
        {label && label !== '' ? (
          <S.Label hasDarkBackground={hasDarkBackground}>
            {required && <span>*</span>} {label}
          </S.Label>
        ) : null}
        {description && description !== '' ? (
          <S.Description> {description}</S.Description>
        ) : null}
      </S.LabelWrapper>
      <S.Select
        ref={wrapperRef}
        hasBorder={hasBorder}
        onClick={() => {
          if (!showOption && inputRef && inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <S.InputWrapper multipleProps={multipleProps}>
          {multiple && multipleSelection.length > 0 ? (
            <S.MultipleSelectedOptionWrapper>
              {multipleSelection.map((option, index) => (
                <S.TagSelectedOption key={`${index}-${option.value}`}>
                  <S.SelectedOptionLabel>{option.label}</S.SelectedOptionLabel>
                  <S.ClearButton
                    type="button"
                    onClick={() => handleSelection(option)}
                  >
                    <FaPlus size={10} />
                  </S.ClearButton>
                </S.TagSelectedOption>
              ))}
            </S.MultipleSelectedOptionWrapper>
          ) : null}
          <S.Input
            id={`search-input-${id}`}
            name={`search-input-${id}`}
            type={fieldType}
            ref={inputRef}
            placeholder={placeholder}
            value={searchInput ?? ''}
            disabled={disabled}
            readOnly={readOnly}
            onChange={(e) => handleSearch(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
            onFocus={() => focusHandler()}
            autoComplete="one-time-code"
            filled={Boolean(selected) && !showOption}
            onBlur={onBlur}
            status={selected && (selected?.label as TextVariantKeys)}
          />
        </S.InputWrapper>
        <S.ClearButton type="button" onClick={clearAll} disabled={disabled}>
          <FaPlus size={12} />
        </S.ClearButton>
        <S.Divider />
        <S.ToggleShowButton
          show={showOption}
          disabled={disabled}
          onClick={() => setShowOption((prev) => !prev)}
        >
          <FaChevronDown />
        </S.ToggleShowButton>
        {showOption ? (
          <S.Options hasDarkBackground={hasDarkBackground}>
            {RenderOption}
          </S.Options>
        ) : null}
      </S.Select>
      {errorMessagePosition === 'bottom'
        ? errorElement(errorMessagePosition)
        : null}
    </S.Wrapper>
  );
};

export default SearchSelect;
