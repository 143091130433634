import styled, { css } from 'styled-components';

type ContainerProps = {
  height?: number;
};

export const Container = styled.div<ContainerProps>`
  ${({ theme }) => css`
    max-height: 36vh;
    padding-right: ${theme.spacing.xs1};
    overflow-y: auto;
  `}

  ::-webkit-scrollbar {
    width: 10px;
    height: 200px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`;
