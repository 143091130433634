import { useIntl } from 'react-intl';

import { BreadcrumbLink } from 'components/shared';

import { Urls } from 'constants/urls';

import {
  BillPrintingOptionsType,
  DocumentsOptionsType,
  ModelOptionsType,
  searchForOptionsType,
} from './types';

export const useConstants = () => {
  const intl = useIntl();

  const documents = [
    {
      label: intl.formatMessage({
        id: 'PaymentSlip.print.optionsDocument.carnet.label',
      }),
      value: DocumentsOptionsType.Carnet,
    },
    {
      label: intl.formatMessage({
        id: 'PaymentSlip.print.optionsDocument.cover.label',
      }),
      value: DocumentsOptionsType.Cover,
    },
    {
      label: intl.formatMessage({
        id: 'PaymentSlip.print.optionsDocument.etiquette.label',
      }),
      value: DocumentsOptionsType.Label,
    },
  ];

  const model = [
    {
      label: intl.formatMessage({
        id: 'PaymentSlip.print.optionsModel.taloner.label',
      }),
      value: ModelOptionsType.Taloner,
    },
    {
      label: intl.formatMessage({
        id: 'PaymentSlip.print.optionsModel.carnetSheet.label',
      }),
      value: ModelOptionsType.CarnetSheet,
    },
  ];

  const billPrinting = [
    {
      label: intl.formatMessage({
        id: 'PaymentSlip.print.optionsBillPrinting.notPrinted.label',
      }),

      value: BillPrintingOptionsType.NotPrinted,
    },
    {
      label: intl.formatMessage({
        id: 'PaymentSlip.print.optionsBillPrinting.reprint.label',
      }),
      value: BillPrintingOptionsType.Reprint,
    },
  ];

  const searchFor = [
    {
      label: intl.formatMessage({
        id: 'PaymentSlip.print.optionsSearchFor.registrations.label',
      }),
      value: searchForOptionsType.Registrations,
    },
    {
      label: intl.formatMessage({
        id: 'PaymentSlip.print.optionsSearchFor.batchCode.label',
      }),
      value: searchForOptionsType.BatchCode,
    },
  ];
  const breadcrumbLinks: BreadcrumbLink[] = [
    {
      label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
      redirectTo: Urls.HomePage,
    },
    {
      label: intl.formatMessage({ id: 'breadcrumb.paymentSlipMenu' }),
      redirectTo: Urls.menuPaymentSlip,
    },
    {
      label: intl.formatMessage({ id: 'breadcrumb.printPaymentSlip' }),
      redirectTo: Urls.printPaymentSlip,
    },
  ];
  return {
    documents,
    model,
    billPrinting,
    searchFor,
    breadcrumbLinks,
  };
};
