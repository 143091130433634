import React from 'react';

import Tooltip from 'components/shared/newCore/Tooltip';

import * as S from './Textarea.styles';

export type TextareaSize = 'default' | 'small';
export interface TextareaProps {
  label: string;
  type?: string;
  helperText?: string;
  id?: string;
  name?: string;
  step?: string;
  min?: number;
  max?: number;
  hasBorder?: boolean;
  hasErrors?: boolean;
  variant: S.InputVariant;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  value: string | number;
  testid?: string;
  required?: boolean;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  cols?: number;
  rows?: number;
  size?: TextareaSize;
}

const Textarea = ({
  label,
  helperText,
  id,
  name,
  min,
  max,
  hasBorder,
  hasErrors,
  variant,
  disabled,
  startAdornment,
  endAdornment,
  onChange,
  value,
  testid,
  required,
  onBlur,
  placeholder,
  cols,
  rows,
  size = 'default',
}: TextareaProps) => (
  <>
    <S.Label className={variant} htmlFor={id} size={size}>
      {required && <span>*</span>} {label}
    </S.Label>
    <S.InputWrapper
      startAdornment={!!startAdornment}
      endAdornment={!!endAdornment}
      hasBorder={hasBorder}
      className={variant}
      disabled={disabled}
      error={hasErrors}
    >
      {startAdornment && (
        <S.StartAdornmentWrapper>{startAdornment}</S.StartAdornmentWrapper>
      )}

      <S.CustomInput
        rows={rows || 3}
        cols={cols}
        hasStartAdornment={!!startAdornment}
        className={variant}
        id={id}
        onBlur={onBlur}
        name={name}
        minLength={min}
        maxLength={max}
        onChange={onChange}
        disabled={disabled}
        value={value}
        data-testid={testid}
        placeholder={placeholder}
      />
      {endAdornment && (
        <S.EndAdornmentWrapper>{endAdornment}</S.EndAdornmentWrapper>
      )}
    </S.InputWrapper>
    {hasErrors && <Tooltip type="error" message={helperText as string} />}
  </>
);

export default Textarea;
