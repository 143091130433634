import styled, { css } from 'styled-components';

export const variantsStyles = {
  outlined: css`
    ${({ theme }) => css`
      background-color: transparent;
      border: ${theme.new.shapes.borderWidth.md} solid
        ${theme.new.colors.primary.main};
      color: ${theme.new.colors.primary.main};

      &:active {
        background-color: ${theme.new.colors.primary.main};
        color: ${theme.new.colors.neutrals['0']};
      }

      &:hover {
        box-shadow: none;
        background-color: ${theme.new.colors.primary.main};
        color: ${theme.new.colors.neutrals['0']};
      }

      &:disabled {
        background-color: transparent;
        opacity: ${theme.new.effects.opacity.xs};
        cursor: not-allowed;
        pointer-events: none;
      }
    `}
  `,
  ghost: css`
    background-color: transparent;
    border: none;

    &:hover {
      box-shadow: unset;
    }

    &:active {
      background-color: transparent;
    }

    &:disabled {
      opacity: ${({ theme }) => theme.new.effects.opacity.xs};
      background-color: unset;
      box-shadow: none;
      cursor: not-allowed;
    }
  `,
  cta: css`
    ${({ theme }) => css`
      color: ${theme.new.colors.primary.main};
      background-color: transparent;
      border: none;
      padding: unset;

      svg {
        margin-right: ${theme.new.spacing.md};
        width: 22px;
        height: auto;
      }

      &:hover {
        box-shadow: unset;
      }

      &:active {
        background-color: transparent;
      }

      &:disabled {
        opacity: ${theme.new.effects.opacity.xs};
        background-color: unset;
        box-shadow: none;
        cursor: not-allowed;
      }
    `}
  `,
};

export const Wrapper = styled.button<{
  variants?: keyof typeof variantsStyles;
  paymentSlipPage?: boolean;
  generatePdf?: boolean;
}>`
  ${({ theme, variants }) => css`
    border: none;
    background-color: ${theme.new.colors.secondary.main};
    color: ${theme.new.colors.primary.main};
    font-size: ${theme.new.typography.size.sm};
    border-radius: ${theme.new.shapes.borderRadius.lg};
    padding: ${theme.new.spacing.lg} ${theme.new.spacing.lg2};
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    transition: all ease-in-out 250ms;

    &:hover {
      box-shadow: 2px 4px 18px ${theme.new.colors.secondary.main}${theme.new.effects.colorOpacity.xs};
    }

    &:active {
      background-color: ${theme.new.colors.secondary.dark};
    }

    &:disabled {
      opacity: ${theme.new.effects.opacity.xs};
      background-color: ${theme.new.colors.secondary.dark};
      box-shadow: none;
      cursor: not-allowed;
    }

    svg {
      margin-left: ${theme.new.spacing.lg};
    }

    ${variants && variantsStyles[variants]}
  `}
  ${({ generatePdf, theme }) =>
    generatePdf &&
    theme &&
    css`
      border: 1px solid ${theme.texts.lightSecondary2};
      color: ${theme.texts.lightSecondary2};
      font-size: ${theme.fontSizes.small};
      line-height: ${theme.fontSizes.medium};
      font-weight: 600;
      padding: 11px 20px;
      text-transform: uppercase;
      background-color: transparent;
      border-radius: 100px;
      cursor: pointer;
      transition: all 250ms;

      &:hover {
        color: #fff;
        background-color: ${({ theme }) => theme.texts.lightSecondary2};
      }

      @media screen and (max-width: 400px) {
        padding: 0.4rem 0.8rem;
      }

      &:disabled {
        opacity: ${theme.new.effects.opacity.xs};
        background-color: transparent;
        box-shadow: none;
        cursor: not-allowed;
      }
    `}

  ${({ paymentSlipPage, theme }) =>
    paymentSlipPage &&
    theme &&
    css`
      width: 100px;
      height: 60px;
      font-size: ${theme.new.typography.size.xs};
    `}
`;
