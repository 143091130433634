export const spacing = {
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  lg1: '18px',
  lg2: '20px',
  xl: '24px',
  xl2: '32px',
  xl3: '40px',
  xl4: '48px',
  xl5: '56px',
  xl6: '64px',
  xl7: '80px',
} as const;
