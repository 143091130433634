import * as S from './breadcrumb.styles';

export interface BreadcrumbLink {
  label: string;
  redirectTo: string;
}

export interface BreadcrumbProps {
  path: BreadcrumbLink[];
}

const Breadcrumb = ({ path }: BreadcrumbProps) => (
  <S.Nav>
    {path.map((item, index, array) => (
      <span key={item.label}>
        <S.CustomLink to={item.redirectTo}>{item.label}</S.CustomLink>
        {index < array.length - 1 && (
          <S.Separator as="span" mx="8px" fontSize="small">
            /
          </S.Separator>
        )}
      </span>
    ))}
  </S.Nav>
);

export default Breadcrumb;
