import {
  Associate,
  AssociateLogRegister,
  AssociateSyncResponse,
  Dependent,
  IChangeExpirationDate,
} from 'types';
import {
  BillingScheduling,
  BillingSchedulingRequest,
} from 'types/BillingScheduling';
import { CampaignRequest } from 'types/Campaigns';

import { getURLWithParams } from 'lib/contextual';

import {
  STATUS_DEPENDENTS,
  STATUS_DEPENDENTS_API,
  STATUS_DEPENDENTS_IDS,
} from 'constants/StatusDependents';
import {
  ASSOCIATES,
  BILLINGS,
  DOWNLOADS,
  PAYMENTS,
  PET_DEPENDENTS_ASSOCIATE,
} from 'constants/endpoints';

import api from './api';

type FetchAllAssociatesSyncResponse<T> = {
  totalItems: number;
  itemCount: number;
  currentPage: number;
  totalPages: number;
  items: T[];
};

type FetchAllAssociatesResponseMeta = {
  totalItems: number;
  itemCount: number;
  currentPage: number;
  totalPages: number;
};

type FetchAllAssociatesResponse<T> = {
  data: T[];
  meta: FetchAllAssociatesResponseMeta;
};

export type AllAssociateItem = {
  id: string;
  subscriptionCode: number;
  name: string;
  cpf: string | null;
  cnpj: string | null;
  personType: 'Fisica' | 'Juridica';
};

export type FetchAllAssociatesParams = {
  pageParam?: number;
  limit?: number;
  subscriptionCode?: number;
  socialSecurityNumber?: string;
  name?: string;
  dependantName?: string;
  dependentSocialSecurityNumber?: string;
  phone?: string;
  oldContractNumber?: number;
  nationalIdentityCard?: string;
  companyName?: string;
  address?: string;
  neighborhood?: string;
  contractDate?: string;
  status?: STATUS_DEPENDENTS_IDS;
};

export type DownloadAssociateSlipsParams = {
  subscriptionCode: number;
  references: string[];
  branchAccountId?: number;
};

interface PaginationResponse<T> {
  items: T[];
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export const associates = () => {
  const getAllAssociates = async (
    params?: FetchAllAssociatesParams
  ): Promise<FetchAllAssociatesResponse<AllAssociateItem>> => {
    params = {
      pageParam: 1,
      limit: 50,
      ...params,
    };

    const url = getURLWithParams(ASSOCIATES.GET_ALL_ASSOCIATES, params);
    const response = await api.getRequest(url);

    return response.data;
  };

  const getPaginatedAssociates = async (
    params?: FetchAllAssociatesParams
  ): Promise<PaginationResponse<AllAssociateItem>> => {
    params = {
      pageParam: 1,
      limit: 50,
      ...params,
    };

    const url = getURLWithParams(ASSOCIATES.ALL_SYNC, params);
    const response = await api.getRequest(url);

    return response.data;
  };

  const getAllAssociatesSync = async (
    page = 1,
    params?: FetchAllAssociatesParams
  ): Promise<FetchAllAssociatesSyncResponse<AssociateSyncResponse>> => {
    const url = getURLWithParams(ASSOCIATES.ALL_ASSOCIATES_SYNC, {
      ...params,
      page,
      limit: 50,
    });
    const response = await api.getRequest(url);

    return response.data;
  };

  const getOneAssociatesSync = async (
    subscriptionCode: string
  ): Promise<Associate> => {
    const url = getURLWithParams(ASSOCIATES.FIND_ONE_ASSOCIATE_SYNC, {
      subscriptionCode,
    });
    const response = await api.getRequest(url);

    return response.data;
  };

  const getAssociatesDependentsSync = async ({
    subscriptionCode,
    pageParam = 1,
    limit = 50,
    name,
    onlyHuman = false,
  }: {
    subscriptionCode: string;
    pageParam?: number;
    limit?: number;
    name?: string;
    onlyHuman?: boolean;
  }): Promise<FetchAllAssociatesSyncResponse<Dependent>> => {
    const url = getURLWithParams(
      ASSOCIATES.FIND_ALL_ASSOCIATE_DEPENDENTS_SYNC(subscriptionCode),
      {
        page: pageParam,
        limit,
        name,
        onlyHuman,
      }
    );
    const response = await api.getRequest(url);

    return response.data;
  };

  const getAssociatesDependentV3 = async ({
    subscriptionCode,
    pageParam = 1,
    limit = 50,
    name = '',
    onlyHuman = false,
    status = [],
  }: {
    subscriptionCode: string;
    pageParam?: number;
    limit?: number;
    name?: string;
    onlyHuman?: boolean;
    status?: STATUS_DEPENDENTS[];
  }): Promise<{
    items: Dependent[];
    meta: FetchAllAssociatesResponseMeta;
  }> => {
    const statusToApi = status.map((item) => STATUS_DEPENDENTS_API[item]);

    const url = getURLWithParams(
      ASSOCIATES.GET_DEPENDENT_V3(subscriptionCode),
      {
        page: pageParam,
        limit,
        ...(name.length > 2 && { name }),
        onlyHuman,
        ...(statusToApi.length && { status: statusToApi }),
      }
    );
    const response = await api.getRequest(url);

    return response.data;
  };

  return {
    getAllAssociates,
    getPaginatedAssociates,
    getAllAssociatesSync,
    getOneAssociatesSync,
    getAssociatesDependentsSync,
    getAssociatesDependentV3,
  };
};

export const getBillingSchedulingBySubscription = async (
  data: BillingSchedulingRequest
): Promise<BillingScheduling[]> => {
  const response = await api.getRequest(
    BILLINGS.BILLING_SCHEDULING(data.subscription),
    {
      params: {
        getOpenedLastWeek: data.getOpenedLastWeek,
      },
    }
  );
  return response?.data;
};

export const getAssociateCampaignsBySubscription = async (
  data: CampaignRequest
) => {
  try {
    const response = await api.getRequest(
      ASSOCIATES.GET_CAMPAIGNS_BY_ID(data.subscription),
      {
        params: {
          fetchActive: data.fetchActive,
        },
      }
    );
    return response?.data;
  } catch (e) {
    throw e;
  }
};

export const associateHistoryLogs = () => {
  const getAssociateHistoryLogsBySubscriptionCode = async (
    subscriptionCode?: number
  ): Promise<AssociateLogRegister[]> => {
    const url = getURLWithParams(ASSOCIATES.FIND_HISTORY_BY_SUBSCRIPTION_CODE, {
      subscriptionCode,
    });

    const response = await api.getRequest(url);
    return response?.data;
  };
  return {
    getAssociateHistoryLogsBySubscriptionCode,
  };
};

export const createPetDependent = async (
  body: any,
  subscriptionCode: string
) => {
  try {
    await api.postRequest(
      PET_DEPENDENTS_ASSOCIATE.CREATE(subscriptionCode),
      body,
      {
        headers: {
          transactionId: 'create-associate-dependents',
        },
      }
    );
  } catch (error: any) {
    return error.response.status;
  }
};

export const updatePetDependent = async (
  body: any,
  subscriptionCode: string
) => {
  try {
    const response = await api.patchRequest(
      PET_DEPENDENTS_ASSOCIATE.UPDATE(subscriptionCode),
      body,
      {
        headers: {
          transactionId: 'update-associate-dependents',
        },
      }
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const associateDetails = async (
  subscriptionCode: string,
  getCardData: boolean = false
): Promise<Associate> => {
  const url = ASSOCIATES.DETAILS(subscriptionCode, getCardData);
  try {
    const response = await api.getRequest(url);
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const associateDependentsCategories = () => {
  const getAssociateDependentsCategories = async (subscriptionCode: string) => {
    const url = ASSOCIATES.LIST_DEPENDENTS_CATEGORIES(subscriptionCode);
    const response = await api.getRequest(url);
    return response?.data;
  };

  return {
    getAssociateDependentsCategories,
  };
};

export const changeExpirationDate = (payload: IChangeExpirationDate) =>
  api.putRequest(PAYMENTS.CHANGE_DATE_EXPIRATION, payload);

export const getSubscriptionUpSell = async ({
  subscription,
  upsell,
}: {
  subscription: string;
  upsell: boolean;
}) => {
  try {
    return await api.getRequest(
      BILLINGS.GET_SUBSCRIPTION_UPSELL(subscription, upsell)
    );
  } catch (err) {
    throw err;
  }
};

export const patchSubscriptionUpSell = async (subscription: string) => {
  try {
    return await api.patchRequest(
      BILLINGS.PATCH_SUBSCRIPTION_UPSELL(subscription)
    );
  } catch (err) {
    throw err;
  }
};

export const downloadAssociateBankSlips = async (
  payload: DownloadAssociateSlipsParams
): Promise<{
  bankSlipUrl: string;
}> => {
  try {
    const response = await api.postRequest(
      DOWNLOADS.BILLET(payload.subscriptionCode.toString()),
      {
        references: payload.references,
        branchAccountId: payload.branchAccountId,
      }
    );
    return response?.data;
  } catch (e) {
    throw e;
  }
};
