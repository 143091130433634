const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_API_URL_VENDAS = process.env.REACT_APP_BASE_API_URL_VENDAS;
const userPrefix = 'user-manager';
const associatePrefix = 'associate-manager';
const installmentPrefix = 'installment-manager';
const contractPrefix = 'contract-manager';
const paymentPrefix = 'payment-method';
const cartPrefix = 'cart';
const authPrefix = 'auth';
const affiliatePrefix = 'affiliate-manager';
const pdfPrefix = 'pdfs';
const touchPoint = 'zelo-touchpoint';
const deliveryManager = 'zelo-delivery';
const documentPrefix = 'service-documents';
const employerPrefix = 'employer-manager';
const billingsPrefix = 'billings';
const historicalApprovals = 'historical-approvals';
const approverSync = 'approvers/sync';
const approver = 'approvers';
const afterSales = 'service-after-sales';
const schedulesCollectorPrefix = `service-collector`;
const geolocationPrefix = 'locations';
const financialPrefix = 'finance-department';
const columbariumPrefix = 'columbarium';
const customerAttendanceReportsPrefix = 'customer-attendance-reports';
const orthopedicPrefix = 'orthopedic-support';
const bankShieldPrefix = 'bank-shield';
const depositOfReceiptsPrefix = 'service-deposito-boletos';
const serviceFuneralHomePrefix = 'service-funeral-home';
const serviceWorkOrderPrefix = 'service-work-order';
const serviceFee = 'service-fee';
const bankSlipService = 'service-bank-slips';
const associateNewPrefix = 'service-associate';
const serviceFuneralManagerPrefix = 'service-funeral-manager';

export const CONTENT_HOST_HEADER = 'http-middleware-api';

export const TOUCHPOINT = {
  GETASSOCIATES: `${BASE_API_URL}/${touchPoint}/associates`,
  POSTTRANSACTION: `${BASE_API_URL}/${touchPoint}/transactions`,
  GETTRANSACTION: `${BASE_API_URL}/${touchPoint}/transactions`,
  GETREPORTS: `${BASE_API_URL}/${touchPoint}/reports/transactions`,
};

export const AUTH = {
  SIGN_IN: `${BASE_API_URL}/${userPrefix}/${authPrefix}/signin`,
  REFRESH: `${BASE_API_URL}/${userPrefix}/${authPrefix}/refresh-token`,
  FORGOT_PASSWORD: `${BASE_API_URL}/${userPrefix}/${authPrefix}/forgot-password`,
  CHANGE_PASSWORD: `${BASE_API_URL}/${userPrefix}/${authPrefix}/change-password`,
  LOGOUT: `${BASE_API_URL}/${userPrefix}/${authPrefix}/signout`,
};

export const USERS = {
  ALL: `${BASE_API_URL}/${userPrefix}/users/list-users`,
  ALL_SYNC: `${BASE_API_URL}/${userPrefix}/users/list-users/sync`,
  BY_EMAIL: (email: string) =>
    `${BASE_API_URL}/${userPrefix}/users/${email}/show-user`,
  BY_EMAIL_SYNC: (email: string) =>
    `${BASE_API_URL}/${userPrefix}/users/find-by-email/${email}/sync`,
  POST: `${BASE_API_URL}/${userPrefix}/users`,
  EDIT: `${BASE_API_URL}/${userPrefix}/users`,
};

export const ASSOCIATES = {
  ALL: `${BASE_API_URL}/${associatePrefix}/associates/all`,
  ALL_SYNC: `${BASE_API_URL}/${associatePrefix}/associates/all/sync`,
  DETAILS: (subscriptionCode: string, getCardData: boolean = false) =>
    `${BASE_API_URL}/${associatePrefix}/api/associates/sync?subscriptionCode=${subscriptionCode}&getCardData=${getCardData}`,
  CEP: (cep: string) => `${BASE_API_URL}/${associatePrefix}/cep/${cep}`,
  EDIT: `${BASE_API_URL}/${associatePrefix}/associates`,
  CREATE: (id: string) =>
    `${BASE_API_URL}/${associatePrefix}/associates/${id}/dependants`,
  GET_DEPENDENT: (id: string) =>
    `${BASE_API_URL}/${associatePrefix}/associates/${id}/dependants`,
  GET_DEPENDENT_V3: (id: string) =>
    `${BASE_API_URL}/${associateNewPrefix}/api/associates/${id}/dependants`,
  FIND: `${BASE_API_URL}/${associatePrefix}/associates`,
  UPDATE_GRACE_PERIOD: `${BASE_API_URL}/${associatePrefix}/associates/update-funeral-grace-period`,
  CANCEL_PLAN: `${BASE_API_URL}/${associatePrefix}/associates/:id/cancel-plan`,
  CHANGE_OWNERSHIP: () =>
    `${BASE_API_URL}/${associatePrefix}/associates/:id/change-ownership?user=:userName`,
  CHANGE_OWNERSHIP_FROM_DEPENDENT: () =>
    `${BASE_API_URL}/${associatePrefix}/associates/:id/change-ownership?dependantId=:dependantId&user=:userName&active=:active&kinship=:kinship`,
  LIST_CATEGORIES: `${BASE_API_URL}/${associatePrefix}/category`,
  LIST_DEPENDENTS_CATEGORIES: (subscriptionCode: string) =>
    `${BASE_API_URL}/${contractPrefix}/api/products/dependents/${subscriptionCode}/categories`,
  LIST_DELIVERIES: `${BASE_API_URL}/${associatePrefix}/delivery/all`,
  LIST_FEES: `${BASE_API_URL}/${associatePrefix}/associates/fees/all`,
  DEATH_HISTORY: (id: string) =>
    `${BASE_API_URL}/${associatePrefix}/death-history/${id}`,
  LIST_GROUPS: `${BASE_API_URL}/${associatePrefix}/group`,
  LIST_SUB_GROUPS: `${BASE_API_URL}/${associatePrefix}/subgroup`,
  DOWNLOAD_MEMBERSHIP_CARD: `${BASE_API_URL}/${pdfPrefix}/api/pdf/membershipCard`,
  DOWNLOAD_PDF_CONTRACT: (subscriptionCode: string) =>
    `${BASE_API_URL}/${associatePrefix}/api/associates/${subscriptionCode}/dependants/reports`,
  DOWNLOAD_PDF_FINANCIAL_STATEMENT: `${BASE_API_URL}/${associatePrefix}/api/associates/financial-statements`,
  VERIFY_ASSOCIATE_CHANGES: (subscriptionCode: string) =>
    `${BASE_API_URL}/${associatePrefix}/associates/verify-associate/${subscriptionCode}/sync`,
  VERIFY_DEPENDENTS_CHANGES: (subscriptionCode: string) =>
    `${BASE_API_URL}/${associatePrefix}/api/associates/${subscriptionCode}/dependants/verify-dependents/sync`,
  REGISTER_HISTORY: `${BASE_API_URL}/${associatePrefix}/api/history/create-history`,
  FIND_HISTORY: `${BASE_API_URL}/${associatePrefix}/api/history/sync`,
  FIND_HISTORY_BY_SUBSCRIPTION_CODE: `${BASE_API_URL}/${associatePrefix}/history/by-subscription/sync`,
  LIST_COLLECTORS: `${BASE_API_URL}/${associatePrefix}/api/collectors`,
  ALL_ASSOCIATES_SYNC: `${BASE_API_URL}/${associateNewPrefix}/api/associates`,
  GET_NEW_CLIENTS: `${BASE_API_URL}/${contractPrefix}/api/fresh-contracts`,
  GET_PDF_NEW_CLIENTS: `${BASE_API_URL}/${contractPrefix}/api/fresh-contracts/download`,
  ASSOCIATE_SYNC: `${BASE_API_URL}/${associatePrefix}/api/associates/sync`,
  FIND_ONE_ASSOCIATE_SYNC: `${BASE_API_URL}/${associatePrefix}/api/associates/sync`,
  FIND_ALL_ASSOCIATE_DEPENDENTS_SYNC: (subscriptionCode: string) =>
    `${BASE_API_URL}/${associatePrefix}/associates/${subscriptionCode}/dependants/sync`,
  GET_PAGINATED_SUBSIDIARIES: `${BASE_API_URL}/${associatePrefix}/api/branches/all/sync`,
  GET_ALL_ASSOCIATES: `${BASE_API_URL}/service-billing/api/${billingsPrefix}/associates`,
  BILLING_SCHEDULING: (subscription: number) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/${subscription}/schedule`,
  GET_CAMPAIGNS_BY_ID: (subscription: number) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/campaigns/${subscription}/schedules`,
  GET_ALL_INSTALLMENTS: (subscription: string) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/associates/${subscription}/monthly-fees?pastDueAndFuture=true`,
  CARD_REGISTER: (subscription: string) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/associates/${subscription}/card/register`,
  FIND_BY_CRITERIA: `${BASE_API_URL}/${associatePrefix}/associates/find-by/criteria/sync`,
  PATCH_OBSERVATION: () =>
    `${BASE_API_URL}/${associatePrefix}/api/associates/sync`,
};

export const PRODUCTS = {
  ALL_AVAILABLE: `${BASE_API_URL}/${contractPrefix}/api/products/avaliable`,
  BRANCHES_BY_GROUP: (group: string) =>
    `${BASE_API_URL}/api/${contractPrefix}/products/branch/${group}`,
  LIST_INSTALLMENTS: `${BASE_API_URL}/${contractPrefix}/api/products/installments`,
};

export const MONTHLYFEE = {
  MONTHLYFEE_BY_REFERENCE_UPDATE: (subscription: string) =>
    `${BASE_API_URL}/${installmentPrefix}/monthlyfee/sync/update-value-by-references/${subscription}`,
  MONTHLYFEE_BY_SUBSCRIPTION: (subscription: string) =>
    `${BASE_API_URL}/${contractPrefix}/api/monthly-fees/${subscription}`,
  MONTHLYFEE_BY_SUBSCRIPTION_AND_REFERENCE: (
    subscription: string,
    reference: string
  ) =>
    `${BASE_API_URL}/${contractPrefix}/api/monthly-fees/${subscription}/${reference}`,
  MONTHLYFEE_BY_SUBSCRIPTION_AND_REFERENCE_DELETE: (
    subscription: string,
    reference: string
  ) =>
    `${BASE_API_URL}/${paymentPrefix}/installments/associate/${subscription}/${reference}`,
};

export const SUBSCRIPTIONS = {
  V2_BY_CODE: (subscription: string) =>
    `${BASE_API_URL}/${contractPrefix}/api/v2/subscriptions/${subscription}`,
  V2_BY_CODE_AND_INSTALLMENT: (subscription: string, installment: string) =>
    `${BASE_API_URL}/${contractPrefix}/api/v2/subscriptions/${subscription}/${installment}`,
};

export const DEPENDENTS_ASSOCIATE = {
  ALL_DEPENDANTS: `${BASE_API_URL}/${associatePrefix}/associates/:id/dependants`,
  CREATE: (id: string) =>
    `${BASE_API_URL}/${associatePrefix}/associates/${id}/dependants`,
  UPDATE: (id: string) =>
    `${BASE_API_URL}/${associatePrefix}/associates/${id}/dependants`,
  GET_ONE: (id: string, dependentId: string) =>
    `${BASE_API_URL}/${associatePrefix}/associates/${id}/dependants/${dependentId}`,
};

export const PET_DEPENDENTS_ASSOCIATE = {
  ALL_PET_DEPENDANTS: `${BASE_API_URL}/${associatePrefix}/api/associates/:id/pets/sync`,
  CREATE: (id: string) =>
    `${BASE_API_URL}/${associatePrefix}/api/associates/${id}/pets/sync`,
  UPDATE: (id: string) =>
    `${BASE_API_URL}/${associatePrefix}/api/associates/${id}/pets/sync`,
  GET_ONE: (id: string, dependentId: string) =>
    `${BASE_API_URL}/${associatePrefix}/api/associates/${id}/pets/${dependentId}/sync`,
};

export const INSTALLMENTS = {
  ACTIVE: (id: string) =>
    `${BASE_API_URL}/${installmentPrefix}/installments/${id}/active`,
  CREATE: `${BASE_API_URL}/${installmentPrefix}/installments`,
  UPDATE: `${BASE_API_URL}/${installmentPrefix}/installments`,
  PAYMENTS_HISTORY: `${BASE_API_URL}/${installmentPrefix}/payments-history`,
  PAYMENTS_HISTORY_PDF_REPORT: `${BASE_API_URL}/${installmentPrefix}/payments-history/pdf-report`,
  PAYMENT_HISTORY_DETAIL: `${BASE_API_URL}/${installmentPrefix}/payments-history/report`,
  PAYMENTS_REGISTER: `${BASE_API_URL}/${installmentPrefix}/payments-history/register`,
  PAYMENTS_CHARGES: `${BASE_API_URL}/${paymentPrefix}/api/charges`,
  PAYMENTS_NO_CHARGES: `${BASE_API_URL}/${paymentPrefix}/payment-methods/no-charges`,
  FETCH_CHARGES: `${BASE_API_URL}/${paymentPrefix}/api/charges/list-many-references`,
  BOX_DATA: `${BASE_API_URL}/${installmentPrefix}/installments/:id/get-all-cash-flow`,
  CLOSE_BOX: `${BASE_API_URL}/${installmentPrefix}/installments/:id/close-installment`,
  CASH_REVERSAL: (id: string) =>
    `${BASE_API_URL}/${installmentPrefix}/cash-reversal/${id}/list-low-cash`,
  MAKE_CASH_REVERSAL: `${BASE_API_URL}/${installmentPrefix}/cash-reversal/reverse-payment`,
  CHANGE_PRICE: `${BASE_API_URL}/${installmentPrefix}/monthlyfee/update-value-by-period`,
  LAUNCH: `${BASE_API_URL}/${installmentPrefix}/monthlyfee`,
  CASH_REPORT: `${BASE_API_URL}/${installmentPrefix}/payments-history/cashier-closing`,
  CASH_REPORT_SYNC: `${BASE_API_URL}/${installmentPrefix}/payments-history/sync/cashier-closing`,
  DOWNLOAD_CASH_REPORT_SYNC: `${BASE_API_URL}/${installmentPrefix}/payments-history/sync/file`,
  DOWNLOAD_CASH_REPORT: `${BASE_API_URL}/${installmentPrefix}/payments-history/file`,
  TUITION_ADJUSTMENTS: `${BASE_API_URL}/${installmentPrefix}/parameter-readjustment/sync`,
  FETCH_APPROVALS: `${BASE_API_URL}/${installmentPrefix}/historical-approvals`,
  WITHOUT_CASH_FLOW: `${BASE_API_URL}/${paymentPrefix}/payment-methods/sync/without-cash-flow`,
};

export const DOWNLOADS = {
  GERENCIANET_BILLET: `${BASE_API_URL}/${paymentPrefix}/payment-methods/bank-slip/activate`,
  BILLET: (subscription: string) =>
    `${BASE_API_URL}/${paymentPrefix}/installments/associate/${subscription}/bank-slips`,
  BILLET_EXPIRED: `${BASE_API_URL}/${paymentPrefix}/payment-methods/bank-slip/generate-expired`,
};

export const CART = {
  LIST: `${BASE_API_URL}/${cartPrefix}/api/shopCart/:id/list-all-carts`,
  LIST_ITEMS: (id: string) =>
    `${BASE_API_URL}/${cartPrefix}/api/shopCart/list-items-of-cart/${id}`,
  ADD_ITEM: `${BASE_API_URL}/${cartPrefix}/api/shopCart`,
  DELETE_ITEM: (idCart: string, idItem: string) =>
    `${BASE_API_URL}/${cartPrefix}/api/shopCart/${idCart}/delete-cart-item/${idItem}`,
  CHECKOUT: `${BASE_API_URL}/${cartPrefix}/api/shopCart/checkout/:id`,
};

export const PAYMENTS = {
  CHANGE: `${BASE_API_URL}/${paymentPrefix}/payment-methods/change`,
  MACHINE: `${BASE_API_URL}/${paymentPrefix}/payment-methods/terminal/request-payment`,
  PRINT: `${BASE_API_URL}/adyen-adapter/receipts-printing/sync/cash-receipts-printing`,
  CHANGE_DATE_EXPIRATION: `${BASE_API_URL}/${paymentPrefix}/payment-methods/expiration-date/update`,
};

export const BANK_SHIELD = {
  BANK_SHIELD: (subscriptionCode: string) =>
    `${BASE_API_URL}/${bankShieldPrefix}/v1/debit-account?subscriptionCode=${subscriptionCode}&status=true`,
};

export const PLANS = {
  CHANGE: `${BASE_API_URL}/${associatePrefix}/associates/change-sub-group`,
};

export const CASH_WITHDRAWALS = {
  REGISTER: `${BASE_API_URL}/${installmentPrefix}/cash-withdrawals`,
  SERVICES: `${BASE_API_URL}/${installmentPrefix}/withdrawals`,
  SUMMARY_CASH_OUTFLOWS: (userId: string) =>
    `${BASE_API_URL}/${installmentPrefix}/installments/${userId}/get-all-cash-flow`,
  SUMMARY_CASH_OUTFLOWS_SYNC: (userId: string) =>
    `${BASE_API_URL}/${installmentPrefix}/installments/${userId}/get-all-cash-flow/sync`,
  HISTORY_CASH_OUTFLOWS: (userId: string) =>
    `${BASE_API_URL}/${installmentPrefix}/withdrawals/${userId}/withdrawals-history`,
  HISTORY_CASH_OUTFLOWS_SYNC: (userId: string) =>
    `${BASE_API_URL}/${installmentPrefix}/withdrawals/sync/${userId}/withdrawals-history`,
  URL_PROOF_OF_DEPOSIT: (installmentId: string) =>
    `${BASE_API_URL}/${installmentPrefix}/cash-withdrawals/${installmentId}/urls`,
  ADD_TRANSACTION_ATTACHMENT: (installmentId: string, transactionId: string) =>
    `${BASE_API_URL}/${installmentPrefix}/cash-withdrawals/sync/${installmentId}/transactions/${transactionId}/attachments`,

  SISFUNER_SUMMARY_CASH_OUTFLOWS: (userId: string) =>
    `${BASE_API_URL}/${installmentPrefix}/funeral-installments/${userId}/get-all-cash-flow`,
};

export const OBSERVATIONS = {
  ALL: `${BASE_API_URL}/${contractPrefix}/observations`,
  POST: `${BASE_API_URL}/${contractPrefix}/observations`,
};

export const BRANCHES = {
  ALL: `${BASE_API_URL}/${affiliatePrefix}/affiliates`,
  POST: `${BASE_API_URL}/${affiliatePrefix}/affiliates`,
  BY_ID: (id: string) => `${BASE_API_URL}/${affiliatePrefix}/affiliates/${id}`,
  BY_ID_SYNC: (id: string) =>
    `${BASE_API_URL}/${affiliatePrefix}/affiliates/sync/${id}`,
  BY_NAME_CITY: (params: string) =>
    `${BASE_API_URL}/${affiliatePrefix}/affiliates/city-or-name/${params}`,
  UPDATE: (id: string) => `${BASE_API_URL}/${affiliatePrefix}/affiliates/${id}`,
};

export const MACHINE_TERMINAL = {
  ALL: `${BASE_API_URL}/${affiliatePrefix}/terminals`,
  POST: `${BASE_API_URL}/${affiliatePrefix}/terminals`,
  DELETE: (id: string) => `${BASE_API_URL}/${affiliatePrefix}/terminals/${id}`,
};

export const BANK_SLIP = {
  GET_BANKS: `${BASE_API_URL}/${bankSlipService}`,
  GET_BATCHES: `${BASE_API_URL}/${bankSlipService}/batches`,
  POST_CREATE_BATCH: `${BASE_API_URL}/${bankSlipService}/batches`,
  EXECUTE_BATCH_PROCESS: (id: string) =>
    `${BASE_API_URL}/${bankSlipService}/batches/${id}/execute`,
  GET_BATCHES_DOWNLOAD: (batchId: string) =>
    `${BASE_API_URL}/${bankSlipService}/batches/count-subscription?code=${batchId}`,
  GET_TEMPLATE_FILE: `${BASE_API_URL}/${bankSlipService}/temp-files/new-associate-template-file`,
  POST_DOWNLOAD_CARNET_PDF: `${BASE_API_URL}/${bankSlipService}/printers/bank-slips`,
  POST_DOWNLOAD_LABELS_PDF: `${BASE_API_URL}/${bankSlipService}/printers/labels`,
  POST_DOWNLOAD_COVERS_PDF: `${BASE_API_URL}/${bankSlipService}/printers/covers`,
  GET_BATCHES_DOWNLOAD_PDF: (fileName: string) =>
    `${BASE_API_URL}/${bankSlipService}/printers/${fileName}?retries=${Date.now()}`,
};

export const ONLINE = {
  ONLINE_BATCHES: `${BASE_API_URL}/${bankSlipService}/batch-bank-slip-mailings`,
  ONLINE_BATCH: (id: string) =>
    `${BASE_API_URL}/${bankSlipService}/batch-bank-slip-mailings/${id}/execute`,
};

export const CITY = {
  GET_ALL: `${BASE_API_URL}/locations/api/cities`,
  GET_BY_NAME: `${BASE_API_URL}/locations/api/cities/by-name`,
  GET_BY_UF: `${BASE_API_URL}/locations/api/cities/by-state`,
};

export const CITY_GROUP = {
  GET_ALL: `${BASE_API_URL}/service-bank-slips/city-groups`,
  GET_BY_ID: (id: string) =>
    `${BASE_API_URL}/service-bank-slips/city-groups/${id}`,
  PATCH: (id: string) => `${BASE_API_URL}/service-bank-slips/city-groups/${id}`,
  POST: `${BASE_API_URL}/service-bank-slips/city-groups`,
};

export const COMPANY = {
  GET_ALL: `${BASE_API_URL}/${customerAttendanceReportsPrefix}/api/companies`,
};

export const DELIVERY_MANAGER = {
  ASSOCIATES_PAGINATED: `${BASE_API_URL}/${deliveryManager}/associates`,
  ASSOCIATES_ONE: (subscription: string) =>
    `${BASE_API_URL}/${deliveryManager}/associates/${subscription}`,
  COLLECTORS_PAGINATED: `${BASE_API_URL}/${deliveryManager}/collectors`,
  COLLECTORS_ONE: (code: string) =>
    `${BASE_API_URL}/${deliveryManager}/collectors/${code}`,
  COLLECTORS_ORDERS: (code: string) =>
    `${BASE_API_URL}/${deliveryManager}/collectors/${code}/orders`,
  DELIVERIES_ALL: `${BASE_API_URL}/${deliveryManager}/deliveries`,
  DELIVERIES_ONE: (id: string) =>
    `${BASE_API_URL}/${deliveryManager}/deliveries/${id}`,
  DELIVERIES_TYPES: `${BASE_API_URL}/${deliveryManager}/deliveries-types?page=1&limit=100`,
  DELIVERIES_ITEMS_BY_ID: (id: string) =>
    `${BASE_API_URL}/${deliveryManager}/deliveries-items/delivery/${id}`,
  DELIVERIES_ITEMS_CREATE_BY_ID_AND_SUBSCRIPTION: (
    id: string,
    subscription: string
  ) =>
    `${BASE_API_URL}/${deliveryManager}/deliveries-items/delivery/${id}/${subscription}`,
};

export const DOCUMENTS = {
  ASSOCIATE_DOCUMENTS: (id: string) =>
    `${BASE_API_URL}/${documentPrefix}/api/v1/associates/${id}`,
  DOWNLOAD_DOCUMENTS: (documentId: string, associateId: string) =>
    `${BASE_API_URL}/${documentPrefix}/api/v1/associates/${associateId}/${documentId}`,
  UPLOAD_DOCUMENTS: (subscription: string) =>
    `${BASE_API_URL}/${documentPrefix}/api/v1/associates/${subscription}/upload`,
  CHECK_SUM: (subscription: string) =>
    `${BASE_API_URL}/${documentPrefix}/api/v1/associates/${subscription}/checksum`,

  GET_WORK_ORDER_DOCUMENTS: (workOrderId: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${documentPrefix}/api/v1/funeral-homes/${funeralHomeId}/work-orders/${workOrderId}`,
  UPLOAD_WORK_ORDER_DOCUMENTS: (workOrderId: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${documentPrefix}/api/v1/funeral-homes/${funeralHomeId}/work-orders/${workOrderId}`,
};

export const EMPLOYERS_MANAGER = {
  COORDINATORS_PAGINATED: `${BASE_API_URL}/${employerPrefix}/api/v1/coordinators`,
  COORDINATORS_ONE: (id: string) =>
    `${BASE_API_URL}/${employerPrefix}/api/v1/coordinators/${id}`,
  MANAGERS_PAGINATED: `${BASE_API_URL}/${employerPrefix}/api/v1/managers`,
  MANAGERS_ONE: (id: string) =>
    `${BASE_API_URL}/${employerPrefix}/api/v1/managers/${id}`,
  SUPERVISORS_PAGINATED: `${BASE_API_URL}/${employerPrefix}/api/v1/supervisors`,
  SUPERVISORS_ONE: (id: string) =>
    `${BASE_API_URL}/${employerPrefix}/api/v1/supervisors/${id}`,
  SELLERS_PAGINATED: `${BASE_API_URL}/${employerPrefix}/api/v1/sellers`,
  SELLERS_ONE: (id: string) =>
    `${BASE_API_URL}/${employerPrefix}/api/v1/sellers/${id}`,
};

export const TUITION_READJUSTMENT = {
  TUITION_READJUSTMENT_UPDATE_STATUS_HISTORICAL_APPROVAL: (
    subscription: string
  ) =>
    `${BASE_API_URL}/${installmentPrefix}/${historicalApprovals}/${subscription}`,
  TUITION_READJUSTMENT_RETRIVE_STATUS_HISTORICAL_APPROVAL: `${BASE_API_URL}/${installmentPrefix}/${historicalApprovals}`,
  CREATE_BRANCH_APPROVER: () =>
    `${BASE_API_URL}/${associatePrefix}/${approverSync}`,
  GET_FIT_APPROVER: `${BASE_API_URL}/${associatePrefix}/${approver}/all`,
};

export const BILLINGS = {
  GET_CALL_STATUSES: () =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/campaigns/call-statuses`,
  PAYMENTS_METHOD: (subscription: string, type: 'url' | 'slip' | 'pix') =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/payments/${subscription}/${type}`,
  SEND_SLIP_BY_EMAIL: (subscription: string) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/payments/${subscription}/slip/email`,
  GET_ALL_REPORT_INQUIRIES: () =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/reports/campaign/current`,
  BILLING_SCHEDULING: (subscription: string) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/schedules/${subscription}`,
  GET_CAMPAIGNS_BY_ID: (subscription: number) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/campaigns/${subscription}/schedules`,
  GET_MY_SCHEDULES: `${BASE_API_URL}/service-billing/api/${billingsPrefix}/reports/schedule`,
  POST_REPORT_COMISSION: `${BASE_API_URL}/service-billing/api/${billingsPrefix}/reports/comission`,
  GET_REPORT_COMISSION: (fileName: string, seed: number) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/reports/comission/${fileName}?noCache=${seed}`,
  GET_REPORT_BILLING_ELETRONIC: `${BASE_API_URL}/service-billing/api/${billingsPrefix}/reports/schedule/urls`,
  GET_USERS: `${BASE_API_URL}/service-billing/api/${billingsPrefix}/users/list`,
  PATCH_AVAILABILITY: (
    subscription: string,
    status: 'available' | 'unavailable'
  ) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/associates/${subscription}/availability/${status}`,
  GET_SUBSCRIPTION_UPSELL: (subscription: string, upsell: boolean) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/associates/${subscription}/upsell/${upsell}`,
  GET_REPORT_UPSELL: `${BASE_API_URL}/service-billing/api/${billingsPrefix}/associates/reportUpsell`,
  PATCH_SUBSCRIPTION_UPSELL: (subscription: string) =>
    `${BASE_API_URL}/service-billing/api/${billingsPrefix}/associates/${subscription}/validationUpsell`,
};

export const MAILING_EXPORT = {
  GET_BATCHES_ARCHIVE: (
    startDate: string,
    endDate: string,
    selectAll: boolean
  ) =>
    `${BASE_API_URL}/${afterSales}/assignments?startDate=${startDate}&endDate=${endDate}&selectAll=${selectAll}`,
  POST_BATCHES_ARCHIVE: `${BASE_API_URL}/${afterSales}`,
};

export const BILLING_AT_HOME = {
  GET_COLLECTORS: `${BASE_API_URL}/${schedulesCollectorPrefix}/api/agents`,
  GET_SCHEDULABLES: `${BASE_API_URL}/${schedulesCollectorPrefix}/api/schedulables`,
  GET_UZO: `${BASE_API_URL}/${schedulesCollectorPrefix}/api/uzo/agent`,
  POST_SCHEDULE: `${BASE_API_URL}/${schedulesCollectorPrefix}/api/schedules`,
  POST_UZO: `${BASE_API_URL}/${schedulesCollectorPrefix}/api/uzo/agent`,
  PATCH_OPEN_SCHEDULE: (id: number) =>
    `${BASE_API_URL}/${schedulesCollectorPrefix}/api/schedules/${id}/open`,
  PATCH_UZO: () =>
    `${BASE_API_URL}/${schedulesCollectorPrefix}/api/uzo/actions/synchronize`,
  GET_SCHEDULES: `${BASE_API_URL}/${schedulesCollectorPrefix}/api/schedules`,
  GET_SCHEDULES_ORDERS: `${BASE_API_URL}/${schedulesCollectorPrefix}/api/schedules-orders`,
  CREATE_REPORT: (reportName: string) =>
    `${BASE_API_URL}/${schedulesCollectorPrefix}/api/schedules-orders/reports/${reportName}`,
  GET_REPORT_FILE_PRESIGNED_URL: (fileName: string) =>
    `${BASE_API_URL}/${schedulesCollectorPrefix}/api/schedules-orders/files/${fileName}/download?retry=${Date.now().toString()}`,
  GET_SCHEDULES_BY_ID: (id: number) =>
    `${BASE_API_URL}/${schedulesCollectorPrefix}/api/schedules/${id}`,
  OPEN_ONE_SCHEDULE: (id: number) =>
    `${BASE_API_URL}/${schedulesCollectorPrefix}/api/schedules/${id}/open`,
  CLOSE_ONE_SCHEDULE: (id: number) =>
    `${BASE_API_URL}/${schedulesCollectorPrefix}/api/schedules/${id}/close`,
  GET_CITIES_BY_NAME: `${BASE_API_URL}/${geolocationPrefix}/api/cities/by-name`,
  GET_CITIS_BY_STATE: `${BASE_API_URL}/${geolocationPrefix}/api/cities/by-state`,
  GET_NEIGHBORHOODS: `${BASE_API_URL}/${geolocationPrefix}/api/neighborhoods`,
  GET_AREAS: `${BASE_API_URL}/${schedulesCollectorPrefix}/api/areas`,
};

export const FINANCIAL = {
  POST_DEBT_WRITE_OFF: `${BASE_API_URL}/${financialPrefix}/api/moviments/debt-write-off`,
  RECEIVE_PAY_ON: `${BASE_API_URL}/${financialPrefix}/api/moviments/receive-pay-on`,
  REVERSAL: `${BASE_API_URL}/${financialPrefix}/api/moviments/reversal`,
  INSTALLMENTS_LIST: `${BASE_API_URL}/${financialPrefix}/api/installments`,
  TRANSACTIONS_HISTORY: `${BASE_API_URL}/${financialPrefix}/api/history`,
  GET_BRANCHES: (page: number, itemsPerPage: number) =>
    `${BASE_API_URL}/${financialPrefix}/api/branches?page=${page}&limit=${itemsPerPage}`,
  GET_USER_SYSTEM: `${BASE_API_URL}/${financialPrefix}/api/users`,
  POST_ANALYTIC_REPORT: `${BASE_API_URL}/${financialPrefix}/api/installments/report/analytic`,
  POST_SYNTHETIC_REPORT: `${BASE_API_URL}/${financialPrefix}/api/installments/report/synthetic`,
  GET_REPORT: (fileName: string) =>
    `${BASE_API_URL}/${financialPrefix}/api/installments/report/status/${fileName}/?retry=${Date.now().toString()}`,
  GET_APP_USERS: `${BASE_API_URL}/${financialPrefix}/api/users/app`,
  GET_BANKS_FINANCE_REPORT: `${BASE_API_URL}/${financialPrefix}/api/users/bank`,
  TRANSACTIONS_USERS: (subscription: number) =>
    `${BASE_API_URL}/${financialPrefix}/api/users/installment/${subscription}`,
};

export const COLUMBARIUM = {
  GET_VAULTS: `${BASE_API_URL}/${columbariumPrefix}/api/vaults`,
  GET_VAULT_BY_ID: (id: string) =>
    `${BASE_API_URL}/${columbariumPrefix}/api/vaults/${id}`,
  POST_VAULT: `${BASE_API_URL}/${columbariumPrefix}/api/vaults`,
  PATCH_VAULT: (id: number) =>
    `${BASE_API_URL}/${columbariumPrefix}/api/vaults/${id}`,
  GET_CEMETERIES: `${BASE_API_URL}/${columbariumPrefix}/api/cemeteries`,
  GET_BLOCKS: `${BASE_API_URL}/${columbariumPrefix}/api/blocks`,
  GET_ALLOTMENTS: `${BASE_API_URL}/${columbariumPrefix}/api/allotments`,
  GET_MODELS: `${BASE_API_URL}/${columbariumPrefix}/api/models`,
  GET_ASSOCIATE_VAULTS: (subscription: string) =>
    `${BASE_API_URL}/${columbariumPrefix}/api/associates/${subscription}/vaults`,
  DELETE_ASSOCIATE_VAULT: (vaultId: string) =>
    `${BASE_API_URL}/${columbariumPrefix}/api/vaults/association/${vaultId}`,
  PATCH_ASSOCIATE_VAULT: (vaultId: string) =>
    `${BASE_API_URL}/${columbariumPrefix}/api/vaults/association/${vaultId}`,
  POST_ASSOCIATE_VAULT: (subscription: string) =>
    `${BASE_API_URL}/${columbariumPrefix}/api/associates/${subscription}/vaults/create-association`,
};

export const CUSTOMER_ATTENDANCE_REPORTS = {
  POST_REPORT_CANCELED_BANK_SLIPS: `${BASE_API_URL}/${customerAttendanceReportsPrefix}/api/reports/canceled-bank-slips`,
  POST_REPORT_ASSOCIATE_BY_STATUS: `${BASE_API_URL}/${customerAttendanceReportsPrefix}/api/reports/associate-by-status`,
  POST_REPORT_SALES: `${BASE_API_URL}/${customerAttendanceReportsPrefix}/api/reports/sales`,
  POST_REPORT_PLAN_COMPANY: `${BASE_API_URL}/${customerAttendanceReportsPrefix}/api/reports/plan-company`,
  DOWNLOAD_FILE: (fileName: string) =>
    `${BASE_API_URL}/${customerAttendanceReportsPrefix}/api/reports/files/${fileName}/download`,
};

export const ORTHOPEDIC_ENDPOINTS = {
  GET_ASSOCIATES: `${BASE_API_URL}/${orthopedicPrefix}/api/associates`,
  GET_ASSOCIATE_ONE: (subscriptionCode: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/associates/${subscriptionCode}`,
  GET_ASSOCIATE_DEBIT: (subscription: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/associates/${subscription}/debits`,
  GET_BRANCHES: `${BASE_API_URL}/${orthopedicPrefix}/api/branches`,
  GET_BRANCHES_ONE: (id: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/branches/${id}`,
  GET_EQUIPMENTS_GROUPS: `${BASE_API_URL}/${orthopedicPrefix}/api/equipments-groups`,
  POST_EQUIPMENTS_GROUPS: `${BASE_API_URL}/${orthopedicPrefix}/api/equipments-groups`,
  GET_EQUIPMENT_GROUP_ONE: (id: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/equipments-groups/${id}`,
  PATCH_EQUIPMENT_GROUP_ONE: (id: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/equipments-groups/${id}`,
  DELETE_EQUIPMENT_GROUP_ONE: (id: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/equipments-groups/${id}`,
  GET_EQUIPMENTS_LOAN: `${BASE_API_URL}/${orthopedicPrefix}/api/movements/loan`,
  POST_EQUIPMENTS_LOAN: `${BASE_API_URL}/${orthopedicPrefix}/api/movements/loan`,
  PATCH_EQUIPMENT_LOAN_ONE: (id: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/movements/loan/${id}`,
  POST_EQUIPMENT_LOAN_CONTRACT: (id: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/movements/loan/${id}/contract`,
  POST_EQUIPMENT_LOAN_CONTRACT_RETURN: (id: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/movements/loan/${id}/contract-return`,
  PATCH_EQUIPMENT_LOAN_RETURN_ONE: (id: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/movements/loan/${id}/return`,
  GET_EQUIPMENT_LOAN_ONE: (id: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/movements/loan/${id}`,
  GET_EQUIPMENTS: `${BASE_API_URL}/${orthopedicPrefix}/api/equipments`,
  GET_EQUIPMENT_ONE: (id: string) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/equipments/${id}`,
  POST_EQUIPMENT: `${BASE_API_URL}/${orthopedicPrefix}/api/equipments`,
  PATCH_EQUIPMENT: (id: number) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/equipments/${id}`,
  DELETE_EQUIPMENT: (id: number) =>
    `${BASE_API_URL}/${orthopedicPrefix}/api/equipments/${id}`,
  POST_MAINTENANCE: `${BASE_API_URL}/${orthopedicPrefix}/api/inventory/maintenance`,
};

export const DEBIT_ACCOUNT = {
  SEND_LINK: `${BASE_API_URL_VENDAS}assinatura`,
  REGISTER_ACCOUNT: `${BASE_API_URL_VENDAS}pagamentos/debito-automatico/registrar-conta`,
  PAYMENT_STATUS_CHECK: (subscriptionCode: string) =>
    `${BASE_API_URL_VENDAS}pagamentos/debito-automatico?inscricao=${subscriptionCode}`,
};

export const DEPOSIT_OF_RECEIPTS = {
  DEPOSITS: `${BASE_API_URL}/${depositOfReceiptsPrefix}/api/deposits`,
  GET_DEPOSIT: (id: string) =>
    `${BASE_API_URL}/${depositOfReceiptsPrefix}/api/deposits/${id}`,
  POST_DEPOSITS: `${BASE_API_URL}/${depositOfReceiptsPrefix}/api/deposits`,
  UPDATE_DEPOSIT: (id: string) =>
    `${BASE_API_URL}/${depositOfReceiptsPrefix}/api/deposits/${id}/confirm`,
  GET_TRANSACTION: `${BASE_API_URL}/${depositOfReceiptsPrefix}/api/transactions`,
  POST_DOWNLOAD_DOCUMENT: `${BASE_API_URL}/${depositOfReceiptsPrefix}/api/documents/download`,
  POST_UPLOAD_DOCUMENT: `${BASE_API_URL}/${depositOfReceiptsPrefix}/api/documents/upload`,
  POST_IMPORT_COLLECTOR_SCHEDULE: `${BASE_API_URL}/${depositOfReceiptsPrefix}/api/collector`,
  GET_SCHEDULE_TOTAL_VALUE: `${BASE_API_URL}/${depositOfReceiptsPrefix}/api/collector/total-value`,
};

export const BRANCHES_FUNERAL = {
  REGISTER_BRANCH: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/funeralHomes`,
  BRANCH_FUNERAL_BY_ID: (id: string) =>
    `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/funeralHomes/${id}`,
  MACHINES: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/paymentMachines`,
  DELETE_MACHINE: (id: number) =>
    `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/paymentMachines/${id}`,
};

export const SISFUNER_MANAGEMENT = {
  GET_FUNERAL_HOMES: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/funeralHomes`,
  USERS: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/users`,
  GET_HIERARCHY: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/hierarchies`,
  GET_POSITIONS: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/positions`,
  POST_EMPLOYEES: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/employees`,
  PATCH_EMPLOYEE: (id: number) =>
    `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/employees/${id}`,
  GET_EMPLOYEE: (id: number) =>
    `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/employees/${id}`,
  GET_EMPLOYEE_BY_EMAIL: (email: string) =>
    `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/employees/by-user-platform-email/${email}`,
  GET_PAYMENT_DEADLINE_INVOICES: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/paymentDeadlineInvoices`,
  POST_PAYMENT_DEADLINE_INVOICES: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/paymentDeadlineInvoices`,
  PUT_PAYMENT_DEADLINE_INVOICES: (id: string | number) =>
    `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/paymentDeadlineInvoices/${id}`,
  GET_EMPLOYEES: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/employees`,
  GET_EMPLOYEES_FUNERAL_HOMES: (email: string) =>
    `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/employees/by-email/${email}/funeral-homes`,
  GET_FUNERAL_SOLICITATIONS: (funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/funeral-solicitations/funeral-homes/${funeralHomeId}`,
  GET_FUNERAL_SOLICITATION: (id: number) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/funeral-solicitations/${id}`,
  GET_INVOICE_BY_ID: (id: number) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/invoices/${id}`,
  PATCH_FUNERAL_SOLICITATION: (id: number) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/funeral-solicitations/${id}`,
  GET_INVOICES: (funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/invoices/funeral-homes/${funeralHomeId}`,
  PRINT_INVOICE: (id: number) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/invoices/${id}/print`,
  FIND_INVOICES: (funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/invoices/funeral-homes/${funeralHomeId}`,
  GET_INVOICES_REPORT: `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/invoices/report`,

  GET_PRODUCTS: `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/products`,
  GET_SUBPRODUCTS: `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/products/subgroups`,
  GET_GROUP_PRODUCTS: `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/products/groups`,
  GET_HISTORY_REPORT: `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/products/history-report`,
  STOCK_MOVEMENT: `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/stock-movement`,
  GET_STOCK_MOVEMENT_BY_ID: (id: number) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/stock-movement/${id}`,
  PUT_STOCK_MOVEMENT_BY_ID: (id: number) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/stock-movement/${id}`,
  GET_SUPPLIERS: `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/suppliers`,
  POST_INVENTORY_CONFERENCE_REPORT: `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/products/inventory-conference-report`,
  GET_INVENTORY_CONFERENCE_REPORT: (fileName: string) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/products/inventory-conference-report/${fileName}`,
  GET_MISSING_URNS_REPORT: `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/work-orders/missing-urns`,
  GET_MISSING_URNS_RETURN_FILE_NAME: `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/work-orders/missing-urns-report`,
  GET_DOCUMENT_GENERATOR_FILE_BY_NAME: (fileName: string) =>
    `${BASE_API_URL}/${serviceFuneralManagerPrefix}/api/files/${fileName}/download`,
};

export const DISCOUNT_PROFILES = {
  GET_DISCOUNT_PROFILES: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/discountProfiles`,
  POST_DISCOUNT_PROFILES: `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/discountProfiles`,
  PUT_DISCOUNT_PROFILES: (id: string | number) =>
    `${BASE_API_URL}/${serviceFuneralHomePrefix}/api/discountProfiles/${id}`,
};

export const SISFUNER_WORK_ORDER = {
  GET_ORDER_TYPE: (workType: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/service-type/work-order-type/${workType}`,
  GET_DECLARANT: (deathRecordId: number, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/declarant/deathRecord/${deathRecordId}/funeralHome/${funeralHomeId}`,
  FIND_DECLARANT: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/declarant`,
  GET_WORK_ORDER: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  POST_WORK_ORDERS: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders`,
  POST_DECLARANT: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/declarant`,
  PUT_DECLARANT: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/declarant`,
  GET_WORK_ORDER_OBSERVATIONS: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders/order-number/${orderNumber}/funeral-home/${funeralHomeId}/observations`,
  POST_WORK_ORDER_OBSERVATION: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders/order-number/${orderNumber}/funeral-home/${funeralHomeId}/observations`,
  PATCH_WORK_ORDER: (orderNumber: number, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  POST_WORK_ORDER_CLOSE: (orderNumber: number, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders/order-number/${orderNumber}/funeral-home/${funeralHomeId}/close`,

  GET_WORK_ORDER_ITEMS: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-items/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,

  PRINT_WORK_ORDER_DOCUMENTS: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-documents/order-number/${orderNumber}/funeral-home/${funeralHomeId}/print`,

  GET_WORK_ORDER_SERVICES: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-services/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  GET_WORK_ORDER_FINANCIAL_RESUME: (
    orderNumber: string,
    funeralHomeId: string
  ) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders/financial-resume/${orderNumber}/${funeralHomeId}`,
  GET_WORK_ORDER_SERVICE_PROVIDER: () =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/service-provider`,
  GET_WORK_ORDER_ITEMS_FINANCIAL_RESUME: (
    orderNumber: string,
    funeralHomeId: string
  ) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders/financial-resume/${orderNumber}/${funeralHomeId}`,

  POST_WORK_ORDER_ITEM: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-items/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  DELETE_WORK_ORDER_ITEMS: (
    code: number,
    funeralHomeId: string,
    orderNumber: number
  ) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-items/funeral-home/${funeralHomeId}/order-number/${orderNumber}/code/${code}`,
  FIND_INSURERS: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/insurers`,
  GET_WORK_ORDER_PAYMENT_METHODS: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-payment/types`,
  POST_WORK_ORDER_REPORT: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders/report`,
  POST_WORK_ORDER_PAYMENTS: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-payment/order-number/${orderNumber}/funeral-home/${funeralHomeId}/process-payment`,
  WORK_ORDER_DISCOUNT: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-discount/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  PATCH_WORK_ORDER_DISCOUNT: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-discount/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  GET_WORK_ORDER_PAYMENT: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-payment/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  POST_WORK_ORDER_PAYMENT: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-payment/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  PATCH_WORK_ORDER_PAYMENT: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-payment/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  DELETE_WORK_ORDER_PAYMENT: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-payment/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  GET_WORK_ORDER_INVOICES: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/invoices/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  POST_PAYMENT_LINK: (
    orderNumber: string,
    funeralHomeId: string,
    paymentMethodId: number
  ) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-payment/orderNumber/${orderNumber}/funeral-home/${funeralHomeId}/paymentMethodId/${paymentMethodId}/payment-link`,
  POST_WORK_ORDER_PAYMENT_RESPONSIBLE: (
    orderNumber: string,
    funeralHomeId: string
  ) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-payment/orderNumber/${orderNumber}/funeralHome/${funeralHomeId}/responsible`,

  GET_WORK_ORDER_INVOICE_CANCEL_REASONS: () =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/invoices/cancel-reasons`,
  GET_WORK_ORDER_STATUS_HISTORY: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-status-histories/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  POST_WORK_ORDER_INVOICE: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/invoices/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  POST_WORK_ORDER_INVOICE_REPROCESS: (invoiceId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/invoices/${invoiceId}/issue`,
  PUT_WORK_ORDER_INVOICE: (id: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/invoices/invoice/${id}`,
  PATCH_WORK_ORDER_MANUAL_PAYMENT: (
    orderNumber: number,
    funeralHomeId: string
  ) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-payment/manual-payment/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
  CREATE_BANK_SLIP: (orderNumber: number, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-payment/order-number/${orderNumber}/funeral-home/${funeralHomeId}/create-bank-slip`,
  POST_WORK_ORDER_MACHINE_PAYMENT: `${BASE_API_URL}/${paymentPrefix}/api/payment-methods/sync/funeral-payment`,
};

export const SISFUNER_FUNERAL_AGENT = {
  GET_CEMETERIES: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/cemeteries`,
  GET_DOCTORS: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/doctors`,
  GET_REGISTRY_OFFICES: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/registry-office`,
  GET_RELIGIONS: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/religions`,
  GET_SKIN_COLORS: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/colors`,
  GET_DEATH_RECORD: (deathRecordId: number, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/death-record/${deathRecordId}/funeral-home/${funeralHomeId}`,
  GET_FUNERAL_LOCATION: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/memorials`,
  GET_THANATO_LOCATION: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/laboratories`,
  PUT_DEATH_RECORD: (deathRecordId: number, funeralHomeId: number) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/death-record/${deathRecordId}/funeral-home/${funeralHomeId}`,
  POST_WORK_ORDERS: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders`,
  WORK_ORDERS: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-orders`,
  GET_PRODUCTS: `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/products`,
  POST_SERVICES_FROM_WORK_ORDER: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-services/work-orders/${orderNumber}/funeralHome/${funeralHomeId}/add-services`,
  DELETE_SERVICE_FROM_WORK_ORDER: (
    orderNumber: string,
    funeralHomeId: string,
    id: number
  ) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-services/order-number/${orderNumber}/funeral-home/${funeralHomeId}/service/${id}`,
  DELETE_WORK_ORDER_INVOICE: (id: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/invoices/cancel/${id}`,
  DELETE_WORK_ORDER_DISCOUNT: (orderNumber: string, funeralHomeId: string) =>
    `${BASE_API_URL}/${serviceWorkOrderPrefix}/api/work-order-discount/order-number/${orderNumber}/funeral-home/${funeralHomeId}`,
};

export const ASSOCIATE_OBSERVATIONS = {
  LIST: (subscription: number) =>
    `${BASE_API_URL}/${contractPrefix}/api/observations/by-subscription/${subscription}`,
};

export const SERVICE_FEE = {
  fees: `${BASE_API_URL}/${serviceFee}/api/fees`,
  associateFees: (subscriptionCode: number) =>
    `${BASE_API_URL}/${serviceFee}/api/associates/${subscriptionCode}/fees`,
  associateFee: (
    subscriptionCode: number,
    description: string,
    feeCode: string
  ) =>
    `${BASE_API_URL}/${serviceFee}/api/associates/${subscriptionCode}/fees/${feeCode}/${description}`,
};

export const SISFUNER_INSTALLMENTS = {
  ACTIVE: (userId: string) =>
    `${BASE_API_URL}/${installmentPrefix}/funeral-installments/${userId}/active`,
  CREATE: `${BASE_API_URL}/${installmentPrefix}/funeral-installments`,
  BOX_DATA: `${BASE_API_URL}/${installmentPrefix}/funeral-installments/:id/get-all-cash-flow`,
  CLOSE_BOX: `${BASE_API_URL}/${installmentPrefix}/funeral-installments/:userId/close-funeral-installment`,
  REVERSAL: (installmentId: string) =>
    `${BASE_API_URL}/${installmentPrefix}/funeral-cash-reversal/${installmentId}/list-low-cash`,
  REVERSE_PAYMENTS: `${BASE_API_URL}/${installmentPrefix}/funeral-cash-reversal/reverse-payment`,
};
