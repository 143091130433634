import { useInfiniteQuery } from 'react-query';

import { getPaginatedUsers } from 'services/billings';

export const useFindAllUsers = (filterName: string, whenEnabled = true) => {
  const { data, ...rest } = useInfiniteQuery(
    ['findAllCollectors', filterName],
    ({ pageParam }) => getPaginatedUsers({ pageParam, filterName }),
    {
      enabled: whenEnabled,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage < lastPage.meta.totalPages
          ? lastPage.meta.currentPage + 1
          : undefined,
      cacheTime: 1000 * 60 * 30,
      staleTime: 1000 * 60 * 30, // 30 minutes
    }
  );

  return { ...rest, allUsers: data };
};
