import React, { useState } from 'react';

import { InputVariant } from 'components/shared/forms/Input/Input.styles';
import Tooltip from 'components/shared/newCore/Tooltip';

import { EyeClosedSchema, EyeOpenedSchema } from './EyeClosedSchema';
import * as S from './PasswordInput.styles';

interface PasswordInputProps {
  label: string;
  hasErrors?: boolean;
  ballon?: boolean;
  helperText?: string;
  id?: string;
  name?: string;
  disabled?: boolean;
  value: string | number;
  variant: InputVariant;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  testid?: string;
  placeholder?: string;
  required?: boolean;
}

const PasswordInput = ({
  label,
  hasErrors,
  helperText,
  id,
  name,
  disabled,
  value,
  variant,
  onChange,
  testid,
  placeholder,
  ballon,
  required,
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggle = () => {
    if (disabled) return;
    setShowPassword((previous) => !previous);
  };

  if (ballon) {
    return (
      <>
        <S.Label className={variant} htmlFor={id}>
          {required && <span>*</span>} {label}
        </S.Label>
        <S.InputWrapper
          disabled={disabled}
          className={variant}
          error={hasErrors}
        >
          <S.CustomInput
            disabled={disabled}
            className={variant}
            id={id}
            name={name}
            onChange={onChange}
            value={value}
            type={showPassword ? 'text' : 'password'}
            data-testid={testid}
            placeholder={placeholder}
          />
          <S.EndAdornment className={variant} onClick={toggle}>
            {showPassword ? (
              <EyeOpenedSchema variant={variant} />
            ) : (
              <EyeClosedSchema variant={variant} />
            )}
          </S.EndAdornment>
        </S.InputWrapper>
        {hasErrors && <S.BallonError>{helperText}</S.BallonError>}
      </>
    );
  }

  return (
    <>
      <S.Label className={variant} htmlFor={id}>
        {required && <span>*</span>} {label}
      </S.Label>
      <S.InputWrapper disabled={disabled} className={variant} error={hasErrors}>
        <S.CustomInput
          disabled={disabled}
          className={variant}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          type={showPassword ? 'text' : 'password'}
          data-testid={testid}
          placeholder={placeholder}
        />
        <S.EndAdornment className={variant} onClick={toggle}>
          {showPassword ? (
            <EyeOpenedSchema variant={variant} />
          ) : (
            <EyeClosedSchema variant={variant} />
          )}
        </S.EndAdornment>
      </S.InputWrapper>
      {hasErrors && <Tooltip type="error" message={helperText as string} />}
    </>
  );
};

export default PasswordInput;
