import { AxiosRequestConfig } from 'axios';
import CryptoJS from 'crypto-js';
import * as localForage from 'localforage';
import Cookies from 'universal-cookie';

export type Cache = {
  expire: string;
  value: any;
};
export enum TimeUnit {
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}

export type MaxAge = [number, TimeUnit];

export interface CachableRequestConfig extends AxiosRequestConfig {
  cache?: boolean;
  maxAge?: MaxAge;
  delayOnCache?: number;
  type?: string;
}

const addIntervalToDate = (date: Date, interval: number, unit: TimeUnit) => {
  const ONE_SECOND = 1000;
  const ONE_MINUTE = 60 * ONE_SECOND;
  const ONE_HOUR = 60 * ONE_MINUTE;
  const ONE_DAY = 24 * ONE_HOUR;
  const ONE_WEEK = 7 * ONE_DAY;
  const ONE_MONTH = 30 * ONE_DAY;
  switch (unit) {
    case TimeUnit.MONTHS:
      return new Date(date.getTime() + interval * ONE_MONTH);
    case TimeUnit.WEEKS:
      return new Date(date.getTime() + interval * ONE_WEEK);
    case TimeUnit.DAYS:
      return new Date(date.getTime() + interval * ONE_DAY);
    case TimeUnit.HOURS:
      return new Date(date.getTime() + interval * ONE_HOUR);
    case TimeUnit.MINUTES:
      return new Date(date.getTime() + interval * ONE_MINUTE);
    case TimeUnit.SECONDS:
      return new Date(date.getTime() + interval * ONE_SECOND);
    default:
      return date;
  }
};

export const removeFromCache = async (urlPattern: string) => {
  const cacheInstance = CacheControl.getDbInstance();
  const keys = await cacheInstance.keys();
  const keysToDelete = keys.filter((key) => atob(key).includes(urlPattern));
  await Promise.all(keysToDelete.map((key) => cacheInstance.removeItem(key)));
};

export const storeInCache = (
  data: any,
  url: string,
  requestOptions?: CachableRequestConfig
) => {
  if (requestOptions?.cache) {
    const key = btoa(url + JSON.stringify(requestOptions?.params));

    const payload = encrypt(data);
    CacheControl.setCache(
      key,
      payload,
      requestOptions?.maxAge || [0, TimeUnit.SECONDS],
      requestOptions?.type
    );
  }
};

export const retrieveFromCache = async (
  url: string,
  requestOptions: CachableRequestConfig | undefined,
  onReceiveData: (data: any) => void
): Promise<boolean> => {
  if (requestOptions?.cache) {
    const key = btoa(url + JSON.stringify(requestOptions?.params));
    const { expire, value } = await CacheControl.getCache(key);
    const isExpired = expire && new Date(expire) <= new Date();

    const retrievedFromCache = value && !isExpired;
    if (value && !isExpired) {
      const delay = requestOptions?.delayOnCache ?? 0;
      setTimeout(() => onReceiveData(decrypt(value)), delay);
    }

    return retrievedFromCache;
  }

  return false;
};

const encrypt = (content: unknown) => {
  const secret = getKey();
  return CryptoJS.AES.encrypt(JSON.stringify(content), secret).toString();
};

const decrypt = (content: string) => {
  const secret = getKey();
  const bytes = CryptoJS.AES.decrypt(content, secret);
  const bytesString = bytes.toString(CryptoJS.enc.Utf8);
  return bytesString ? JSON.parse(bytesString) : '';
};

const generateCacheName = (username: string): string => {
  const cookie = new Cookies();
  const cacheName = cookie.get('local_cache_dbname');

  if (cacheName) {
    return cacheName;
  }

  const seed = getSessionSecret();
  const hash = CryptoJS.SHA256(`${username}${seed}`).toString();
  const name = `cache-${hash}`;
  const cacheNames = cookie.get('cacheNames') || [];
  cacheNames.push(name);
  cookie.set('cacheNames', JSON.stringify(cacheNames));

  cookie.set('cacheName', name);
  return `cache-${hash}`;
};

function getSessionSecret() {
  const sesionSecret = sessionStorage.getItem('session');
  if (sesionSecret) {
    return sesionSecret;
  }

  const secret = getSalt(Math.random().toString(16).substring(2));
  sessionStorage.setItem('session', secret);
  return secret;
}

function getKey(): string {
  const cookie = new Cookies();
  const secret = cookie.get('cache_secret');
  if (secret) {
    return secret;
  }

  const newSecret = getSalt(getSessionSecret());
  cookie.set('cache_secret', newSecret);
  return newSecret;
}

function getSalt(pass: string): string {
  const salt = CryptoJS.lib.WordArray.random(128 / 8);
  return CryptoJS.PBKDF2(pass, salt, {
    keySize: 512 / 32,
    iterations: 1000,
  }).toString();
}

export default class CacheControl {
  private static dbInstance: LocalForage;

  private constructor() {}

  static getDbInstance(): LocalForage {
    if (!this.dbInstance) {
      localForage.config({
        driver: localForage.LOCALSTORAGE,
        name: 'Ducash',
        version: 1,
        storeName: 'CacheControl',
      });
      const user = localStorage.email ?? '';
      const name = generateCacheName(user);
      this.dbInstance = localForage.createInstance({
        name,
      });
    }
    return this.dbInstance;
  }

  static async getCache(key: string): Promise<Partial<Cache>> {
    const cache = (await CacheControl.getDbInstance().getItem(key)) as Cache;
    return cache || {};
  }

  static setCache(key: string, value: any, maxAge: MaxAge, type = ''): void {
    const now = new Date();
    const data: any = {
      value,
      type,
      cratedAt: now.toISOString(),
      expire: addIntervalToDate(now, maxAge[0], maxAge[1]).toISOString(),
    };
    this.getDbInstance().setItem(key, data);
  }

  static async hasCache(): Promise<number> {
    let length: number;
    try {
      length = await this.getDbInstance().length();
    } catch (error) {
      length = 0;
    }
    return length;
  }

  static dropAllCaches(): void {
    const cookie = new Cookies();
    const cacheNames = cookie.get('cacheNames') || [];
    sessionStorage.removeItem('session');
    for (const db of cacheNames) {
      window.indexedDB.deleteDatabase(db);
      sessionStorage.removeItem(db);
    }
    cookie.remove('cacheNames');
  }

  static clearCache(): void {
    this.getDbInstance().clear();
  }

  static clearExpiredCache(): void {
    this.getDbInstance().iterate((value: any, key: string, _) => {
      if (value) {
        const expire = new Date(value.expire);
        const now = new Date();
        if (expire.getTime() <= now.getTime()) {
          this.getDbInstance().removeItem(key);
        }
      }
    });
  }
}
