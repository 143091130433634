import { Permission, PermissionRule } from 'lib/contextual';

const isPermissionRule = (
  rule: PermissionRule | string[]
): rule is PermissionRule => typeof rule === 'object' && !Array.isArray(rule);

function checkPermission(path: string, permissions: Permission): boolean {
  const pathArray = path.split('.');
  let current: PermissionRule | string[] = permissions.rules;

  for (let i = 0; i < pathArray.length; i++) {
    if (isPermissionRule(current) && current[pathArray[i]] !== undefined) {
      current = current[pathArray[i]];
    } else if (Array.isArray(current) && current.includes(pathArray[i])) {
      return true;
    } else {
      return false;
    }
  }

  return true;
}

export default checkPermission;
