export enum FileOptions {
  PDF = 'pdf',
  EXCEL = 'xls',
  LIBREOFFICE = 'csv',
}

export enum ReportType {
  Campaign = 'campaign',
  Receptive = 'receptive',
}

export enum ReportTemplate {
  Analytical = 'analytic',
  Synthetic = 'synthetic',
}
