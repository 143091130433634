import styled, { css } from 'styled-components';

import { Grid, Row } from 'components/shared';
import RoundedIcon from 'components/shared/core/roundedIcon';

import { device } from 'styles/tokens/devices';

export const BatchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

interface BatchCardProps {
  cardSelected: Boolean;
}

export const BatchCard = styled.div<BatchCardProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 543px;
  height: 82px;
  border-radius: 15px;
  border: ${(props) =>
    props.cardSelected && `3px solid ${props.theme.new.colors.secondary.main}`};
  background-color: #fff;
  color: ${({ theme }) => theme.new.colors.primary.main};
  cursor: pointer;

  span {
    display: none;
  }

  @media ${device.tabletL} {
    padding-left: 15px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    color: ${({ theme }) => theme.new.colors.primary.light};
    font-size: 1.1rem;
    width: 100%;
    height: 150px;
    overflow-x: auto;
    span {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.new.colors.primary.main};
      font-weight: 600;
      display: inline;
      padding-right: 0.2rem;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.large};
  @media ${device.tabletL} {
    font-size: 1.8rem;
  }
`;

export const Advise = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const Header = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacing.md} 0px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 600;
`;

export const InputContainer = styled(Row)`
  height: auto;

  & textarea {
    height: auto;
  }
`;

export const InputBatchCode = styled(Row)`
  height: auto;
`;

export const TablePrint = styled.table`
  border-collapse: separate;
  flex-direction: column;
  border-spacing: 0 1rem;
  height: auto;
  width: 48.5%;
  @media screen and (max-width: 820px) {
    width: 100%;
  }
`;

export const TBodyTable = styled.tbody`
  td:first-child {
    border-radius: 1rem 0 0 1rem;
  }
  td:last-child {
    border-radius: 0 1rem 1rem 0;
  }

  @media screen and (max-width: 950px) {
    td:first-child {
      border-radius: 1rem 1rem 0 0;
    }
    td:last-child {
      border-radius: 0 0 1rem 1rem;
    }
  }
`;
export const TrBody = styled.tr``;

type BodyColumnProp = {
  textOrientation?: 'center' | '-webkit-right';
};

export const BodyColumn = styled.td<BodyColumnProp>`
  background-color: #fff;
  overflow: hidden;
  height: 100%;
  text-transform: capitalize;
  ${({ theme }) => css`
    padding-top: ${theme.spacing.sm1};
    padding-bottom: ${theme.spacing.sm1};
    color: ${theme.texts.thinParagraph};
  `}
  text-align: center;
  padding: 0 0.8rem;

  ${(props) =>
    props.textOrientation &&
    css`
      text-align: ${props.textOrientation};
    `}

  span {
    display: none;
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.small1};
      color: ${theme.secondary.dark};
    `}
  }

  @media screen and (max-width: 950px) {
    text-align: left;
    display: block;
    float: left;
    width: 100%;
    padding: 0.5rem;
    span {
      display: inline;
      font-weight: bold;
      margin-right: 0.5rem;
    }
  }
`;

export const ListStatusColumn = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => css`
    padding-top: ${theme.spacing.xs2};
    padding-bottom: ${theme.spacing.xs2};
    color: ${theme.texts.thinParagraph};
  `}

  span {
    display: none;
  }

  @media screen and (max-width: 950px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;

    span {
      display: inline;
      font-weight: bold;
    }
  }
`;

export const CustomRoundedIcon = styled(RoundedIcon)`
  padding: ${({ theme }) => theme.spacing.sm};
  height: 56px;
  width: 56px;
`;

export const SearchFor = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const InscriptionTablePrint = styled.div``;

export const MessageNotFoundBatch = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.new.colors.status.error};
`;

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;
