import axios, { AxiosResponse } from 'axios';
import { BatchBankSlipMailing } from 'types/BatchBankSlipMailing';
import { BatchInfo } from 'types/BatchInfo';
import { BatchResponse } from 'types/BatchResponse';
import { OnlineBatchRequestType } from 'types/PaymentSlip';

import api from 'services/api';

import { getURLWithParams } from 'lib/contextual/Url';

import { BANK_SLIP, ONLINE } from 'constants/endpoints';

export const onlinePaymentSlip = () => {
  const onlineBatches = async ({
    pageParam = 1,
    code = '',
    date = '',
    status = '',
  }): Promise<{
    items: BatchBankSlipMailing[];
    meta: { currentPage: number };
  }> => {
    const params = {
      page: pageParam,
      limit: 15,
      ...(code !== '' ? { code } : ''),
      ...(date !== '' ? { createdAt: date } : ''),
      ...(status !== '' ? { status } : ''),
    };

    const url = getURLWithParams(`${ONLINE.ONLINE_BATCHES}`, params);

    const allBatches = await api.getRequest(url);
    return allBatches.data;
  };

  const createOnlineBatch = async (
    period: OnlineBatchRequestType,
    archive: File
  ) => {
    const url = `${ONLINE.ONLINE_BATCHES}`;

    const { data: batch }: AxiosResponse<BatchResponse> = await api.postRequest(
      url,
      period
    );

    const { id, uploadUrl, uploadKey } = batch;

    await axios.put(uploadUrl, archive, {
      headers: { 'Content-Type': archive.type },
    });

    await api.putRequest(ONLINE.ONLINE_BATCH(id), {
      documentId: uploadKey,
      originalFilename: uploadKey,
    });

    return batch;
  };

  const getBatchesInfos = async (batchId: string): Promise<BatchInfo[]> => {
    const url = `${BANK_SLIP.GET_BATCHES_DOWNLOAD(batchId)}`;
    const result = await api.getRequest(url);
    return result.data;
  };

  const getDownloadBatchPdf = async (fileName: string) => {
    const url = `${BANK_SLIP.GET_BATCHES_DOWNLOAD_PDF(fileName)}`;
    const result = await api.getRequest(url);
    return result;
  };

  return {
    onlineBatches,
    createOnlineBatch,
    getBatchesInfos,
    getDownloadBatchPdf,
  };
};
