import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import {
  OrderServiceParams,
  OrdersServicesResponse,
} from 'types/orderServiceSisfuner';

import { getOrdersService } from 'services/sisfunerOrderService';

import { useFuneralHomesUser } from 'contexts/FuneralHomesUserContext';

export const useFindOrdersService = (
  params: OrderServiceParams,
  enableOnMount: boolean
) => {
  const THIRTY_MINUTES = 1000 * 60 * 30;
  const { funeralHomesUser } = useFuneralHomesUser();

  const queryKey = [
    `findOrdersServicesSisfuner-${funeralHomesUser.selectedFuneralHomesUser}`,
    `${params.queryType}-${params.query}`,
  ];

  const { data, isLoading, ...rest } = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) =>
      getOrdersService({
        query: params.query,
        queryType: params.queryType,
        page: pageParam,
        limit: params.limit,
        funeralHomeId: params.funeralHomeId,
      }),
    {
      enabled: enableOnMount,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage >= lastPage.meta.totalPages
          ? undefined
          : lastPage.meta.currentPage + 1,
      staleTime: THIRTY_MINUTES,
    }
  );

  const ordersServicesData = useMemo(
    () => data?.pages.flatMap(({ items }) => items),
    [data]
  ) as OrdersServicesResponse[];

  return {
    ...rest,
    isLoading,
    orderServiceBaseData: data,
    ordersServicesData,
  };
};
