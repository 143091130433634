import styled, { css } from 'styled-components';

type RoundedIconTypes = {
  newPaySlipPage?: boolean;
  documentsAccordion?: boolean;
  AccordinInstallments?: boolean;
};

const RoundedIcon = styled.div<RoundedIconTypes>`
  border: none;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.texts.darkSecondary};
  font-size: ${(props) => props.theme.fontSizes.large};
  background-color: ${(props) => props.theme.primary.main};

  &:active {
    background-color: ${(props) => props.theme.primary.dark};
  }

  &:hover {
    filter: drop-shadow(0px 10px 20px ${({ theme }) => theme.primary.darken});
  }

  ${(props) =>
    props.newPaySlipPage &&
    css`
      width: 2.5rem;
      height: 2.5rem;
      font-size: ${(props) => props.theme.fontSizes.small};
      background-color: ${(props) => props.theme.primary.main};
      margin-top: 2rem;
    `}
  ${(props) =>
    props.documentsAccordion &&
    css`
      width: 3rem;
      height: 3rem;
      font-size: ${(props) => props.theme.fontSizes.small};
      background-color: transparent;
      margin-top: 2rem;
    `}
  ${(props) =>
    props.AccordinInstallments &&
    css`
      width: 2.8rem;
      height: 2.8rem;
      font-size: ${(props) => props.theme.fontSizes.small};
      margin-top: 1.1rem;
    `}

  @media screen and (max-width: 750px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export default RoundedIcon;
