import { useQuery } from 'react-query';

import { paymentSlip } from 'services/paymentSlip';

export const useBatchInformations = (batchId: string) => {
  const { getBatchesInfos } = paymentSlip();

  const {
    data,
    refetch: fetchBatchInformation,
    isLoading: fetchBatchInformationLoading,
  } = useQuery(['batchsInfos', batchId], () => getBatchesInfos(batchId), {
    enabled: false,
  });

  return {
    batchInfos: data,
    fetchBatchInformation,
    fetchBatchInformationLoading,
  };
};
