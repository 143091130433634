import Switch from 'react-switch';

interface SwitcherProps {
  checked: boolean;
  disabled?: boolean;
  onChange: (
    checked: boolean,
    event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string
  ) => void;
}

const Switcher = ({ onChange, checked, disabled }: SwitcherProps) => (
  <Switch
    height={22}
    width={38}
    className="my-switch"
    handleDiameter={18}
    uncheckedIcon={false}
    checkedIcon={false}
    onColor="#1229A6"
    offColor="#A8A8CC"
    onChange={onChange}
    checked={checked}
    disabled={disabled}
  />
);

export default Switcher;
