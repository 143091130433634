import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useOrthopedicEquipmentValidation = () => {
  const intl = useIntl();
  return Yup.object().shape({
    group: Yup.string(),
    description: Yup.string()
      .nullable(true)
      .min(3, intl.formatMessage({ id: 'minimum3Chars' })),
  });
};
