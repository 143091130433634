import { Root } from '@radix-ui/react-alert-dialog';
import { useAlert, useSession } from 'contexts';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useFetchBranchById } from 'useCases/branches';
import {
  useFetchApprovalById,
  useOutletModalTuitionReadjusmentModal,
  useTuitionReadjusmentApproveOrDisapprove,
} from 'useCases/tuitionReadjustment';
import { DataApprove } from 'useCases/tuitionReadjustment/types';

import RoundedIcon from 'components/shared/core/roundedIcon';
import Button from 'components/shared/forms/ButtonLink';

import { Urls } from 'constants/urls';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';
import { ReactComponent as FaClose } from 'assets/images/Close.svg';

import * as S from './ModalTuitionReadjustment.styles';
import { TuitionReadjustmentForm } from './TuitionReadjustmentForm';
import TuitionReadjustmentsSharedResources from './tuitionReadjustmentsSharedResources';

export type ReadjustmentSolicitation = {
  subscription?: number | string;
  associate?: string;
  branch?: string;
  user?: string;
  tuitionValue?: number;
  tuitionReadjustment?: number;
  reference?: string;
  solicitationDate?: string;
  id?: number | string;
};

const TuitionReadjustmentModal = () => {
  const { id } = useParams();
  const { user } = useSession();
  const intl = useIntl();
  const { showAlert, setClear } = useAlert();
  const {
    refetch: getApprovalById,
    data: retrivedApproval,
    isError,
  } = useFetchApprovalById(String(id));
  const [isClosing, setIsClosing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataOutlet, setDataOutlet] = useOutletModalTuitionReadjusmentModal();
  const { readjustmentSolicitation } = dataOutlet;
  const { state } = useLocation();
  const [branchClient, setBranchClient] = useState<any>();
  const [readjustmentSolicitationData, setReadjustmentSolicitationData] =
    useState<Partial<DataApprove> | null>({
      id: undefined,
      subscriptionCode: undefined,
      associate: {
        name: '',
      },
      branch: '',
      userManager: {
        email: '',
        id: '',
        fullName: '',
      },
      valuePrevious: undefined,
      updatedValue: undefined,
      dataCreated: undefined,
    });

  const { updateApproveOrDisapprove } =
    useTuitionReadjusmentApproveOrDisapprove();

  const { publish: publishBranchClient } = useFetchBranchById({
    onSuccess(data) {
      setBranchClient(data);
    },
  });

  const handlePublishInstallmentsAndSummary = async () => {
    await publishBranchClient(user.branchesIds[0]);
  };

  useEffect(() => {
    handlePublishInstallmentsAndSummary();
  }, [isOpen]);

  const navigate = useNavigate();
  useEffect(() => {
    if (!state) {
      return navigate('..', { replace: true });
    }
    setIsOpen(true);
    if (!state.isNotification) {
      setReadjustmentSolicitationData(
        readjustmentSolicitation as Partial<DataApprove>
      );
      return;
    }
    if (state.isNotification) {
      (async () => {
        setIsLoading(true);
        await getApprovalById();

        if (isError) {
          setClear();
          showAlert({
            title: intl.formatMessage({
              id: 'tuitonReadjustment.modal.onBackendCall.error1',
            }),
            type: 'error',
          });
          setTimeout(() => {
            onCancel();
          }, 3200);
        }
        setIsLoading(false);
      })();
    }
  }, [id]);

  useEffect(() => {
    if (retrivedApproval?.data?.items && state.isNotification) {
      const retrivedAprovalData = retrivedApproval?.data.items[0] ?? undefined;
      setReadjustmentSolicitationData(retrivedAprovalData);
    }
  }, [retrivedApproval]);

  const formik = useFormik({
    initialValues: {
      status: readjustmentSolicitationData?.status ?? '',
      reason: readjustmentSolicitationData?.reason ?? '',
    },
    validationSchema:
      TuitionReadjustmentsSharedResources().tuitionReadjustmentSchema,
    onSubmit: (formValues) => {
      updateApproveOrDisapprove(
        {
          ...formValues,
          id: readjustmentSolicitationData?.id,
          approvingUser: user.cognitoId,
          userUpdated: user.cognitoId,
        },
        setIsOpen,
        setIsClosing
      );
      setDataOutlet({ ...dataOutlet, refresh: true });
      formik.resetForm();
      setTimeout(() => {
        navigate(Urls.monthlyApprovalHistory, {
          state: {
            refresh: true,
          },
        });
      }, 3000);
    },
  });
  const onCancel = () => {
    setIsClosing(true);
    formik.resetForm();
    setIsOpen(false);
    setTimeout(() => {
      navigate('..', { replace: true });
    }, 200);
    setReadjustmentSolicitationData(null);
  };

  return (
    <Root
      open={isOpen}
      defaultOpen={false}
      onOpenChange={(isOpen) => setIsOpen(!isOpen)}
    >
      <S.Overlay />
      <S.Container $isClosing={isClosing && !isOpen}>
        <S.Header $isClosing={isClosing && !isOpen}>
          <h1>
            {intl.formatMessage({ id: 'tuitonReadjustment.modal.title' })}
          </h1>
          <Button type="button" variant="lighter" onClick={onCancel}>
            <FaClose />
          </Button>
        </S.Header>
        <S.Section>
          <TuitionReadjustmentForm
            readjustmentSolicitationData={readjustmentSolicitationData ?? null}
            formik={formik}
            isLoading={isLoading}
          />
        </S.Section>
        <S.Footer>
          {' '}
          {user.cognitoId === branchClient?.branchManager && (
            <>
              <Button onClick={onCancel} variant="lighter">
                {intl.formatMessage({ id: 'tuitonReadjustment.modal.cancel' })}
              </Button>
              <form onSubmit={formik.handleSubmit}>
                <Button
                  disabled={!formik.isValid}
                  variant="lighter"
                  type="submit"
                >
                  {intl.formatMessage({
                    id: 'tuitonReadjustment.modal.confirm',
                  })}
                  <RoundedIcon>
                    <ArrowRight />
                  </RoundedIcon>
                </Button>
              </form>
            </>
          )}
        </S.Footer>
      </S.Container>
    </Root>
  );
};

export default TuitionReadjustmentModal;
