import { useQuery } from 'react-query';

import { financial } from 'services/financial';

export const useBranchs = (
  page: number,
  itemsPerPage: number,
  whenEnabled = false
) => {
  const { getBranches } = financial();
  const { data, isLoading } = useQuery(
    ['branches', page, itemsPerPage],
    () => getBranches(page, itemsPerPage),
    {
      enabled: whenEnabled,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
    }
  );
  return {
    branches: data,
    branchesLoading: isLoading,
  };
};
