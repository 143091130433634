import { Branch, MutateListener } from 'types';

import api from 'services/api';

import { useMutationGateway } from 'hooks/useMutationGateway';

import { BRANCHES } from 'constants/endpoints';

export const useRegisterBranch = ({ onSuccess, onError }: MutateListener) => {
  const create = async (transactionId: string, body: Branch): Promise<void> => {
    await api.postRequest(BRANCHES.POST, body, {
      headers: {
        transactionId,
      },
    });
  };

  const { mutate, isMutateLoading } = useMutationGateway(create, {
    transactionId: 'register-branch',
    onError: onError,
    onSuccess: onSuccess,
  });

  return {
    mutate,
    isLoading: isMutateLoading,
  };
};
