export enum BatchType {
  NewAssociates = 'new-associates',
  Readjustments = 'readjustments',
}

export enum BatchAssociateInputType {
  Sheets = 'sheets',
  Api = 'api',
  Associates = 'associates',
}

export enum CityFilterInputType {
  Groups = 'groups',
  Selection = 'selection',
  None = 'none',
}

export type Lots = {
  id: string;
  code: string;
  dateStart: string;
  dateEnd: string;
  createdAt: string;
  status: string;
  processingResult?: string;
  batchType: BatchType;
  associateInputType: BatchAssociateInputType;
  inputFile: string;
  currentStage: number;
  totalStages: number;
};

export type BankType = {
  id: number;
  bankCode: string;
  name: string;
};

export type AccountBankType = {
  id: number;
  bankCode: string;
  assignor: string;
  account: string;
  bank: BankType;
  gsAdminId?: number;
};

export type BankPercentageTypes = {
  bankAccountId: string;
  percentage: number;
  acceptsNordeste: boolean;
  enablesApiIntegration: boolean;
};

export type CreatePaymentSlipForm = {
  archive: File | null;
  dateStart: string;
  dateEnd: string;
  bankAccountId: string;
  cityGroupId: string;
  associates?: string;
  groups?: string;
  bankPercentages: BankPercentageTypes[];
  bankPercentagesError?: string;
};

export type CreateOnlinePaymentSlipForm = {
  archive: File | null;
  dateStart: string;
  dateEnd: string;
};

export type BatchRequestType = {
  associateInputType: BatchAssociateInputType;
  batchType: BatchType;
  bankPercentages: BankPercentageTypes[];
  dateStart: string;
  dateEnd: string;
};

export type OnlineBatchRequestType = {
  dateStart: string;
  dateEnd: string;
};

export type BatchFormOptionsType = {
  isReadjustments: boolean;
  isNewBySheet: boolean;
  isNewByAssociate: boolean;
  isNewByApi: boolean;
};

export type Batch = {
  documentModel?: string;
  printType?: string;
  searchType: string;
  batch?: string;
  subscriptions?: string[];
};
