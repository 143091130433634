import { useAlert } from 'contexts';
import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReportEletronicBilling } from 'types/BillingScheduling';

import { useReporEletronicComission } from 'useCases/billings/useReportEletronicComission';

import { formatFromMask } from 'lib/core';

import HomeContent from 'components/contextual/homeContent';
import { BreadcrumbLink, Row } from 'components/shared';
import AlertDialog from 'components/shared/core/alertDialog';
import Divider from 'components/shared/core/divider';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';
import Breadcrumb from 'components/shared/newCore/Breadcrumb';

import { Urls } from 'constants/urls';

import { ReactComponent as FaArrowRight } from 'assets/images/ArrowRight.svg';

import * as S from './electronicBillingReport.styles';
import { OptionsFile, OptionsFilterBy, ResultFilteringBy } from './types';
import { useElectronicBillingReportValidation } from './useElectronicBillingReportValidation';

const ElectronicBillingReport = () => {
  const intl = useIntl();
  const { showAlert, setClear } = useAlert();
  const [dynamicInputLabel, setDynamicInputLabel] = useState(false);
  const [labelOption, setLabelOption] = useState('');
  const { fetchDocument, isLoading } = useReporEletronicComission();

  const breadcrumbLinks: BreadcrumbLink[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
        redirectTo: Urls.HomePage,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.chargeAssociate' }),
        redirectTo: Urls.associateChargeMenu,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.reportAndResearchMenu' }),
        redirectTo: Urls.reportAndResearchMenu,
      },
      {
        label: intl.formatMessage({
          id: 'breadcrumb.electronicbillingreport',
        }),
        redirectTo: Urls.currentCampaign,
      },
    ],
    [intl]
  );

  const filterBy = [
    {
      label: intl.formatMessage({
        id: 'electronicBillingReport.label.enrollment',
      }),
      value: OptionsFilterBy.Enrollment,
    },
    {
      label: intl.formatMessage({
        id: 'electronicBillingReport.label.AssociatedName',
      }),
      value: OptionsFilterBy.AssociatedName,
    },
  ];

  const resultFilteringBy = [
    {
      label: intl.formatMessage({
        id: 'electronicBillingReport.label.enrollment',
      }),
      value: ResultFilteringBy.Enrollment,
    },

    {
      label: intl.formatMessage({
        id: 'electronicBillingReport.label.AssociatedName',
      }),
      value: ResultFilteringBy.AssociatedName,
    },
  ];

  const file = [
    {
      label: intl.formatMessage({
        id: 'electronicBillingReport.label.EXCEL',
      }),
      value: OptionsFile.EXCEL,
    },
    {
      label: intl.formatMessage({
        id: 'electronicBillingReport.label.LIBREOFFICE',
      }),
      value: OptionsFile.LIBREOFFICE,
    },
  ];

  const validationSchema = useElectronicBillingReportValidation();

  const formik = useFormik({
    initialValues: {
      startDate: formatFromMask(addDays(new Date(), -7), 'yyyy-MM-dd'),
      endDate: formatFromMask(new Date(), 'yyyy-MM-dd'),
      filterBy: '',
      file: '' as 'xls' | 'csv',
      enrollment: '',
      associatedName: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const params: ReportEletronicBilling = {
        startDate: values.startDate,
        endDate: values.endDate,
        type: values.file,
        associateName: values.associatedName,
        subscription: values.enrollment,
      };
      setClear();
      showAlert({
        type: 'loading',
        title: intl.formatMessage({
          id: 'electronicBillingReport.modal.loadind.title',
        }),
        message: intl.formatMessage({
          id: 'electronicBillingReport.modal.loading.message',
        }),
      });
      fetchDocument(params);
    },
  });

  useEffect(() => {
    if (formik.values.filterBy === OptionsFilterBy.Enrollment) {
      setDynamicInputLabel(true);
      setLabelOption(OptionsFilterBy.Enrollment);
      return;
    }
    if (formik.values.filterBy === OptionsFilterBy.AssociatedName) {
      setDynamicInputLabel(true);
      setLabelOption(OptionsFilterBy.AssociatedName);
      return;
    }
    setDynamicInputLabel(false);
  }, [formik]);

  return (
    <HomeContent>
      <Breadcrumb path={breadcrumbLinks} />
      <S.Title>
        <S.MobileControl>
          {intl.formatMessage({
            id: 'electronicBillingReport.Title',
          })}
        </S.MobileControl>
      </S.Title>
      <S.Form onSubmit={formik.handleSubmit}>
        <S.QueryWrapper>
          <Row span="3">
            <Input
              required
              id="startDate"
              name="startDate"
              onChange={formik.handleChange}
              value={formik.values?.startDate}
              variant="lighter"
              type="date"
              label={intl.formatMessage({
                id: 'electronicBillingReport.initialShippingDate',
              })}
              hasErrors={
                !formik.isValid && formik.errors.startDate !== undefined
              }
              helperText={String(formik.errors.startDate ?? '')}
            />
          </Row>
          <Row span="3">
            <Input
              required
              id="endDate"
              name="endDate"
              onChange={formik.handleChange}
              value={formik.values?.endDate}
              variant="lighter"
              type="date"
              label={intl.formatMessage({
                id: 'electronicBillingReport.finalShippingDate',
              })}
              helperText={String(formik.errors.endDate ?? '')}
              hasErrors={!formik.isValid && formik.errors.endDate !== undefined}
            />
          </Row>

          <S.StyledRow span="3">
            <CustomSelect
              label={intl.formatMessage({
                id: 'electronicBillingReport.filterBy',
              })}
              isBordered
              inputName="filterBy"
              id="filterBy"
              inputValue={formik.values.filterBy}
              setValue={(label, value) => {
                formik.setFieldValue('filterBy', value);
                formik.setFieldValue('enrollment', '');
                formik.setFieldValue('associatedName', '');
              }}
              options={filterBy}
            />
          </S.StyledRow>

          {dynamicInputLabel && (
            <Row span="3">
              <Input
                placeholder={
                  labelOption === OptionsFilterBy.Enrollment
                    ? resultFilteringBy[0].label
                    : resultFilteringBy[1].label
                }
                id={
                  labelOption === OptionsFilterBy.Enrollment
                    ? 'enrollment'
                    : 'associatedName'
                }
                name={
                  labelOption === OptionsFilterBy.Enrollment
                    ? 'enrollment'
                    : 'associatedName'
                }
                onChange={formik.handleChange}
                value={
                  labelOption === OptionsFilterBy.Enrollment
                    ? formik.values.enrollment
                    : formik.values.associatedName
                }
                variant="lighter"
                type={
                  formik.values.filterBy === 'enrollment' ? 'number' : 'text'
                }
                label={
                  labelOption === OptionsFilterBy.Enrollment
                    ? resultFilteringBy[0].label
                    : resultFilteringBy[1].label
                }
              />
            </Row>
          )}

          <S.StyledFile span="3">
            <CustomSelect
              label={intl.formatMessage({
                id: 'electronicBillingReport.file',
              })}
              required
              isBordered
              inputName="file"
              id="file"
              inputValue={formik.values.file}
              setValue={formik.setFieldValue}
              options={file}
              hasErrors={Boolean(formik.errors.file)}
              helperText={String(formik.errors.file)}
            />
          </S.StyledFile>
        </S.QueryWrapper>

        <Divider />
        <S.ButtonContainer>
          <ButtonLink type="submit" disabled={isLoading}>
            {intl.formatMessage({
              id: 'electronicBillingReport.downloadReport',
            })}
            <RoundedIcon>
              <FaArrowRight />
            </RoundedIcon>
          </ButtonLink>
        </S.ButtonContainer>
      </S.Form>
      <AlertDialog delay={2000} />
    </HomeContent>
  );
};

export default ElectronicBillingReport;
