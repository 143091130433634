import React, { ButtonHTMLAttributes, ComponentType } from 'react';

import { ButtonVariant } from 'components/shared';
import Box from 'components/shared/core/box';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';

export interface RoundedLinkProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ComponentType<any>;
  label: string;
  variant: ButtonVariant;
}

const RoundedLink = ({ icon: Icon, label, ...other }: RoundedLinkProps) => (
  <ButtonLink {...other}>
    {label}
    <Box>
      <RoundedIcon>
        <Icon />
      </RoundedIcon>
    </Box>
  </ButtonLink>
);

export default RoundedLink;
