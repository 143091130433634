import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { BreadcrumbLink } from 'components/shared';

import { Urls } from 'constants/urls';

import { OptionsSearchFor } from './types';

export const useCurrentCampaignConstants = () => {
  const intl = useIntl();
  const breadcrumbLinks: BreadcrumbLink[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
        redirectTo: Urls.HomePage,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.chargeAssociate' }),
        redirectTo: Urls.associateChargeMenu,
      },
      {
        label: intl.formatMessage({
          id: 'breadcrumb.reportAndResearchMenu',
        }),
        redirectTo: Urls.reportAndResearchMenu,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.currentCampaign' }),
        redirectTo: Urls.currentCampaign,
      },
    ],
    [intl]
  );

  const searchFor = [
    {
      label: intl.formatMessage({
        id: 'currentCampaign.SearchFor.label.enrollment',
      }),
      value: OptionsSearchFor.Subscription,
    },
    {
      label: intl.formatMessage({
        id: 'currentCampaign.SearchFor.label.associatedName',
      }),
      value: OptionsSearchFor.AssociateName,
    },
    {
      label: intl.formatMessage({
        id: 'currentCampaign.SearchFor.label.ticketCollector',
      }),
      value: 'userId',
    },
  ];

  const resultSearchFor = [
    {
      label: intl.formatMessage({
        id: 'currentCampaign.SearchFor.label.enrollment',
      }),
      value: OptionsSearchFor.Subscription,
    },

    {
      label: intl.formatMessage({
        id: 'currentCampaign.SearchFor.label.associatedName',
      }),
      value: OptionsSearchFor.AssociateName,
    },
    {
      label: intl.formatMessage({
        id: 'currentCampaign.SearchFor.label.ticketCollector',
      }),
      value: 'userId',
    },
  ];

  return {
    breadcrumbLinks,
    searchFor,
    resultSearchFor,
  };
};
