import { useQuery } from 'react-query';

import { postSellingReport } from 'services/postSellingReport';

export const useGetMailing = (
  startDate: string,
  endDate: string,
  selectAll: boolean,
  downloadReport: (data: any) => void
) => {
  const { exportMailing } = postSellingReport();
  const {
    isLoading: getMailingLoading,
    data: getMailingResponse,
    status: fileStatus,
    refetch,
  } = useQuery(
    ['getMailing', startDate, endDate, selectAll],
    () => exportMailing(startDate, endDate, selectAll),
    {
      enabled: false,
      onSuccess: (response) => {
        response && downloadReport(response.data);
      },
    }
  );

  return {
    isLoading: getMailingLoading,
    isFinished: fileStatus === 'success',
    getMailingResponse,
    refetch,
  };
};
