import { ReactNode } from 'react';

import * as S from './ContainerOverflow.styles';

type ContainerOverflowProps = {
  children: ReactNode;
  height?: number;
};

const ContainerOverflow = ({ children, height }: ContainerOverflowProps) => (
  <S.Container height={height}>{children}</S.Container>
);

export default ContainerOverflow;
