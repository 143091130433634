import styled, { css } from 'styled-components';

import Skeleton from 'components/shared/newCore/Skeleton';
import Table from 'components/shared/newCore/Table';

import { device } from 'styles/tokens/devices';

interface StatusColumnProps {
  status: string;
}

export const Container = styled.div`
  ${Table.Column} {
    width: 16.67%;

    @media ${device.tabletL} {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

export const ContentTable = styled.div``;

export const EmptyStateContainer = styled.section`
  ${({ theme }) => css`
    margin-top: ${theme.new.spacing.xl6};
    display: flex;
    justify-content: center;
  `}
`;

export const ListSkeleton = styled(Skeleton)`
  ${({ theme }) => css`
    width: calc(100% - 8px);
    height: 72px;
    margin-bottom: ${theme.new.spacing.sm};
  `}
`;

export const ListItemColumn = styled.section`
  ${({ theme }) => css`
    color: ${theme.new.colors.neutrals[400]};
    font-size: ${theme.new.typography.size.default};
    padding: ${theme.new.spacing.xl} 0;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-all;
    text-overflow: ellipsis;
    text-align: center;

    &:last-child {
      display: flex;
      justify-content: flex-end;

      @media ${device.tabletL} {
        justify-content: flex-start;
      }
    }
  `};
`;

const textVariants: any = {
  completed: css`
    color: ${({ theme }) => theme.new.colors.status.information};
  `,
  finished: css`
    color: ${({ theme }) => theme.new.colors.status.success};
  `,
  pending: css`
    color: ${({ theme }) => theme.new.colors.secondary.dark};
  `,
  processing: css`
    color: ${({ theme }) => theme.new.colors.status.information};
  `,
  failed: css`
    color: ${({ theme }) => theme.new.colors.status.error};
  `,
};

export const Button = styled.button`
  background: transparent;
  border: none;
  @media ${device.tabletL} {
    height: 1.5rem;
    svg {
      height: 100%;
    }
    div {
      div {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  @media ${device.tabletL} {
    display: flex;
    flex-direction: row;
    display: inline-block;
  }
`;

export const MobileControl = styled.div`
  span {
    display: none;
  }
  @media ${device.tabletL} {
    font-size: 0.875rem;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    span {
      color: ${({ theme }) => theme.new.colors.primary.main};
      font-weight: 600;
      display: inline;
      padding-right: 0.2rem;
    }
  }
`;

export const ControlSpaceArea = styled.div`
  text-align: center;
  max-width: 300px;
  overflow: hidden;
  span {
    display: none;
  }
  @media ${device.tabletL} {
    text-align: left;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    span {
      color: ${({ theme }) => theme.new.colors.primary.main};
      font-weight: 600;
      display: inline;
      padding-right: 0.2rem;
    }
  }
`;

export const StatusColumn = styled(ControlSpaceArea)<StatusColumnProps>`
  ${({ status }) => textVariants[status]}
  span {
    display: none;
  }
  @media ${device.tabletL} {
    padding: 0px;
    font-size: 0.875rem;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    span {
      display: inline;
      font-weight: 600;
      color: ${({ theme }) => theme.new.colors.primary.main};
      padding-right: 0.2rem;
    }
  }
`;
