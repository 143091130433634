import {
  BatchAssociateInputType,
  BatchFormOptionsType,
  BatchRequestType,
  BatchType,
  CreatePaymentSlipForm,
} from 'types/PaymentSlip';

import {
  clearFilterList,
  getCurrentMonthFirstDay,
  getCurrentMonthLastDay,
} from './utils';

export const getInitialValues = (): CreatePaymentSlipForm => ({
  archive: null,
  bankAccountId: '',
  cityGroupId: '',
  dateEnd: '',
  dateStart: '',
  associates: '',
  groups: '',
  bankPercentages: [
    {
      bankAccountId: '',
      percentage: 0,
      acceptsNordeste: false,
      enablesApiIntegration: false,
    },
  ],
});

export const mapToApi = (
  formSelection: BatchFormOptionsType,
  formValues: CreatePaymentSlipForm
): BatchRequestType => {
  if (formSelection.isReadjustments) {
    const groups = clearFilterList(formValues.groups ?? '').map((group) => ({
      group,
    }));
    const associates = clearFilterList(formValues.associates ?? '').map(
      (subscriptionCode) => ({ subscriptionCode })
    );

    const data = {
      batchType: BatchType.Readjustments,
      associateInputType: BatchAssociateInputType.Sheets,
      dateStart: formValues.dateStart,
      dateEnd: formValues.dateEnd,
      bankPercentages: formValues.bankPercentages.map((item) => {
        const bankPercentagesFormatted = { ...item };
        bankPercentagesFormatted.percentage =
          Number(bankPercentagesFormatted.percentage) / 100;
        return bankPercentagesFormatted;
      }),
      groups,
      associates,
    };

    return data;
  }
  if (formSelection.isNewBySheet) {
    const data = {
      batchType: BatchType.NewAssociates,
      associateInputType: BatchAssociateInputType.Sheets,
      dateStart: getCurrentMonthFirstDay(),
      dateEnd: getCurrentMonthLastDay(),
      bankPercentages: [
        {
          bankAccountId: formValues.bankAccountId,
          percentage: 1,
          acceptsNordeste: false,
          enablesApiIntegration: true,
        },
      ],
    };

    return data;
  }

  if (formSelection.isNewByApi) {
    const data = {
      batchType: BatchType.NewAssociates,
      associateInputType: BatchAssociateInputType.Api,
      dateStart: formValues.dateStart,
      dateEnd: formValues.dateEnd,
      bankPercentages: [
        {
          bankAccountId: formValues.bankAccountId,
          percentage: 1,
          acceptsNordeste: false,
          enablesApiIntegration: true,
        },
      ],
      cityGroup: formValues.cityGroupId
        ? {
            id: formValues.cityGroupId,
          }
        : undefined,
    };

    return data;
  }

  return {} as BatchRequestType;
};
