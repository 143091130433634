import { AxiosError } from 'axios';
import {
  EquipmentBranchType,
  EquipmentGroupType,
  EquipmentLoan,
  EquipmentLoanBody,
  EquipmentLoanParams,
  EquipmentResponse,
  OrthopedicEquipmentBranchesParams,
  OrthopedicEquipmentGroupsParams,
  OrthopedicEquipmentParams,
  ResponseWithPagination,
} from 'types';
import {
  EquipmentsFormProps,
  LoanUpdateParams,
  MetaPagination,
  OrthopedicAssociate,
  OrthopedicAssociateDebits,
  OrthopedicAssociatesDebitsParams,
  OrthopedicAssociatesParams,
  OrthopedicError,
  OrthopedicMaintenanceBody,
} from 'types/Orthopedic';

import { getURLWithParams } from 'lib/contextual';

import { ORTHOPEDIC_ENDPOINTS } from 'constants/endpoints';

import api from './api';

export const orthopedicLoan = () => {
  // Associate
  const getAllOrthopedicAssociates = async (): Promise<
    ResponseWithPagination<OrthopedicAssociate>
  > => {
    const url = `${ORTHOPEDIC_ENDPOINTS.GET_ASSOCIATES}`;
    const result = await api.getRequest(url);

    return result.data;
  };

  const getAllOrthopedicAssociatesWithParams = async ({
    page = 1,
    limit = 10,
    cnpj,
    cpf,
    name,
    rg,
    personType,
  }: OrthopedicAssociatesParams): Promise<
    ResponseWithPagination<OrthopedicAssociate>
  > => {
    const queryParams = {
      page,
      limit,
      name,
      cpf,
      cnpj,
      rg,
      personType,
    };

    const params = Object.fromEntries(
      Object.entries({
        ...queryParams,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(
      `${ORTHOPEDIC_ENDPOINTS.GET_ASSOCIATES}`,
      params
    );
    const allOrthopedicAssociates = await api.getRequest(url);
    return allOrthopedicAssociates.data;
  };

  const getOrthopedicAssociate = async (
    subscription: string
  ): Promise<OrthopedicAssociate> => {
    const url = `${ORTHOPEDIC_ENDPOINTS.GET_ASSOCIATE_ONE(subscription)}`;
    const result = await api.getRequest(url);

    return result.data;
  };

  const getOrthopedicAssociateDebits = async ({
    subscription,
    limit = 50,
    page = 1,
  }: OrthopedicAssociatesDebitsParams): Promise<OrthopedicAssociateDebits> => {
    const url = `${ORTHOPEDIC_ENDPOINTS.GET_ASSOCIATE_DEBIT(subscription)}`;

    const result = await api.getRequest(url, {
      params: {
        limit,
        page,
      },
    });

    return result.data;
  };

  const getAllOrthopedicEquipmentsLoan = async (): Promise<
    ResponseWithPagination<EquipmentLoan>
  > => {
    const url = `${ORTHOPEDIC_ENDPOINTS.GET_EQUIPMENTS_LOAN}`;
    const result = await api.getRequest(url);
    return result.data;
  };

  const getOrthopedicEquipmentLoan = async (
    id: string
  ): Promise<EquipmentLoan> => {
    const url = `${ORTHOPEDIC_ENDPOINTS.GET_EQUIPMENT_LOAN_ONE(id)}`;
    const result = await api.getRequest(url);

    return result.data;
  };

  const createOrthopedicEquipmentLoan = async (
    data: EquipmentLoanBody
  ): Promise<EquipmentLoan> => {
    const url = `${ORTHOPEDIC_ENDPOINTS.POST_EQUIPMENTS_LOAN}`;
    const result = await api.postRequest(url, data);

    return result.data;
  };

  const createOrthopedicEquipmentLoanContract = async (
    id: string
  ): Promise<File> => {
    const url = `${ORTHOPEDIC_ENDPOINTS.POST_EQUIPMENT_LOAN_CONTRACT(id)}`;
    const result = await api.postRequest(
      url,
      { undefined },
      {
        responseType: 'blob',
      }
    );

    return result.data;
  };

  const createOrthopedicEquipmentLoanContractReturn = async (
    id: string
  ): Promise<File> => {
    const url = `${ORTHOPEDIC_ENDPOINTS.POST_EQUIPMENT_LOAN_CONTRACT_RETURN(
      id
    )}`;
    const result = await api.postRequest(
      url,
      { undefined },
      {
        responseType: 'blob',
      }
    );

    return result.data;
  };

  const updateOrthopedicEquipmentLoan = async (
    data: LoanUpdateParams
  ): Promise<EquipmentLoan> => {
    const url = `${ORTHOPEDIC_ENDPOINTS.PATCH_EQUIPMENT_LOAN_ONE(data.id)}`;
    const result = await api.patchRequest(url, {
      returnDate: data.returnDate,
      returnComment: data.returnComment,
      platformUserId: data.platformUserId,
    });

    return result.data;
  };

  const updateReturnOrthopedicEquipmentLoan = async (id: string) => {
    const url = `${ORTHOPEDIC_ENDPOINTS.PATCH_EQUIPMENT_LOAN_RETURN_ONE(id)}`;
    const result = await api.patchRequest(url);

    return result.data;
  };

  const updateOrthopedicEquipment = async (data: EquipmentsFormProps) => {
    const url = `${ORTHOPEDIC_ENDPOINTS.PATCH_EQUIPMENT(data.id as number)}`;
    const result = await api.patchRequest(url, data);

    return result;
  };

  const createOrthopedicEquipment = async (data: EquipmentsFormProps) => {
    const url = `${ORTHOPEDIC_ENDPOINTS.POST_EQUIPMENT}`;
    const result = await api.postRequest(url, data);

    return result;
  };

  const deleteOrthopedicEquipment = async (id: number) => {
    const url = `${ORTHOPEDIC_ENDPOINTS.DELETE_EQUIPMENT(id)}`;

    try {
      const result = await api.deleteRequest(url);
      return result;
    } catch (error) {
      const e = error as unknown as AxiosError<OrthopedicError>;
      throw e;
    }
  };

  const getAllOrthopedicEquipmentsLoanWithParams = async (
    queryParams: EquipmentLoanParams
  ): Promise<ResponseWithPagination<EquipmentLoan>> => {
    const params = Object.fromEntries(
      Object.entries({
        ...queryParams,
      }).filter(([_key, value]) => value !== undefined)
    );

    const allOrthopedicAssociates = await api.getRequest(
      ORTHOPEDIC_ENDPOINTS.GET_EQUIPMENTS_LOAN,
      {
        params,
      }
    );
    return allOrthopedicAssociates.data;
  };

  const getAllOrthopedicEquipmentsWithParams = async ({
    page = 1,
    limit = 10,
    term,
    branch,
    equipmentGroup,
    status,
    id,
  }: OrthopedicEquipmentParams): Promise<{
    meta: MetaPagination;
    items: Array<EquipmentResponse>;
  }> => {
    const queryParams = {
      page,
      limit,
      term,
      branch,
      equipmentGroup,
      status,
      id,
      retries: Date.now(),
    };

    const params = Object.fromEntries(
      Object.entries({
        ...queryParams,
      }).filter(([_key, value]) => value !== undefined)
    );

    const allOrthopedicAssociates = await api.getRequest(
      ORTHOPEDIC_ENDPOINTS.GET_EQUIPMENTS,
      {
        params,
      }
    );

    return allOrthopedicAssociates.data;
  };

  const getOrthopedicEquipmentProperty = async (
    id: string
  ): Promise<EquipmentResponse> => {
    const url = `${ORTHOPEDIC_ENDPOINTS.GET_EQUIPMENT_ONE(id)}`;
    const result = await api.getRequest(url);

    return result.data;
  };

  // Branches

  const getAllEquipmentBranches = async ({
    name,
    id,
    limit = 100,
    page = 1,
  }: OrthopedicEquipmentBranchesParams): Promise<
    ResponseWithPagination<EquipmentBranchType>
  > => {
    const queryParams = {
      page,
      limit,
      name,
      id,
    };

    const params = Object.fromEntries(
      Object.entries({
        ...queryParams,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(
      `${ORTHOPEDIC_ENDPOINTS.GET_BRANCHES}`,
      params
    );
    const result = await api.getRequest(url);

    return result.data;
  };

  const getEquipmentBranch = async (
    id: string
  ): Promise<EquipmentBranchType> => {
    const url = `${ORTHOPEDIC_ENDPOINTS.GET_BRANCHES_ONE(id)}`;
    const result = await api.getRequest(url);

    return result.data;
  };

  // Groups

  const getAllEquipmentGroups = async ({
    description,
    id,
    limit = 100,
    page = 1,
  }: OrthopedicEquipmentGroupsParams): Promise<
    ResponseWithPagination<EquipmentGroupType>
  > => {
    const queryParams = {
      page,
      limit,
      description,
      id,
    };

    const params = Object.fromEntries(
      Object.entries({
        ...queryParams,
      }).filter(([_key, value]) => value !== undefined)
    );

    const url = getURLWithParams(
      `${ORTHOPEDIC_ENDPOINTS.GET_EQUIPMENTS_GROUPS}`,
      params
    );
    const result = await api.getRequest(url);

    return result.data;
  };

  const getEquipmentGroup = async (id: string): Promise<EquipmentGroupType> => {
    const url = `${ORTHOPEDIC_ENDPOINTS.GET_EQUIPMENT_GROUP_ONE(id)}`;
    const result = await api.getRequest(url);

    return result.data;
  };

  const createGroup = async (data: EquipmentGroupType) => {
    const response = await api.postRequest(
      ORTHOPEDIC_ENDPOINTS.POST_EQUIPMENTS_GROUPS,
      data
    );
    return response.data;
  };

  const updateGroup = async (data: EquipmentGroupType) => {
    const response = await api.patchRequest(
      ORTHOPEDIC_ENDPOINTS.PATCH_EQUIPMENT_GROUP_ONE(data.id),
      data
    );
    return response.data;
  };

  const deleteGroup = async (id: string) => {
    const response = await api.deleteRequest(
      ORTHOPEDIC_ENDPOINTS.DELETE_EQUIPMENT_GROUP_ONE(id)
    );
    return response.data;
  };

  // Maintenance

  const createMaintenance = async (data: OrthopedicMaintenanceBody) => {
    const response = await api.postRequest(
      ORTHOPEDIC_ENDPOINTS.POST_MAINTENANCE,
      data
    );
    return response.data;
  };

  return {
    getAllOrthopedicAssociates,
    getAllOrthopedicAssociatesWithParams,
    getOrthopedicAssociate,
    getAllOrthopedicEquipmentsLoan,
    getAllOrthopedicEquipmentsLoanWithParams,
    getOrthopedicEquipmentLoan,
    getAllOrthopedicEquipmentsWithParams,
    getOrthopedicEquipmentProperty,
    createMaintenance,
    updateOrthopedicEquipmentLoan,
    updateReturnOrthopedicEquipmentLoan,
    updateOrthopedicEquipment,
    createOrthopedicEquipmentLoan,
    createOrthopedicEquipmentLoanContract,
    createOrthopedicEquipmentLoanContractReturn,
    createOrthopedicEquipment,
    deleteOrthopedicEquipment,
    getAllEquipmentBranches,
    getEquipmentBranch,
    getAllEquipmentGroups,
    getEquipmentGroup,
    getOrthopedicAssociateDebits,
    createGroup,
    updateGroup,
    deleteGroup,
  };
};
