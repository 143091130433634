export const breakpoints = {
  small: '576px', // small devices
  medium: '768px', // Medium devices (tablet)
  large: '992px', // Large
  mobileS: '320px',
  mobileXs: '360px',
  mobileM: '375px',
  mobileL: '425px',
  tabletS: '650px',
  tablet: '768px',
  tabletL: '768px',
  tabletG: '850px',
  laptopS: '950px',
  laptop: '1024px',
  laptopHD: '1279px',
  desktopMini: '1150px',
  laptopL: '1440px',
  desktop: '2560px',
} as const;
