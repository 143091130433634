import * as AlertDialog from '@radix-ui/react-alert-dialog';
import styled, { css, Keyframes, keyframes } from 'styled-components';

const modalToValue = '-50%, -50%, 0';
const modalFromValue = '-50%, 0, 0';
const contentFromValue = '0, -25px, 0';
const contentToValue = '0, 0, 0';

const animationFrame = (isModal: boolean, close: boolean) =>
  close
    ? keyframes`
  0% {
    opacity: 1;
    -webkit-transform: translate3d(${isModal ? modalToValue : contentToValue});
    transform: translate3d(${isModal ? modalToValue : contentToValue});
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(${
      isModal ? modalFromValue : contentFromValue
    });
    transform: translate3d(${isModal ? modalFromValue : contentFromValue});
  }
`
    : keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(${
      isModal ? modalFromValue : contentFromValue
    });
    transform: translate3d(${isModal ? modalFromValue : contentFromValue});
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(${isModal ? modalToValue : contentToValue});
    transform: translate3d(${isModal ? modalToValue : contentToValue});
  }
`;

const openAnimation = (animation: Keyframes) => css`
  animation: ${animation} 0.3s ease-out 0s forwards;
  -webkit-animation: ${animation} 0.3s ease-out 0s forwards;
`;

const closeAnimation = (animation: Keyframes) => css`
  animation: ${animation} 0.3s ease-out 0.05s forwards;
  -webkit-animation: ${animation} 0.3s ease-out 0.05s forwards;
`;

type LoaderProps = {
  isLoading?: boolean;
};

export const Overlay = styled(AlertDialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Modal = styled(AlertDialog.Content)<{ $isClosing: boolean }>`
  ${({ theme, $isClosing }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 558px;
    min-height: 361px;
    height: fit-content;
    background-color: #fff;
    border-radius: ${theme.borderRadius.md};
    box-shadow: ${theme.elevation.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    ${$isClosing
      ? closeAnimation(animationFrame(true, $isClosing))
      : openAnimation(animationFrame(true, $isClosing))};
  `}

  @media screen and (max-width: 585px) {
    width: 20rem;
  }
`;

export const Content = styled.div<{ $isClosing: boolean }>`
  ${({ theme, $isClosing }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    padding-left: ${theme.spacing.lg};
    padding-right: ${theme.spacing.lg};
    padding-top: ${theme.spacing.lg};
    opacity: 0;

    ${$isClosing
      ? openAnimation(animationFrame(false, $isClosing))
      : closeAnimation(animationFrame(false, $isClosing))};
  `};
`;

export const Title = styled.span`
  ${({ theme }) => css`
    color: ${theme.texts.darkSecondary};
    font-size: ${theme.fontSizes.large};
    margin-top: ${theme.spacing.sm};
  `}
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 585px) {
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.medium2};
    `}
  }
`;

export const Text = styled.p`
  margin-top: 18px;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.cards.text};
  text-align: center;
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    border-top: 1px solid ${theme.separatorColorLight};
    padding-top: ${theme.spacing.sm1};
    padding-bottom: ${theme.spacing.sm1};
    margin-top: ${theme.spacing.lg};
    justify-content: space-between;
    width: 100%;
  `}
`;

export const ContainerLoader = styled.div<LoaderProps>`
  ${({ theme, isLoading }) =>
    isLoading &&
    css`
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      opacity: 0.5;
      border-radius: ${theme.borderRadius.md};
    `}
`;

export const Loader = styled.div<LoaderProps>`
  ${({ theme, isLoading }) =>
    isLoading &&
    css`
      position: absolute;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: ${theme.spacing.xs} solid transparent;
      border-top: ${theme.spacing.xs} solid ${theme.texts.darkSecondary};
      border-bottom: ${theme.spacing.xs} solid ${theme.texts.darkSecondary};
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;

      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}
`;
