import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { EquipmentLoan, EquipmentLoanParams } from 'types';
import { convertJsonToExcel } from 'utils-general/convertJsontoExcel';

import { orthopedicLoan } from 'services/orthopedic';

export const useFindAllOrthopedicLoan = (
  {
    limit = 100,
    id,
    subscription,
    equipmentId,
    name,
    cpf,
    outDate,
    forecastReturnDate,
    returnDate,
    equipmentGroup,
    equipmentStatus,
  }: EquipmentLoanParams,
  enabledOnMount?: boolean,
  hasQueries?: boolean
) => {
  const { getAllOrthopedicEquipmentsLoanWithParams } = orthopedicLoan();

  const queries = hasQueries ? { subscription, id, equipmentId } : {};

  const {
    data: allOrthopedicLoans,
    error,
    ...rest
  } = useInfiniteQuery(
    ['findAllOrthopedicEquipmentsLoan', queries],
    ({ pageParam }) =>
      getAllOrthopedicEquipmentsLoanWithParams({
        page: pageParam,
        limit,
        id,
        subscription,
        equipmentId,
        name,
        cpf,
        outDate,
        forecastReturnDate,
        returnDate,
        equipmentGroup,
        equipmentStatus,
      }),
    {
      getNextPageParam: (data) => {
        if (data.items?.length === 0) return;
        return data.meta.currentPage + 1;
      },
      enabled: enabledOnMount,
      staleTime: 1000 * 20,
    }
  );

  const convertToExcel = (data: EquipmentLoan[]) => {
    const excel = data?.map((loan: EquipmentLoan) => {
      const xlsx = {
        Codigo: loan.id,
        Inscricao: loan.subscription,
        Equipamento1: loan?.equipment1?.description,
        Equipamento2: loan?.equipment2?.description,
        Grupo_Equipamento_1: loan?.equipment1?.equipmentGroup?.description,
        Grupo_Equipamento_2: loan?.equipment2?.equipmentGroup?.description,
        Filial: loan?.equipment1?.branch?.name,
        Nome: loan.name,
        Cep: loan.postalCode,
        Bairro: loan.neighborhood,
        Rua: loan.street,
        Cidade: loan.city.name,
        UF: loan.city.state,
        Data_Emprestimo: loan.outDate,
        Data_Previsao_Retorno: loan.forecastReturnDate,
        Data_Devolucao: loan?.returnDate,
        Situacao: loan.isClosed ? 'Devolvido' : 'Emprestado',
        Comentario_Devolucao: loan.returnCommment,
        Comentario_Emprestimo: loan.outCommment,
      };
      return xlsx;
    });
    convertJsonToExcel(excel, `Relatorio de empréstimos`);
  };

  const allValues = useMemo(
    () => allOrthopedicLoans?.pages.flatMap(({ items }) => items),
    [allOrthopedicLoans]
  );

  return {
    allOrthopedicLoans: allValues?.length ? allValues : [],
    convertToExcel,
    ...rest,
  };
};
