import { useMutation } from 'react-query';
import { MutateListener } from 'types';

import { orthopedicLoan } from 'services/orthopedic';

export const useRegisterMaintenance = ({
  onSuccess,
  onError,
}: MutateListener) => {
  const { createMaintenance } = orthopedicLoan();

  return useMutation(createMaintenance, {
    onSuccess,
    onError,
  });
};
