export default function downloadBlobPDF(file: File | Blob, filename: string) {
  const fileURL = URL.createObjectURL(file);
  const anchor = document.createElement('a');
  anchor.href = fileURL;
  anchor.download = filename.endsWith('.pdf') ? filename : `${filename}.pdf`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(fileURL);
}
