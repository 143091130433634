import styled, { css } from 'styled-components';

import Skeleton from 'components/shared/newCore/Skeleton';

export type InputVariant = 'lighter' | 'darken';

interface ContainerProps {
  disabled: boolean;
  isAccordionRegistrationDataForm?: boolean;
}

interface CustomInputProps {
  isAccordionRegistrationDataForm?: boolean;
}
interface InputWrapperProps {
  error?: boolean;
  disabled?: boolean;
  startAdornment: boolean;
  endAdornment: boolean;
  hasBorder?: boolean;
}

export const Label = styled.label`
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.input.outlined.label};

  &.lighter {
    color: ${(props) => props.theme.input.primary.label};
    font-weight: 600;
  }

  span {
    color: ${({ theme }) => theme.contextual.error};
  }
`;

export const Container = styled.div<ContainerProps>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
  ${(props) =>
    props.disabled &&
    props.isAccordionRegistrationDataForm &&
    css`
      opacity: 1;
    `}
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  margin-top: ${({ theme }) => theme.spacing.xs};
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.input.outlined.background};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.contextual.error}` : 'none'};

  &.lighter,
  &.lighter_in_darken {
    background-color: ${({ theme }) => theme.input.primary.background};
    ${({ hasBorder, error }) =>
      hasBorder &&
      !error &&
      css`
        border: ${({ theme }) => theme.input.primary.optionalBorder};
      `}
    ${(props) =>
      props.disabled &&
      css`
        background-color: ${({ theme }) => theme.input.primary.background};
        border: none;
      `}
  }

  &.lighter:focus-within {
    border: 1px solid ${({ theme }) => theme.input.primary.label};
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${({ theme }) => theme.surface.transparent};
      border: ${({ theme }) => theme.input.disabled.border};
    `}

  &:focus-within, &:focus-visible {
    border: 1px solid ${({ theme }) => theme.surface.main};
  }

  ${(props) =>
    props.startAdornment &&
    css`
      padding-left: ${({ theme }) => theme.spacing.md};
    `}

  ${(props) =>
    props.endAdornment &&
    css`
      padding-right: ${({ theme }) => theme.spacing.md};
    `}


  overflow: hidden;
`;

export const SkeletonWrapper = styled(Skeleton)`
  margin-top: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  height: 48px;
`;

export const CustomInput = styled.input<CustomInputProps>`
  width: 100%;
  padding: 14px ${({ theme }) => theme.spacing.sm};
  font-size: ${({ theme }) => theme.fontSizes.small};
  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.surface.transparent};
  color: ${({ theme }) => theme.input.outlined.textColor};
  letter-spacing: -0.02em;
  line-height: 100%;
  &.lighter {
    color: ${({ theme }) => theme.input.primary.textColor};

    ${(props) =>
      props.disabled &&
      css`
        color: ${({ theme }) => theme.input.primary.disabledTextColor};
      `}

    &::placeholder {
      color: ${({ theme }) => theme.input.placeholder.textColor};
      opacity: 0.6;
    }
  }

  &.lighter:-webkit-autofill {
    -webkit-text-fill-color: text;
    -webkit-text-fill-color: ${({ theme }) => theme.input.primary.textColor};
  }

  &:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme }) => theme.input.outlined.textColor};
  }
  ${(props) =>
    props.disabled &&
    props.isAccordionRegistrationDataForm &&
    css`
      color: ${({ theme }) => theme.new.colors.status.subtext};
      font-weight: 600;
      background-color: #dddff1;
    `}
`;

export const StartAdornmentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.spacing.sm};
  transform: translateY(-50%);
`;

export const ErrorLabel = styled.small`
  color: ${(props) => props.theme.contextual.error};
  font-size: ${(props) => props.theme.fontSizes.small};
`;

export const EndAdornmentWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing.sm};
  transform: translateY(-50%);
`;
