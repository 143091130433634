import * as S from './styles';

interface LoadingSpinnerProps {
  width?: number;
  height?: number;
  borderSize?: number;
}
const LoadingSpinner = (props: LoadingSpinnerProps) => (
  <S.LoadingArea>
    <S.Container {...props} />
  </S.LoadingArea>
);

export default LoadingSpinner;
