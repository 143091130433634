import styled, { css } from 'styled-components';

import { TextareaSize } from '.';

export type InputVariant = 'lighter' | 'darken';
interface InputWrapperProps {
  error?: boolean;
  disabled?: boolean;
  hasBorder?: boolean;
  startAdornment: boolean;
  endAdornment: boolean;
}

interface LabelProps {
  size?: TextareaSize;
}

export const Label = styled.label<LabelProps>`
  text-transform: uppercase;
  font-size: ${({ theme, size }) => {
    switch (size) {
      case 'small':
        return theme.fontSizes.xs;
      default:
        return theme.fontSizes.small;
    }
  }};
  color: ${(props) => props.theme.input.outlined.label};

  span {
    color: ${({ theme }) => theme.contextual.error};
  }

  &.lighter {
    color: ${(props) => props.theme.input.primary.label};
    font-weight: 600;
  }
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: ${({ theme }) => theme.spacing.xs};
  width: 100%;
  background-color: ${({ theme }) => theme.input.outlined.background};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.contextual.error}` : 'none'};

  &.lighter {
    background-color: ${({ theme }) => theme.input.primary.background};
    ${({ hasBorder, error }) =>
      hasBorder &&
      !error &&
      css`
        border: ${({ theme }) => theme.input.primary.optionalBorder};
      `}
    ${(props) =>
      props.disabled &&
      css`
        background-color: ${({ theme }) => theme.surface.transparent};
        border: ${({ theme }) => theme.input.primary.disabledBorder};
      `}
  }

  &.lighter:focus-within {
    border: 1px solid ${({ theme }) => theme.input.primary.focusColor};
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${({ theme }) => theme.surface.transparent};
      border: ${({ theme }) => theme.input.disabled.border};
    `}

  &:focus-within, &:focus-visible {
    border: 1px solid ${({ theme }) => theme.surface.main};
  }

  ${(props) =>
    props.startAdornment &&
    css`
      padding-left: ${({ theme }) => theme.spacing.md};
    `}

  ${(props) =>
    props.endAdornment &&
    css`
      padding-right: ${({ theme }) => theme.spacing.md};
    `}
  overflow: hidden;
`;

interface CustomInputProps {
  hasStartAdornment: boolean;
}

export const CustomInput = styled.textarea<CustomInputProps>`
  width: 98%;
  padding: 21px ${({ theme }) => theme.spacing.sm};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.surface.transparent};
  color: ${({ theme }) => theme.input.outlined.textColor};
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.new.colors.neutrals[400]};
  }

  &.lighter {
    ${({ theme }) => css`
      font-size: ${theme.new.typography.size.default};
      line-height: ${theme.new.typography.lineHeight.default};
      color: ${theme.new.colors.neutrals[700]};
    `}

    ${({ hasStartAdornment }) =>
      hasStartAdornment &&
      css`
        font-weight: 600;
      `}

    ${(props) =>
      props.disabled &&
      css`
        color: ${({ theme }) => theme.input.primary.disabledTextColor};
      `}
  }

  &.lighter:-webkit-autofill {
    -webkit-text-fill-color: text;
    -webkit-text-fill-color: ${({ theme }) => theme.input.primary.textColor};
  }

  &:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme }) => theme.input.outlined.textColor};
  }

  height: 96px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 200px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`;

export const StartAdornmentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const EndAdornmentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: ${({ theme }) => theme.spacing.md};
`;

export const ErrorLabel = styled.small`
  color: ${(props) => props.theme.contextual.error};
  font-size: ${(props) => props.theme.fontSizes.small};
`;
