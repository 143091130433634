import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useElectronicBillingReportValidation = () => {
  const intl = useIntl();

  return Yup.object().shape({
    startDate: Yup.date().required(
      intl.formatMessage({ id: 'cashReport.inputCashierDate.empty' })
    ),
    endDate: Yup.date()
      .required(intl.formatMessage({ id: 'cashReport.inputCashierDate.empty' }))
      .min(
        Yup.ref('startDate'),
        intl.formatMessage({
          id: 'reportUpdateAssociates.input.errorEndDate',
        })
      ),
    file: Yup.string().required(intl.formatMessage({ id: 'emptyField' })),
    filterBy: Yup.string(),
  });
};
