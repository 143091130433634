import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useFinancialCashReportValidation = () => {
  const intl = useIntl();
  return Yup.object().shape({
    typeOfDischarge: Yup.string().required(
      intl.formatMessage({ id: 'emptyField' })
    ),
    bank: Yup.array().when('typeOfDischarge', {
      is: 'bank',
      then: Yup.array()
        .min(1, intl.formatMessage({ id: 'emptyField' }))
        .required(intl.formatMessage({ id: 'emptyField' })),
    }),
    startDate: Yup.date().required(
      intl.formatMessage({ id: 'cashReport.inputCashierDate.empty' })
    ),
    endDate: Yup.date()
      .required(intl.formatMessage({ id: 'cashReport.inputCashierDate.empty' }))
      .min(
        Yup.ref('startDate'),
        intl.formatMessage({ id: 'reportUpdateAssociates.input.errorEndDate' })
      ),
    collectors: Yup.string().when('typeOfDischarge', {
      is: 'application',
      then: Yup.string().required(intl.formatMessage({ id: 'emptyField' })),
    }),
    user: Yup.string().when('typeOfDischarge', {
      is: 'user',
      then: Yup.string().required(intl.formatMessage({ id: 'emptyField' })),
    }),
  });
};
