import { useAlert } from 'contexts';
import React from 'react';
import { useIntl } from 'react-intl';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useOutletContext } from 'react-router-dom';

import {
  getApprovalById,
  getFitApprover,
  getPaginatedUserSync,
  patchReadjustmentApprover,
} from './api';
import { updateTuitionReadjustmentPayload } from './interfaces';
import { DtoTuitionReadjusmentProps } from './types';

export const useTuitionReadjusmentApproveOrDisapprove = () => {
  const { showAlert, setClear } = useAlert();
  const intl = useIntl();
  const mutation = useMutation({
    mutationFn: patchReadjustmentApprover,
    onError: (_err: Error, _, _context: any) => {
      setClear();
      showAlert({
        title: intl.formatMessage({
          id: 'modalEditPortionPrice.onBackendCall.error',
        }),
        type: 'error',
      });
    },
  });
  const makeUpdatePayload = (
    rawUpdatePayload: any
  ): updateTuitionReadjustmentPayload => {
    const normalizedPayload: updateTuitionReadjustmentPayload = {
      id: rawUpdatePayload.id,
      approvalUpdateObject: {
        status: rawUpdatePayload.status,
        reason: rawUpdatePayload.reason,
        approvingUser: rawUpdatePayload.approvingUser,
        userUpdated: rawUpdatePayload.approvingUser,
      },
    };
    return normalizedPayload;
  };
  const updateApproveOrDisapprove = async (
    updateAprroveOrDisapproveData: any,
    setIsOpen: (status: boolean) => void | undefined,
    setIsClosing: (status: boolean) => void
  ) => {
    try {
      showAlert({
        title: intl.formatMessage({
          id: 'tuitonReadjustment.modal.onBackendCall.loading',
        }),
        type: 'loading',
      });

      const payload = makeUpdatePayload(updateAprroveOrDisapproveData);
      await mutation.mutateAsync(payload);
      setClear();
      setIsClosing(true);
      setIsOpen(false);
      showAlert({
        title: intl.formatMessage({
          id: 'tuitonReadjustment.modal.onBackendCall.success',
        }),
        type: 'success',
      });
    } catch (e) {}
  };
  return {
    updateApproveOrDisapprove,
  };
};
export const useFetchTuitionFitApprovers = (
  { name }: { name?: string },
  alreadyHaveDependentData?: boolean
) => {
  const { data, error, ...rest } = useInfiniteQuery(
    ['findAllUsersSync', { name }],
    ({ pageParam }) =>
      getPaginatedUserSync({
        limit: 50,
        name,
        exclusiveStartKey: pageParam,
      }),
    {
      enabled: !!alreadyHaveDependentData,
      getNextPageParam: (lastPage) => {
        if (!lastPage.error) {
          return lastPage.lastEvaluatedKey === ''
            ? undefined
            : lastPage.lastEvaluatedKey;
        }
      },
      onError: (_err) => {
        //todo
      },
    }
  );

  const allValues = data?.pages.flatMap(({ items }) => items);

  return { ...rest, customError: error, allUsers: allValues };
};

export const useFetchRegisteredApproval = () => {
  const dynamicParams = {
    type: 1,
    status: 1,
  };
  return useQuery(['findFitApprover'], () => getFitApprover(dynamicParams), {
    enabled: false,
  });
};

export const useFetchApprovalById = (id: string) =>
  useQuery(['findFitApprover'], () => getApprovalById(id), {
    enabled: false,
  });
export function useOutletModalTuitionReadjusmentModal() {
  return useOutletContext<
    [DtoTuitionReadjusmentProps, React.SetStateAction<any>]
  >();
}
