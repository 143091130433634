export const shapes = {
  borderRadius: {
    none: 'none',
    sm: '4px',
    md: '8px',
    lg: '16px',
    full: '999px',
  },
  borderWidth: {
    none: 'none',
    md: '1px',
    lg: '2px',
    xl: '4px',
    xl2: '8px',
  },
} as const;
