import { useQuery } from 'react-query';

import { financial } from 'services/financial';

export const useGetBanksForFinancialReport = (
  enabledOnMount = true,
  page = 1,
  limit = 1000
) => {
  const { getBankFinanceReport } = financial();
  const { data: banks } = useQuery(
    ['banksToFinanceReport'],
    () => getBankFinanceReport(page, limit),
    {
      enabled: enabledOnMount,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
    }
  );
  return { banks };
};
