export enum DocumentsOptionsType {
  Carnet = 'carnet',
  Cover = 'cover',
  Label = 'label',
}

export enum ModelOptionsType {
  Taloner = 'TALONADORA',
  CarnetSheet = 'CARNET',
}

export enum BillPrintingOptionsType {
  NotPrinted = 'NOT_PRINTED',
  Reprint = 'PRINTED',
}

export enum searchForOptionsType {
  Registrations = 'SUBSCRIPTIONS',
  BatchCode = 'BATCH_CODE',
}
