import { City } from 'types/City';

export function sortCitiesByStateAndName(a: City, b: City): number {
  const stateComparison = a.state.localeCompare(b.state);

  if (stateComparison === 0) {
    return a.name.localeCompare(b.name);
  }

  return stateComparison;
}
