import styled from 'styled-components';

interface HomeContentProps {
  padding?: string;
}

const HomeContent = styled.div<HomeContentProps>`
  padding: ${({ padding }) => (padding ? padding : '6vh 8vw')};
  min-height: 100%;

  @media screen and (max-width: 703px) {
    padding: 7vw;
  }
`;

export default HomeContent;
