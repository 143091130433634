export enum PostSelingEnum {
  tag = 'Nome da Carga',
  subscription = 'Inscricao',
  name = 'Nome Cliente',
  phone1 = 'Telefone 1',
  ddd1 = 'DDD1',
  phone2 = 'Telefone 2',
  ddd2 = 'DDD2',
  phone3 = 'Telefone 3',
  ddd3 = 'DDD3',
  group = 'Grupo',
  cpfCnpj = 'CPF/CNPJ',
  createdAt = 'DataInsert',
  verifiedAt = 'DataInsertSmart',
}
