import { Root } from '@radix-ui/react-alert-dialog';
import { ReactNode, useEffect, useState } from 'react';

import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';

import { ReactComponent as AlertError } from 'assets/images/AlertError.svg';
import { ReactComponent as AlertSucess } from 'assets/images/AlertSuccess.svg';
import { ReactComponent as AlertWarning } from 'assets/images/AlertWarning.svg';

import * as S from './ConfirmDialog.styles';

export type AlertType = 'error' | 'success' | 'warn';

export const AlertIcon = (props: { type?: AlertType }) => {
  if (props.type === 'success') return <AlertSucess />;
  if (props.type === 'warn') return <AlertWarning />;
  if (props.type === 'error') return <AlertError />;
  return null;
};

interface ConfirmDialogProps {
  title: string;
  cancelLabel: string;
  confirmLabel?: string;
  iconType: AlertType;
  cancelIcon?: ReactNode;
  confirmIcon?: ReactNode;
  onCancel: () => void;
  onConfirm?: () => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  isLoading?: boolean;
  text?: string;
  children?: React.ReactChild;
  buttonDisable?: boolean;
}

const ConfirmDialog = ({
  title,
  cancelLabel,
  confirmLabel,
  iconType,
  cancelIcon,
  confirmIcon,
  onCancel,
  onConfirm,
  open,
  onOpenChange,
  isLoading,
  text,
  children,
  buttonDisable,
}: ConfirmDialogProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (open === isOpen) {
      return;
    }

    if (open) {
      setIsOpen(open);
    } else {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(open);
        setIsClosing(false);
      }, 300);
    }

    return () => {};
  }, [open, isOpen]);

  if (isLoading) {
    return (
      <Root onOpenChange={onOpenChange} open={isOpen}>
        <S.Overlay />
        <S.Modal $isClosing={isClosing}>
          {isLoading && (
            <S.ContainerLoader isLoading={isLoading}>
              <S.Loader isLoading={isLoading} />
            </S.ContainerLoader>
          )}
        </S.Modal>
      </Root>
    );
  }

  return (
    <Root onOpenChange={onOpenChange} open={isOpen}>
      <S.Overlay />
      <S.Modal $isClosing={isClosing}>
        {isLoading && (
          <S.ContainerLoader isLoading={isLoading}>
            <S.Loader isLoading={isLoading} />
          </S.ContainerLoader>
        )}

        <S.Content $isClosing={isClosing}>
          <AlertIcon type={iconType} />
          <S.Title>{title}</S.Title>

          {text && <S.Text>{text}</S.Text>}
          {children}
          <S.Footer>
            <ButtonLink onClick={onCancel} variant="lighter">
              {cancelIcon}
              {cancelLabel}
            </ButtonLink>
            {confirmLabel && (
              <ButtonLink
                disabled={buttonDisable}
                onClick={onConfirm && onConfirm}
                type="button"
                variant="lighter"
              >
                {confirmLabel}
                <RoundedIcon>{confirmIcon}</RoundedIcon>
              </ButtonLink>
            )}
          </S.Footer>
        </S.Content>
      </S.Modal>
    </Root>
  );
};

export default ConfirmDialog;
