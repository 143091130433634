import styled from 'styled-components';
import { space, SpaceProps, typography, TypographyProps } from 'styled-system';

export interface HeadingProps extends SpaceProps, TypographyProps {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  color?: string;
}

const Heading = styled('h1').attrs<HeadingProps>(({ level }) => ({
  as: `h${level}`,
}))<HeadingProps>`
  ${typography}
  ${space}
  color: ${(props) => props.color ?? '#000'};
`;

export default Heading;
