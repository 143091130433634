import { getItem } from 'hooks/useLocalStorage';

import { CashRegisterStatus, Permissions } from 'constants/enums';
import { LocalStorageKeys } from 'constants/localStorage';

import { Role } from './Session';

interface Rules {
  [key: string]: (role: Role, payload?: any) => boolean;
}

export const canAcessUserManager = () => true;

export const canAccessOpenBox = () =>
  getItem(LocalStorageKeys.cashRegisterStatus) !== CashRegisterStatus.ABERTO;

export const canAccessCloseBox = () =>
  getItem(LocalStorageKeys.cashRegisterStatus) === CashRegisterStatus.ABERTO;

export const canAccessReversal = () =>
  getItem(LocalStorageKeys.cashRegisterStatus) === CashRegisterStatus.ABERTO;

export const rules: Rules = {
  [Permissions.USER_MANAGER_ACCESS]: canAcessUserManager,
  [Permissions.OPEN_BOX_ACCESS]: canAccessOpenBox,
  [Permissions.CLOSE_BOX_ACCESS]: canAccessCloseBox,
  [Permissions.REVERSAL_ACCESS]: canAccessReversal,
};
