import { Grid, Row } from 'components/shared/core/grid';
import Input from 'components/shared/forms/Input';
import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { CreateOnlinePaymentSlipForm } from 'types/PaymentSlip';
import * as S from './CreateNewOnlineBatchReadjustments.styles';

interface CreateNewOnlineBatchReadjustmentsProps {
  formik: FormikProps<CreateOnlinePaymentSlipForm>;
  handleFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CreateNewOnlineBatchReadjustments = ({
  formik,
  handleFile,
}: CreateNewOnlineBatchReadjustmentsProps) => {
  const intl = useIntl();

  return (
    <S.Form>
      <Grid>
        <Row span="4">
          <Input
            onChange={(e) => handleFile(e)}
            variant="lighter"
            required
            id="inputFileReadjustments"
            type="file"
            accept=".xlsx"
            inputFileLabel={intl.formatMessage({
              id: 'createPaymentSlip.form.label.buttonFile',
            })}
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.file',
            })}
            hasErrors={formik.touched.archive && Boolean(formik.errors.archive)}
            helperText={formik.errors.archive}
          />
        </Row>
        <Row span="4">
          <Input
            required
            id="dateStartReadjustments"
            name="dateStart"
            value={formik.values.dateStart}
            hasErrors={
              formik.touched.dateStart && Boolean(formik.errors.dateStart)
            }
            helperText={formik.errors.dateStart}
            onChange={formik.handleChange}
            variant="lighter"
            type="date"
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.initialDate',
            })}
          />
        </Row>
        <Row span="4">
          <Input
            required
            id="dateEndReadjustments"
            name="dateEnd"
            type="date"
            value={formik.values.dateEnd}
            hasErrors={formik.touched.dateEnd && Boolean(formik.errors.dateEnd)}
            helperText={formik.errors.dateEnd}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="lighter"
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.finalDate',
            })}
          />
        </Row>
      </Grid>
    </S.Form>
  );
};

export default CreateNewOnlineBatchReadjustments;
