import styled, { css } from 'styled-components';

import Skeleton from 'components/shared/newCore/Skeleton';

interface WrapProps {
  disabled: boolean;
  borderBackgroudWhite?: boolean;
  isAccordionForm?: boolean;
}
interface SelectInputProps {
  isFocused: boolean;
  isBordered?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  isAccordionForm?: boolean;
  darkBorder?: boolean;
}

interface LabelProps {
  isBordered?: boolean;
}

interface SelectLabelProps {
  isSelected: boolean;
  status?: string;
}

type ContainerProps = {
  size?: number;
  minWidth?: number;
  error?: boolean;
};

interface PropsSkeleton {
  size?: number;
}

export const Wrap = styled.div<WrapProps>`
  & > input {
    display: none;
  }

  ${(props) =>
    props.borderBackgroudWhite &&
    css`
      & div:nth-child(1) {
        border: 1px solid ${({ theme }) => theme.input.primary.focusColor};
      }
    `}

  opacity: ${(props) => props.disabled && 0.3};

  ${(props) =>
    props.disabled &&
    props.isAccordionForm &&
    css`
      opacity: 1;
    `}
`;

export const LabelSelect = styled.label<LabelProps>`
  ${({ theme, isBordered }) => css`
    text-transform: uppercase;
    span {
      color: ${theme.new.colors.status.error};
    }
    ${!isBordered &&
    css`
      margin-top: 5px;
    `}
    display: block;
    font-size: ${theme.new.typography.size.xs};
    font-weight: ${theme.typography.fontWeight.semiBold};
    color: ${theme.new.colors.primary.main};
  `}
`;

export const Container = styled.div<ContainerProps>`
  ${({ theme, size, minWidth, error }) => css`
    min-width: ${minWidth && minWidth > 0 ? `${minWidth}px` : '100px'};
    width: ${size && size > 0 ? `${size}px` : '100%'};
    height: 48px;
    position: relative;
    display: flex;
    margin-top: ${theme.new.spacing.sm};
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: ${theme.new.shapes.borderRadius.lg};
    border: ${error ? `1px solid ${theme.contextual.error}` : 'none'};
  `}
`;

export const SkeletonWrapper = styled(Skeleton)<PropsSkeleton>`
  ${({ theme, size }) => css`
    margin-top: ${theme.new.spacing.sm};
    border-radius: ${theme.new.shapes.borderRadius.lg};
    height: 48px;
    min-width: ${size ? size : 200}px;
  `}
`;

export const SelectInput = styled.div<SelectInputProps>`
  ${({
    theme,
    isFocused,
    isBordered,
    isDisabled,
    placeholder,
    isAccordionForm,
    darkBorder,
  }) => css`
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 14px ${theme.new.spacing.lg};
    border-radius: ${theme.new.shapes.borderRadius.lg};
    display: flex;
    flex-direction: row !important;
    ${placeholder
      ? css`
          justify-content: space-between;
        `
      : css`
          justify-content: flex-end;
        `};
    align-items: center;
    svg {
      margin-left: ${theme.new.spacing.lg};
      font-size: ${theme.new.typography.size.default};
      color: ${theme.new.colors.secondary.main};
      transition: transform 0.5s;
    }

    ${isBordered &&
    !isDisabled &&
    css`
      border: ${theme.input.primary.optionalBorder};
    `}

    ${isFocused &&
    !isDisabled &&
    css`
      border: 1px solid ${theme.input.primary.focusColor};

      svg {
        transform: scaleY(-1);
      }
    `}

    ${isFocused &&
    isDisabled &&
    css`
      border: ${theme.surface.transparent};
      cursor: default;
    `}
    ${isAccordionForm &&
    isDisabled &&
    css`
      color: ${({ theme }) => theme.new.colors.status.subtext};
      font-weight: 600;
      background-color: #dddff1;
      opacity: 0.5;
    `}
    ${darkBorder &&
    css`
      border: 0.5px solid ${({ theme }) => theme.new.colors.status.texts};
      height: 44px;
    `}
  `}
`;

export const SpanSelectInput = styled.span<SelectLabelProps>`
  ${({ theme, isSelected, status }) => css`
    display: block;
    ${isSelected
      ? css`
          color: ${theme.new.colors.primary.main};
          font-weight: ${theme.new.typography.weight.regular};
        `
      : css`
          color: ${theme.new.colors.primary.light};
          font-weight: ${theme.new.typography.weight.semibold};
        `}
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-direction: row;
    ${status && textVariants[status] ? textVariants[String(status)] : null}
  `}
`;

const textVariants: any = {
  active: css`
    color: ${({ theme }) => theme.new.colors.status.success};
    text-transform: uppercase;
  `,
  inactive: css`
    color: ${({ theme }) => theme.new.colors.status.error};
    text-transform: uppercase;
  `,
  ATIVO: css`
    color: ${({ theme }) => theme.new.colors.status.success};
  `,
  INATIVO: css`
    color: ${({ theme }) => theme.new.colors.status.error};
  `,
  Ativo: css`
    color: ${({ theme }) => theme.new.colors.status.success};
  `,
  Cancelado: css`
    color: ${({ theme }) => theme.new.colors.status.error};
  `,
  Suspenso: css`
    color: ${({ theme }) => theme.new.colors.status.alert};
  `,
};
type BackdropProps = {
  fullWidth: boolean;
  fullHeight: boolean;
};
export const Backdrop = styled.div<BackdropProps>`
  ${({ fullHeight, fullWidth }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: ${fullWidth ? '100vw' : 'auto'};
    height: ${fullHeight ? '100vh' : 'auto'};
    z-index: 1050;
  `}
`;

type OptionsProps = {
  shadowColor: 'light' | 'dark';
};

export const Options = styled.div<OptionsProps>`
  ${({ theme, shadowColor }) => css`
    background-color: ${theme.new.colors.neutrals[0]};
    padding: ${theme.new.spacing.lg} 2px ${theme.new.spacing.lg}
      ${theme.new.spacing.lg};
    border-radius: ${theme.new.shapes.borderRadius.lg};
    box-shadow: 0px ${theme.new.spacing.xl} ${theme.new.spacing.xl2}
      ${shadowColor === 'light' ? theme.new.colors.neutrals[100] : '#0A1A5A'};
  `}
  width: 100%;
  height: auto;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1051;
`;

export const OptionsList = styled.ul`
  max-height: 200px;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: ${({ theme }) => theme.new.spacing.sm};

  ::-webkit-scrollbar {
    width: 5px;
    height: 150px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`;

export const OptionsListItem = styled.li`
  cursor: pointer;
  ${({ theme }) => css`
    padding: ${theme.new.spacing.sm} 0;
    color: ${theme.new.colors.neutrals[700]};
  `}
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.new.spacing.xs};

  :hover {
    ${({ theme }) => css`
      color: ${theme.new.colors.primary.main};
      font-weight: ${theme.new.typography.weight.semibold};
    `}
  }

  & > span {
    width: 100%;
  }

  & > button {
    ${({ theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: ${theme.new.colors.primary.main};
      border-radius: ${theme.new.shapes.borderRadius.lg};
      padding: 2px;
      cursor: pointer;

      &:hover {
        background: ${theme.new.colors.primary.light};
      }

      & > svg {
        width: ${theme.new.spacing.lg2};
        height: ${theme.new.spacing.lg2};

        & > path {
          stroke: ${theme.new.colors.neutrals[0]};
      }
    `}
  }
`;

export const ErrorLabel = styled.small`
  ${({ theme }) => css`
    color: ${theme.new.colors.status.error};
    font-size: ${theme.new.typography.size.sm};
  `}
`;

export const FilterArea = styled.div`
  width: 95%;
  margin-bottom: ${({ theme }) => theme.new.spacing.md};
`;
