import { useMutation } from 'react-query';

import { orthopedicLoan } from 'services/orthopedic';

export const useDeleteOrthopedicEquipment = () => {
  const { deleteOrthopedicEquipment } = orthopedicLoan();
  return useMutation({
    mutationFn: (id: number) => deleteOrthopedicEquipment(id),
  });
};
