import { ReactComponent as AlertError } from 'assets/images/AlertError.svg';
import { ReactComponent as CancelIcon } from 'assets/images/CancelIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import EmptyState from 'components/shared/core/EmptyState';
import InfiniteScroll from 'components/shared/core/infiniteScroll';
import LoadingSpinner from 'components/shared/core/loadingSpinner';
import Table from 'components/shared/newCore/Table';
import { BatchBankSlipMailingStatusByStatus, BatchBankSlipMailingStatusEnum } from 'constants/enums';
import { formatHour, formatSimpleDate } from 'lib/core';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { BatchBankSlipMailing } from 'types/BatchBankSlipMailing';
import { useOnlineBatches } from 'useCases/onlinePaymentSlip/useOnlineBatches';
import * as S from './OnlineBankSlipsTable.styles';

interface OnlineBankSlipsTableProps {
  batch: string;
  date: string;
  selectedStatus: string;
  isMasterUser: boolean;
}

const OnlineBankSlipsTable = ({
  batch,
  date,
  selectedStatus,
  isMasterUser,
}: OnlineBankSlipsTableProps) => {
  const intl = useIntl();
  const listRef = useRef<any>([]);
  const isFirstRender = useRef(true);

  const { allBatches, rest } = useOnlineBatches({
    code: batch.replace(/[a-zA-Z]+/gi, ''),
    date,
    status: selectedStatus,
    isMasterUser,
  });

  const batchStatsStyle = (state: BatchBankSlipMailingStatusEnum) => {
    if (BatchBankSlipMailingStatusByStatus[state]) {
      return BatchBankSlipMailingStatusByStatus[state];
    } else {
      return state;
    }
  };

  const determineIndex = () => {
    if (listRef.current.length > 0) {
      const lastNumber = String(listRef.current.length).slice(-1);
      if (lastNumber === '0') {
        return listRef.current.length - (13 - Number(lastNumber));
      } else {
        return listRef.current.length - Number(lastNumber) - 3;
      }
    }
    return 0;
  };
  const executeScroll = () => {
    if (listRef.current.length > 0) {
      listRef.current[determineIndex()]?.scrollIntoView();
    }
  };

  const checkIfBatchFailed = (batchStatus: BatchBankSlipMailingStatusEnum) => {
    if (BatchBankSlipMailingStatusByStatus[batchStatus] === 'pending') {
      return <LoadingSpinner width={34} height={34} borderSize={5} />;
    }

    if (BatchBankSlipMailingStatusByStatus[batchStatus] === 'failed') {
      return <AlertError width={50} height={34} cursor="auto" />;
    }

    return <CancelIcon width={50} cursor="auto" />;
  };

  useEffect(() => {
    isFirstRender.current = false;
    if (rest.isFetched && isFirstRender.current) {
      executeScroll();
    }
  }, []);

  const loadMore = () => {
    listRef.current = [];
    rest.fetchNextPage();
  };

  const addToRefs = (el: any) => {
    if (el && !listRef.current.includes(el)) {
      listRef.current.push(el);
    }

    if (allBatches.length < listRef.current.length) {
      listRef.current.shift();
    }
  };

  if (rest.isFetching && !rest.isFetchingNextPage) {
    return (
      <>
        <S.ListSkeleton />
        <S.ListSkeleton />
        <S.ListSkeleton />
      </>
    );
  }

  if (allBatches.length === 0) {
    return (
      <S.EmptyStateContainer>
        <EmptyState />
      </S.EmptyStateContainer>
    );
  }

  return (
    <S.Container>
      <Table.Header screenXs>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({
            id: 'batchList.table.code',
          })}
        </Table.Column>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({
            id: 'batchList.table.interval',
          })}
        </Table.Column>
        <Table.Column 
            isHeader 
            contentPosition="center" 
            columnSize={280}>
            {intl.formatMessage({
            id: 'batchList.table.date',
            })}
        </Table.Column>
        <Table.Column
          isHeader
          contentPosition="center"
          columnSize={200}
          paddingRight={30}
        >
          {intl.formatMessage({
            id: 'batchList.table.dataOrigin',
          })}
        </Table.Column>
        <Table.Column
          isHeader
          contentPosition="center"
          paddingRight={40}
        >
          {intl.formatMessage({
            id: 'batchList.table.status',
          })}
        </Table.Column>
        <Table.Column
          isHeader
          contentPosition="center"
          columnSize={300}
          paddingRight={70}
        >
          {intl.formatMessage({
            id: 'batchList.table.details',
          })}
        </Table.Column>
      </Table.Header>

      <S.ContentTable>
        <Table resizeHeight={315}>
          {allBatches.map((batch: BatchBankSlipMailing) => (
            <Table.Row key={batch.id} screenXs ref={(el) => addToRefs(el)}>
              <Table.Column title={`${batch.code}`} contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.code',
                    })}
                    :
                  </span>
                  {batch.code}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column title={batch.dateStart} contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.interval',
                    })}
                    :
                  </span>
                  {formatSimpleDate(batch.dateStart)} -{' '}
                  {formatSimpleDate(batch.dateEnd)}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column title={batch.createdAt} contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.date',
                    })}
                    :
                  </span>
                  {`${formatSimpleDate(batch.createdAt)}-${formatHour(
                    new Date(batch.createdAt)
                  )}`}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.dataOrigin',
                    })}
                    :
                  </span>
                  <S.ButtonContainer>
                    <S.Button>
                      {batch.inputFile ? (
                        <a href={batch.inputFile}>
                          <DownloadIcon />
                        </a>
                      ) : (
                        <CancelIcon width={50} cursor="auto" />
                      )}
                    </S.Button>
                  </S.ButtonContainer>
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column contentPosition="center">
                <S.StatusColumn status={batchStatsStyle(batch.status)}>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.status',
                    })}
                    :
                  </span>
                  {intl.formatMessage({
                    id: `bankSlipStatus.${batch.status}`,
                  })}
                  {` - ${batch.currentStage}/${batch.totalStages}`}
                </S.StatusColumn>
              </Table.Column>
              <Table.Column contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.details',
                    })}
                    :
                  </span>
                  <S.ButtonContainer>
                    <S.Button>
                      {batch.processingResult ? (
                        <a href={batch.processingResult}>
                          <DownloadIcon />
                        </a>
                      ) : (
                        checkIfBatchFailed(batch.status)
                      )}
                    </S.Button>
                  </S.ButtonContainer>
                </S.ControlSpaceArea>
              </Table.Column>
            </Table.Row>
          ))}

          {!!rest.hasNextPage && (
            <>
              <InfiniteScroll loadMore={loadMore} />
              <LoadingSpinner width={20} height={20} borderSize={3} />
            </>
          )}
        </Table>
      </S.ContentTable>
    </S.Container>
  );
};

export default OnlineBankSlipsTable;
