import { useAlert, useSession } from 'contexts';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  BatchAssociateInputType,
  BatchFormOptionsType,
  BatchType,
  CityFilterInputType,
  CreatePaymentSlipForm,
} from 'types/PaymentSlip';

import { useBatchCreator } from 'useCases/paymentSlip';
import { useNewAssociateTemplateFile } from 'useCases/paymentSlip/useNewAssociateTemplateFile';

import { useCreatePaymentSlipValidation } from 'components/contextual/createPaymentSlip/formValidation';
import {
  getInitialValues,
  mapToApi,
} from 'components/contextual/createPaymentSlip/mappers';
import CreateNewBatchNewAssociatesByApi from 'components/contextual/createPaymentSlip/newAssociatesByApi';
import CreateNewBatchNewAssociatesBySheet from 'components/contextual/createPaymentSlip/newAssociatesBySheet';
import CreateNewBatchReadjustments from 'components/contextual/createPaymentSlip/readjustments';
import {
  batchAssociateTypeOptions,
  batchTypeOptions,
  pickInitialBatchType,
} from 'components/contextual/createPaymentSlip/selectOptions';
import { createBreadcumbLinks } from 'components/contextual/createPaymentSlip/utils';
import HomeContent from 'components/contextual/homeContent';
import { RadioInputOption } from 'components/shared';
import AlertDialog from 'components/shared/core/alertDialog';
import Divider from 'components/shared/core/divider';
import { Grid, Row } from 'components/shared/core/grid';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Breadcrumb from 'components/shared/newCore/Breadcrumb';

import { Urls } from 'constants/urls';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';

import * as C from './CreatePaymentSlip.styles';

const CreatePaymentSlip = () => {
  const intl = useIntl();
  const history = useNavigate();
  const { showAlert, setVisible, setClear } = useAlert();
  const { user } = useSession();
  const [isMasterUser, setIsMasterUser] = useState(false);
  const [batchType, setBatchType] = useState<BatchType | ''>('');
  const [associateInputType, setAssociateInputType] = useState<
    BatchAssociateInputType | ''
  >('');
  const [cityFilter, setCityFilter] = useState<RadioInputOption>({
    label: '',
    value: '',
  });

  const breadcumbLinks = useMemo(() => createBreadcumbLinks(intl), [intl]);
  const formSelection: BatchFormOptionsType = {
    isReadjustments: batchType === BatchType.Readjustments,
    isNewBySheet:
      batchType === BatchType.NewAssociates &&
      associateInputType === BatchAssociateInputType.Sheets,
    isNewByAssociate:
      batchType === BatchType.NewAssociates &&
      associateInputType === BatchAssociateInputType.Associates,
    isNewByApi:
      batchType === BatchType.NewAssociates &&
      associateInputType === BatchAssociateInputType.Api,
  };

  const formik = useFormik<CreatePaymentSlipForm>({
    initialValues: getInitialValues(),
    validationSchema: useCreatePaymentSlipValidation({
      ...formSelection,
      isCityFilterOff: cityFilter.value === CityFilterInputType.None,
    }),
    onSubmit: async () => {
      showAlert({
        type: 'loading',
        title: intl.formatMessage({
          id: 'createPaymentSlip.form.saveBatch',
        }),
      });
      await mutate();
    },
  });

  useEffect(() => {
    formik.setFieldValue('archive', null);
  }, [batchType]);

  useEffect(() => {
    formik.setFieldValue('cityGroupId', '');
  }, [cityFilter]);

  useEffect(() => {
    const initialBatchType = pickInitialBatchType(user.permissions);
    setIsMasterUser(user.permissions.name === 'master');
    setBatchType(initialBatchType);
  }, [user.permissions.name]);

  const { mutate, isSuccess } = useBatchCreator(
    mapToApi(formSelection, formik.values),
    formik.values.archive!
  );

  const { data: templateUrl } = useNewAssociateTemplateFile();

  const handleArchive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      formik.setFieldValue('archive', file);
    }
  };

  const formOptions: Record<keyof BatchFormOptionsType, JSX.Element> = {
    isReadjustments: (
      <CreateNewBatchReadjustments handleFile={handleArchive} formik={formik} />
    ),

    isNewBySheet: (
      <CreateNewBatchNewAssociatesBySheet
        handleFile={handleArchive}
        formik={formik}
      />
    ),

    isNewByAssociate: (
      <CreateNewBatchNewAssociatesBySheet
        withAssociates
        handleFile={handleArchive}
        formik={formik}
      />
    ),

    isNewByApi: (
      <CreateNewBatchNewAssociatesByApi
        formik={formik}
        cityFilter={cityFilter}
        setCityFilter={setCityFilter}
      />
    ),
  };

  const keysForActiveForm = Object.keys(formSelection) as Array<
    keyof BatchFormOptionsType
  >;
  const activeForm = keysForActiveForm.find((key) => formSelection[key]);

  const formToRender = () => (activeForm ? formOptions[activeForm] : null);

  useEffect(() => {
    if (isSuccess) {
      setClear();
      setTimeout(() => {
        showAlert({
          type: 'success',
          title: intl.formatMessage({
            id: 'createPaymentSlip.form.submitEditSuccess',
          }),
        });
      });

      setTimeout(() => {
        setVisible(false);
        history(Urls.paymentSlip);
      }, 3000);
    }
  }, [isSuccess]);

  return (
    <HomeContent>
      <Breadcrumb path={breadcumbLinks} />
      <C.Header>
        <C.Title>
          {intl.formatMessage({
            id: 'createPaymentSlip.newPaymentSlip',
          })}
        </C.Title>
      </C.Header>
      <Grid>
        <Row span="3">
          <CustomSelect
            id="batchType"
            inputName="batchType"
            inputValue={batchType}
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.batchType',
            })}
            options={batchTypeOptions(intl)}
            setValue={(_, v) => setBatchType(v)}
            isBordered
            disabled={!isMasterUser}
          />
        </Row>
        {batchType === BatchType.NewAssociates && (
          <Row span="4">
            <CustomSelect
              id="associateInputType"
              inputName="associateInputType"
              inputValue={associateInputType}
              label={intl.formatMessage({
                id: 'createPaymentSlip.form.label.associateType',
              })}
              options={batchAssociateTypeOptions(intl)}
              setValue={(_, v) => setAssociateInputType(v)}
              isBordered
            />
            {batchType === BatchType.NewAssociates &&
              associateInputType === BatchAssociateInputType.Sheets && (
                <C.DownloadTemplateLink
                  href={templateUrl}
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({
                    id: 'createPaymentSlip.form.linkDownload',
                  })}
                </C.DownloadTemplateLink>
              )}
          </Row>
        )}
      </Grid>

      <Divider />

      {formToRender()}

      {formSelection.isReadjustments || associateInputType ? (
        <>
          <Divider />
          <C.Footer>
            <ButtonLink
              onClick={() => formik.handleSubmit()}
              type="button"
              variant="lighter"
              disabled={formSelection.isNewByApi && !cityFilter.value}
            >
              {intl.formatMessage({
                id: 'createPaymentSlip.form.buttonSubmit',
              })}
              <RoundedIcon>
                <ArrowRight />
              </RoundedIcon>
            </ButtonLink>
          </C.Footer>
        </>
      ) : null}
      <AlertDialog delay={3000} />
    </HomeContent>
  );
};

export default CreatePaymentSlip;
