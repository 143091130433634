import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useMySchedulesSchema = () => {
  const intl = useIntl();
  return Yup.object().shape({
    startDate: Yup.date()
      .max(
        new Date(),
        intl.formatMessage({
          id: 'invalidInitialDate',
        })
      )
      .required(
        intl.formatMessage({
          id: 'emptyField',
        })
      ),
    endDate: Yup.date()
      .min(
        Yup.ref('startDate'),
        intl.formatMessage({
          id: 'endDate',
        })
      )
      .required(
        intl.formatMessage({
          id: 'emptyField',
        })
      ),
  });
};
