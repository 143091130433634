import { useIntl } from 'react-intl';
import * as Yup from 'yup';

type CreatePaymentSlipValidationProps = {
  isReadjustments: boolean;
  isNewBySheet: boolean;
  isNewByAssociate: boolean;
  isNewByApi: boolean;
  isCityFilterOff: boolean;
};

export const useCreatePaymentSlipValidation = ({
  isReadjustments,
  isNewBySheet,
  isNewByAssociate,
  isNewByApi,
  isCityFilterOff,
}: CreatePaymentSlipValidationProps) => {
  const intl = useIntl();

  return Yup.object().shape({
    archive:
      isReadjustments || isNewBySheet
        ? Yup.mixed()
            .required(
              intl.formatMessage({
                id: 'createPaymentSlip.form.emptyArchive',
              })
            )
            .test(
              'is-valid-file',
              intl.formatMessage({ id: 'invalidFile' }),
              function (value) {
                const path = this.path;

                if (!value) return false;

                const allowedExtensions = ['xlsx'];
                const fileExtension = value.name.split('.').pop();
                return allowedExtensions.includes(fileExtension)
                  ? true
                  : this.createError({
                      message: intl.formatMessage({ id: 'invalidFile' }),
                      path,
                    });
              }
            )
        : Yup.mixed().nullable(),
    bankAccountId: !isReadjustments
      ? Yup.string().required(intl.formatMessage({ id: 'emptyField' }))
      : Yup.string().nullable(),
    cityGroupId:
      !isNewByApi || isCityFilterOff
        ? Yup.string().nullable()
        : Yup.string().required(intl.formatMessage({ id: 'emptyField' })),
    dateStart:
      isReadjustments || isNewByApi
        ? Yup.date().required(
            intl.formatMessage({ id: 'createPaymentSlip.form.dateError' })
          )
        : Yup.date().nullable(),
    dateEnd:
      isReadjustments || isNewByApi
        ? Yup.date()
            .required(
              intl.formatMessage({ id: 'createPaymentSlip.form.dateError' })
            )
            .min(
              Yup.ref('dateStart'),
              intl.formatMessage({ id: 'createPaymentSlip.form.endDateError' })
            )
        : Yup.date().nullable(),
    associates: isNewByAssociate
      ? Yup.string().required(intl.formatMessage({ id: 'emptyField' }))
      : Yup.string(),
    groups: Yup.string(),
    bankPercentages: isReadjustments
      ? Yup.array()
          .min(
            1,
            intl.formatMessage({ id: 'createPaymentSlip.form.emptyBanks' })
          )
          .of(
            Yup.object().shape({
              bankAccountId: Yup.string().required(
                intl.formatMessage({ id: 'emptyField' })
              ),
              percentage: Yup.number()
                .required(intl.formatMessage({ id: 'emptyField' }))
                .min(
                  1,
                  intl.formatMessage({
                    id: 'createPaymentSlip.form.percentageMinError',
                  })
                )
                .max(
                  100,
                  intl.formatMessage({
                    id: 'createPaymentSlip.form.percentageMaxError',
                  })
                ),
              acceptsNordeste: Yup.boolean().required(
                intl.formatMessage({ id: 'emptyField' })
              ),
              enablesApiIntegration: Yup.boolean().required(
                intl.formatMessage({ id: 'emptyField' })
              ),
            })
          )
          .test(
            'sum-of-percentages',
            intl.formatMessage({
              id: 'createPaymentSlip.form.percentageError',
            }),
            function (value) {
              const path = `${this.path}Error`;

              if (!value) return false;
              const totalPercentage = value.reduce(
                (sum, item) => sum + (item.percentage ?? 0),
                0
              );
              return totalPercentage === 100
                ? true
                : this.createError({
                    message: intl.formatMessage({
                      id: 'createPaymentSlip.form.percentageError',
                    }),
                    path,
                  });
            }
          )
      : Yup.array().nullable(),
  });
};
