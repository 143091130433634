import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { OrthopedicEquipmentSearchType } from 'types';

import { BreadcrumbLink } from 'components/shared/newCore/Breadcrumb';

import {
  EquipmentStatusEnumTypes,
  SearchForEquipmentsTypeEnum,
} from 'constants/enums';
import { Urls } from 'constants/urls';

export const useConstants = () => {
  const intl = useIntl();

  const breadcumbLinks: BreadcrumbLink[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
        redirectTo: Urls.HomePage,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.orthopedicSupport' }),
        redirectTo: Urls.orthopedicSupport,
      },
      {
        label: intl.formatMessage({ id: 'orthopedicSupport.equipment' }),
        redirectTo: Urls.orthopedicEquipments,
      },
    ],
    [intl]
  );

  const searchForEquipments = [
    {
      label: intl.formatMessage({
        id: 'orthopedicEquipment.optionsDescription',
      }),
      value:
        SearchForEquipmentsTypeEnum.Description as OrthopedicEquipmentSearchType,
    },
    {
      label: intl.formatMessage({
        id: 'orthopedicEquipment.optionsAssetNumber',
      }),
      value:
        SearchForEquipmentsTypeEnum.AssetNumber as OrthopedicEquipmentSearchType,
    },
    {
      label: intl.formatMessage({
        id: 'orthopedicEquipment.optionsStatus',
      }),
      value:
        SearchForEquipmentsTypeEnum.Status as OrthopedicEquipmentSearchType,
    },
  ];

  const equipmentStatusOptions = [
    {
      label: intl.formatMessage({ id: 'orthopedicEquipment.forms.available' }),
      value: EquipmentStatusEnumTypes.AVAILABLE,
    },
    {
      label: intl.formatMessage({ id: 'orthopedicEquipment.forms.located' }),
      value: EquipmentStatusEnumTypes.LOCATED,
    },
    {
      label: intl.formatMessage({
        id: 'orthopedicEquipment.forms.inMaintenance',
      }),
      value: EquipmentStatusEnumTypes.MAINTENANCE,
    },
    {
      label: intl.formatMessage({ id: 'orthopedicEquipment.forms.invalid' }),
      value: EquipmentStatusEnumTypes.INVALID,
    },
    {
      label: intl.formatMessage({ id: 'orthopedicEquipment.forms.sold' }),
      value: EquipmentStatusEnumTypes.SOLD,
    },
  ];

  return {
    searchForEquipments,
    equipmentStatusOptions,
    breadcumbLinks,
  };
};
