import * as S from './LoadingState.styles';

type LoadingProps = {
  fill?: boolean;
  spin?: boolean;
};

const LoadingState = ({ fill = false, spin = false }: LoadingProps) => (
  <S.Container $fill={fill}>
    {spin ? (
      <S.Loading />
    ) : (
      <S.Bar>
        <S.Progress animate={{ width: '100%' }} />
      </S.Bar>
    )}
  </S.Container>
);

export default LoadingState;
