import { useIntl } from 'react-intl';
import { CurrentCampaignResult } from 'types/ReportAndInquiriesTypes';

import EmptyState from 'components/shared/core/EmptyState';
import Skeleton from 'components/shared/newCore/Skeleton';

import * as S from './styles';

export interface CurrentCampaignProps {
  campaignData: { data: CurrentCampaignResult[] };
  isLoading: boolean;
}

const ListCurrentCampaign = ({
  campaignData,
  isLoading,
}: CurrentCampaignProps) => {
  const intl = useIntl();

  if (isLoading) {
    return (
      <S.TableContainer>
        <Skeleton height={105}></Skeleton>
        <Skeleton height={105}></Skeleton>
        <Skeleton height={105}></Skeleton>
      </S.TableContainer>
    );
  }

  if (campaignData.data === undefined || campaignData.data.length === 0) {
    return (
      <S.EmptyStateContainer>
        <EmptyState />
      </S.EmptyStateContainer>
    );
  }

  return (
    <S.TableContainer>
      <S.TableStyled>
        <S.TableHeader>
          <S.ListHeader>
            <S.ThStyled>
              {intl.formatMessage({
                id: 'listcurrentCampaign.SearchFor.label.scheduled',
              })}
            </S.ThStyled>

            <S.ThStyled>
              {intl.formatMessage({
                id: 'listcurrentCampaign.SearchFor.label.concluded',
              })}
            </S.ThStyled>

            <S.ThStyled>
              {intl.formatMessage({
                id: 'listcurrentCampaign.SearchFor.label.ticketCollector',
              })}
            </S.ThStyled>

            <S.ThStyled>
              {intl.formatMessage({
                id: 'listcurrentCampaign.SearchFor.label.enrollment',
              })}
            </S.ThStyled>

            <S.ThStyled>
              {intl.formatMessage({
                id: 'listcurrentCampaign.SearchFor.label.name',
              })}
            </S.ThStyled>

            <S.ThStyled>
              {intl.formatMessage({
                id: 'listcurrentCampaign.SearchFor.label.Cpf',
              })}
            </S.ThStyled>

            <S.ThStyled>
              {intl.formatMessage({
                id: 'listcurrentCampaign.SearchFor.label.group',
              })}
            </S.ThStyled>

            <S.ThStyled>
              {intl.formatMessage({
                id: 'listcurrentCampaign.SearchFor.label.subgroup',
              })}
            </S.ThStyled>

            <S.ThStyled>
              {intl.formatMessage({
                id: 'listcurrentCampaign.SearchFor.label.neighborhood',
              })}
            </S.ThStyled>

            <S.ThStyled>
              {intl.formatMessage({
                id: 'listcurrentCampaign.SearchFor.label.city',
              })}
            </S.ThStyled>
          </S.ListHeader>
        </S.TableHeader>

        <S.TbodyStyled>
          {campaignData.data.map((campaign, index) => (
            <S.ListItem key={index} concluded={campaign.isScheduled}>
              <S.TdStyled>
                {campaign.isScheduled
                  ? intl.formatMessage({
                      id: 'listCurrentCampaign.isScheduled',
                    })
                  : intl.formatMessage({
                      id: 'listCurrentCampaign.noScheduled',
                    })}
              </S.TdStyled>
              <S.TdStyled>
                {campaign.status
                  ? intl.formatMessage({
                      id: 'listCurrentCampaign.concluded',
                    })
                  : intl.formatMessage({
                      id: 'listCurrentCampaign.pending',
                    })}
              </S.TdStyled>
              <S.TdStyled>{campaign.billerName}</S.TdStyled>
              <S.TdStyled>{campaign.subscription}</S.TdStyled>
              <S.TdStyled>{campaign.associateName}</S.TdStyled>
              <S.TdStyled>{campaign.associateCpf}</S.TdStyled>
              <S.TdStyled>{campaign.associateGroup}</S.TdStyled>
              <S.TdStyled>{campaign.associateSubgroup}</S.TdStyled>
              <S.TdStyled>{campaign.associateBillingNeighbourhood}</S.TdStyled>
              <S.TdStyled>{campaign.associateBillingCity}</S.TdStyled>
            </S.ListItem>
          ))}
        </S.TbodyStyled>
      </S.TableStyled>
    </S.TableContainer>
  );
};

export default ListCurrentCampaign;
