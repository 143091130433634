import { AxiosError } from 'axios';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { OrthopedicEquipmentParams, StatusType } from 'types/EquipmentMovement';
import { EquipmentsProps } from 'types/Orthopedic';

import {
  useDeleteOrthopedicEquipment,
  useFindAllEquipmentProperties,
  useUpdateOrthopedicEquipment,
} from 'useCases/orthopedic';
import { useCreateOrthopedicEquipment } from 'useCases/orthopedic/useCreateOrthopedicEquiment';

import { useAlert } from 'contexts/AlertContext';

import HomeContent from 'components/contextual/homeContent';
import OrthopedicEquipmentTable from 'components/contextual/orthopedicEquipmentTable';
import OrthopedicModalEquipment from 'components/contextual/orthopedicModalEquipment';
import OrthopedicEquipmentFilter from 'components/orthopedicEquipmentFilter';
import { Row } from 'components/shared';
import EmptyState from 'components/shared/core/EmptyState';
import AlertDialog from 'components/shared/core/alertDialog';
import ConfirmDialog from 'components/shared/core/confirmDialog';
import LoadingSpinner from 'components/shared/core/loadingSpinner';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import Breadcrumb from 'components/shared/newCore/Breadcrumb';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';

import * as S from './OrthopedicEquipment.styles';
import { useConstants } from './constants';

const OrthopedicEquipment = () => {
  const intl = useIntl();
  const { breadcumbLinks } = useConstants();
  const [showModalEquipment, setShowModalEquipment] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [statusModal, setStatusModal] = useState<string>('edit');
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const { showAlert, setVisible, setClear } = useAlert();
  const [equipmentGroupId, setEquipmentGroupId] = useState<
    string | undefined
  >();
  const [filterQuery, setFilterQuery] = useState<OrthopedicEquipmentParams>({
    limit: 100,
  } as OrthopedicEquipmentParams);
  const updateEquipments = useUpdateOrthopedicEquipment({});
  const createEquipments = useCreateOrthopedicEquipment({});
  const deleteEquipment = useDeleteOrthopedicEquipment();
  const disable =
    !equipmentGroupId &&
    !filterQuery.term?.length &&
    !filterQuery.status &&
    !filterQuery.id;

  const [activeEquipment, setActiveEquipment] = useState<EquipmentsProps>({
    id: Number(''),
    status: 'available',
    description: '',
    comment: '',
    value: '',
    aquisitionValue: '',
    branch: {
      id: Number(''),
      name: '',
    },
    branchId: Number(''),
  });

  const {
    equipmentPropertiesData,
    refetch: refetchEquipment,
    isLoading: isLoadingEquipment,
    isFetching: isFetchingEquipment,
    hasNextPage: hasNextPageEquipment,
    fetchNextPage: fetchNextPageEquipment,
    equipmentsByGroupLength,
    downloadReport,
  } = useFindAllEquipmentProperties(
    {
      ...filterQuery,
    },
    !disable
  );

  const valuesEquipments = {
    id: Number(''),
    status: 'available' as StatusType,
    description: '',
    comment: '',
    value: '',
    aquisitionValue: '',
    equipmentGroupId: equipmentGroupId,
    branch: {
      id: Number(''),
      name: '',
    },
    branchId: Number(''),
  };

  const equipments: EquipmentsProps[] = equipmentPropertiesData?.map(
    (equipment) => ({
      id: equipment.id,
      status: equipment.status,
      description: equipment.description,
      comment: equipment.comment,
      value: equipment.value.toFixed(2),
      aquisitionValue: equipment.aquisitionValue!.toFixed(2),
      equipmentGroupId: equipment?.equipmentGroup?.id ?? equipmentGroupId,
      branch: {
        id: equipment.branch.id,
        name: equipment.branch.name,
      },
      branchId: equipment.branch.id,
    })
  );

  const handleChangeModalEquipment = (
    equipment: EquipmentsProps,
    type: string
  ) => {
    if (type === 'edit') {
      setModalTitle(
        intl.formatMessage({
          id: 'orthopedicEquipment.modal.editEquipment.title',
        })
      );
    } else {
      setModalTitle(
        intl.formatMessage({
          id: 'orthopedicEquipment.modal.registerNewEquipment.title',
        })
      );
    }
    setActiveEquipment((prev) => ({ ...prev, ...equipment }));
    setStatusModal(type);
    setShowModalEquipment(true);
  };

  const handleCloseConfirmDialog = () => {
    setShowConfirm(false);
  };
  const handleOpenConfirmDialog = (id: number) => {
    setShowConfirm(true);
    setDeleteId(id);
  };

  const handleConfirmDelete = () => {
    showAlert({
      type: 'loading',
      title: intl.formatMessage({
        id: 'orthopedicEquipment.modal.showAlertLoadingSuccess',
      }),
      message: intl.formatMessage({
        id: 'orthopedicEquipment.modal.loading.message',
      }),
    });

    deleteEquipment.mutate(deleteId!, {
      onSuccess: (data) => {
        setVisible(false);
        setClear();
        showAlert({
          type: 'success',
          title: intl.formatMessage({
            id: 'orthopedicEquipment.modal.showAlertSuccessDelete',
          }),
        });
        refetchEquipment();
      },
      onError: (error: unknown) => {
        const e = error as AxiosError;
        setVisible(false);
        setClear();
        if (e.response?.status === 422) {
          showAlert({
            type: 'error',
            title: intl.formatMessage({
              id: 'orthopedicEquipment.modal.showAlertErrorHistoric',
            }),
          });
        } else {
          showAlert({
            type: 'error',
            title: intl.formatMessage({
              id: 'orthopedicEquipment.modal.showAlertError',
            }),
          });
        }
      },
    });

    setShowConfirm(false);
    setDeleteId(0);
  };

  return (
    <HomeContent>
      <Breadcrumb path={breadcumbLinks} />
      <S.Title>
        {intl.formatMessage({ id: 'orthopedicEquipment.title' })}
      </S.Title>
      <Row span="12">
        <S.SectionTitle>
          {intl.formatMessage({
            id: 'orthopedicEquipment.section.groupData',
          })}
        </S.SectionTitle>
      </Row>

      <OrthopedicEquipmentFilter
        setFilterQuery={setFilterQuery}
        setEquipmentGroupId={setEquipmentGroupId}
        equipmentsByGroupLength={equipmentsByGroupLength}
      />

      {(equipmentGroupId ||
        filterQuery.term ||
        filterQuery.id ||
        filterQuery.status) &&
      equipments.length > 0 ? (
        <OrthopedicEquipmentTable
          equipments={equipments}
          handleChangeModalEquipment={handleChangeModalEquipment}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          hasNextPageEquipment={hasNextPageEquipment}
          fetchNextPageEquipment={fetchNextPageEquipment}
          refreshField={equipments.length}
          isLoading={isLoadingEquipment || isFetchingEquipment}
        />
      ) : null}
      {!isLoadingEquipment && equipments?.length === 0 && !disable && (
        <S.EmptyStateContainer>
          <EmptyState />
        </S.EmptyStateContainer>
      )}
      {isFetchingEquipment ? (
        <S.LoadingContainer>
          <LoadingSpinner width={20} height={20} borderSize={3} />
        </S.LoadingContainer>
      ) : null}
      <S.Footer>
        <ButtonLink type="button" onClick={downloadReport}>
          {intl.formatMessage({
            id: 'orthopedicEquipment.generateReport',
          })}
          <RoundedIcon>
            <ArrowRight />
          </RoundedIcon>
        </ButtonLink>
        <ButtonLink
          type="button"
          onClick={() => handleChangeModalEquipment(valuesEquipments, 'Create')}
          disabled={disable}
        >
          {intl.formatMessage({
            id: 'orthopedicEquipment.modal.registerNewEquipment.title',
          })}
          <RoundedIcon>
            <ArrowRight />
          </RoundedIcon>
        </ButtonLink>
      </S.Footer>
      <OrthopedicModalEquipment
        open={showModalEquipment}
        closeModal={() => {
          setShowModalEquipment(false);
        }}
        title={modalTitle}
        equipment={activeEquipment}
        onSubmit={statusModal === 'edit' ? updateEquipments : createEquipments}
        refetch={refetchEquipment}
        statusModal={statusModal}
      />
      <ConfirmDialog
        iconType="warn"
        onConfirm={handleConfirmDelete}
        open={showConfirm}
        onOpenChange={() => {}}
        onCancel={handleCloseConfirmDialog}
        title={intl.formatMessage({
          id: 'orthopedicEquipment.confirmDialog.title',
        })}
        text={intl.formatMessage({
          id: 'orthopedicEquipment.confirmDialog.subTitle',
        })}
        cancelLabel={intl.formatMessage({
          id: 'orthopedicEquipment.confirmDialog.button.cancel',
        })}
        confirmLabel={intl.formatMessage({
          id: 'orthopedicEquipment.confirmDialog.button.continue',
        })}
        confirmIcon={<ArrowRight />}
      ></ConfirmDialog>
      <AlertDialog delay={3000} />
    </HomeContent>
  );
};
export default OrthopedicEquipment;
