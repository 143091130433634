import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { paymentSlip } from 'services/paymentSlip';

export const useGetBanks = () => {
  const { banks } = paymentSlip();
  const intl = useIntl();
  const { data: banksNames } = useQuery('banksNames', banks);
  const accounts = useMemo(() => {
    const items = banksNames?.map((item: any) => ({
      label: `${item.bankCode} - ${item.account} - ${item.assignor} (${item.bank.name})`,
      value: item.id,
    }));
    if (items) return items;
    return [
      {
        label: intl.formatMessage({
          id: 'emptyBranchList',
        }),
        value: undefined,
      },
    ];
  }, [banksNames]);
  return {
    accounts,
  };
};
