import axios, { AxiosResponse } from 'axios';
import { BatchInfo } from 'types/BatchInfo';
import { BatchResponse } from 'types/BatchResponse';
import {
  AccountBankType,
  Batch,
  BatchAssociateInputType,
  BatchRequestType,
  Lots,
} from 'types/PaymentSlip';

import api from 'services/api';

import { getURLWithParams } from 'lib/contextual/Url';

import { BANK_SLIP } from 'constants/endpoints';

export const paymentSlip = () => {
  const banks = async () => {
    try {
      const url = `${BANK_SLIP.GET_BANKS}/bank-accounts`;
      const allBanks = await api.getRequest(url);
      return allBanks.data.items;
    } catch (e) {
      throw e;
    }
  };

  const banksWithParams = async (
    page: number,
    limit: number,
    enablesApiIntegration: boolean
  ): Promise<AccountBankType[]> => {
    const params = {
      page,
      limit,
      enablesApiIntegration,
    };
    const url = getURLWithParams(
      `${BANK_SLIP.GET_BANKS}/bank-accounts`,
      params
    );
    const allBanks = await api.getRequest(url);
    return allBanks.data.items;
  };

  const batches = async ({
    pageParam = 1,
    code = '',
    date = '',
    status = '',
    batchType = '',
  }): Promise<{
    items: Lots[];
    meta: { currentPage: number };
  }> => {
    const params = {
      page: pageParam,
      limit: 15,
      ...(code !== '' ? { code } : ''),
      ...(date !== '' ? { createdAt: date } : ''),
      ...(status !== '' ? { status } : ''),
      ...(batchType !== '' ? { batchType } : ''),
    };

    const url = getURLWithParams(`${BANK_SLIP.GET_BATCHES}`, params);

    const allBatches = await api.getRequest(url, {
      headers: { 'Content-Host': 'service-bank-slips' },
    });
    return allBatches.data;
  };

  const createBatch = async (bank: BatchRequestType, archive: File) => {
    const url = `${BANK_SLIP.POST_CREATE_BATCH}`;
    const { data: batch } = (await api.postRequest(
      url,
      bank
    )) as AxiosResponse<BatchResponse>;
    const { id, uploadUrl, uploadKey } = batch;

    if (bank.associateInputType === BatchAssociateInputType.Sheets) {
      await axios.put(uploadUrl, archive, {
        headers: { 'Content-Type': archive.type },
      });
    }

    await api.putRequest(BANK_SLIP.EXECUTE_BATCH_PROCESS(id), {
      documentId: uploadKey,
      originalFilename: uploadKey,
    });

    return batch;
  };

  const getBatchesInfos = async (batchId: string): Promise<BatchInfo[]> => {
    try {
      const url = `${BANK_SLIP.GET_BATCHES_DOWNLOAD(batchId)}`;
      const result = await api.getRequest(url);

      return result.data;
    } catch (e) {
      throw e;
    }
  };

  const getNewAssociateTemplateFile = async (): Promise<string> => {
    const url = BANK_SLIP.GET_TEMPLATE_FILE;
    const result = await api.getRequest(url);
    return result.data;
  };

  const postBatchForPrinterCarnet = async (batch: Batch) => {
    try {
      const url = `${BANK_SLIP.POST_DOWNLOAD_CARNET_PDF}`;
      const result = await api.postRequest(url, batch);
      return result;
    } catch (e) {
      throw e;
    }
  };

  const postBatchForPrinterCover = async (batch: Batch) => {
    try {
      const url = `${BANK_SLIP.POST_DOWNLOAD_COVERS_PDF}`;
      const result = await api.postRequest(url, batch);
      return result;
    } catch (e) {
      throw e;
    }
  };

  const postBatchForPrinterLabels = async (batch: Batch) => {
    try {
      const url = `${BANK_SLIP.POST_DOWNLOAD_LABELS_PDF}`;
      const result = await api.postRequest(url, batch);
      return result;
    } catch (e) {
      throw e;
    }
  };

  const getDownloadBatchPdf = async (fileName: string) => {
    try {
      const url = `${BANK_SLIP.GET_BATCHES_DOWNLOAD_PDF(fileName)}`;
      const result = await api.getRequest(url);
      return result;
    } catch (e) {
      throw e;
    }
  };

  return {
    banks,
    banksWithParams,
    batches,
    createBatch,
    getBatchesInfos,
    postBatchForPrinterCarnet,
    postBatchForPrinterCover,
    postBatchForPrinterLabels,
    getDownloadBatchPdf,
    getNewAssociateTemplateFile,
  };
};
