import { AxiosError } from 'axios';
import { useAlert } from 'contexts';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { getMySchedules } from 'services/billings';

export const useMySchedules = (
  page: number,
  startDate: string,
  endDate: string
) => {
  const { showAlert } = useAlert();
  const intl = useIntl();
  const { data, ...rest } = useQuery(
    ['mySchedules', startDate, endDate],
    () => getMySchedules(page, startDate, endDate),
    {
      enabled: false,
      onError: (err: AxiosError) => {
        if (err.response?.status === 403) {
          showAlert({
            type: 'error',
            title: intl.formatMessage({ id: 'permission' }),
          });
          return;
        }

        showAlert({
          type: 'error',
          title: intl.formatMessage({ id: 'serverError' }),
        });
      },
    }
  );

  return {
    mySchedules: data ? data : [],
    ...rest,
  };
};
