import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CityFilterInputType, CreatePaymentSlipForm } from 'types/PaymentSlip';

import { useFindAllCityGroup, useFindCityGroup } from 'useCases/cityGroup';
import { useGetBanksWithParams } from 'useCases/paymentSlip/useGetBanksWithParams';

import { cityFilterOptions } from 'components/contextual/createPaymentSlip/selectOptions';
import ModalCityGroupPicker from 'components/contextual/modalCityGroupPicker';
import { Grid, Row } from 'components/shared/core/grid';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';
import RadioInputGroup, {
  RadioInputOption,
} from 'components/shared/forms/RadioInputGroup';

import * as S from './CreateNewBatchNewAssociatesByApi.styles';

interface CreateNewBatchNewAssociatesByApiProps {
  formik: FormikProps<CreatePaymentSlipForm>;
  cityFilter: RadioInputOption;
  setCityFilter: React.Dispatch<React.SetStateAction<RadioInputOption>>;
}

const CreateNewBatchNewAssociatesByApi = ({
  formik,
  cityFilter,
  setCityFilter,
}: CreateNewBatchNewAssociatesByApiProps) => {
  const intl = useIntl();

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [cityGroupIdFromPicker, setCityGroupIdFromPicker] = useState('');

  const { accounts } = useGetBanksWithParams({});
  const { allCityGroups, rest: restCityGroups } = useFindAllCityGroup();
  const { cityGroupData } = useFindCityGroup(formik.values.cityGroupId);

  const isFilterByGroup = cityFilter.value === CityFilterInputType.Groups;
  const isFilterBySelection =
    cityFilter.value === CityFilterInputType.Selection;
  const isFilterOff = cityFilter.value === CityFilterInputType.None;

  useEffect(() => {
    const refetchCityGroups = async () => {
      await restCityGroups.refetch();
    };
    if (cityGroupIdFromPicker) {
      refetchCityGroups();
      formik.setFieldValue('cityGroupId', cityGroupIdFromPicker);
    }
  }, [cityGroupIdFromPicker]);

  return (
    <S.Form>
      <Grid>
        <Row span="4">
          <Input
            required
            id="dateStartNewAssociates"
            name="dateStart"
            type="date"
            value={formik.values.dateStart}
            hasErrors={
              formik.touched.dateStart && Boolean(formik.errors.dateStart)
            }
            helperText={formik.errors.dateStart}
            onChange={formik.handleChange}
            variant="lighter"
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.initialDate',
            })}
          />
        </Row>
        <Row span="4">
          <Input
            required
            id="dateEndNewAssociates"
            name="dateEnd"
            type="date"
            value={formik.values.dateEnd}
            hasErrors={formik.touched.dateEnd && Boolean(formik.errors.dateEnd)}
            helperText={formik.errors.dateEnd}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="lighter"
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.finalDate',
            })}
          />
        </Row>
        <Row span="4">
          <S.SelectWrapper>
            <CustomSelect
              required
              id="bankAccountIdByApi"
              inputName="bankAccountId"
              inputValue={formik.values.bankAccountId}
              label={intl.formatMessage({
                id: 'createPaymentSlip.form.label.selectBank',
              })}
              placeholder={intl.formatMessage({
                id: 'createPaymentSlip.form.placeholder.selectBank',
              })}
              options={accounts}
              setValue={(_, v) => formik.setFieldValue('bankAccountId', v)}
              isBordered
              hasErrors={
                formik.touched.bankAccountId &&
                Boolean(formik.errors.bankAccountId)
              }
              helperText={formik.errors.bankAccountId}
            />
          </S.SelectWrapper>
        </Row>
        <Row span="5">
          <S.ContainerSubTitle>
            <S.Subtitle>
              {intl.formatMessage({ id: 'createPaymentSlip.cityFilterTitle' })}
            </S.Subtitle>
          </S.ContainerSubTitle>
          <RadioInputGroup
            onChange={(v) => setCityFilter(v)}
            options={cityFilterOptions(intl)}
            selected={cityFilter}
            greaterMaxWidth
          />
          <S.Paragraph>
            {isFilterByGroup
              ? intl.formatMessage({
                  id: 'createPaymentSlip.cityFilterParagraph.groups',
                })
              : null}
            {isFilterBySelection
              ? intl.formatMessage({
                  id: 'createPaymentSlip.cityFilterParagraph.selection',
                })
              : null}
            {isFilterOff
              ? intl.formatMessage({
                  id: 'createPaymentSlip.cityFilterParagraph.none',
                })
              : null}
          </S.Paragraph>
        </Row>
        {isFilterByGroup ? (
          <>
            <Row span="3">
              <CustomSelect
                required
                id="cityGroupIdByApi"
                inputName="cityGroupId"
                inputValue={formik.values.cityGroupId}
                label={intl.formatMessage({
                  id: 'createPaymentSlip.form.label.cityGroupList',
                })}
                handlePagination={() => {
                  restCityGroups.fetchNextPage();
                }}
                hasPagination={restCityGroups.hasNextPage}
                options={allCityGroups}
                setValue={(_, v) => formik.setFieldValue('cityGroupId', v)}
                isBordered
                hasErrors={
                  formik.touched.cityGroupId &&
                  Boolean(formik.errors.cityGroupId)
                }
                helperText={formik.errors.cityGroupId}
                isLoading={restCityGroups.isFetching}
              />
            </Row>
            <Row span="4">
              <S.ContainerButton>
                <S.Button type="button" onClick={() => setIsModalOpened(true)}>
                  {intl.formatMessage({
                    id: 'createPaymentSlip.cityFilterForm.buttonEdit',
                  })}
                </S.Button>
              </S.ContainerButton>
            </Row>
          </>
        ) : null}
        {isFilterBySelection ? (
          <>
            <Row span="4">
              <S.ContainerButton>
                <S.Button type="button" onClick={() => setIsModalOpened(true)}>
                  {intl.formatMessage({
                    id: 'createPaymentSlip.cityFilterForm.buttonSelect',
                  })}
                </S.Button>
                {formik.touched.cityGroupId &&
                  Boolean(formik.errors.cityGroupId) && (
                    <S.ErrorText>
                      {intl.formatMessage({
                        id: 'createPaymentSlip.form.anonymousCityGroupError',
                      })}
                    </S.ErrorText>
                  )}
              </S.ContainerButton>
            </Row>
          </>
        ) : null}

        {cityGroupData?.cities?.length ? (
          <Row span="12">
            <S.FooterText>
              {intl.formatMessage(
                {
                  id:
                    cityGroupData.cities.length < 2
                      ? 'cityGroupPicker.modal.selectedCity'
                      : 'cityGroupPicker.modal.selectedCities',
                },
                { count: cityGroupData.cities.length }
              )}
            </S.FooterText>
          </Row>
        ) : null}
      </Grid>
      {isModalOpened ? (
        <ModalCityGroupPicker
          onCancel={() => setIsModalOpened(false)}
          open={isModalOpened}
          setAnonymousCreationId={setCityGroupIdFromPicker}
          withGroups={isFilterByGroup}
        />
      ) : null}
    </S.Form>
  );
};

export default CreateNewBatchNewAssociatesByApi;
