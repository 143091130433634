import styled from 'styled-components';
import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

type TextProps = ColorProps & TypographyProps & SpaceProps;

const Text = styled.p<TextProps>`
  ${color}
  ${typography}
  ${space}
`;

export default Text;
