import { ReactNode } from 'react';

import Column from './Column';
import Header from './Header';
import Row from './Row';
import * as S from './Table.styles';

interface TableProps {
  children: ReactNode;
  resizeHeight?: number;
  screenM?: number;
  acceptMobile?: boolean;
  resizeHeightVh?: number;
  minHeight?: number;
}

const Table = ({
  children,
  resizeHeight,
  screenM,
  acceptMobile,
  resizeHeightVh,
  minHeight,
}: TableProps) => (
  <S.Container
    resizeHeight={resizeHeight}
    acceptMobile={acceptMobile}
    screenM={screenM}
    resizeHeightVh={resizeHeightVh}
    minHeight={minHeight}
  >
    {children}
  </S.Container>
);

Table.Row = Row;
Table.Column = Column;
Table.Header = Header;

export default Table;
