import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useInfiniteQuery } from 'react-query';
import { OrthopedicEquipmentGroupsParams } from 'types';

import { orthopedicLoan } from 'services/orthopedic';

export const useFindAllEquipmentGroups = (
  { description, id, limit = 100 }: OrthopedicEquipmentGroupsParams,
  enableOnMount?: boolean
) => {
  const { getAllEquipmentGroups } = orthopedicLoan();
  const intl = useIntl();

  const { data: equipmentGroups, ...rest } = useInfiniteQuery(
    ['findAllEquipmentGroups', { description, id }],
    ({ pageParam }) =>
      getAllEquipmentGroups({
        description,
        id,
        limit,
        page: pageParam,
      }),
    {
      getNextPageParam: (data) => {
        if (data.items?.length === 0) return;
        return data.meta.currentPage + 1;
      },
      enabled: enableOnMount,
    }
  );

  const allValues = useMemo(
    () => equipmentGroups?.pages.flatMap(({ items }) => items),
    [equipmentGroups]
  );

  const equipmentGroupsNormalizedData = useMemo(() => {
    const items = allValues?.map((item) => ({
      label: item?.description || '',
      value: item?.description || '',
    }));
    if (items) return items;

    return [
      {
        label: intl.formatMessage({
          id: 'emptyGroupsList',
        }),
        value: '',
      },
    ];
  }, [equipmentGroups]);

  return {
    equipmentGroups,
    equipmentGroupsNormalizedData,
    ...rest,
  };
};
