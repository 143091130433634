import { Root } from '@radix-ui/react-alert-dialog';
import { useIntl } from 'react-intl';

import { dataColectionTableInterface } from 'pages/collectionCommissionReport';

import { Grid, Row } from 'components/shared';
import Button from 'components/shared/forms/ButtonLink';

import { ReactComponent as Close } from 'assets/images/Close.svg';

import * as S from './collectionCommissionTable.style';

interface ModalNewObservationProps {
  open: boolean;
  isClosing: boolean;
  dataColectionTable: dataColectionTableInterface[];
  closeModal: () => void;
}

export const CollectionCommissionTable = ({
  open,
  isClosing,
  dataColectionTable,
  closeModal,
}: ModalNewObservationProps) => {
  const intl = useIntl();

  return (
    <Root open={open}>
      <S.Overlay />
      <S.Modal $isClosing={isClosing}>
        <S.Content $isClosing={isClosing}>
          <S.Title>
            {intl.formatMessage({
              id: 'collectionCommissionReport.modal.title',
            })}
          </S.Title>
          <S.Container>
            <S.List>
              <Grid>
                <Row span="12">
                  <S.ListHeader>
                    <S.ColumnHeader>
                      {intl.formatMessage({
                        id: 'collectionCommissionReport.modal.startDay',
                      })}
                    </S.ColumnHeader>

                    <S.ColumnHeader>
                      {intl.formatMessage({
                        id: 'collectionCommissionReport.modal.endDay',
                      })}
                    </S.ColumnHeader>

                    <S.ColumnHeader>
                      {intl.formatMessage({
                        id: 'collectionCommissionReport.modal.campaign',
                      })}
                    </S.ColumnHeader>

                    <S.ColumnHeader>
                      {intl.formatMessage({
                        id: 'collectionCommissionReport.modal.receptive',
                      })}
                    </S.ColumnHeader>
                  </S.ListHeader>
                </Row>
              </Grid>
              <S.ListBody>
                {dataColectionTable.map((dado: dataColectionTableInterface) => (
                  <Grid key={dado.id}>
                    <Row span="12">
                      <S.ListItem key={dado.id}>
                        <S.ListItemColumn>
                          <span>
                            {intl.formatMessage({
                              id: 'collectionCommissionReport.modal.startDay',
                            })}
                            :
                          </span>{' '}
                          {dado.startDate}
                        </S.ListItemColumn>
                        <S.ListItemColumn>
                          <span>
                            {intl.formatMessage({
                              id: 'collectionCommissionReport.modal.endDay',
                            })}
                            :
                          </span>{' '}
                          {dado.endDate}
                        </S.ListItemColumn>
                        <S.ListItemColumn>
                          <span>
                            {intl.formatMessage({
                              id: 'collectionCommissionReport.modal.campaign',
                            })}
                            :
                          </span>{' '}
                          {dado.campaign}
                        </S.ListItemColumn>
                        <S.ListItemColumn>
                          <span>
                            {intl.formatMessage({
                              id: 'collectionCommissionReport.modal.receptive',
                            })}
                            :
                          </span>{' '}
                          {dado.receptive}
                        </S.ListItemColumn>
                      </S.ListItem>
                    </Row>
                  </Grid>
                ))}
              </S.ListBody>
            </S.List>
            <S.ButtonClose>
              <Button type="button" variant="lighter" onClick={closeModal}>
                <Close />
              </Button>
            </S.ButtonClose>
          </S.Container>
        </S.Content>
      </S.Modal>
    </Root>
  );
};
