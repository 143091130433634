import { breakpoints } from './breakpoints';

export const device = {
  small: `(max-width: ${breakpoints.small})`,
  mobileS: `(min-width: ${breakpoints.mobileS})`,
  mobileXs: `(max-width: ${breakpoints.mobileXs})`,
  tabletL: `(max-width: ${breakpoints.tabletL})`,
  mobileM: `(min-width: ${breakpoints.mobileM})`,
  mobileL: `(min-width: ${breakpoints.mobileL})`,
  tablet: `(min-width: ${breakpoints.tablet})`,
  tabletS: `(max-width: ${breakpoints.tabletS})`,
  laptopS: `(max-width: ${breakpoints.laptopS})`,
  laptop: `(min-width: ${breakpoints.laptop})`,
  laptopHD: `(min-width: ${breakpoints.laptopHD})`,
  maxLaptopHD: `(max-width: ${breakpoints.laptopHD})`,
  laptopL: `(min-width: ${breakpoints.laptopL})`,
  maxLaptopL: `(max-width: ${breakpoints.laptopL})`,
  desktop: `(min-width: ${breakpoints.desktop})`,
  desktopMini: `(max-width: ${breakpoints.desktopMini})`,
  desktopL: `(min-width: ${breakpoints.desktop})`,
  desktopG: `(max-width: ${breakpoints.tabletG})`,
  largeDevices: `(max-width: ${breakpoints.large})`,
};
