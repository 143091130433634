import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm1};

  @media screen and (max-width: 770px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
`;

interface ButtonProps {
  active?: boolean;
}

export const Button = styled.button<ButtonProps>`
  border: none;
  cursor: pointer;
  min-width: 260px;

  ${({ theme }) => css`
    background-color: ${theme.surface.main};
    padding: ${theme.spacing.sm1} ${theme.spacing.md};
    font-size: ${theme.fontSizes.medium};
    color: ${theme.texts.muted};
    border-radius: ${theme.borderRadius.md};
  `}

  font-weight: 600;
  text-align: left;

  ${({ active }) =>
    active &&
    css`
      border: 2px solid ${(props) => props.theme.primary.main};
      color: ${(props) => props.theme.primary.main};
    `}
`;
