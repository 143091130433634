import styled, { css } from 'styled-components';

export type InputVariant = 'lighter' | 'darken' | 'bordered';
interface InputWrapperProps {
  error?: boolean;
  disabled?: boolean;
  hasBorder?: boolean;
  startAdornment: boolean;
  endAdornment: boolean;
  variant: InputVariant;
}

const LabelVariants = {
  lighter: css`
    color: ${(props) => props.theme.input.primary.label};
    font-weight: 600;
  `,
  darken: css``,
  bordered: css``,
};

interface LabelProps {
  variant: InputVariant;
}

export const Container = styled.div``;

export const Label = styled.label<LabelProps>`
  text-transform: uppercase;

  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.input.outlined.label};
    span {
      color: ${theme.contextual.error};
    }
  `}

  ${({ variant }) => LabelVariants[variant]}
`;

const applyInputWrapperVariant = (props: InputWrapperProps) => {
  if (props.variant === 'lighter') {
    return css`
      background-color: ${({ theme }) => theme.input.primary.background};

      ${props.hasBorder &&
      !props.error &&
      css`
        border: ${({ theme }) => theme.input.primary.optionalBorder};
      `}

      border: ${({ theme }) =>
        props.error ? `1px solid ${theme.contextual.error}` : 'none'};

      ${props.disabled &&
      css`
        background-color: ${({ theme }) => theme.surface.transparent};
        border: ${({ theme }) => theme.input.primary.disabledBorder};
      `}

      &:focus-within {
        border: 1px solid ${({ theme }) => theme.input.primary.focusColor};
      }
    `;
  }

  if (props.variant === 'bordered') {
    return css`
      background-color: ${({ theme }) => theme.surface.transparent};
      border: ${({ theme }) => theme.input.bordered.border};

      &:focus-within,
      &:focus-visible {
        border: 1px solid ${({ theme }) => theme.secondary.dark};
      }
    `;
  }

  return css`
    background-color: ${({ theme }) => theme.input.outlined.background};
    border: ${({ theme }) =>
      props.error ? `1px solid ${theme.contextual.error}` : 'none'};

    ${props.disabled &&
    css`
      background-color: ${({ theme }) => theme.surface.transparent};
      border: ${({ theme }) => theme.input.disabled.border};
    `}

    &:focus-within, &:focus-visible {
      border: 1px solid ${({ theme }) => theme.surface.main};
    }
  `;
};

export const InputWrapper = styled.div<InputWrapperProps>`
  margin-top: ${({ theme }) => theme.spacing.xs};
  width: 100%;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.md};

  ${(props) => applyInputWrapperVariant(props)}

  ${(props) =>
    props.startAdornment &&
    css`
      padding-left: ${({ theme }) => theme.spacing.md};
    `}

  ${(props) =>
    props.endAdornment &&
    css`
      padding-right: ${({ theme }) => theme.spacing.md};
    `}

  overflow: hidden;
`;

interface CustomInputProps {
  hasStartAdornment: boolean;
  variant: InputVariant;
  uppercased?: boolean;
}

const applyVariantCustomInput = (props: CustomInputProps) => {
  if (props.variant === 'lighter') {
    return css`
      color: ${({ theme }) => theme.input.primary.textColor};

      ${props.hasStartAdornment &&
      css`
        font-weight: 600;
      `}

      &:disabled {
        color: ${({ theme }) => theme.input.primary.disabledTextColor};
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: text;
        -webkit-text-fill-color: ${({ theme }) =>
          theme.input.primary.textColor};
      }
    `;
  }

  if (props.variant === 'bordered') {
    return css`
      color: ${({ theme }) => theme.input.bordered.textColor};
      background-color: ${({ theme }) => theme.surface.transparent};

      &:-webkit-autofill {
        -webkit-background-clip: text;
        -webkit-text-fill-color: ${({ theme }) => theme.surface.transparent};
      }
    `;
  }

  return css`
    background-color: ${({ theme }) => theme.surface.transparent};
    color: ${({ theme }) => theme.input.outlined.textColor};

    &:-webkit-autofill {
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${({ theme }) => theme.input.outlined.textColor};
    }
  `;
};

export const CustomInput = styled.input<CustomInputProps>`
  ${({ theme, uppercased = true }) => css`
    width: 100%;
    padding: 14px ${theme.spacing.sm};
    font-size: ${theme.fontSizes.small};
    outline: none;
    border: none;
    text-transform: ${uppercased ? 'uppercase' : 'none'};

    letter-spacing: -0.02em;
    line-height: 100%;
  `}
  ${(props) => applyVariantCustomInput(props)}
`;

export const StartAdornmentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.spacing.sm};
  transform: translateY(-50%);
`;

export const EndAdornmentWrapper = styled.div`
  position: absolute;
  top: 60%;
  right: ${({ theme }) => theme.spacing.sm};
  transform: translateY(-60%);
`;

export const ErrorLabel = styled.small`
  color: ${(props) => props.theme.contextual.error};
  font-size: ${(props) => props.theme.fontSizes.small};
`;
