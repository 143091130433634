import { IntlShape } from 'react-intl';
import {
  BatchAssociateInputType,
  BatchType,
  CityFilterInputType,
} from 'types/PaymentSlip';

import { Permission } from 'lib/contextual';

import { SelectItem } from 'components/shared';

const valuesBatchType = {
  'producao lote novos associados': BatchType.NewAssociates,
  cadastro: BatchType.NewAssociates,
  'producao lote reajuste': BatchType.Readjustments,
  atendente: BatchType.NewAssociates,
  atendenteN1: BatchType.NewAssociates,
};

export const pickInitialBatchType = (permission: Permission) =>
  valuesBatchType[permission.name as keyof typeof valuesBatchType] ?? '';

export const batchTypeOptions = (intl: IntlShape): SelectItem[] => [
  {
    label: intl.formatMessage({
      id: 'createPaymentSlip.batchTypeOptions.newAssociates',
    }),
    value: BatchType.NewAssociates,
  },
  {
    label: intl.formatMessage({
      id: 'createPaymentSlip.batchTypeOptions.readjustments',
    }),
    value: BatchType.Readjustments,
  },
];

export const batchAssociateTypeOptions = (intl: IntlShape): SelectItem[] => [
  {
    label: intl.formatMessage({
      id: 'createPaymentSlip.associateTypeOptions.sheet',
    }),
    value: BatchAssociateInputType.Sheets,
  },
  {
    label: intl.formatMessage({
      id: 'createPaymentSlip.associateTypeOptions.api',
    }),
    value: BatchAssociateInputType.Api,
  },
];

export const cityFilterOptions = (intl: IntlShape): SelectItem[] => [
  {
    label: intl.formatMessage({
      id: 'createPaymentSlip.cityFilterOptions.groups',
    }),
    value: CityFilterInputType.Groups,
  },
  {
    label: intl.formatMessage({
      id: 'createPaymentSlip.cityFilterOptions.selection',
    }),
    value: CityFilterInputType.Selection,
  },
  {
    label: intl.formatMessage({
      id: 'createPaymentSlip.cityFilterOptions.none',
    }),
    value: CityFilterInputType.None,
  },
];
