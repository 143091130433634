import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import api from 'services/api';

import { clear, getItem } from 'hooks/useLocalStorage';

import { AUTH } from 'constants/endpoints';
import { Urls } from 'constants/urls';

type WaitingRefreshToken = Params & { method: string };

let isPromisse = false;
const waitingRefreshToken: WaitingRefreshToken[] = [];

const clearAndRedirectToHome = () => {
  clear();
  window.location.href = Urls.Login;
};

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const handleRefreshToken = async () => {
  try {
    isPromisse = true;
    if (isPromisse) {
      await api.postRequest(
        AUTH.REFRESH,
        { email: getItem('email') },
        {
          cancelToken: source.token,
        }
      );
    }
  } catch (err) {
    clearAndRedirectToHome();
  }
};

type Params = {
  path: string;
  payload?: object;
  requestOptions?: AxiosRequestConfig;
};

const handleRecall = async (
  method: keyof typeof api,
  { path, payload, requestOptions }: Params
) => {
  try {
    if (!isPromisse) {
      await handleRefreshToken();
    }

    if (isPromisse) {
      if (waitingRefreshToken.length === 0) {
        setTimeout(() => {
          waitingRefreshToken.forEach(({ method, path, requestOptions }) => {
            switch (method) {
              case 'getRequest':
                return api.getRequest(path, requestOptions);
              case 'deleteRequest':
                return api.deleteRequest(path, payload, requestOptions);
              case 'patchRequest':
                return api.patchRequest(path, payload, requestOptions);
              case 'postRequest':
                return api.postRequest(path, payload || {}, requestOptions);
              case 'putRequest':
                return api.putRequest(path, payload, requestOptions);
            }
          });
        }, 300);
      }

      waitingRefreshToken.push({ path, requestOptions, method });
    }
  } catch (err) {
    clearAndRedirectToHome();
    return;
  }
};

const refreshUserAuthToken = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (http: AxiosResponse) => http,
    (error) => {
      const { response, config } = error;
      const destination = config.url;

      if (destination === AUTH.REFRESH) {
        clearAndRedirectToHome();
        return;
      }

      if (destination === AUTH.SIGN_IN) {
        return Promise.reject(error);
      }

      if (response?.status === 401 || !response) {
        const headers = config.headers;
        const method = `${config.method}Request` as keyof typeof api;
        const data = config.data;
        const path = destination;

        return handleRecall(method, {
          path,
          payload: JSON.parse(data || '{}'),
          requestOptions: {
            headers: {
              ...headers,
            },
          },
        });
      }

      return Promise.reject(error);
    }
  );
};

export default refreshUserAuthToken;
