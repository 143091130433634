import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  ${({ theme }) => css`
    width: 370px;
    gap: ${theme.spacing.sm};
    border-radius: ${theme.borderRadius.sm};
    align-items: center;
    padding: ${theme.spacing.sm} ${theme.spacing.sm1};
    background-color: ${theme.surface.emptyState};
  `}

  & img,
  & svg {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small1};
    color: ${theme.texts.darkSecondary};
    font-weight: 600;
    margin-bottom: ${theme.spacing.xs};
  `}
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small1};
    color: ${theme.texts.muted};
    line-height: ${theme.fontSizes.medium1};
  `}
`;
