import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Associate } from 'types';

import { useAssociateDetails } from 'useCases/associate/useAssociateDetails';

import { ZELO_PRIME_SUBGROUPS } from 'constants/subgroups';

interface AssociateProps {
  associateDetails?: Associate;
  associateSubscription: string;
  isPrimeAssociate: boolean;
  isLoadingAssociateContext: boolean;
  publishAssociate: () => void;
}

const initialState: AssociateProps = {
  associateSubscription: '',
  isPrimeAssociate: false,
  isLoadingAssociateContext: false,
  publishAssociate: () => {},
};

const AssociateContext = createContext<AssociateProps>(initialState);

interface AssociateProviderProps {
  children: ReactNode | ReactNode[];
}

export function isCardDataNecessary() {
  if (window.location.pathname.includes('pagamento')) return true;
  return false;
}

export const AssociateProvider = ({ children }: AssociateProviderProps) => {
  const [associateSubscription, setAssociateSubscription] = useState('');
  const [getCardData, setGetCardData] = useState(isCardDataNecessary());
  const { pathname } = useLocation();
  const { id } = useParams();
  const [isComplete, setIsComplete] = useState(false);

  const { associateDetails, isLoading, isFetching, publish } =
    useAssociateDetails(associateSubscription, getCardData);

  useEffect(() => {
    if (id && pathname) {
      setGetCardData(isCardDataNecessary());
      setAssociateSubscription(id);
    }
  }, [id, pathname]);

  useEffect(() => {
    if (isComplete) return;
    if (associateSubscription) {
      publish();
      if (getCardData) setIsComplete(true);
    }
  }, [getCardData]);

  const publishAssociate = () => {
    publish();
  };

  const isPrimeAssociate =
    !!associateDetails &&
    ZELO_PRIME_SUBGROUPS.includes(associateDetails?.subGroup?.id?.trim());

  return (
    <AssociateContext.Provider
      value={{
        isPrimeAssociate,
        associateDetails,
        associateSubscription,
        isLoadingAssociateContext: isLoading || isFetching,
        publishAssociate,
      }}
    >
      {children}
    </AssociateContext.Provider>
  );
};

export const useAssociateContext = () => useContext(AssociateContext);
