import { useState } from 'react';
import { BatchRequestType } from 'types/PaymentSlip';

import { paymentSlip } from 'services/paymentSlip';

export const useBatchCreator = (batch: BatchRequestType, archive: File) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const mutate = async () => {
    setIsLoading(true);
    try {
      await paymentSlip().createBatch(batch, archive);
      setIsSuccess(true);
    } catch (error) {
      setHasError(true);
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isSuccess,
    hasError,
    mutate,
  };
};
