import { ButtonHTMLAttributes } from 'react';

import * as S from './Button.styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variants?: keyof typeof S.variantsStyles;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  paymentSlipPage?: boolean;
  generatePdf?: boolean;
}

const Button = ({ children, startIcon, endIcon, ...props }: ButtonProps) => (
  <S.Wrapper generatePdf={props.generatePdf} {...props}>
    {startIcon && startIcon}
    {children}
    {endIcon && endIcon}
  </S.Wrapper>
);

export default Button;
