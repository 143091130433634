import { useAlert, useSession } from 'contexts';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Installments } from 'types';

import {
  patchInstallmentByReferences,
  postNewReadjusmentSolicitation,
} from './api';
import { postNewReadjusmentSolicitationPayload } from './interfaces';

export const useTuitionReadjustmentSolicitationFlow = () => {
  const { showAlert, setClear } = useAlert();
  const { user } = useSession();

  const intl = useIntl();
  const patchInstallmentsByReference = useMutation({
    mutationFn: patchInstallmentByReferences,
    onError: (_err: Error, _, _context: any) => {
      setClear();
      showAlert({
        title: intl.formatMessage({
          id: 'tuitonReadjustment.modal.onBackendCall.error2',
        }),
        type: 'error',
      });
    },
  });
  const createNewReadjustmentSolicitation = useMutation({
    mutationFn: postNewReadjusmentSolicitation,
    onError: (_err: Error, _, _context: any) => {
      setClear();
      showAlert({
        title: intl.formatMessage({
          id: 'tuitionLimitsParameterization.Save.Message.Error',
        }),
        type: 'error',
      });
    },
  });
  const withoutSolicitationReadjustment = async (
    values: {
      newValue: string;
      expirationDate: string;
    },
    installments: Installments[],
    subscriptionCode: string
  ) => {
    showAlert({
      title: intl.formatMessage({
        id: 'modalEditPortionPrice.onBackendCall.loading',
      }),
      type: 'loading',
    });
    await patchInstallmentsByReference.mutateAsync({
      newValue: Number.parseFloat(values.newValue),
      references: installments
        ?.map((installment) => installment?.reference)
        .flat(),
      subscriptionCode,
      paymentDateLimit: values.expirationDate,
    });
    setClear();
    showAlert({
      title: intl.formatMessage({
        id: 'modalEditPortionPrice.onBackendCall.success',
      }),
      type: 'success',
    });
  };
  const withReadjusmentSolicitation = async (
    payload: Partial<postNewReadjusmentSolicitationPayload>
  ) => {
    showAlert({
      type: 'loading',
      title: intl.formatMessage({
        id: 'tuitionLimitsParameterization.Save.Message.Loading',
      }),
      message: 'Salvando',
    });
    payload.requestingUser = user.cognitoId;
    payload.userCreated = user.name;
    await createNewReadjustmentSolicitation.mutateAsync(payload);
    setClear();
    showAlert({
      type: 'success',
      title: intl.formatMessage({
        id: 'tuitionLimitsParameterization.Save.Message.Success',
      }),
    });
  };
  return {
    withoutSolicitationReadjustment,
    withReadjusmentSolicitation,
  };
};
