import { useAlert } from 'contexts';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { ReportEletronicBilling } from 'types/BillingScheduling';
import { downloadArchive } from 'utils-general/downloadArchive';
import { transformB64InAB } from 'utils-general/transformB64InAB';

import { getEletronicBillingReport } from 'services/billings';

export const useReporEletronicComission = () => {
  const intl = useIntl();
  const { showAlert, setClear } = useAlert();

  const { data, mutateAsync, isLoading } = useMutation(
    'catchReportEletronicComission',
    (reportInformation: ReportEletronicBilling) =>
      getEletronicBillingReport(reportInformation),
    {
      onSuccess: async (values) => {
        setClear();
        showAlert({
          type: 'success',
          title: intl.formatMessage({
            id: 'electronicBillingReport.modal.success',
          }),
        });

        const type =
          values.reportInformation.type === 'xls'
            ? 'xlsx'
            : values.reportInformation.type;
        const fileName = `${values.reportInformation.startDate}-${values.reportInformation.endDate}.${type}`;
        if (values.reportInformation.type === 'csv') {
          const csvFile = new Blob([values.createReport?.data], {
            type: 'text/csv',
          });
          const downloadUrl = URL.createObjectURL(csvFile);

          await downloadArchive(downloadUrl, fileName);
          return;
        }

        const downloadUrl = transformB64InAB(values.createReport?.data);

        await downloadArchive(downloadUrl, fileName);
      },
      onError: () => {
        setClear();
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'failureToSend',
          }),
        });
      },
    }
  );
  return {
    document: data,
    fetchDocument: mutateAsync,
    isLoading,
  };
};
