import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import Skeleton from 'components/shared/newCore/Skeleton';

const Paper = styled.button`
  border: none;
  width: 100%;
  height: 100%;  
  ${({ theme }) => css`
    background-color: ${theme.surface.main};
    border-radius: ${theme.borderRadius.md};
    box-shadow: ${theme.elevation.default};
  `}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:active {
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.3;
  }

  &:hover {
    border: 2px solid ${(props) => props.theme.primary.main};
  }
`;

const LoadingPaper = styled(Skeleton)``;

type PaperProps = {
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Component = ({ isLoading = false, ...props }: PaperProps) =>
  isLoading ? <LoadingPaper /> : <Paper {...props} />;

export default Component;
