import { useAlert } from 'contexts';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useInfiniteQuery } from 'react-query';
import { EquipmentResponse, OrthopedicEquipmentParams } from 'types';
import { convertJsonToExcel } from 'utils-general/convertJsontoExcel';

import { orthopedicLoan } from 'services/orthopedic';

export const useFindAllEquipmentProperties = (
  {
    limit = 100,
    term,
    branch,
    equipmentGroup,
    status,
    id,
    aquisitionValue,
    value,
  }: OrthopedicEquipmentParams,
  enableOnMount?: boolean,
  queries?: boolean
) => {
  const { getAllOrthopedicEquipmentsWithParams } = orthopedicLoan();
  const [equipmentsByGroupLength, setEquipmentGroupLength] = useState(0);
  const { showAlert, setVisible, setClear } = useAlert();

  const intl = useIntl();

  const {
    data: allEquipmentProperties,
    error,
    ...rest
  } = useInfiniteQuery(
    [
      'findAllEquipmentProperties',
      { branch, equipmentGroup, status, id, term },
    ],
    ({ pageParam }) =>
      getAllOrthopedicEquipmentsWithParams({
        limit,
        page: pageParam,
        term,
        branch,
        equipmentGroup,
        status,
        id,
        aquisitionValue,
        value,
      }),
    {
      getNextPageParam: (data) => {
        if (data.items?.length === 0) return;
        return data.meta.currentPage + 1;
      },
      enabled: enableOnMount,
      onSuccess(data) {
        const validByGroupQuery =
          !term && !branch && !status && !id && !aquisitionValue && !value;
        if (validByGroupQuery) {
          setEquipmentGroupLength(data?.pages[0].meta.totalItems);
        }
      },
    }
  );

  const downloadReport = () => {
    if (!allValues?.length && !rest.isFetchedAfterMount) {
      downloadFullExcelReport();
      return;
    }

    convertToExcel(allValues);
  };

  const convertToExcel = (data: EquipmentResponse[] = []) => {
    convertJsonToExcel(
      data?.map((equipment: EquipmentResponse) => ({
        Descricao: equipment.description,
        Numero_patrimonio: equipment.id,
        Observacao: equipment.comment,
        Filial: equipment.branch.name,
        Valor_Do_Equipamento: equipment.value.toFixed(2),
        Valor_da_Aquisicao: equipment.aquisitionValue?.toFixed(2),
        Status: equipment.status
          ? intl.formatMessage({
              id: `orthopedicEquipment.status.${equipment?.status}`,
            })
          : '-',
      })),
      `Relatorio de gestão de equipamentos`
    );
  };

  const downloadFullExcelReport = () => {
    showAlert({
      type: 'loading',
      title: intl.formatMessage({
        id: 'orthopedicEquipment.modal.showAlertLoadingSuccess',
      }),
    });

    getAllOrthopedicEquipmentsWithParams({
      limit: 9999,
    })
      .then(({ items }) => {
        convertToExcel(items);
        showAlert({
          type: 'success',
          title: intl.formatMessage({
            id: 'orthopedicEquipment.modal.showAlertSuccess',
          }),
        });
      })
      .catch(() => {
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'orthopedicEquipment.modal.showAlertError',
          }),
        });
      })
      .finally(() => {
        setVisible(false);
        setClear();
      });
  };

  const allValues = useMemo(
    () => allEquipmentProperties?.pages.flatMap(({ items }) => items),
    [allEquipmentProperties]
  );

  const equipmentPropertiesDataNormalized = useMemo(() => {
    const items = allValues?.map((item) => ({
      label: `${item.id} - ${item.description}`,
      value: item.id,
    }));
    if (items) return items;

    return [
      {
        label: intl.formatMessage({
          id: 'emptyEquipmentsList',
        }),
        value: '',
      },
    ];
  }, [allValues]);

  return {
    equipmentPropertiesData: allValues?.length ? allValues : [],
    equipmentsByGroupLength,
    equipmentPropertiesDataNormalized,
    downloadReport,
    ...rest,
  };
};
