import { useCallback, useEffect, useRef, useState } from 'react';

import api from 'services/api';

import { useLoading } from 'hooks/useLoading';
import { useWebsocket } from 'hooks/useWebsocket';

import { generateRandomHash } from 'lib/core/GenerateRandomHash';
import { isStatusCodeError } from 'lib/core/statusCodeErrorVerify';

import { useSession } from 'contexts/AuthContext';

import { AUTH } from 'constants/endpoints';

import { getItem } from './useLocalStorage';

type UseMutateGatewayOptions<T> = {
  onSuccess: (data: T) => void;
  onError?: (data: any) => void;
  transactionId: string;
};

const DEFAULT_TIMEOUT_TIME = 60;

export type Payload<T> = {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
  items: T[];
  [key: string]: any;
};

type Fetcher<Result = any, Body = any> = (
  transactionId: string,
  body: Body
) => Promise<Result>;

export const useMutationGateway = <P = any, data = any, body = any>(
  callback: Fetcher<data, body>,
  { transactionId, onError, onSuccess }: UseMutateGatewayOptions<P>,
  timeoutTime: number = DEFAULT_TIMEOUT_TIME
) => {
  const { user } = useSession();
  const [payload, setPayload] = useState<Payload<any>>();
  const { dispatch, ...otherLoadingProps } = useLoading();
  const { current, subscribe, retries } = useWebsocket();
  const [event, setEvent] = useState(transactionId);
  const [socketId, setSocketId] = useState(current.id);
  const isValid = useRef(false);
  const isFirstCall = useRef(true);
  const responseArrived = useRef(true);
  let timeoutcounter: NodeJS.Timeout;

  const handleTimeoutError = () => {
    dispatch({ type: 'SET_STATUS', payload: 'error' });
    onError?.({ error: 'Request Timeout' });
  };

  useEffect(() => {
    if (responseArrived.current) {
      clearTimeout(timeoutcounter);
    }
  }, [responseArrived.current]);

  const onRecieveData = (data: any) => {
    const body = JSON.parse(data.Body);
    const message = JSON.parse(body.Message);
    responseArrived.current = true;

    if (message.payload) {
      if (
        message.payload.errors ||
        message.payload.error ||
        Number.isInteger(message.payload?.payload) ||
        isStatusCodeError(message.payload.statusCode)
      ) {
        dispatch({ type: 'SET_STATUS', payload: 'error' });
        onError?.(message.payload);
        return;
      }

      setPayload(message.payload);
      onSuccess(message.payload);
    }
    dispatch({ type: 'SET_STATUS', payload: 'success' });
  };

  const mutate = async (variables: any) => {
    dispatch({ type: 'SET_STATUS', payload: 'pending' });
    try {
      const transactionIdWithHash = `${event}|${generateRandomHash()}`;
      await callback(transactionIdWithHash, variables);
      subscribe(transactionIdWithHash, onRecieveData);
      responseArrived.current = false;

      if (!timeoutcounter) {
        timeoutcounter = setTimeout(() => {
          if (!responseArrived.current) {
            handleTimeoutError();
          }
        }, timeoutTime * 1000);
      }
    } catch (err) {
      dispatch({ type: 'SET_STATUS', payload: 'error' });
      onError?.(err);
    }
  };

  const dispatchConnectCallback = useCallback(async () => {
    await api.postRequest(AUTH.REFRESH, { email: getItem('email') });
  }, []);

  useEffect(() => {
    if (transactionId && !!user.cognitoId && socketId) {
      dispatch({ type: 'SET_STATUS', payload: 'idle' });
      isValid.current = true;
      setEvent(`${transactionId}|${user.cognitoId}|${socketId}`);
      return;
    }

    if (retries >= 3 && isFirstCall.current) {
      isFirstCall.current = false;
      dispatchConnectCallback();
    }

    return () => {
      isValid.current = false;
    };
  }, [
    transactionId,
    user.cognitoId,
    socketId,
    dispatch,
    retries,
    dispatchConnectCallback,
  ]);

  current.on('connect', () => {
    setSocketId(current.id);
  });

  return {
    payload,
    mutate,
    isMutateLoading: otherLoadingProps.isLoading,
    state: otherLoadingProps.state,
    isIdle: otherLoadingProps.isIdle,
    isSuccess: otherLoadingProps.isSuccess,
  };
};
