import { useRef, useState } from 'react';

import Websocket from 'lib/core/Websocket';

import { Urls } from 'constants/urls';

export const useWebsocket = () => {
  const socket = Websocket.getInstance();

  const [retries, setRetries] = useState(0);
  const { current } = useRef(socket);

  const subscribe = (event: string, callback: (data: any) => void) => {
    current.off(event);
    current.on(event, callback);
  };

  current.on('connect_error', () => {
    if (
      document.location.pathname === Urls.Login ||
      document.location.pathname.includes(Urls.RecoverPassword.replace('*', ''))
    ) {
      setTimeout(() => {
        current.disconnect();
      }, 1000);
      return;
    }

    if (retries < 3) {
      setTimeout(() => {
        setRetries((r) => r + 1);
        current.connect();
      }, 1000);
    }
  });

  current.on('connect', () => {
    setRetries(0);
  });

  return {
    subscribe,
    current,
    retries,
  };
};
