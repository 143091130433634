import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { DocumentsOptionsType, searchForOptionsType } from './types';

export const usePrintPaymentSlipValidation = () => {
  const intl = useIntl();

  return Yup.object().shape({
    documents: Yup.string().required(intl.formatMessage({ id: 'emptyField' })),
    documentModel: Yup.string().when('documents', (documents) => {
      if (documents === DocumentsOptionsType.Carnet) {
        return Yup.string().required(intl.formatMessage({ id: 'emptyField' }));
      } else {
        return Yup.string();
      }
    }),
    printing: Yup.string().when('documents', (documents) => {
      if (documents === DocumentsOptionsType.Carnet) {
        return Yup.string().required(intl.formatMessage({ id: 'emptyField' }));
      } else {
        return Yup.string();
      }
    }),
    searchFor: Yup.string().required(intl.formatMessage({ id: 'emptyField' })),
    registrations: Yup.string().when('searchFor', (searchFor) => {
      if (searchFor === searchForOptionsType.Registrations) {
        return Yup.string().required(intl.formatMessage({ id: 'emptyField' }));
      } else {
        return Yup.string();
      }
    }),
    batchCode: Yup.string().when('searchFor', (searchFor) => {
      if (searchFor === searchForOptionsType.BatchCode) {
        return Yup.string().required(intl.formatMessage({ id: 'emptyField' }));
      } else {
        return Yup.string();
      }
    }),
  });
};
