import { IntlShape } from 'react-intl';
import { BatchType } from 'types/PaymentSlip';

import { SelectItem } from 'components/shared';

const statusList = [
  'pending',
  'processing',
  'completed',
  'failed',
  'monthly-payments-processing',
  'monthly-payments-completed',
  'monthly-payments-failed',
  'bank-slips-processing',
  'bank-slips-completed',
  'bank-slips-failed',
  'bank-slips-processing-conciliation',
  'bank-slips-completed-conciliation',
  'bank-slips-failed-conciliation',
  'bank-slips-processing-shipping',
  'bank-slips-completed-shipping',
  'bank-slips-failed-shipping',
  'uploading-results',
  'finished',
];

export const selectStatusOptions = (intl: IntlShape): SelectItem[] =>
  statusList.map((value) => ({
    label: intl.formatMessage({ id: `bankSlipStatus.${value}` }),
    value,
  }));

export const selectBatchOptions = (intl: IntlShape): SelectItem[] => [
  {
    label: intl.formatMessage({
      id: 'createPaymentSlip.batchTypeOptions.newAssociates',
    }),
    value: BatchType.NewAssociates,
  },
  {
    label: intl.formatMessage({
      id: 'createPaymentSlip.batchTypeOptions.readjustments',
    }),
    value: BatchType.Readjustments,
  },
];
