export enum STATUS_DEPENDENTS {
  ATIVO = 'ATIVO',
  SUSPENSO = 'SUSPENSO',
  CANCELADO = 'CANCELADO',
  EM_CARENCIA = 'EM CARÊNCIA',
  AGUARDANDO = 'AGUARDANDO',
  NAO_CONFIRMADO = 'NÃO CONFIRMADO',
  DEVOLVIDO = 'DEVOLVIDO',
  FALECIDO = 'FALECIDO',
}

export enum STATUS_DEPENDENTS_IDS {
  ATIVO = 1,
  SUSPENSO = 2,
  CANCELADO = 3,
  EM_CARENCIA = 4,
  AGUARDANDO = 5,
  NAO_CONFIRMADO = 6,
  DEVOLVIDO = 7,
  FALECIDO = 8,
}

export enum STATUS_DEPENDENTS_API {
  ATIVO = 'ACTIVE',
  SUSPENSO = 'SUSPENDED',
  CANCELADO = 'CANCELED',
  'EM CARÊNCIA' = 'INDEMAND',
  AGUARDANDO = 'AWAIT',
  'NÃO CONFIRMADO' = 'NOTCONFIRMED',
  DEVOLVIDO = 'RETURNED',
  FALECIDO = '',
}
