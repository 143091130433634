import styled, { css } from 'styled-components';

export const Button = styled.button`
  background-color: transparent;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.link.light.textColor};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
  cursor: pointer;
  font-weight: 600;

  &.lighter {
    color: ${({ theme }) => theme.link.primary.textColor};
  }

  &.darken {
    color: ${({ theme }) => theme.link.secondary.textColor};
  }

  &.link:hover {
    color: ${({ theme }) => theme.link.secondary.hover};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  @media screen and (max-width: 585px) {
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.xs};
      gap: ${theme.spacing.xs};
    `}
  }
`;
