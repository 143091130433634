import { memo, useState } from 'react';

import * as S from './paginationButton.styles';

interface PaginationButtonProps {
  totalPages: number;
  pageSelected: (page: number) => void;
}

const PaginationButton = ({
  totalPages,
  pageSelected,
}: PaginationButtonProps) => {
  const [actualPage, setActualPage] = useState(1);
  const [pages, setPages] = useState([3, 4, 5]);

  const secondToLast = totalPages - 2;

  const changePages = (page: number) => {
    if (pages[0] === 3 && page === 3) {
      setActualPage(3);
      return;
    }
    if (pages[2] === secondToLast && page === secondToLast) {
      setActualPage(secondToLast);
      return;
    }
    setActualPage(page);
    setPages([page - 1, page, page + 1]);
  };
  if (!totalPages || totalPages <= 1) return null;
  if (totalPages && totalPages > 10) {
    return (
      <S.PaginationArea>
        <S.NumberPageCheck
          selected={actualPage === 1}
          onClick={() => {
            if (actualPage !== 1) {
              pageSelected(1);
              setActualPage(1);
            }
          }}
        >
          {1}
        </S.NumberPageCheck>
        <S.NumberPageCheck
          selected={actualPage === 2}
          onClick={() => {
            pageSelected(2);
            setActualPage(2);
          }}
        >
          {2}
        </S.NumberPageCheck>
        <S.IntervalWrapper>
          <p>...</p>
        </S.IntervalWrapper>
        {pages.map((page, index) => (
          <S.NumberPageCheck
            selected={actualPage === page}
            onClick={() => {
              changePages(page);
              pageSelected(page);
            }}
            key={index}
          >
            {page}
          </S.NumberPageCheck>
        ))}
        <S.IntervalWrapper>
          <p>...</p>
        </S.IntervalWrapper>
        <S.NumberPageCheck
          selected={actualPage === totalPages - 1}
          onClick={() => {
            if (actualPage !== totalPages - 1) {
              pageSelected(totalPages - 1);
              setActualPage(totalPages - 1);
            }
          }}
        >
          {totalPages - 1}
        </S.NumberPageCheck>
        <S.NumberPageCheck
          selected={actualPage === totalPages}
          onClick={() => {
            pageSelected(totalPages);
            setActualPage(totalPages);
          }}
        >
          {totalPages}
        </S.NumberPageCheck>
      </S.PaginationArea>
    );
  }
  return (
    <S.PaginationArea>
      {Array(totalPages)
        .fill({})
        .map((_, index) => (
          <S.NumberPageCheck
            selected={actualPage === index + 1}
            onClick={() => {
              pageSelected(index + 1);
              setActualPage(index + 1);
            }}
            key={index}
          >
            {index + 1}
          </S.NumberPageCheck>
        ))}
    </S.PaginationArea>
  );
};

export default memo(PaginationButton);
