export enum OptionsFilterBy {
  Enrollment = 'enrollment',
  AssociatedName = 'associatedName',
}

export enum OptionsFile {
  EXCEL = 'xls',
  LIBREOFFICE = 'csv',
}

export enum ResultFilteringBy {
  Enrollment = 'enrollment',
  AssociatedName = 'associatedName',
}
