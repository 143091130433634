import { useAlert } from 'contexts';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { Batch } from 'types/PaymentSlip';
import { downloadWithLink } from 'utils-general/downlodFileWithLink';

import { paymentSlip } from 'services/paymentSlip';

export const useDownloadPdf = (batchType: string) => {
  const fileName = useRef<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const timeToRefetch = 10_000;
  const {
    postBatchForPrinterCover,
    postBatchForPrinterCarnet,
    postBatchForPrinterLabels,
    getDownloadBatchPdf,
  } = paymentSlip();
  const { setClear, showAlert } = useAlert();

  const getDocumentType = () => {
    if (batchType === 'cover') return postBatchForPrinterCover;
    if (batchType === 'label') return postBatchForPrinterLabels;
    return postBatchForPrinterCarnet;
  };

  const { mutateAsync: postBatch } = useMutation(getDocumentType(), {
    onSuccess: (response) => {
      if (response?.data) {
        fileName.current = response.data;
      } else {
        setIsLoading(false);
        process.nextTick(() => {
          setClear();
          showAlert({
            type: 'error',
            title: intl.formatMessage({
              id: 'paymentSlip.print.modal.error',
            }),
            message: intl.formatMessage({
              id: 'paymentSlip.print.modal.error.message',
            }),
          });
        });
      }
    },
    onError: () => {
      setIsLoading(false);
      process.nextTick(() => {
        setClear();
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'paymentSlip.print.modal.error',
          }),
          message: intl.formatMessage({
            id: 'paymentSlip.print.modal.error.message',
          }),
        });
      });
    },
  });

  const { refetch: refetchDownload } = useQuery(
    ['verifyIfFileExists', fileName],
    () => getDownloadBatchPdf(fileName.current),
    {
      enabled: fileName.current !== '',
      onSuccess: (verify) => {
        if (verify?.data?.exists) {
          fileName.current = '';
          downloadWithLink(verify?.data?.url);
          process.nextTick(() => {
            setClear();
            showAlert({
              type: 'success',
              title: intl.formatMessage({
                id: 'paymentSlip.print.modal.success',
              }),
              message: intl.formatMessage({
                id: 'paymentSlip.print.modal.success.message',
              }),
            });
          });
          setIsLoading(false);
        } else {
          setTimeout(() => {
            refetchDownload();
          }, timeToRefetch);
        }
      },
      onError: (error: any) => {
        setIsLoading(false);
        fileName.current = '';
        if (error?.response?.status === 404) {
          process.nextTick(() => {
            setClear();
            showAlert({
              type: 'warn',
              title: intl.formatMessage({
                id: 'paymentSlip.print.modal.noContentError',
              }),
              message: intl.formatMessage({
                id: 'paymentSlip.print.modal.noContentError.message',
              }),
            });
          });

          return;
        }

        process.nextTick(() => {
          setClear();
          showAlert({
            type: 'error',
            title: intl.formatMessage({
              id: 'paymentSlip.print.modal.error',
            }),
            message: intl.formatMessage({
              id: 'paymentSlip.print.modal.error.message',
            }),
          });
        });
      },
    }
  );

  const downloadBatch = (fileName: Batch) => {
    postBatch(fileName);
    setIsLoading(true);
    showAlert({
      type: 'loading',
      title: intl.formatMessage({ id: 'paymentSlip.print.modal.loading' }),
      message: intl.formatMessage({
        id: 'paymentSlip.print.modal.loading.message',
      }),
    });
  };

  return {
    downloadBatch,
    isLoading,
  };
};
