import { Root } from '@radix-ui/react-alert-dialog';
import { AxiosError, AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { UseMutationResult } from 'react-query';
import { EquipmentsFormProps, EquipmentsProps } from 'types/Orthopedic';

import { useFindAllSubsidiaries } from 'useCases/associate/useFindAllSubsidiaries';
import { useFindAllEquipmentGroups } from 'useCases/orthopedic';

import { useAlert } from 'contexts/AlertContext';

import { Grid, Row } from 'components/shared';
import RoundedIcon from 'components/shared/core/roundedIcon';
import { default as ButtonLink } from 'components/shared/forms/ButtonLink';
import Input from 'components/shared/forms/Input';
import MaskedInput from 'components/shared/forms/MaskedInput';
import SearchSelect, {
  SelectOption,
} from 'components/shared/forms/SearchSelect';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';

import { useOrthopedicModalEquipmentValidation } from './UseOrthopedicModalEquipmentValidation';
import * as S from './orthopedicModalEquipment';

interface ModalNewObservationProps {
  open: boolean;
  closeModal: () => void;
  title?: string;
  equipment: EquipmentsProps;
  onSubmit: UseMutationResult<
    AxiosResponse<any>,
    unknown,
    EquipmentsFormProps,
    unknown
  >;
  refetch: () => void;
  statusModal: string;
}

const OrthopedicModalEquipment = ({
  open,
  closeModal,
  title = '',
  equipment,
  onSubmit,
  refetch,
  statusModal,
}: ModalNewObservationProps) => {
  const intl = useIntl();

  const { allSubsidiaries: branches, isLoading: isLoadingBranches } =
    useFindAllSubsidiaries({});

  const {
    equipmentGroups,
    hasNextPage: hasNextPageEquipmentGroups,
    fetchNextPage: fetchNextPageGroups,
    isLoading: isLoadingEquipmentGroups,
    error: errorEquipmentGroups,
  } = useFindAllEquipmentGroups({
    limit: 100,
  });

  const groupEquipment: SelectOption[] = equipmentGroups?.pages[0]?.items
    ? equipmentGroups?.pages[0].items?.map((group) => ({
        label: group.description,
        value: group.id,
      }))
    : ([] as SelectOption[]);

  const allBranchesOptions = useMemo(
    () =>
      branches?.map((branch) => ({
        label: `(${branch.code}) - ${branch.name}`,
        value: branch.code,
      })) ?? [
        {
          label: '',
          value: -1,
        },
      ],
    [branches]
  );

  const { showAlert, setVisible } = useAlert();

  const validationSchema = useOrthopedicModalEquipmentValidation();

  const handlePaginationEquipmentGroups = () => {
    if (!isLoadingEquipmentGroups) {
      fetchNextPageGroups();
    }
  };

  const setFormikValues = (
    equipment: EquipmentsProps
  ): EquipmentsFormProps => ({
    id: equipment.id === 0 || !equipment.id ? '' : equipment.id,
    description: equipment.description,
    comment: equipment.comment,
    value: equipment.value,
    aquisitionValue: equipment.aquisitionValue,
    equipmentGroupId: equipment.equipmentGroupId,
    branchId: equipment.branchId,
  });

  const formik = useFormik<EquipmentsFormProps>({
    initialValues: setFormikValues(equipment),
    validationSchema,
    onSubmit: (values, formikBag) => {
      showAlert({
        type: 'loading',
        title: intl.formatMessage({
          id: 'orthopedicEquipment.modal.showAlertLoadingSuccess',
        }),
        message: intl.formatMessage({
          id: 'orthopedicEquipment.modal.loading.message',
        }),
      });
      onSubmit.mutate(
        {
          ...values,
          value: Number(values.value),
          aquisitionValue: Number(values.aquisitionValue),
          id: Number(values.id),
        },
        {
          onSuccess: (data: AxiosResponse<any>) => {
            closeModal();
            setVisible(false);
            let title: string;
            if (statusModal === 'edit') {
              title = intl.formatMessage({
                id: 'orthopedicEquipment.modal.showAlertEdit',
              });
            } else {
              title = intl.formatMessage({
                id: 'orthopedicEquipment.modal.showAlertCreate',
              });
            }
            showAlert({
              type: 'success',
              title: title,
              message: '',
            });
            refetch();
          },
          onError: (
            error: unknown,
            variables: EquipmentsFormProps,
            context: unknown
          ) => {
            closeModal();
            setVisible(false);
            if ((error as AxiosError<any>).response?.status === 422) {
              showAlert({
                type: 'error',
                title: intl.formatMessage({
                  id: 'orthopedicEquipment.modal.showAlertErrorDuplicityId',
                }),
              });
            } else {
              showAlert({
                type: 'error',
                title: intl.formatMessage({
                  id: 'orthopedicEquipment.modal.showAlertError',
                }),
              });
            }
          },
        }
      );
      formikBag.resetForm();
    },
  });

  const isDisabled = typeof formik.values.id === 'number' && !!formik.values.id;

  useEffect(() => {
    formik.setValues({
      ...setFormikValues(equipment),
    });
  }, [equipment]);

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <Root open={open}>
      <S.Overlay />
      <S.Modal $isClosing={!open}>
        <S.Content $isClosing={!open}>
          <S.Title>{title}</S.Title>
          <S.Form onSubmit={formik.handleSubmit}>
            <Grid>
              <Row span="4">
                <SearchSelect
                  required
                  value={formik.values.equipmentGroupId}
                  setValue={(_, value) =>
                    formik.setFieldValue('equipmentGroupId', value as string)
                  }
                  options={groupEquipment}
                  id="equipmentGroup"
                  isLoading={isLoadingEquipmentGroups}
                  handlePagination={handlePaginationEquipmentGroups}
                  hasPagination={hasNextPageEquipmentGroups}
                  hasErrorPagination={!!errorEquipmentGroups}
                  label={intl.formatMessage({
                    id: 'orthopedicEquipment.group',
                  })}
                  hasErrors={Boolean(formik.errors.equipmentGroupId)}
                  errorMessage={String(formik.errors.equipmentGroupId)}
                  cancelSearch={() => {
                    // clearFilter();
                    formik.setFieldValue('equipmentGroup', '');
                    formik.setFieldValue('freeDays', '');
                  }}
                />
              </Row>
              <Row span="4">
                <Input
                  required
                  id="description"
                  label={intl.formatMessage({
                    id: 'orthopedicEquipment.modal.description.title',
                  })}
                  variant={'lighter'}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasErrors={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={String(formik.errors.description)}
                ></Input>
              </Row>
              <Row span="4">
                <Input
                  required
                  disabled={isDisabled}
                  id="id"
                  label={intl.formatMessage({
                    id: 'orthopedicEquipment.id',
                  })}
                  variant={'lighter'}
                  value={formik.values.id}
                  onChange={({ target: { value } }) => {
                    formik.setFieldValue('id', value.replace(/^[^0-9]/g, ''));
                  }}
                  onBlur={formik.handleBlur}
                  hasErrors={formik.touched.id && Boolean(formik.errors.id)}
                  helperText={String(formik.errors.id)}
                ></Input>
              </Row>
              <Row span="4">
                <SearchSelect
                  required
                  value={formik.values.branchId || ''}
                  setValue={(label, value) => {
                    formik.setFieldValue('branchId', value);
                    formik.setFieldTouched('branchId', false);
                  }}
                  options={allBranchesOptions}
                  id="branchId"
                  isLoading={isLoadingBranches}
                  label={intl.formatMessage({
                    id: 'orthopedicEquipment.modal.branch.title',
                  })}
                  hasErrors={
                    formik.touched.branchId && Boolean(formik.errors.branchId)
                  }
                  errorMessage={formik.errors.branchId || ''}
                  cancelSearch={() => {
                    formik.setFieldValue('branchId', '');
                    formik.setFieldTouched('branchId', false);
                  }}
                ></SearchSelect>
              </Row>
              <Row span="4">
                <Input
                  id="comment"
                  label={intl.formatMessage({
                    id: 'orthopedicEquipment.modal.comments.title',
                  })}
                  variant={'lighter'}
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasErrors={
                    formik.touched.comment && Boolean(formik.errors.comment)
                  }
                  helperText={String(formik.errors.comment)}
                ></Input>
              </Row>
              <Row span="4">
                <MaskedInput
                  maskType="money"
                  label={intl.formatMessage({
                    id: 'orthopedicEquipment.modal.acquisitionValue.title',
                  })}
                  value={Number(formik.values.aquisitionValue).toFixed(2)}
                  onChange={({ target: { value } }) => {
                    formik.setFieldValue('aquisitionValue', value);
                  }}
                  name="aquisitionValue"
                  variant="lighter"
                  hasErrors={
                    formik.touched.aquisitionValue &&
                    Boolean(formik.errors.aquisitionValue)
                  }
                  helperText={String(formik.errors.aquisitionValue)}
                />
              </Row>
            </Grid>
            <S.Footer>
              <ButtonLink onClick={closeModal}>
                {intl.formatMessage({
                  id: 'actions.cancel',
                })}
              </ButtonLink>
              <ButtonLink type="submit">
                {intl.formatMessage({
                  id: 'actions.confirm',
                })}
                <RoundedIcon>
                  <ArrowRight />
                </RoundedIcon>
              </ButtonLink>
            </S.Footer>
          </S.Form>
        </S.Content>
      </S.Modal>
    </Root>
  );
};

export default OrthopedicModalEquipment;
