import React from 'react';
import { useIntl } from 'react-intl';

import CustomFeedback from 'components/shared/core/customFeedback';

import Sad from 'assets/images/Sad.svg';

const EmptyState = () => {
  const intl = useIntl();
  return (
    <CustomFeedback
      iconUrl={Sad}
      title={intl.formatMessage({ id: 'emptyState.title' })}
      description={intl.formatMessage({ id: 'emptyState.subtitle' })}
    />
  );
};

export default EmptyState;
