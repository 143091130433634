import styled, { css } from 'styled-components';

export const SectionTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small1};
    color: ${theme.texts.thinParagraph2};
    margin-top: 35px;
    margin-bottom: 35px;
  `}
`;

export const Title = styled.div`
  margin: ${({ theme }) => theme.spacing.md} 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: end;
  margin-top: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.lg} 0;
  gap: ${({ theme }) => theme.spacing.lg2};
`;

export const LoadingContainer = styled.footer`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.lg2};
  justify-content: center;
  align-items: center;
`;

export const EmptyStateContainer = styled.section`
  ${({ theme }) => css`
    margin-top: ${theme.new.spacing.xl6};
    display: flex;
    justify-content: center;
  `}
`;
