import styled, { css } from 'styled-components';

import Skeleton from 'components/shared/newCore/Skeleton';
import Table from 'components/shared/newCore/Table';

import { device } from 'styles/tokens/devices';

export const Container = styled.div`
  ${Table.Column} {
    width: 16.67%;

    @media ${device.tabletL} {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

export const ContentTable = styled.div``;

export const EmptyStateContainer = styled.section`
  ${({ theme }) => css`
    margin-top: ${theme.new.spacing.xl6};
    display: flex;
    justify-content: center;
  `}
`;

export const ListSkeleton = styled(Skeleton)`
  ${({ theme }) => css`
    width: calc(100% - 8px);
    height: 72px;
    margin-bottom: ${theme.new.spacing.sm};
  `}
`;

// export const ListItemColumn = styled.section`
//   ${({ theme }) => css`
//     color: ${theme.new.colors.neutrals[400]};
//     font-size: ${theme.new.typography.size.default};
//     padding: ${theme.new.spacing.xl} 0;
//     overflow: hidden;
//     white-space: nowrap;
//     word-wrap: break-all;
//     text-overflow: ellipsis;
//     text-align: center;

//     &:last-child {
//       display: flex;
//       justify-content: flex-end;

//       @media ${device.tabletL} {
//         justify-content: flex-start;
//       }
//     }
//   `};
// `;

export const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

interface ControlSpaceAreaProps {
  marginLeft?: number;
}

export const ControlSpaceArea = styled.div<ControlSpaceAreaProps>`
  max-width: 300px;
  overflow: hidden;
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  span {
    display: none;
  }
  @media ${device.tabletL} {
    text-align: left;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow-x: auto;
    margin-left: 0;
    & span {
      color: ${({ theme }) => theme.new.colors.primary.main};
      font-weight: 600;
      display: inline;
      padding-right: 0.2rem;
    }
  }
`;
