import styled, { css } from 'styled-components';

import { device } from 'styles/tokens/devices';

interface ColumnProps {
  flex?: number;
  contentPosition?: 'center' | 'flex-start' | 'flex-end';
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  columnSize?: number;
  paddingLeft?: number;
  paddingRight?: number;
  isHeader?: boolean;
  upperCase?: boolean;
  mobile?: boolean;
}

const Column = styled.div<ColumnProps>`
  ${({
    theme,
    contentPosition,
    direction = 'row',
    columnSize,
    isHeader,
    paddingLeft,
    paddingRight,
  }) => css`
    display: flex;
    flex-direction: ${direction};
    justify-content: ${contentPosition};
    max-width: ${columnSize ? `${columnSize}px` : 'auto'};
    font-weight: ${isHeader
      ? theme.new.typography.weight.semibold
      : theme.new.typography.weight.regular};
    color: ${isHeader
      ? theme.new.colors.primary.main
      : theme.new.colors.neutrals[700]};
    font-size: ${isHeader
      ? theme.new.typography.size.xs
      : theme.new.typography.size.default};
    padding-left: ${paddingLeft ? paddingLeft : 0}px;
    padding-right: ${paddingRight ? paddingRight : 0}px;
  `};
  flex-wrap: wrap;
  ${(props) =>
    props.mobile &&
    css`
      @media ${device.mobileXs} {
        flex-direction: column;
      }
    `}
`;

export default Column;
