import styled from 'styled-components';

import { Grid, Row } from 'components/shared';

import { device } from 'styles/tokens/devices';

export const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

export const MobileControl = styled.div`
  margin: ${({ theme }) => theme.spacing.md} 0px;
  span {
    display: none;
  }
  @media ${device.tabletL} {
    font-size: 1.5rem;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    span {
      color: ${({ theme }) => theme.new.colors.primary.main};
      font-weight: 600;
      display: inline;
      padding-right: 0.2rem;
    }
  }
`;

export const QueryWrapper = styled(Grid)`
  @media screen and (max-width: 1000px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 350px) {
    grid-auto-flow: inherit;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 600;
`;

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;

export const StyledRow = styled(Row)`
  margin-top: 0.3rem;
`;

export const StyledFile = styled(Row)`
  margin-top: 5px;
`;
