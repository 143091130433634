import { ChangeEvent, InputHTMLAttributes, useRef } from 'react';
import VKMasker, {
  toAlphaNumeric,
  toMoney,
  toNumber,
  toPattern,
} from 'vanilla-masker';

import FormatMonetaryValueFromMask from 'lib/core/FormatMonetaryValueFromMask';

import Tooltip from 'components/shared/newCore/Tooltip';

import * as S from './MaskedInput.styles';

interface MoneyOptions {
  precision?: number | undefined;
  separator?: string | undefined;
  delimiter?: string | undefined;
  unit?: string | undefined;
  suffixUnit?: string | undefined;
  zeroCents?: boolean | undefined;
}

interface PatternOptions {
  pattern?: string | undefined;
  placeholder?: string | undefined;
}

type MaskTypes = 'money' | 'number' | 'mask' | 'alphaNumeric';
export type MaskedInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  variant: S.InputVariant;
  maskType?: MaskTypes;
  mask?: string;
  options?: string | MoneyOptions | PatternOptions;
  helperText?: string;
  id?: string;
  name?: string;
  step?: string;
  hasErrors?: boolean;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  testid?: string;
  required?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  maxLength?: number;
  isLoading?: boolean;
  hasBorder?: boolean;
  isAccordionRegistrationDataForm?: boolean;
  positionContainer?: 'absolute' | 'relative';
  sizeContainer?: number;
};

const MaskedInput = ({
  startAdornment,
  endAdornment,
  onChange,
  value,
  variant,
  label,
  hasErrors,
  testid,
  step,
  required,
  id,
  helperText,
  disabled = false,
  isLoading,
  hasBorder = false,
  maskType = 'mask',
  mask = '',
  options = {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: 'R$',
    placeholder: 'x',
  },
  isAccordionRegistrationDataForm,
  positionContainer,
  sizeContainer,
  ...props
}: MaskedInputProps) => {
  const NUMERIC_MASKS: MaskTypes[] = ['money', 'number'];
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputRef?.current && onChange) {
      VKMasker(inputRef.current).unMask();
      if (maskType === 'money') {
        e.target.value = FormatMonetaryValueFromMask(e.target.value).toFixed(2);
      }

      onChange(e);
    }
  };

  const setMaskedValue = (value: string | number | undefined) => {
    if (!value) {
      value = NUMERIC_MASKS.includes(maskType) ? 0 : '';
    }

    switch (maskType) {
      case 'mask':
        return toPattern(
          value,
          options
            ? mask
            : ({
                pattern: mask,
                ...(options as PatternOptions),
              } as PatternOptions)
        );
      case 'money':
        return toMoney(value, options as MoneyOptions);
      case 'number':
        return toNumber(value);
      case 'alphaNumeric':
        return toAlphaNumeric(value);
      default:
        return toPattern(
          value,
          options
            ? mask
            : ({
                pattern: mask,
                ...(options as PatternOptions),
              } as PatternOptions)
        );
    }
  };

  const maskedValue = setMaskedValue(String(value)) || '';
  if (mask.length > 0) {
    props.maxLength = props.maxLength ?? mask.length;
  }

  return (
    <S.Container
      disabled={disabled}
      isAccordionRegistrationDataForm={isAccordionRegistrationDataForm}
    >
      <S.Label className={variant} htmlFor={id}>
        {required && <span>*</span>} {label}
      </S.Label>
      {isLoading ? (
        <S.SkeletonWrapper />
      ) : (
        <S.InputWrapper
          disabled={disabled}
          endAdornment={!!endAdornment}
          startAdornment={!!startAdornment}
          className={variant}
          error={hasErrors}
          hasBorder={hasBorder}
        >
          {startAdornment && (
            <S.StartAdornmentWrapper>{startAdornment}</S.StartAdornmentWrapper>
          )}
          {endAdornment && (
            <S.EndAdornmentWrapper>{endAdornment}</S.EndAdornmentWrapper>
          )}
          <S.CustomInput
            ref={inputRef}
            disabled={disabled}
            step={step}
            className={variant}
            type="text"
            value={maskedValue}
            data-testid={testid}
            onChange={handleChange}
            isAccordionRegistrationDataForm={isAccordionRegistrationDataForm}
            {...props}
          />
        </S.InputWrapper>
      )}
      {hasErrors && (
        <Tooltip
          positionContainer={positionContainer}
          size={sizeContainer}
          type="error"
          message={helperText as string}
        />
      )}
    </S.Container>
  );
};

export default MaskedInput;
