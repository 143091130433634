export enum EducationLevelsEnum {
  NotInformed = 'Não informado',
  Illiterate = 'Analfabeto',
  ElementaryPartial = '5º ao 9º Ano do Fundamental',
  ElementaryComplete = 'Fundamental Completo',
  HighSchoolIncomplete = 'Médio Incompleto',
  HighSchoolComplete = 'Médio Completo',
  UniversityIncomplete = 'Superior Incompleto',
  UniversityComplete = 'Superior Completo',
  Postgraduate = 'Pós-graduação',
  MasterDegree = 'Mestrado',
  DoctorateDegree = 'Doutorado',
}
