import React, { PropsWithChildren, useState } from 'react';

import { ReactComponent as CaretDown } from 'assets/images/CaretDown.svg';
import { ReactComponent as CaretUp } from 'assets/images/CaretUp.svg';

import * as S from './Accordion.styles';

type AccordionProps = {
  title: string;
  initialState?: boolean;
  isLoading?: boolean;
};

const SimpleAccordion = ({
  title,
  initialState,
  children,
  isLoading,
}: PropsWithChildren<AccordionProps>) => {
  const [showContent, setShowContent] = useState(initialState ?? false);

  const toggleView = () => {
    if (isLoading) return;
    setShowContent((previousState) => !previousState);
  };

  return (
    <S.Container>
      <S.Header onClick={toggleView}>
        <S.Title>{title}</S.Title>
        <S.Button onClick={toggleView}>
          {isLoading ? (
            <S.Loading />
          ) : showContent ? (
            <CaretUp onClick={toggleView} />
          ) : (
            <CaretDown onClick={toggleView} />
          )}
        </S.Button>
      </S.Header>
      {showContent && <S.Content>{children}</S.Content>}
    </S.Container>
  );
};

export default SimpleAccordion;
