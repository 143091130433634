import styled, { css } from 'styled-components';

import Paper from 'components/shared/core/paper';
import CustomText from 'components/shared/core/text';

export const CustomPaper = styled(Paper)`
  padding-bottom: ${({ theme }) => theme.spacing.sm1};
  flex-direction: column;
  width: 21rem;
  height: 14rem;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
  @media screen and (max-width: 820px) {
    padding-top: 2vh;
  }
`;

export const Grid = styled.div`
  ${({ theme }) => css`
    flex-direction: column;
  `}
`;

export const SecondContainerPacker = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.sm};
  @media screen and (max-width: 1263px) {
    justify-content: center;
  }
`;
export const FirstContainerWrapper = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: ${({ theme }) => theme.spacing.sm};
  @media screen and (max-width: 1236px) {
    justify-content: center;
    margin-left: -24rem;
  }
  @media screen and (max-width: 819px) {
    margin-left: 0;
  }
`;

export const Icon = styled.img`
  width: 48px;
  height: 48px;
  background-size: cover;
  overflow: hidden;
`;

export const Title = styled(CustomText)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    line-height: ${theme.fontSizes.medium2};
    color: ${theme.texts.lightSecondary};
  `}
  font-weight: normal;
`;

export const Text = styled(CustomText)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.texts.darkSecondary};
`;
