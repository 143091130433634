import { colors } from 'styles/newTokens/colors';
import { effects } from 'styles/newTokens/effects';
import { shapes } from 'styles/newTokens/shapes';
import { spacing } from 'styles/newTokens/spacing';
import { typography } from 'styles/newTokens/typography';

export const newTheme = {
  colors,
  effects,
  shapes,
  spacing,
  typography,
} as const;
