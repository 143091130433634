import styled, { css } from 'styled-components';

import { device } from 'styles/tokens/devices';

type HeaderProps = {
  screenXs?: boolean;
  justifyContent?: string;
};

const Header = styled.div<HeaderProps>`
  ${({ theme, justifyContent }) => css`
    display: flex;
    justify-content: ${justifyContent ?? 'space-between'};
    align-items: center;
    width: 100%;
    overflow-x: none;
    div {
      text-transform: uppercase;
    }
  `};

  ${(props) =>
    props.screenXs &&
    css`
      @media ${device.tabletL} {
        display: none;
      }
    `}
`;

export default Header;
