import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAnalyticalReport } from 'useCases/financial/useAnalyticReport';
import { useDownloadCollectorReport } from 'useCases/financial/useDownloadCollectorReport';
import { useGetBanksForFinancialReport } from 'useCases/financial/useGetBanksForFinancialReport';
import { useSyntheticalReport } from 'useCases/financial/useSyntheticReport';

import { formatFromMask } from 'lib/core';

import SelectBranches from 'components/contextual/SelectBranch';
import SelectCollector from 'components/contextual/SelectCollector';
import SelectUserSystem from 'components/contextual/SelectUserSystem';
import HomeContent from 'components/contextual/homeContent';
import { Grid, Row } from 'components/shared';
import AlertDialog from 'components/shared/core/alertDialog';
import Divider from 'components/shared/core/divider';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import Input from 'components/shared/forms/Input';
import SearchSelect from 'components/shared/forms/SearchSelect';
import Breadcrumb, {
  BreadcrumbLink,
} from 'components/shared/newCore/Breadcrumb';

import { Urls } from 'constants/urls';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';

import * as S from './styles';
import { TypeOfDischargeOptions } from './types';
import { useFinancialCashReportValidation } from './useFinancialCashReportValidation';

const FinancialCashReport = () => {
  const intl = useIntl();

  const breadcumbLinks: BreadcrumbLink[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
        redirectTo: Urls.HomePage,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.financial' }),
        redirectTo: Urls.financial,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.financialCashReport' }),
        redirectTo: Urls.cashReport,
      },
    ],
    [intl]
  );

  const validationSchema = useFinancialCashReportValidation();
  const { generateSyntheticalReport } = useSyntheticalReport();
  const { downloadCollectorReport } = useDownloadCollectorReport();
  const { generateAnalyticReport } = useAnalyticalReport();
  const { banks } = useGetBanksForFinancialReport();
  const formik = useFormik({
    initialValues: {
      typeOfDischarge: 'bank' as 'user' | 'application' | 'bank',
      bank: [],
      application: '',
      startDate: formatFromMask(addDays(new Date(), -7), 'yyyy-MM-dd'),
      endDate: formatFromMask(new Date(), 'yyyy-MM-dd'),
      branch: '',
      user: '',
      collectors: '',
    },
    validationSchema,
    validateOnMount: false,
    onSubmit: (values) => {
      if (values.typeOfDischarge === 'user') {
        const userIds = values.user
          ? values.user.split(',').map((user) => parseInt(user))
          : [];
        generateAnalyticReport({
          startDate: values.startDate,
          endDate: values.endDate,
          userIds,
        });
        return;
      }
      if (values.typeOfDischarge === 'application') {
        return downloadCollectorReport({
          afterOrEqualDueDate: values.startDate,
          beforeOrEqualDueDate: values.endDate,
          agents: values.collectors.split(','),
          payedFee: true,
        });
      }
      generateSyntheticalReport({
        startDate: values.startDate,
        endDate: values.endDate,
        userIds: values.bank,
      });
    },
  });

  const banksForSelect = useMemo(
    () => banks?.items.map((bank) => ({ label: bank.name, value: bank.id })),
    [banks]
  );

  const typeOfDischarge = [
    {
      label: intl.formatMessage({
        id: 'financial.cashReport.typeOfDischarge.bank.label',
      }),
      value: TypeOfDischargeOptions.Bank,
    },
    {
      label: intl.formatMessage({
        id: 'financial.cashReport.typeOfDischarge.user.label',
      }),
      value: TypeOfDischargeOptions.User,
    },
    {
      label: intl.formatMessage({
        id: 'financial.cashReport.typeOfDischarge.application.label',
      }),
      value: TypeOfDischargeOptions.Application,
    },
  ];

  return (
    <HomeContent>
      <Breadcrumb path={breadcumbLinks} />
      <S.Title>
        {intl.formatMessage({
          id: 'financial.cashReport.title',
        })}
      </S.Title>
      <S.Form onSubmit={formik.handleSubmit}>
        <Grid>
          <Row span="3">
            <SearchSelect
              required
              hasBorder
              label={intl.formatMessage({
                id: 'financial.cashReport.typeOfDischarge',
              })}
              options={typeOfDischarge}
              id="typeOfDischarge"
              value={formik.values.typeOfDischarge}
              setValue={(_, value) => {
                formik.setFieldValue('typeOfDischarge', value);
                formik.setFieldValue('user', '');
                formik.setFieldValue('bank', '');
              }}
              hasErrors={Boolean(formik.errors.typeOfDischarge)}
              errorMessage={String(formik.errors.typeOfDischarge)}
            />
          </Row>

          {formik.values.typeOfDischarge === 'bank' && (
            <Row span="3">
              <SearchSelect
                multiple
                required
                options={banksForSelect ?? []}
                setValue={(_, value) => formik.setFieldValue('bank', value)}
                value={formik.values.bank}
                id="bank"
                label={intl.formatMessage({
                  id: 'financial.cashReport.bank.label',
                })}
                hasErrors={Boolean(formik.errors.bank)}
                errorMessage={String(formik.errors.bank)}
              />
            </Row>
          )}

          {formik.values.typeOfDischarge === 'application' && (
            <Row span="3">
              <SelectCollector
                required
                fieldValue={formik.values.collectors}
                setFieldValue={(value) => {
                  formik.setFieldValue('collectors', value);
                }}
                fieldTouched={formik.touched.collectors}
                fieldError={formik.errors.collectors}
                multiple
              />
            </Row>
          )}

          <Row span="3">
            <Input
              hasBorder
              required
              id="startDate"
              name="startDate"
              onChange={formik.handleChange}
              value={formik.values?.startDate}
              type="date"
              variant="lighter"
              label={intl.formatMessage({
                id: 'financial.cashReport.cashStartDate',
              })}
              hasErrors={
                !formik.isValid && formik.errors.startDate !== undefined
              }
              helperText={String(formik.errors.startDate ?? '')}
            />
          </Row>
          <Row span="3">
            <Input
              hasBorder
              id="endDate"
              name="endDate"
              onChange={formik.handleChange}
              value={formik.values?.endDate}
              required
              type="date"
              variant="lighter"
              label={intl.formatMessage({
                id: 'financial.cashReport.cashEndtDate',
              })}
              helperText={String(formik.errors.endDate ?? '')}
              hasErrors={!formik.isValid && formik.errors.endDate !== undefined}
            />
          </Row>

          {formik.values.typeOfDischarge === 'user' && (
            <>
              <Row span="3">
                <SelectBranches
                  fieldValue={formik.values.branch}
                  setFieldValue={(value) =>
                    formik.setFieldValue('branch', value)
                  }
                  cancelSearch={() => formik.setFieldValue('user', '')}
                />
              </Row>
              <Row span="4">
                <SelectUserSystem
                  required
                  fieldValue={formik.values.user}
                  setFieldValue={(value) => formik.setFieldValue('user', value)}
                  fieldError={String(formik.errors.user)}
                  fieldTouched={formik.touched.user}
                  branchIds={formik.values.branch ? [formik.values.branch] : []}
                  hasError={!!formik.errors.user}
                  cancelSearch={() => formik.setFieldValue('user', '')}
                />
              </Row>
            </>
          )}
        </Grid>
        <Divider />
        <S.Footer>
          <ButtonLink
            onClick={() => formik.handleSubmit()}
            disabled={!formik.values.typeOfDischarge}
            type="button"
            variant="lighter"
          >
            {intl.formatMessage({
              id: 'financial.cashReport.buttonDownloadReport',
            })}
            <RoundedIcon>
              <ArrowRight />
            </RoundedIcon>
          </ButtonLink>
        </S.Footer>
      </S.Form>
      <AlertDialog delay={2000} />
    </HomeContent>
  );
};

export default FinancialCashReport;
