import { PropsWithChildren, useState } from 'react';

import { ReactComponent as CaretDown } from 'assets/images/CaretDown.svg';
import { ReactComponent as CaretUp } from 'assets/images/CaretUp.svg';

import * as S from './Accordion.styles';

type AccordionProps = {
  title: string;
  initialState?: boolean;
  isLoading?: boolean;
  onClickFunction?: () => void;
  onClickWhenAccordionOpen?: () => void;
  variant?: 'default' | 'slim';
};

const Accordion = ({
  title,
  initialState,
  children,
  isLoading,
  onClickFunction,
  onClickWhenAccordionOpen,
  variant = 'default',
}: PropsWithChildren<AccordionProps>) => {
  const [showContent, setShowContent] = useState(initialState ?? false);

  const toggleView = () => {
    if (isLoading) return;
    setShowContent((previousState) => !previousState);
  };

  const onClickFunctionCondition =
    onClickFunction !== undefined && !showContent;
  const onClickWhenAccordionOpenCondition =
    onClickWhenAccordionOpen !== undefined && showContent;

  return (
    <S.Container variant={variant}>
      <S.Header
        onClick={() => {
          toggleView();
          onClickFunctionCondition && onClickFunction();
          onClickWhenAccordionOpenCondition && onClickWhenAccordionOpen();
        }}
      >
        <S.Title>{title}</S.Title>
        {isLoading ? (
          <S.Loading />
        ) : (
          <S.Button
            onClick={() => {
              toggleView();
            }}
          >
            {showContent ? (
              <CaretUp onClick={toggleView} />
            ) : (
              <CaretDown onClick={toggleView} />
            )}
          </S.Button>
        )}
      </S.Header>
      {showContent && <S.Content>{children}</S.Content>}
    </S.Container>
  );
};

export default Accordion;
