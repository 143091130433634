import { useAlert } from 'contexts';
import { useIntl } from 'react-intl';

import api from 'services/api';

import { getURLWithParams } from 'lib/contextual';

import { AUTH } from 'constants/endpoints';

export type Logout = {
  email: string;
};

export const useLogout = () => {
  const { showAlert, setClear } = useAlert();
  const intl = useIntl();

  const handlelogout = async (body: Logout) => {
    try {
      const url = getURLWithParams(AUTH.LOGOUT, { email: body.email });

      await api.deleteRequest(url);
    } catch (err) {
      setClear();
      showAlert({
        type: 'error',
        title: intl.formatMessage({
          id: 'logout.error',
        }),
      });
    }
  };
  return {
    handlelogout,
  };
};
