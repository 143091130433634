import { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import api from 'services/api';

import { useLoading } from 'hooks/useLoading';
import { useWebsocket } from 'hooks/useWebsocket';

import {
  CachableRequestConfig,
  retrieveFromCache,
  storeInCache,
} from 'lib/core/CacheControl';
import { generateRandomHash } from 'lib/core/GenerateRandomHash';
import { isStatusCodeError } from 'lib/core/statusCodeErrorVerify';

import { useSession } from 'contexts/AuthContext';

import { AUTH } from 'constants/endpoints';

import { getItem } from './useLocalStorage';

type MutateListener<T = any> = {
  onSuccess: (data: T) => void;
  onError: (data: T) => void;
};

const fetchGateway = async (
  transactionId: string,
  url: string,
  requestOptions?: AxiosRequestConfig
) =>
  await api.getRequest(url, {
    ...requestOptions,
    headers: {
      transactionId,
      ...requestOptions?.headers,
    },
  });

const initialReturn = {
  sendFor: () => {},
  payload: {},
  state: '',
  isIdle: false,
  isLoading: false,
  isSuccess: false,
};

const DEFAULT_TIMEOUT_TIME = 120;

export const useLazyGateway = <T = any>(
  transactionId: string,
  { onSuccess, onError }: MutateListener<T>,
  timeoutTime: number = DEFAULT_TIMEOUT_TIME
) => {
  const { user } = useSession();
  const [payload, setPayload] = useState<T>();
  const { dispatch: dispatchLoading, ...otherLoadingProps } = useLoading();
  const { current, subscribe, retries } = useWebsocket();
  const {
    current: currentPoc,
    subscribe: subscribePoc,
    retries: retriePoc,
  } = useWebsocket();
  const isValid = useRef(false);
  const [socketId, setSocketId] = useState(current.id);
  const [event, setEvent] = useState(transactionId);
  const isFirstCall = useRef(true);
  const responseArrived = useRef(false);
  let timeoutcounter: NodeJS.Timeout;

  const onReceiveData = useCallback(
    (data: any) => {
      responseArrived.current = true;
      const body = JSON.parse(data.Body);
      const message = JSON.parse(body.Message);

      if (message.payload) {
        if (
          message.payload.errors ||
          message.payload.error ||
          isStatusCodeError(message.payload?.statusCode)
        ) {
          dispatchLoading({ type: 'SET_STATUS', payload: 'error' });
          onError(message.payload);
          return;
        }
        setPayload(message.payload);
        onSuccess(message.payload);
      }
      dispatchLoading({ type: 'SET_STATUS', payload: 'success' });
    },
    [dispatchLoading, onError, onSuccess]
  );

  const handleTimeoutError = () => {
    dispatchLoading({ type: 'SET_STATUS', payload: 'error' });
    onError({ error: 'Request Timeout' } as any as T);
  };

  const sendFor = async (
    url: string,
    requestOptions?: CachableRequestConfig
  ) => {
    try {
      const retrievedFromCache = await retrieveFromCache(
        url,
        requestOptions,
        onReceiveData
      );
      if (retrievedFromCache) {
        return {
          status: 200,
        };
      }

      if (!isValid.current) {
        return initialReturn;
      }
      dispatchLoading({ type: 'SET_STATUS', payload: 'pending' });

      const transactionId = `${event}|${generateRandomHash()}`;
      subscribe(transactionId, (data) => {
        storeInCache(data, url, requestOptions);
        onReceiveData(url);
      });

      subscribePoc(transactionId, (data) => {
        storeInCache(data, url, requestOptions);
        onReceiveData(data);
      });

      responseArrived.current = false;
      const result = await fetchGateway(transactionId, url, requestOptions);
      if (isStatusCodeError(result.status)) {
        throw result;
      }
      timeoutcounter = setTimeout(() => {
        if (!responseArrived.current) {
          handleTimeoutError();
        }
      }, timeoutTime * 1000);
      return result;
    } catch (err) {
      dispatchLoading({ type: 'SET_STATUS', payload: 'error' });
      onError(err as T);
      return err;
    }
  };

  useEffect(() => {
    if (current.disconnected && user) {
      setTimeout(() => {
        current.connect();
      }, 1000);
    }
  }, [user]);

  useEffect(() => {
    if (responseArrived.current) {
      clearTimeout(timeoutcounter);
    }
  }, [responseArrived.current]);

  const dispatchConnectCallback = useCallback(async () => {
    await api.postRequest(AUTH.REFRESH, { email: getItem('email') });
  }, []);

  useEffect(() => {
    if (transactionId && !!user.cognitoId && socketId) {
      isValid.current = true;
      dispatchLoading({ type: 'SET_STATUS', payload: 'idle' });
      setEvent(`${transactionId}|${user.cognitoId}|${socketId}`);
      return;
    }

    if (retries >= 3 && retriePoc >= 3 && isFirstCall.current) {
      isFirstCall.current = false;
      dispatchConnectCallback();
    }

    return () => {
      isValid.current = false;
    };
  }, [
    dispatchConnectCallback,
    dispatchLoading,
    retries,
    retriePoc,
    socketId,
    transactionId,
    user.cognitoId,
  ]);

  current.on('connect', () => {
    setSocketId(current.id);
  });

  currentPoc.on('connect', () => {
    setSocketId(current.id);
  });

  return {
    sendFor,
    state: otherLoadingProps.state,
    isIdle: otherLoadingProps.isIdle,
    isSuccess: otherLoadingProps.isSuccess,
    isGatewayLoading: otherLoadingProps.isLoading,
    payload,
    isValid: isValid.current,
  };
};
