import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { city } from 'services/city';

export const useFindCitiesByUF = (filterParam: string) => {
  const { getByUF } = city();

  const { data, ...rest } = useInfiniteQuery(
    ['citiesByUF', filterParam],
    (page) => getByUF({ filterParam, ...page }),
    {
      keepPreviousData: true,
      enabled: Boolean(filterParam),
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage >= lastPage.meta.totalPages
          ? undefined
          : lastPage.meta.currentPage + 1,
    }
  );

  const allValues = useMemo(
    () => data?.pages.flatMap(({ data }) => data),
    [data]
  );

  return {
    citiesByUF: allValues?.length ? allValues : [],
    ...rest,
  };
};

export const useFindCitiesByName = ({
  filterParam,
  enableOnMount = true,
}: {
  filterParam: string;
  enableOnMount?: boolean;
}) => {
  const { getByName } = city();

  const { data, ...rest } = useInfiniteQuery(
    ['citiesByName', filterParam],
    (page) => getByName({ filterParam, ...page }),
    {
      keepPreviousData: true,
      enabled: filterParam.length > 2 && enableOnMount,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage >= lastPage.meta.totalPages
          ? undefined
          : lastPage.meta.currentPage + 1,
    }
  );

  const allValues = useMemo(
    () => data?.pages.flatMap(({ data }) => data),
    [data]
  );

  return {
    citiesByName: allValues?.length ? allValues : [],
    ...rest,
  };
};
