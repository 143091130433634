import { Branch } from 'types';

import { BRANCHES } from 'constants/endpoints';

import api from './api';

export const getBranch = async (branchId: string) => {
  const response = await api.getRequest<Branch>(BRANCHES.BY_ID_SYNC(branchId), {
    headers: { TransactionId: branchId },
  });

  return response.data;
};
