export const normalizeFullName = (string: string) => {
  const prepositions = ['de', 'da', 'das', 'do', 'dos', 'e'];
  const words = string.toLowerCase().split(' ');

  return words
    .map((word, index) => {
      if (index === 0 || !prepositions.includes(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word;
      }
    })
    .join(' ');
};
