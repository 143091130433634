import queryString from 'querystring';

const removeUndefinedFromObject = <T>(obj: {
  [key: string]: unknown;
}): Partial<T> =>
  Object.keys(obj).reduce(
    (acc, key) =>
      obj[key] === undefined ? { ...acc } : { ...acc, [key]: obj[key] },
    {}
  );

export const getQueryString = (obj: { [key: string]: unknown }): string =>
  queryString.stringify(removeUndefinedFromObject(obj));
