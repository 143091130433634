import { useQuery } from 'react-query';

import { associateDetails } from 'services/associates';

export const useAssociateDetails = (
  subscriptionCode: string,
  getCardData: boolean = false
) => {
  const { data, refetch, ...rest } = useQuery(
    ['associate-details', subscriptionCode],
    () => associateDetails(subscriptionCode, getCardData),
    {
      enabled: subscriptionCode !== '',
    }
  );
  return {
    associateDetails: subscriptionCode ? data : undefined,
    publish: refetch,
    ...rest,
  };
};
