import React from 'react';
import styled, { css } from 'styled-components';

import Tooltip from 'components/shared/newCore/Tooltip';

export const Label = styled.header`
  ${({ theme }) => css`
    margin-bottom: 8px;
    text-transform: uppercase;
    font-size: ${theme.new.typography.size.xs};
    font-weight: ${theme.typography.fontWeight.semiBold};
    color: ${theme.new.colors.primary.main};

    span {
      color: ${theme.new.colors.status.error};
    }
  `}
`;

type Props = {
  children: React.ReactNode;
  required?: boolean;
  errorMessage?: string;
};

export const SectionHeader = ({ children, required, errorMessage }: Props) => (
  <>
    <Label>
      {required && <span>*</span>} {children}
    </Label>
    {errorMessage && <Tooltip type="error" message={errorMessage} />}
  </>
);
