export enum statusOptionsEnum {
  ATIVO = 'active',
  INATIVO = 'inactive',
}

export enum updateOptionsEnum {
  OPTIONAL = 1,
  SEMI_OPTIONAL = 2,
  MANDATORY = 3,
}
