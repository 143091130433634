export const effects = {
  opacity: {
    xs: '0.3',
    sm: '0.6',
    full: '1',
  },
  colorOpacity: {
    xs: '4D',
    sm: '99',
    full: 'FF',
  },
} as const;
