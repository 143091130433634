import {
  addDays,
  addMonths,
  differenceInYears,
  eachDayOfInterval,
  endOfMonth,
  format,
  formatISO,
  isAfter,
  isWeekend,
  parseISO,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export const parseISOString = (date: string): Date => parseISO(date);

type ISOStyle = {
  format?: 'extended' | 'basic';
  representation?: 'complete' | 'date' | 'time';
};

export const formatIntlDateTime = (
  date: Date,
  options?: Intl.DateTimeFormatOptions
) =>
  Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    ...options,
  }).format(date);

export const formatIntlDate = (
  date: Date,
  options?: Intl.DateTimeFormatOptions
) =>
  Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    ...options,
  }).format(date);

export const formatISOstring = (date: Date, options?: ISOStyle): string =>
  formatISO(date, options);

export const formatFromMask = (target: Date, mask: string) =>
  format(target, mask);

export const getNextMonthDate = () => {
  const currentDate = new Date();
  const nextMonth = addMonths(currentDate, 1);

  if (isAfter(currentDate, endOfMonth(nextMonth)))
    return format(endOfMonth(nextMonth), 'yyyy-MM-dd');

  return format(nextMonth, 'yyyy-MM-dd');
};

export const formatBrazilianDate = (target: string) =>
  target &&
  format(new Date(target.split('.')[0]), 'dd/MM/yyyy', { locale: ptBR });

export const formatBrazilianDateTime = (target: Date) =>
  format(target, "dd/MM/yyyy 'às ' HH:mm");

export const formatBrazilianDateObservation = (target: Date) =>
  format(target, "dd/MM/yyyy 'às ' HH:mm", { locale: ptBR ?? '' });

// should be use to fix problems related to timezone in short date format
export const formatDateWithTimezone = (value: string) =>
  new Date(`${value}T00:00`).toISOString();

export const parseDateWithTimezone = (value: string): Date => {
  const date = formatDateWithTimezone(value);
  return parseISOString(date);
};

export const formatSimpleDate = (date: string | Date) => {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleDateString('pt-BR', {
    timeZone: 'UTC',
  });
};

export const formatDate = (
  date: string | number | Date,
  formatString = 'dd/MM/yyy'
) => {
  try {
    if (date) {
      return format(new Date(date), formatString, { locale: ptBR });
    }
    return '';
  } catch (error) {
    return '';
  }
};

export const formatHour = (date: Date) => {
  const dateString = new Date(`${date}`);
  const timeString = dateString.toLocaleTimeString('en-US', {
    hour12: false,
    timeZone: 'UTC',
    hour: 'numeric',
    minute: 'numeric',
  });
  return timeString;
};

export const formatHourWithSecond = (date: Date) => {
  const dateString = new Date(`${date}`);
  const timeString = dateString.toLocaleTimeString('en-US', {
    hour12: false,
    timeZone: 'UTC',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
  return timeString;
};

export const convertToAge = (birthDay: string) => {
  const age = differenceInYears(new Date(), new Date(birthDay));

  if (age === 0) {
    return 'N/D';
  }

  return age;
};

export const fomartDateIsoStringToUTC = (isoString: string | Date) => {
  const date = new Date(isoString);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const calculateBusinessDate = (days: number, startDate?: Date) => {
  const firstDate = startDate ? startDate : new Date();
  const endDate = addDays(firstDate, days + Math.floor(days / 5) * 2);
  const interval = eachDayOfInterval({ start: firstDate, end: endDate });

  const businessDays = interval.filter((date) => !isWeekend(date));

  return format(businessDays[days - 1], 'yyyy-MM-dd');
};

export const calculateDate = (days: number, startDate?: Date) => {
  const firstDate = startDate ? startDate : new Date();
  const endDate = addDays(firstDate, days);

  return format(endDate, 'yyyy-MM-dd');
};

export const getISODateTimeLocal = () => {
  const date = new Date();

  return (
    date.getFullYear() +
    '-' +
    String(date.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(date.getDate()).padStart(2, '0') +
    'T' +
    String(date.getHours()).padStart(2, '0') +
    ':' +
    String(date.getMinutes()).padStart(2, '0')
  );
};

export const formatDateAndHour = (date: Date) => {
  const dateString = new Date(`${date}`);

  const timeString = dateString.toLocaleTimeString('en-US', {
    hour12: false,
    timeZone: 'UTC',
    hour: 'numeric',
    minute: 'numeric',
  });

  const formattedDate = dateString.toLocaleDateString('pt-BR');
  return `${formattedDate} às ${timeString}`;
};
