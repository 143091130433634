import styled, { css } from 'styled-components';

type Props = {
  resizeHeight?: number;
  screenM?: number;
  acceptMobile?: boolean;
  resizeHeightVh?: number;
  minHeight?: number;
};

export const Container = styled.div<Props>`
  ${({ theme, resizeHeight, resizeHeightVh, minHeight }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: ${theme.new.spacing.xl2};
    min-height: ${minHeight ? `${minHeight}px` : '0px'};
    max-height: ${resizeHeight
      ? `${resizeHeight}px`
      : resizeHeightVh
      ? `${resizeHeightVh}vh`
      : '500px'};
    overflow-x: hidden;
    overflow-y: auto;
    word-wrap: normal;
    padding-right: ${theme.new.spacing.xs};
    &::-webkit-scrollbar {
      width: 10px;
      height: 200px;
      margin-left: ${theme.new.spacing.sm};
    }

    &::-webkit-scrollbar-track {
      background: ${theme.new.colors.neutrals[400]}${theme.new.effects.colorOpacity.xs};
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.new.colors.neutrals[400]};
      border-radius: ${theme.new.shapes.borderRadius.full};
      border: 4px solid rgba(0, 0, 0, 0);
    }
  `};

  ${(props) =>
    props.acceptMobile &&
    css`
      @media screen and (max-width: 360px) {
        max-height: ${props.screenM}vh;
      }
    `}
`;
