import io, { Socket } from 'socket.io-client';

export type SocketConfiguration = {
  socketUrl: string;
  host: string;
  authorization?: string;
  userId?: string;
};
class WebsocketNotification {
  private static instance?: Socket;
  private socketConfiguration: SocketConfiguration;
  constructor(config: SocketConfiguration) {
    this.socketConfiguration = config;
  }

  getInstance() {
    if (WebsocketNotification.instance) {
      return WebsocketNotification.instance;
    }
    const socket = io(this.socketConfiguration.socketUrl, {
      transports: ['websocket'],
      withCredentials: true,
      extraHeaders: {
        'Content-Host': this.socketConfiguration.host,
      },
      query: {
        userId: this.socketConfiguration.userId,
        authorization: this.socketConfiguration.authorization,
      },
    });

    WebsocketNotification.instance = socket;

    return WebsocketNotification.instance;
  }
}

export default WebsocketNotification;
