import { useQuery } from 'react-query';

import { orthopedicLoan } from 'services/orthopedic';

export const useFindOneLoan = (id: string, enableOnMount: boolean) =>
  useQuery(
    ['findOneOrthopedicLoan', id],
    () => orthopedicLoan().getOrthopedicEquipmentLoan(id),
    {
      enabled: enableOnMount,
    }
  );
