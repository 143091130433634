import axios from 'axios';
import { useAlert } from 'contexts';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import downloadBlobPDF from 'services/downloadBlobPdf';
import { orthopedicLoan } from 'services/orthopedic';

import { Urls } from 'constants/urls';

export const useRegisterEquipmentLoan = () => {
  const {
    createOrthopedicEquipmentLoan,
    createOrthopedicEquipmentLoanContract,
  } = orthopedicLoan();
  const { showAlert } = useAlert();
  const history = useNavigate();
  const intl = useIntl();

  const handleErrorValidation = (status: number) => {
    switch (status) {
      case 422:
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'request.error.branch_and_equipment_does_not_match',
          }),
        });
        break;
      case 400:
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'request.error.equipments_equals',
          }),
        });
        break;
      default:
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'failureToSend',
          }),
        });
        break;
    }
  };

  return useMutation(createOrthopedicEquipmentLoan, {
    onSuccess: async (data) => {
      const file = await createOrthopedicEquipmentLoanContract(
        data.id.toString()
      );

      downloadBlobPDF(file, `loan_contract_${data.id}`);

      showAlert({
        type: 'success',
        title: intl.formatMessage({
          id: 'loan.modal.success.title',
        }),
      });

      setTimeout(() => {
        history(Urls.orthopedicMovements);
      }, 3000);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        const response = error.response;
        handleErrorValidation(response?.status as number);
        return;
      }
    },
  });
};
