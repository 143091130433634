import { useAlert } from 'contexts';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { SENDING_METHOD_ID, SendingMethods } from 'types';

import { sendSlipByEmail } from 'services/payments';
import { postSellingReport } from 'services/postSellingReport';

import { SCHEDULE_PAYMENT_METHOD_OPTIONS } from 'constants/enums';

const SENDING_METHOD_BY_TYPE = {
  SMS: SENDING_METHOD_ID.SMS,
  email: SENDING_METHOD_ID.email,
  whatsapp: SENDING_METHOD_ID.whatsapp,
};

export type DataValues = {
  type: SendingMethods;
  value: string;
};

type PostChangePaymentMethodParams = {
  subscription: string;
  dataValues: DataValues;
  paymentMethod?: string;
  feesReferences?: string[];
};

export const usePostChangePaymentMethod = () => {
  const { cardRegister } = postSellingReport();
  const intl = useIntl();

  const { showAlert } = useAlert();

  const {
    isLoading: postLinkLoading,
    mutate: postLinkMutate,
    status: fileStatus,
  } = useMutation(async (payload: PostChangePaymentMethodParams) => {
    const { subscription, dataValues, feesReferences } = payload;
    const { type, value } = dataValues;
    const body = {
      sendingMethod: SENDING_METHOD_BY_TYPE[type],
      sendingMail: type === 'email' ? value : undefined,
      sendingPhone: type === 'SMS' ? value : undefined,
      billingStatus: 1,
      operation: 'recorrente',
    };

    try {
      if (
        payload.paymentMethod === SCHEDULE_PAYMENT_METHOD_OPTIONS.TICKET &&
        type === 'email'
      ) {
        await sendSlipByEmail({
          subscription,
          dataSendSlip: {
            email: value,
            feesReferences: feesReferences || [],
          },
        });

        showAlert({
          type: 'success',
          title: '',
        });

        return;
      }

      const { data } = await cardRegister(subscription, body);

      showAlert({
        type: 'success',
        title: data.message,
      });
    } catch (err) {
      showAlert({
        type: 'error',
        title: intl.formatMessage({
          id: 'failureToSend',
        }),
      });
    }
  });

  return {
    postLinkMutate,
    isLoading: postLinkLoading,
    isFinished: fileStatus === 'success',
  };
};
