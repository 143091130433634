import styled, { css } from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  max-height: 26rem;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`;

export const TableStyled = styled.table`
  border-spacing: 0px;
`;

export const TableHeader = styled.thead`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.new.colors.neutrals[100]};
`;

export const ThStyled = styled.th`
  display: flex;
  justify-content: center;
  padding: 4px 0px;
  white-space: nowrap;
  min-width: 155px;
  max-width: 155px;
`;

export const TbodyStyled = styled.tbody`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-right: ${({ theme }) => theme.spacing.xs};
  ::-webkit-scrollbar {
    width: 10px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`;

export const TdStyled = styled.td`
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.small1};
  padding: 18px 0px;
  min-width: 155px;
  max-width: 155px;
  white-space: normal;
  text-align: center;
`;

type RowCampaignProps = {
  concluded?: boolean;
};

export const ListItem = styled.tr<RowCampaignProps>`
  display: flex;
  align-items: center;
  max-width: 100%;
  font-weight: 400;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.contextual.table.column};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  padding-left: ${({ theme }) => theme.spacing.sm1};
  ${(props) =>
    props.concluded &&
    css`
      background-color: hsla(119, 37%, 75%, 1);
    `};
`;

export const ListHeader = styled.tr`
  display: flex;
  max-width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  padding-left: ${({ theme }) => theme.spacing.sm1};
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 700;
  text-transform: uppercase;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
