import { useIntl } from 'react-intl';

import { DataMailingItem } from 'pages/postSellingReport';

import { Grid, Row } from 'components/shared';
import CheckboxInput from 'components/shared/forms/CheckboxInput';

import * as G from './../../../styles/themes/global/mainContainer';
import * as S from './postSellingListStyle';

interface IpostSellingReportList {
  selecteds: DataMailingItem[];
  handleCheckbox: (value: any) => void;
}

const PostSellingList = ({
  selecteds,
  handleCheckbox,
}: IpostSellingReportList) => {
  const intl = useIntl();
  const totalRegistrations = selecteds.length;
  return (
    <S.Container>
      <S.List>
        <G.Nav>
          <header>
            <S.Title>
              {intl.formatMessage({ id: 'postSelling.title.table' })}{' '}
              {totalRegistrations
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            </S.Title>
          </header>
        </G.Nav>
        <S.ListBody>
          {selecteds.map((selected: DataMailingItem) => (
            <Grid key={selected.subscription}>
              <Row span="12">
                <S.ListItem>
                  <S.ListItemColumn>
                    <CheckboxInput
                      value={selected}
                      id={selected.subscription.toString()}
                      checked={selected.checked}
                      onChange={(value) => handleCheckbox(value)}
                    />
                  </S.ListItemColumn>
                  <S.ListItemColumn>{selected.subscription}</S.ListItemColumn>
                  <S.ListItemColumn>{selected.name}</S.ListItemColumn>
                </S.ListItem>
              </Row>
            </Grid>
          ))}
        </S.ListBody>
      </S.List>
    </S.Container>
  );
};

export default PostSellingList;
