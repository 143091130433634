import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { paymentSlip } from 'services/paymentSlip';

export const useBatches = ({
  code,
  date,
  status,
  batchType,
  isMasterUser,
}: {
  code: string;
  date: string;
  status: string;
  batchType: string;
  isMasterUser: boolean;
}) => {
  const { batches } = paymentSlip();
  const {
    data: allBatches,
    error,
    ...rest
  } = useInfiniteQuery(
    ['batches'],
    (page) => batches({ code, date, status, batchType, ...page }),
    {
      getNextPageParam: (data) => {
        if (data.items.length === 0) return undefined;
        return data.meta.currentPage + 1;
      },
      enabled: isMasterUser || Boolean(batchType),
    }
  );

  const allValues = useMemo(
    () => allBatches?.pages.flatMap(({ items }) => items),
    [allBatches]
  );
  return {
    allBatches: allValues?.length ? allValues : [],
    rest,
  };
};
