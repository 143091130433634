import { motion } from 'framer-motion';
import styled, { css, keyframes } from 'styled-components';

import HomeContent from 'components/contextual/homeContent';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.span`
  ${({ theme }) => css`
    width: 25px;
    height: 25px;
    border-radius: ${theme.new.shapes.borderRadius.full};
    border: ${theme.new.shapes.borderWidth.xl} solid
      ${theme.new.colors.secondary.dark};
    border-bottom: ${theme.new.shapes.borderWidth.xl} solid transparent;
    -webkit-animation: ${spin} 1s linear infinite;
    animation: ${spin} 1s linear infinite;
  `}
`;

export const Container = styled(HomeContent)<{ $fill: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ $fill }) => ($fill ? '100%' : 'calc(100vh - 118px)')};
`;

export const Bar = styled.div`
  width: 300px;
  background-color: ${({ theme }) => theme.surface.backgroundDark};
  height: 14px;
  padding: 3px 8px;
  border-radius: 100px;
  overflow: hidden;
`;

export const Progress = styled(motion.div)`
  width: 0;
  background-color: ${({ theme }) => theme.secondary.dark};
  border-radius: 100px;
  height: 100%;
`;
