import * as AlertDialog from '@radix-ui/react-alert-dialog';
import styled, { css, Keyframes, keyframes } from 'styled-components';

import CustomSelect from 'components/shared/forms/CustomSelect';

const modalToValue = '-50%, -50%, 0';
const modalFromValue = '-50%, 0, 0';
const contentFromValue = '0, 0, 0';
const contentToValue = '0, 0, 0';

const animationFrame = (isModal: boolean, close: boolean) =>
  close
    ? keyframes`
  0% {
    opacity: 1;
    -webkit-transform: translate3d(${isModal ? modalToValue : contentToValue});
    transform: translate3d(${isModal ? modalToValue : contentToValue});
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(${
      isModal ? modalFromValue : contentFromValue
    });
    transform: translate3d(${isModal ? modalFromValue : contentFromValue});
  }
`
    : keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(${
      isModal ? modalFromValue : contentFromValue
    });
    transform: translate3d(${isModal ? modalFromValue : contentFromValue});
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(${isModal ? modalToValue : contentToValue});
    transform: translate3d(${isModal ? modalToValue : contentToValue});
  }
`;

const openAnimation = (animation: Keyframes) => css`
  animation: ${animation} 0.3s ease-out 0s forwards;
  -webkit-animation: ${animation} 0.3s ease-out 0s forwards;
`;

const closeAnimation = (animation: Keyframes) => css`
  animation: ${animation} 0.3s ease-out 0.05s forwards;
  -webkit-animation: ${animation} 0.3s ease-out 0.05s forwards;
`;

export const Overlay = styled(AlertDialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Container = styled(AlertDialog.Content)<{ $isClosing: boolean }>`
  ${({ theme, $isClosing }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 970px;
    width: 100%;
    background-color: ${theme.surface.main};
    border-radius: ${theme.borderRadius.md};
    box-shadow: ${theme.elevation.secondary};
    display: flex;
    flex-direction: column;
    padding: 44px;
    overflow-y: auto;
    max-height: 80%;
    ::-webkit-scrollbar {
      width: 5px;
      height: 15px;
    }

    ::-webkit-scrollbar-track {
      background: rgba(145, 145, 183, 0.3);
      border-radius: 100px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(145, 145, 183, 0.5);
      border-radius: 100px;
      border: 4px solid rgba(0, 0, 0, 0);
    }
    ${$isClosing
      ? closeAnimation(animationFrame(true, $isClosing))
      : openAnimation(animationFrame(true, $isClosing))};
  `}
`;
export const Label = styled.span`
  display: block;
  text-transform: uppercase;
  ${({ theme }) => css`
    margin-top: ${theme.new.spacing.sm};
    color: ${theme.new.colors.primary.main};
    font-weight: 600;
    font-size: ${theme.new.typography.size.sm};
  `}
`;
export const ApproveOrDisapproveCustomSelect = styled(CustomSelect)`
  height: 20px;
`;
export const PlainText = styled.span`
  display: block;
  text-transform: uppercase;
  ${({ theme }) => css`
    margin-top: ${theme.new.spacing.sm};
    font-size: ${theme.new.typography.size.sm};
  `}
`;
export const LabelPlainTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header<{ $isClosing: boolean }>`
  ${({ theme, $isClosing }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    margin-bottom: 1rem;
    ${$isClosing
      ? openAnimation(animationFrame(false, $isClosing))
      : closeAnimation(animationFrame(false, $isClosing))};

    h1 {
      font-weight: 600;
      font-size: ${theme.fontSizes.large};
      line-height: 100%;
      color: ${theme.texts.lightSecondary};
    }
  `}
`;

export const Section = styled.div`
  ${({ theme }) => css`
    display: flex,
    flex-direction: column,
    align-items: center,
    margin: ${theme.new.spacing.xl2} 0 0;
    justify-content: flex-start
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    border-top: 1px solid ${theme.separatorColorLight};
    padding-top: ${theme.spacing.sm1};
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
  `}
`;
