import styled, { css } from 'styled-components';

interface InputWrapperProps {
  error?: boolean;
  disabled?: boolean;
}

export const Label = styled.label`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-size: ${theme.new.typography.size.xs};
    color: ${theme.new.colors.neutrals[100]};
    span {
      color: ${theme.new.colors.status.error};
    }

    &.lighter {
      font-size: ${theme.new.typography.size.xs};
      font-weight: 600;
      display: block;
      margin-top: 4px;
      text-transform: uppercase;
      color: ${theme.new.colors.primary.main};
      text-transform: uppercase;
    }
  `}
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  margin-top: ${({ theme }) => theme.spacing.xs};
  width: 100%;
  height: 48px;
  background-color: ${({ theme }) => theme.input.outlined.background};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.contextual.error}` : 'none'};
  position: relative;
  padding-right: 36px;

  &.lighter,
  &.lighter_in_darken {
    background-color: ${({ theme }) => theme.input.primary.background};
    color: ${({ theme }) => theme.input.primary.textColor};
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: transparent;
      border: 1px solid rgba(201, 204, 238, 0.3);
    `}

  &:focus-within, &:focus-visible {
    border: 1px solid ${({ theme }) => theme.surface.main};
  }
`;

export const CustomInput = styled.input`
  width: 100%;
  padding: ${({ theme }) => `14px ${theme.spacing.sm}`};
  font-size: ${({ theme }) => theme.fontSizes.small};
  border: none;
  outline: none;
  height: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.input.outlined.textColor};
  letter-spacing: -0.02em;
  line-height: 100%;

  &.lighter,
  &.lighter_in_darken {
    color: ${({ theme }) => theme.input.primary.textColor};

    &::placeholder {
      color: ${({ theme }) => theme.input.placeholder.textColor};
      opacity: 0.6;
    }
  }

  &.lighter:-webkit-autofill,
  &.lighter_in_darken:-webkit-autofill {
    -webkit-text-fill-color: text;
    -webkit-text-fill-color: ${({ theme }) => theme.input.primary.textColor};
  }

  &:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme }) => theme.input.outlined.textColor};
  }
`;

export const ErrorLabel = styled.small`
  color: ${(props) => props.theme.contextual.error};
  font-size: ${(props) => props.theme.fontSizes.small};
`;

export const EndAdornment = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
`;

export const BallonError = styled.div`
  ${({ theme }) => css`
    background: ${theme.new.colors.status.error};
    font-size: ${theme.new.typography.size.xs};
    line-height: ${theme.new.typography.lineHeight.xs};
    border-radius: ${theme.new.shapes.borderRadius.sm};
    width: 100%;
    color: ${theme.new.colors.neutrals[0]};
    padding: ${theme.new.spacing.xs} ${theme.new.spacing.sm};
    position: relative;
    margin-top: ${theme.new.spacing.sm};
    :after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      border-left: 15px solid transparent;
      border-right: ${theme.new.spacing.md} solid transparent;
      border-bottom: ${theme.new.spacing.md} solid
        ${theme.new.colors.status.error};
      top: -5px;
      left: 5px;
    }
  `}
`;
