import { useSession } from 'contexts';

import checkPermission from './checkPermission';

interface ComponentGateProps {
  children: React.ReactNode;
  permission?: string;
  permissions?: string[];
  noPermissionFallback?: React.ReactNode;
}

export const ComponentGate = ({
  children,
  permission = '',
  permissions = [],
  noPermissionFallback = null,
}: ComponentGateProps) => {
  const session = useSession();
  const allPermissions = [...permissions, permission].filter((p) => p);
  const hasPermission = allPermissions.some((p) =>
    checkPermission(p, session.user.permissions)
  );

  if (hasPermission) return <>{children}</>;

  return <>{noPermissionFallback}</>;
};

export default ComponentGate;
