import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useFetchUserSystem } from 'useCases/financial/useFetchUsersSystem';

import SearchSelect from 'components/shared/forms/SearchSelect';

interface SelectBranchesProps {
  fieldValue: string;
  setFieldValue: (value: string) => void;
  fieldTouched?: boolean;
  fieldError?: string;
  required?: boolean;
  branchIds?: Array<string | number>;
  cancelSearch?: () => void;
  hasError?: boolean;
}

const SelectUserSystem = ({
  fieldValue,
  setFieldValue,
  fieldTouched,
  fieldError,
  required = false,
  branchIds,
  cancelSearch,
  hasError,
}: SelectBranchesProps) => {
  const intl = useIntl();

  const [userSearch, setUserSearch] = useState('');

  const {
    users,
    isLoading,
    refetch,
    fetchNextPage,
    hasNextPage,
    error,
    isFetching,
    isFetchingNextPage,
  } = useFetchUserSystem(
    undefined,
    branchIds,
    userSearch,
    undefined,
    !userSearch
  );

  const debouncedSearchCollectors = useCallback(debounce(refetch, 800), []);

  const optionsFieldsCoordinators = useMemo(
    () =>
      users.map((item) => ({
        label: `${item.id} - ${item.name}`,
        value: item.id,
      })) ?? [],

    [required, users, userSearch, fieldValue]
  );

  const getNextPage = () => {
    if (!isLoading) {
      fetchNextPage();
    }
  };
  useEffect(() => {
    setUserSearch('');
  }, [branchIds]);

  return (
    <SearchSelect
      asyncFilteredOptions
      id="branches"
      required={required}
      options={optionsFieldsCoordinators}
      label={intl.formatMessage({ id: 'forms.users' })}
      placeholder={intl.formatMessage({ id: 'forms.users.placeholder' })}
      valueLabel={fieldValue}
      setValue={(_label, value) => {
        setFieldValue(String(value));
      }}
      hasErrors={fieldTouched && hasError && !!!fieldValue}
      errorMessage={fieldError}
      isLoading={isLoading || (isFetching && !isFetchingNextPage)}
      onSearchChange={(value) => {
        setUserSearch(String(value));
        debouncedSearchCollectors();
      }}
      cancelSearch={() => {
        setUserSearch('');
        setFieldValue('');
        cancelSearch && cancelSearch();
      }}
      handlePagination={getNextPage}
      hasPagination={hasNextPage}
      hasErrorPagination={!!error}
      multiple
      multipleProps={{
        flexColumn: true,
      }}
    />
  );
};

export default SelectUserSystem;
