import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { financial } from 'services/financial';

export const useFetchUserSystem = (
  userIds?: Array<number | string>,
  branchIds?: Array<string | number>,
  userName?: string,
  userNick?: string,
  enableOnMount = true,
  itemsPerPage = 50
) => {
  const { getUsersSystem } = financial();

  const { data, ...rest } = useInfiniteQuery(
    ['usersSystem', userNick, userName, userIds, branchIds],
    (context) =>
      getUsersSystem(
        context.pageParam,
        itemsPerPage,
        userIds,
        branchIds,
        userName,
        userNick
      ),
    {
      enabled: enableOnMount,
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,

      getNextPageParam: (data) =>
        data.meta.currentPage < data.meta.totalPages
          ? Number(data.meta.currentPage) + 1
          : undefined,
    }
  );

  const allValues = useMemo(
    () => data?.pages.flatMap(({ items }) => items),
    [data]
  );

  return {
    users: allValues?.length ? allValues : [],
    ...rest,
  };
};
