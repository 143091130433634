import styled from 'styled-components';

import { device } from 'styles/tokens/devices';

interface ResponsiveGridProps {
  gap?: string;
}

export const ResponsiveGrid = styled.div<ResponsiveGridProps>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: ${({ gap, theme }) => gap ?? theme.spacing.md};
  @media ${device.laptop} {
    grid-template-columns: repeat(12, 1fr);
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

type RowProps = {
  screenLg?:
    | '1'
    | '2'
    | '2.5'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12';
  screenMd?:
    | '1'
    | '2'
    | '2.5'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12';

  screenSm?: '1' | '2';
};

export const Row = styled.div<RowProps>`
  grid-column: span ${(props) => props.screenSm};

  @media ${device.laptop} {
    grid-column: span ${(props) => props.screenLg};
  }

  @media ${device.tablet} {
    grid-column: span ${(props) => props.screenMd};
  }
`;

/*
@media (max-width: 2000px) {
    grid-column: span ${({ screenLg }) => screenLg};
  }
  @media (max-width: 1000px) {
    grid-column: span ${({ screenSm }) => screenSm};
  }

*/
