import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const List = styled.div`
  width: 100%;
  margin-top: 28px;
`;

const columns = css``;

export const ListHeader = styled.header`
  ${({ theme }) => css`
    display: grid;
    padding: ${theme.spacing.sm1};
    padding-bottom: ${theme.spacing.sm};
  `};
  ${columns}

  @media screen and (max-width: 950px) {
    padding: 1rem 0;
  }
`;
export const ListItem = styled.article`
  width: calc(100% - 8px);
  display: grid;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.contextual.table.column};
  font-size: ${({ theme }) => theme.fontSizes.small1};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  padding-left: ${({ theme }) => theme.spacing.sm1};
  align-items: center;
  word-wrap: normal;
  grid-template-columns: 0.1fr 0.2fr 0.5fr;
  ${columns}

  @media screen and (max-width: 950px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-block: 2vh;
  }
`;

export const ListItemColumn = styled.section`
  background: ${({ theme }) => theme.surface.main};
  color: ${({ theme }) => theme.texts.thinParagraph};
  font-size: ${({ theme }) => theme.fontSizes.small1};
  padding: 25px 0;
  overflow: hidden;
  word-wrap: break-all;
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 268px;

  &:last-child {
    border-radius: ${({ theme }) => theme.borderRadius.md};
    width: 100%;
    display: flex;
    justify-content: center;
  }

  span {
    display: none;
  }

  @media screen and (max-width: 950px) {
    padding: 0.5rem 0.2rem;

    span {
      display: inline;
      font-weight: bold;
      margin-right: 0.5rem;
      color: ${({ theme }) => theme.texts.thinParagraph};
    }
  }
`;

export const ListBody = styled.div`
  max-height: 290px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 200px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`;
export const ColumnHeader = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.secondary.dark};
  text-transform: uppercase;
  font-weight: 600;

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    line-height: ${theme.fontSizes.medium2};
    color: ${theme.texts.lightSecondary};
  `}
  font-weight: normal;
`;

export const Fields = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.xs};

  @media screen and (max-width: 950px) {
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing.lg} 0;
  }
`;

export const FieldsGrow = styled(Fields)`
  & > div {
    flex-grow: 1;
  }
`;

export const FieldsSpaceBetween = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.xs};

  @media screen and (max-width: 950px) {
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing.lg} 0;
  }
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    span {
      font-size: ${theme.new.typography.size.sm};
      font-weight: ${theme.new.typography.weight.semibold};
      line-height: ${theme.new.typography.lineHeight.sm};
      color: ${theme.new.colors.primary.main};
    }
  `}
`;

export const fieldsAttachFile = styled.div`
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: baseline;
  gap: 50px;
`;

export const UploadArea = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: opacity 300ms;
  text-decoration: none;
  top: 0px;
`;

export const ButtonUpload = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const SectionContent = styled.article``;

export const Title = styled.h1`
  font-weight: 600;
  text-transform: uppercase;
  ${({ theme }) => css`
    color: ${theme.texts.lightSecondary};
    margin-top: ${theme.spacing.lg};
    margin-bottom: ${theme.spacing.md};
  `}
`;
