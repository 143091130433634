import { FormikValues } from 'formik';
import { useIntl } from 'react-intl';

import { DataApprove } from 'useCases/tuitionReadjustment/types';

import { formatterMonetaryValue } from 'lib/core';

import { ResponsiveGrid, Row } from 'components/shared/core/responsiveGrid';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';
import Skeleton from 'components/shared/newCore/Skeleton/Skeleton';

import * as S from './ModalTuitionReadjustment.styles';
import TuitionReadjustmentsSharedResources from './tuitionReadjustmentsSharedResources';

const backdropSettings = {
  fullHeight: false,
  fullWidth: false,
};
export const TuitionReadjustmentForm = ({
  readjustmentSolicitationData,
  formik,
  isLoading,
}: {
  readjustmentSolicitationData?: Partial<DataApprove> | null;
  formik: FormikValues;
  isLoading: Boolean;
}) => {
  const intl = useIntl();

  return (
    <ResponsiveGrid>
      <Row screenLg="1" screenSm="1" screenMd="1">
        <S.LabelPlainTextContainer>
          <S.Label>
            {intl.formatMessage({
              id: 'tuitonReadjustment.modal.field.label.subscription',
            })}
          </S.Label>
          {isLoading && <Skeleton width="10vw" height="100%" />}
          {!isLoading && (
            <S.PlainText>
              {readjustmentSolicitationData?.subscriptionCode}
            </S.PlainText>
          )}
        </S.LabelPlainTextContainer>
      </Row>
      <Row screenLg="1" screenSm="2" screenMd="1">
        <S.LabelPlainTextContainer>
          <S.Label>
            {intl.formatMessage({
              id: 'tuitonReadjustment.modal.field.label.associate',
            })}
          </S.Label>
          {isLoading && <Skeleton width="10vw" height="100%" />}
          {!isLoading && (
            <S.PlainText>
              {readjustmentSolicitationData?.associate?.name}
            </S.PlainText>
          )}
        </S.LabelPlainTextContainer>
      </Row>
      <Row screenLg="1" screenSm="2" screenMd="1">
        <S.LabelPlainTextContainer>
          <S.Label>
            {intl.formatMessage({
              id: 'tuitonReadjustment.modal.field.label.branch',
            })}
          </S.Label>
          {isLoading && <Skeleton width="10vw" height="100%" />}
          {!isLoading && (
            <S.PlainText>
              {readjustmentSolicitationData?.affiliate?.name}
            </S.PlainText>
          )}
        </S.LabelPlainTextContainer>
      </Row>
      <Row screenLg="3" screenSm="2" screenMd="3">
        <S.LabelPlainTextContainer>
          <S.Label>
            {intl.formatMessage({
              id: 'tuitonReadjustment.modal.field.label.user',
            })}
          </S.Label>
          {isLoading && <Skeleton width="10vw" height="100%" />}
          {!isLoading && (
            <S.PlainText>
              {readjustmentSolicitationData?.userCreated}
            </S.PlainText>
          )}
        </S.LabelPlainTextContainer>
      </Row>
      <Row screenLg="1" screenSm="2" screenMd="1">
        <S.LabelPlainTextContainer>
          <S.Label>
            {intl.formatMessage({
              id: 'tuitonReadjustment.modal.field.label.tuitionValue',
            })}
          </S.Label>
          {isLoading && <Skeleton width="5vw" height="100%" />}
          {!isLoading && (
            <S.PlainText>
              {formatterMonetaryValue(
                readjustmentSolicitationData?.valuePrevious ?? 0
              )}
            </S.PlainText>
          )}
        </S.LabelPlainTextContainer>
      </Row>
      <Row screenLg="1" screenSm="2" screenMd="1">
        <S.LabelPlainTextContainer>
          <S.Label>
            {intl.formatMessage({
              id: 'tuitonReadjustment.modal.field.label.tuitionReadjustment',
            })}
          </S.Label>
          {isLoading && <Skeleton width="5vw" height="100%" />}
          {!isLoading && (
            <S.PlainText>
              {formatterMonetaryValue(
                readjustmentSolicitationData?.updatedValue ?? 0
              )}
            </S.PlainText>
          )}
        </S.LabelPlainTextContainer>
      </Row>
      <Row screenLg="3" screenSm="2" screenMd="3">
        <S.LabelPlainTextContainer>
          <S.Label>
            {intl.formatMessage({
              id: 'tuitonReadjustment.modal.field.label.solicitationDate',
            })}
          </S.Label>
          {isLoading && <Skeleton width="5vw" height="100%" />}
          {!isLoading && (
            <S.PlainText>
              {new Date(
                readjustmentSolicitationData?.dataCreated ?? ''
              ).toLocaleDateString()}
            </S.PlainText>
          )}
        </S.LabelPlainTextContainer>
      </Row>

      <Row screenLg="2" screenSm="2" screenMd="2">
        {isLoading && <Skeleton width="15vw" height="10vh" />}
        {!isLoading && readjustmentSolicitationData?.status !== 1 ? (
          readjustmentSolicitationData?.status === 2 ? (
            <S.LabelPlainTextContainer>
              <S.Label>
                {intl.formatMessage({
                  id: 'tuitonReadjustment.modal.field.label.RequestStatus',
                })}
              </S.Label>
              <S.PlainText>
                {intl.formatMessage({
                  id: 'tuitonReadjustment.modal.field.result.approved',
                })}
              </S.PlainText>
            </S.LabelPlainTextContainer>
          ) : (
            <S.LabelPlainTextContainer>
              <S.Label>
                {intl.formatMessage({
                  id: 'tuitonReadjustment.modal.field.label.RequestStatus',
                })}
              </S.Label>
              <S.PlainText>
                {intl.formatMessage({
                  id: 'tuitonReadjustment.modal.field.result.disapproved',
                })}
              </S.PlainText>
            </S.LabelPlainTextContainer>
          )
        ) : (
          !isLoading && (
            <CustomSelect
              id="status"
              inputName="status"
              placeholder={intl.formatMessage({
                id: 'tuitonReadjustment.modal.field.label.approve.placeholder',
              })}
              label={intl.formatMessage({
                id: 'tuitonReadjustment.modal.field.label.approve',
              })}
              inputValue={formik.values.status}
              options={TuitionReadjustmentsSharedResources().optionsStatus}
              hasErrors={formik.touched.status && Boolean(formik.errors.status)}
              borderBackgroudWhite
              helperText={formik.errors.status}
              setValue={formik.setFieldValue}
              backdropSettings={backdropSettings}
            />
          )
        )}
      </Row>

      <Row screenLg="3" screenSm="2" screenMd="3">
        {isLoading && <Skeleton width="15vw" height="10vh" />}
        {!isLoading && readjustmentSolicitationData?.reason !== ' ' ? (
          <S.LabelPlainTextContainer>
            <S.Label>
              {intl.formatMessage({
                id: 'tuitonReadjustment.modal.field.label.reason',
              })}
            </S.Label>
            <S.PlainText>{readjustmentSolicitationData?.reason}</S.PlainText>
          </S.LabelPlainTextContainer>
        ) : (
          !isLoading && (
            <Input
              variant="lighter"
              hasBorder
              required
              name="reason"
              id="reason"
              type="text"
              value={formik.values.reason}
              onChange={formik.handleChange}
              label={intl.formatMessage({
                id: 'tuitonReadjustment.modal.field.label.reason',
              })}
              helperText={formik.errors.reason}
              hasErrors={formik.touched.reason && Boolean(formik.errors.reason)}
            />
          )
        )}
      </Row>
    </ResponsiveGrid>
  );
};
