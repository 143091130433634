import { useAlert } from 'contexts';
import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { MailingReportResponse } from 'types/MailingReportResponse';
import { ExcelRow, convertExceltoJson } from 'utils-general/convertExceltoJson';
import { convertJsonToCsv, isCsvFile } from 'utils-general/convertJsontoCsv';

import { postSellingAssignmentStatus } from 'services/postSellingReport';

import { useGetMailing } from 'useCases/postSellingReport';

import { formatFromMask, formatSimpleDate } from 'lib/core';

import HomeContent from 'components/contextual/homeContent';
import PostSellingList from 'components/contextual/postSellingList';
import AlertDialog from 'components/shared/core/alertDialog';
import ConfirmDialog, { AlertType } from 'components/shared/core/confirmDialog';
import Divider from 'components/shared/core/divider';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import CheckboxInput from 'components/shared/forms/CheckboxInput';
import Input from 'components/shared/forms/Input';
import Breadcrumb, {
  BreadcrumbLink,
} from 'components/shared/newCore/Breadcrumb';

import { Urls } from 'constants/urls';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';
import { ReactComponent as UploadIcon } from 'assets/images/download.svg';

import * as G from './../../styles/themes/global/mainContainer';
import { useInputsDateValidation } from './dateValidation';
import * as S from './styles';
import { PostSelingEnum } from './types';

export interface DataMailingItem {
  subscription: number;
  name: string;
  checked: boolean;
}

const PostSellingReport = () => {
  const intl = useIntl();
  const { showAlert, setClear } = useAlert();
  const [boxExtratReport, setBoxExtratReport] = useState(false);
  const [boxImportReport, setBoxImportReport] = useState(false);
  const [visibleDate, setVisibleDate] = useState(false);
  const [checkSelectAll, setCheckSelectAll] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [selecteds, setSelecteds] = useState<DataMailingItem[]>([]);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [iconType, setIconType] = useState<AlertType>('success');
  const breadcumbLinks: BreadcrumbLink[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
        redirectTo: Urls.HomePage,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.postSellingOptions' }),
        redirectTo: Urls.postSellingReport,
      },
    ],
    [intl]
  );

  const validationSchema = useInputsDateValidation();

  const formik = useFormik({
    initialValues: {
      startDate: formatFromMask(addDays(new Date(), -7), 'yyyy-MM-dd'),
      endDate: formatFromMask(new Date(), 'yyyy-MM-dd'),
    },
    validateOnMount: false,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const downloadReport = (data: MailingReportResponse[]) => {
    const newData = data.map((item) => {
      const newItem = {
        tag: item['tag'].replace('malling', 'pos_venda'),
        subscription: item['subscription'],
        name: item['name'],
        DDD1: item.phone1?.slice(0, 2),
        phone1: item['phone1']?.slice(2, item.phone1.length),
        DDD2: item.phone2?.slice(0, 2),
        phone2: item['phone2']?.slice(2, item.phone2.length),
        DDD3: item.phone3?.slice(0, 2),
        phone3: item['phone3']?.slice(2, item.phone3.length),
        group: item['group'],
        cpfCnpj: item['cpfCnpj'],
        createdAt: formatSimpleDate(item.createdAt),
        verifiedAt: formatSimpleDate(item.verifiedAt),
      };

      return newItem;
    });

    if (newData.length > 0) {
      setClear();

      const headers = {
        tag: PostSelingEnum.tag,
        subscription: PostSelingEnum.subscription,
        name: PostSelingEnum.name,
        DDD1: PostSelingEnum.ddd1,
        phone1: PostSelingEnum.phone1,
        DDD2: PostSelingEnum.ddd2,
        phone2: PostSelingEnum.phone2,
        DDD3: PostSelingEnum.ddd3,
        phone3: PostSelingEnum.phone3,
        group: PostSelingEnum.group,
        cpfCnpj: PostSelingEnum.cpfCnpj,
        createdAt: PostSelingEnum.createdAt,
        verifiedAt: PostSelingEnum.verifiedAt,
      };

      const startDateFilter = formik.values.startDate.replace(/-/g, '');
      const endDateFilter = formik.values.endDate.replace(/-/g, '');
      const monthDay = endDateFilter.substring(4);

      convertJsonToCsv(
        newData,
        headers,

        intl
          .formatMessage({
            id: 'postSelling.reportTitle',
          })
          .replace('{startDate}', startDateFilter)
          .replace('{endDate}', endDateFilter)
          .replace(
            '{monthDay}',
            monthDay.match(/\d{2}/g)?.reverse()?.join('') ?? monthDay
          )
      );
      setClear();
      showAlert({
        type: 'success',
        title: intl.formatMessage({
          id: 'postSelling.title.downloadReport',
        }),
      });
    } else {
      setIsOpenAlert(true);
      setIconType('warn');
      setMessage(
        intl.formatMessage({
          id: 'postSelling.title.downloadReport.statusNotFound',
        })
      );
    }
  };

  const { refetch, isLoading } = useGetMailing(
    formik.values.startDate,
    formik.values.endDate,
    checkSelectAll,
    downloadReport
  );

  const handleSelectOption = (value: string) => {
    if (value === 'extratReport') {
      setBoxExtratReport(true);
      setBoxImportReport(false);
      setVisibleDate(true);
      setShowUploadButton(false);
    } else if (value === 'importReport') {
      setBoxExtratReport(false);
      setBoxImportReport(true);
      setVisibleDate(false);
      setShowUploadButton(true);
    }
  };

  const handleSelectAll = () => {
    setCheckSelectAll(!checkSelectAll);
  };

  const handleCheckbox = (value: DataMailingItem) => {
    const newSelecteds = [...selecteds]
      .filter((selected) => selected.subscription !== value.subscription)
      .concat({
        ...value,
        checked: !value.checked,
      })
      .sort((a, b) => a.subscription - b.subscription);
    setSelecteds(newSelecteds);
  };

  const handleAlert = (options: {
    title: string;
    type: 'success' | 'error';
    message?: string;
  }) => {
    showAlert({ ...options });
  };

  const handleFileChange = async (e: any) => {
    if (e?.target?.files?.length > 0) {
      const file = e.target.files[0];

      if (!isCsvFile(file)) {
        return handleAlert({
          title: 'Arquivo inválido',
          type: 'error',
        });
      }

      showAlert({
        type: 'loading',
        title: intl.formatMessage({ id: 'postSelling.loadingTheData' }),
      });

      const dataConvert = (await convertExceltoJson(file)) as ExcelRow[];

      const [, ...list] = dataConvert;

      const extractedData = [...list]
        .map((row) => {
          const [, subscription, name] = row;
          return { subscription, name, checked: true } as DataMailingItem;
        })
        .sort((a, b) => a.subscription - b.subscription);
      setSelecteds(extractedData);
      setClear();
    }
  };

  const sendFeedback = async () => {
    try {
      showAlert({
        type: 'loading',
        title: intl.formatMessage({ id: 'postSelling.importing' }),
      });
      await postSellingAssignmentStatus(selecteds);
      setSelecteds([]);
      setClear();
      process.nextTick(() => {
        handleAlert({
          type: 'success',
          title: intl.formatMessage({
            id: 'postSelling.successfullyImported',
          }),
        });
      });
    } catch (error: any) {
      setClear();
      process.nextTick(() => {
        handleAlert({
          type: 'success',
          title: intl.formatMessage({
            id: 'postSelling.title.statusInternalError',
          }),
        });
      });
    }
  };

  return (
    <HomeContent>
      <Breadcrumb path={breadcumbLinks} />
      <G.Container>
        <G.Nav>
          <header>
            <G.Title>{intl.formatMessage({ id: 'postSelling.title' })}</G.Title>
          </header>
        </G.Nav>
      </G.Container>
      <G.Container>
        <S.FieldsSpaceBetween>
          <G.FlexGrow>
            <S.fieldsAttachFile>
              <S.Fields>
                <CheckboxInput
                  value="extratReport"
                  id="extratReport"
                  checked={boxExtratReport}
                  onChange={handleSelectOption}
                />
                <G.Text>
                  {intl.formatMessage({ id: 'postSelling.checkBoxExtrat' })}
                </G.Text>
              </S.Fields>
              <S.Fields>
                <CheckboxInput
                  value="importReport"
                  id="importReport"
                  checked={boxImportReport}
                  onChange={handleSelectOption}
                />
                <G.Text>
                  {' '}
                  {intl.formatMessage({
                    id: 'postSelling.checkBoxImportReport',
                  })}
                </G.Text>
              </S.Fields>
            </S.fieldsAttachFile>
          </G.FlexGrow>
          <G.FlexGrow>
            <S.Fields>
              {showUploadButton && (
                <S.ButtonUpload>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                  />
                  <G.Text>
                    {intl.formatMessage({
                      id: 'postSelling.title.uploadbutton',
                    })}
                  </G.Text>
                  <UploadIcon />
                </S.ButtonUpload>
              )}
            </S.Fields>
          </G.FlexGrow>
          {visibleDate && (
            <G.FlexGrow>
              <S.FieldsGrow>
                <Input
                  required
                  id="startDate"
                  name="startDate"
                  onChange={formik.handleChange}
                  value={formik.values?.startDate}
                  variant="lighter"
                  type="date"
                  label={intl.formatMessage({
                    id: 'postSelling.form.label.initialDate',
                  })}
                  hasErrors={
                    !formik.isValid && formik.errors.startDate !== undefined
                  }
                  helperText={String(formik.errors.startDate ?? '')}
                />
                <Input
                  required
                  id="endDate"
                  name="endDate"
                  onChange={formik.handleChange}
                  value={formik.values?.endDate}
                  variant="lighter"
                  type="date"
                  label={intl.formatMessage({
                    id: 'postSelling.form.label.finalDate',
                  })}
                  helperText={String(formik.errors.endDate ?? '')}
                  hasErrors={
                    !formik.isValid && formik.errors.endDate !== undefined
                  }
                />
              </S.FieldsGrow>
              <S.SectionContent>
                <S.Fields>
                  <CheckboxInput
                    id="selecionar todos"
                    checked={checkSelectAll}
                    value="elecionar todos"
                    onChange={handleSelectAll}
                    disabled={false}
                  />
                  <G.Text>
                    {intl.formatMessage({ id: 'postSelling.selectAll' })}
                  </G.Text>
                </S.Fields>
              </S.SectionContent>
            </G.FlexGrow>
          )}
        </S.FieldsSpaceBetween>
        <Divider />
        <S.Footer>
          <S.Paragraph>
            {intl.formatMessage({ id: 'postSelling.home.description' })}
          </S.Paragraph>
          {visibleDate && (
            <ButtonLink
              variant="lighter"
              disabled={!formik.isValid || isLoading}
              onClick={() => refetch()}
            >
              {intl.formatMessage({
                id: isLoading
                  ? 'reportUpdateAssociates.loading'
                  : 'reportUpdateAssociates.downloadReport',
              })}
              <RoundedIcon>
                <ArrowRight />
              </RoundedIcon>
            </ButtonLink>
          )}
        </S.Footer>
        {selecteds.length > 0 && showUploadButton && (
          <PostSellingList
            handleCheckbox={handleCheckbox}
            selecteds={selecteds}
          />
        )}

        <S.FooterImport>
          {selecteds.length > 0 && boxImportReport && (
            <ButtonLink
              variant="lighter"
              disabled={!formik.isValid || isLoading}
              onClick={sendFeedback}
            >
              {intl.formatMessage({
                id: isLoading
                  ? 'reportUpdateAssociates.loading'
                  : 'reportUpdateAssociates.uploadReport',
              })}
              <RoundedIcon>
                <ArrowRight />
              </RoundedIcon>
            </ButtonLink>
          )}
        </S.FooterImport>
        <AlertDialog delay={3000} />
        <ConfirmDialog
          iconType={iconType}
          confirmIcon={<ArrowRight />}
          title={message}
          open={isOpenAlert}
          confirmLabel="Ok"
          onConfirm={() => setIsOpenAlert(false)}
          cancelLabel=""
          onCancel={() => setIsOpenAlert(false)}
          onOpenChange={() => setIsOpenAlert(!isOpenAlert)}
        />
      </G.Container>
    </HomeContent>
  );
};

export default PostSellingReport;
