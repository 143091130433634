import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import api from 'services/api';
import { getParams } from 'services/employers_service';

import { getURLWithParams } from 'lib/contextual/Url';

import { ASSOCIATES } from 'constants/endpoints';

const getPaginatedSubsidiaries = async ({
  pageParam = 1,
  limit = 1000,
  branchCode = '',
  branchName = '',
  branchCity = '',
}): Promise<{
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;

  items: Array<{
    code: number;
    name: string;
    address: string;
    neighborhood: string;
    city: number;
    cep: string;
    addressNumber: string;
  }>;
}> => {
  const object = getParams({
    branchCode,
    branchName,
    branchCity,
  });

  const fullParams = {
    migrationOnly: true,
    page: pageParam,
    limit,
    ...(Object.keys(object).length > 0 ? { ...object } : undefined),
  };

  const { data } = await api.getRequest(
    getURLWithParams(ASSOCIATES.GET_PAGINATED_SUBSIDIARIES, fullParams)
  );

  return data;
};

export const useFindAllSubsidiaries = ({
  branchCode,
  branchName,
  branchCity,
}: {
  branchCode?: string;
  branchName?: string;
  branchCity?: string;
}) => {
  const { data, ...rest } = useInfiniteQuery(
    ['findAllSubsidiaries', branchCode, branchName, branchCity],
    ({ pageParam }) =>
      getPaginatedSubsidiaries({
        pageParam,
        branchCode,
        branchName,
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.currentPage >= lastPage.totalPages
          ? undefined
          : lastPage.currentPage + 1,
    }
  );

  const allSubsidiaries = useMemo(
    () => data?.pages?.flatMap(({ items }) => items),
    [data]
  );

  return { ...rest, allSubsidiaries };
};
