import { useCallback, useEffect, useRef, useState } from 'react';
import { Branch } from 'types/Branch';

import { useLazyGateway } from 'hooks/useLazyGateway';

import { getURLWithParams } from 'lib/contextual';

import { BRANCHES } from 'constants/endpoints';

interface BranchDataProps {
  items: Branch[];
  lastEvaluatedKey: string;
}

export const useFetchBranches = () => {
  const [data, setData] = useState<BranchDataProps>({
    items: [],
    lastEvaluatedKey: '',
  });
  const [filterBranches, setFilterBranches] = useState<BranchDataProps>({
    items: [],
    lastEvaluatedKey: '',
  });
  const [error, setError] = useState<any>({});
  const isFirstRender = useRef(true);
  const isCompleteLoad = useRef(false);
  const isFilterBranch = useRef(false);
  const [isLoadingFilterBranch, setIsLoadingFilterBranch] = useState(false);

  const { sendFor, isSuccess, isGatewayLoading, isValid } = useLazyGateway(
    'branches-all',
    {
      onSuccess: (response) => {
        if (!isFirstRender.current && response?.lastEvaluatedKey.length === 0) {
          isCompleteLoad.current = true;
        }

        if (isFilterBranch.current) {
          setFilterBranches({
            items: response?.items,
            lastEvaluatedKey: response?.lastEvaluatedKey,
          });
          setIsLoadingFilterBranch(false);
        } else {
          setData({ items: [], lastEvaluatedKey: '' });
          setData({
            items: data.items.concat(response?.items),
            lastEvaluatedKey: response?.lastEvaluatedKey,
          });
        }
      },
      onError: (err: any) => {
        if (err?.response?.data) {
          setError(err?.response?.data);
        }
        if (err?.error) {
          setError(err.error);
          setIsLoadingFilterBranch(false);
        }
      },
    }
  );

  const publish = useCallback(
    async (params?: string) => {
      if (params) {
        isFilterBranch.current = true;
        setIsLoadingFilterBranch(true);
        await sendFor(BRANCHES.BY_NAME_CITY(params));
        return;
      }
      isFilterBranch.current = false;
      const url = getURLWithParams(BRANCHES.ALL, {
        limit: 50,
        exclusiveStartKey: data.lastEvaluatedKey,
      });

      await sendFor(url);
    },
    [sendFor, data, filterBranches]
  );

  useEffect(() => {
    const fetch = async () => {
      await publish('');
    };
    if (isValid && isFirstRender.current) {
      isFirstRender.current = false;
      fetch();
    }
  }, [isValid, publish]);

  const clearFilterBranches = () => {
    setFilterBranches({ items: [], lastEvaluatedKey: '' });
  };

  return {
    data: data.items,
    isSuccess,
    publish,
    isLoading: isGatewayLoading,
    error,
    isCompleteLoad: isCompleteLoad.current,
    filterBranches: filterBranches.items,
    isFilterBranch: isFilterBranch.current,
    isLoadingFilterBranch,
    clearFilterBranches,
  };
};
