import { useEffect, useRef } from 'react';

interface InfiniteScrollProps {
  loadMore: () => void;
  children?: any;
  refresh?: any;
}
const InfiniteScroll = ({
  loadMore,
  children,
  refresh,
}: InfiniteScrollProps) => {
  const containerRef = useRef(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        observer.disconnect();
        loadMore();
      }
    }, options);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [refresh]);
  return <div ref={containerRef}>{children}</div>;
};

export default InfiniteScroll;
