import ComponentGate from 'permissions/ComponentGate';
import { useEffect, useMemo, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import {
  getSubscriptionUpSell,
  patchSubscriptionUpSell,
} from 'services/associates';

import { getItem } from 'hooks/useLocalStorage';

import { useAlert } from 'contexts/AlertContext';

import HomeContent from 'components/contextual/homeContent';
import AlertDialog from 'components/shared/core/alertDialog';
import Divider from 'components/shared/core/divider';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import Breadcrumb, {
  BreadcrumbLink,
} from 'components/shared/newCore/Breadcrumb';
import Skeleton from 'components/shared/newCore/Skeleton';

import { ComponentsPermissions } from 'constants/enums';
import { Urls } from 'constants/urls';

import * as G from './../../styles/themes/global/mainContainer';
import * as S from './styles';

export interface DataMailingItem {
  subscription: number;
  name: string;
  checked: boolean;
}

const UpSellAfterSales = () => {
  const intl = useIntl();
  const [data, setData] = useState<any>({});
  const [validated, setIsValidated] = useState<boolean>(false);
  const { showAlert, setClear } = useAlert();
  const location = useLocation();

  const breadcumbLinks: BreadcrumbLink[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
        redirectTo: Urls.HomePage,
      },
      {
        label: intl.formatMessage({ id: 'searchPartner.partners' }),
        redirectTo: Urls.Attendance,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.upSellAfterSales' }),
        redirectTo: Urls.UpSellAfterSales,
      },
    ],
    [intl]
  );

  useEffect(() => {
    const fetchSubscriptionUpsell = async () => {
      try {
        const response = await getSubscriptionUpSell({
          subscription: String(getItem('subscriptionCode')),
          upsell: true,
        });
        setData(response.data);
      } catch (error) {}
    };
    fetchSubscriptionUpsell();
  }, []);

  const TipoCobranca = [
    { code: 1, pagamento: 'BOLETO' },
    { code: 2, pagamento: 'COBRADOR' },
    { code: 3, pagamento: 'CARNE' },
    { code: 4, pagamento: 'DEBITO CONTA LUZ' },
    { code: 5, pagamento: 'DEBITO CONTA BANCARIA' },
    { code: 6, pagamento: 'DESCONTO EM FOLHA' },
    { code: 7, pagamento: 'CARTÃO CREDITO RECORRENTE' },
    { code: 8, pagamento: 'EDP ESCELSA' },
    { code: 9, pagamento: 'CARTAO DE CREDITO' },
    { code: 10, pagamento: 'EMPRESA' },
    { code: 11, pagamento: 'CARTEIRA' },
  ];

  const converteData = (dataOriginal: string) => {
    const data = new Date(dataOriginal);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const handleSubmit = async () => {
    try {
      showAlert({
        type: 'loading',
        title: intl.formatMessage({ id: 'upSell.message.loanding' }),
        message: intl.formatMessage({ id: 'upSell.message.loanding.message' }),
      });
      const response = await patchSubscriptionUpSell(
        String(getItem('subscriptionCode'))
      );
      if (response.status === 200) {
        setIsValidated(true);
        setClear();
        showAlert({
          type: 'success',
          title: intl.formatMessage({ id: 'upSell.message.success' }),
        });
      } else {
        setClear();
        showAlert({
          type: 'error',
          title: intl.formatMessage({ id: 'serverError' }),
        });
      }
    } catch (error) {
      setClear();
      showAlert({
        type: 'error',
        title: intl.formatMessage({ id: 'serverError' }),
      });
    }
  };

  return (
    <HomeContent>
      <AlertDialog />
      <Breadcrumb path={breadcumbLinks} />
      <G.Container>
        <G.Nav>
          <header>
            <G.Title>{intl.formatMessage({ id: 'upSell.header' })}</G.Title>
          </header>
        </G.Nav>
      </G.Container>
      <G.Container>
        <G.Text>
          {intl.formatMessage({ id: 'upSell.header.Migration.History' })}
        </G.Text>
        <S.Subtitle>
          {intl.formatMessage({ id: 'upSell.header.AcceptanceData' })}
        </S.Subtitle>
        <S.ContainerSubTitle>
          <S.FieldsBold>
            {intl.formatMessage({ id: 'upSell.header.ShippingMethod' })}
          </S.FieldsBold>
          <S.Fields>
            {data.dadosAceite?.tipoEnvio ? (
              data.dadosAceite?.tipoEnvio
            ) : (
              <Skeleton width={150} height={15} />
            )}
          </S.Fields>
          <S.FieldsBold>
            {intl.formatMessage({ id: 'upSell.header.ShippingDestination' })}
          </S.FieldsBold>
          <S.Fields>
            {data.dadosAceite?.destinoEnvio ? (
              data.dadosAceite?.destinoEnvio
            ) : (
              <Skeleton width={150} height={15} />
            )}
          </S.Fields>
          <S.FieldsBold>
            {intl.formatMessage({ id: 'upSell.header.AcceptanceDate' })}
          </S.FieldsBold>
          <S.Fields>
            {data.dadosAceite?.dataAceite ? (
              data.dadosAceite?.dataAceite.split('-').join('/')
            ) : (
              <Skeleton width={150} height={15} />
            )}
          </S.Fields>

          <ComponentGate
            permission={ComponentsPermissions.PERMISSION_VALIDATES_UPSELL}
          >
            <S.ContainerButton>
              <ButtonLink
                onClick={handleSubmit}
                type="button"
                disabled={location.state || validated}
                variant="lighter"
              >
                <S.Text>
                  {location.state || validated
                    ? 'Upsell validado'
                    : 'Validar upsell'}
                </S.Text>
                <RoundedIcon>
                  <FiCheck />
                </RoundedIcon>
              </ButtonLink>
            </S.ContainerButton>
          </ComponentGate>
        </S.ContainerSubTitle>
        <S.FlexGrow>
          <S.SectionContent>
            <S.FieldsGrow>
              <S.SectionContent>
                <S.ColumnHeader>
                  {intl.formatMessage({
                    id: 'upSell.header.PreviousPlanDetails',
                  })}
                </S.ColumnHeader>
                <>
                  <S.Subtitle>
                    {intl.formatMessage({ id: 'upSell.body.Contract' })}
                  </S.Subtitle>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Description' })}:
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.plano.Descricao ? (
                        data.historico?.plano.Descricao
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Subgroup' })}:
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.plano.SubGrupo ? (
                        data.historico?.plano.SubGrupo
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.BaseValue' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.plano.ValorBase ? (
                        'R$ ' + data.historico?.plano.ValorBase.toFixed(2)
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({
                        id: 'upSell.body.TotalMonthlyValue',
                      })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.plano.ValorTotal ? (
                        'R$ ' + data.historico?.plano.ValorTotal.toFixed(2)
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({
                        id: 'upSell.body.FirstMonthlyPayment',
                      })}
                    </S.FieldsBold>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsUnorderedList>
                      {intl.formatMessage({ id: 'upSell.body.Date' })}
                    </S.FieldsUnorderedList>
                    <S.Fields>
                      {data.historico?.plano.Mensalidade.DataPagamento ? (
                        data.historico?.plano.Mensalidade.DataPagamento.split(
                          '-'
                        ).join('/')
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsUnorderedList>
                      {intl.formatMessage({ id: 'upSell.body.Value' })}
                    </S.FieldsUnorderedList>
                    <S.Fields>
                      {data.historico?.plano.Mensalidade.Valor ? (
                        'R$ ' +
                        data.historico?.plano.Mensalidade.Valor.toFixed(2)
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Seller' })}:
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.plano.NomeVendedor ? (
                        data.historico?.plano.NomeVendedor
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Supervisor' })}:
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.plano.NomeSupervisor ? (
                        data.historico?.plano.NomeSupervisor
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Coordinator' })}:
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.plano.NomeCoordenador ? (
                        data.historico?.plano.NomeCoordenador
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Lack' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.plano.Carencia ? (
                        data.historico?.plano.Carencia.split('-').join('/')
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                </>
                <>
                  <S.Subtitle>
                    {intl.formatMessage({ id: 'upSell.body.Associate' })}
                  </S.Subtitle>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.FormPayment' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.associado.AssTipoCobranca ? (
                        TipoCobranca.filter(
                          (e) =>
                            e.code === data.historico?.associado.AssTipoCobranca
                        )[0].pagamento
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.DueDate' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.associado.DiaVenc ? (
                        data.historico?.associado.DiaVenc
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.CellPhone' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.associado.Celular ? (
                        data.historico?.associado.Celular
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Telephone' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.associado.Telefone ? (
                        data.historico?.associado.Telefone
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Email' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.associado.AssEmail ? (
                        data.historico?.associado.AssEmail
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Address' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.associado.Residencia ? (
                        data.historico?.associado.Residencia
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Reference' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.associado.AssReferenciaEndereco ? (
                        data.historico?.associado.AssReferenciaEndereco
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                </>
                <>
                  <S.Subtitle>
                    {intl.formatMessage({ id: 'upSell.body.Dependents' })}
                  </S.Subtitle>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Amount' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.dependentes.length
                        ? data.historico?.dependentes.length
                        : 0}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.PetTypeKinship' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.historico?.dependentes[0] ? 'Sim' : 'Não'}
                    </S.Fields>
                  </S.ContainerGrow>
                  {data.historico?.dependentes ? (
                    data.historico?.dependentes.map((dependente: any) => (
                      <>
                        <Divider />
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Name' })}
                          </S.FieldsBold>
                          <S.Fields>{dependente.Nome}</S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Kinship' })}
                          </S.FieldsBold>
                          <S.Fields>{dependente.Parentesco}</S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Category' })}
                          </S.FieldsBold>
                          <S.Fields>
                            {dependente['CategoriaParentesco.Descricao']}
                          </S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Birth' })}
                          </S.FieldsBold>
                          <S.Fields>{dependente.Nascimento}</S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Age' })}
                          </S.FieldsBold>
                          <S.Fields>{dependente.Idade}</S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Sex' })}
                          </S.FieldsBold>
                          <S.Fields>{dependente.DepSexo}</S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Value' })}
                          </S.FieldsBold>
                          <S.Fields>
                            {'R$ ' + dependente.valor.toFixed(2)}
                          </S.Fields>
                        </S.ContainerGrow>
                      </>
                    ))
                  ) : (
                    <S.ContainerGrow>
                      <Skeleton width={150} height={15} />
                    </S.ContainerGrow>
                  )}
                </>
              </S.SectionContent>
              <S.SectionContent>
                <S.ColumnHeader>
                  {intl.formatMessage({
                    id: 'upSell.header.CurrentPlanDetails',
                  })}
                </S.ColumnHeader>
                <>
                  <S.Subtitle>
                    {intl.formatMessage({ id: 'upSell.body.Contract' })}
                  </S.Subtitle>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Description' })}:
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.plano.Descricao ? (
                        data.atual?.plano.Descricao
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Subgroup' })}:
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.plano.SubGrupo ? (
                        data.atual?.plano.SubGrupo
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.BaseValue' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.plano.ValorBase ? (
                        'R$ ' + data.atual?.plano.ValorBase.toFixed(2)
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({
                        id: 'upSell.body.TotalMonthlyValue',
                      })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.plano.ValorTotal ? (
                        'R$ ' + data.atual?.plano.ValorTotal.toFixed(2)
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.NextMonthlyFee' })}
                    </S.FieldsBold>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsUnorderedList>
                      {intl.formatMessage({ id: 'upSell.body.Date' })}
                    </S.FieldsUnorderedList>
                    <S.Fields>
                      {data.atual?.plano.Mensalidade.DataPagamento ? (
                        data.atual?.plano.Mensalidade.DataPagamento.split(
                          '-'
                        ).join('/')
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsUnorderedList>
                      {intl.formatMessage({ id: 'upSell.body.Value' })}
                    </S.FieldsUnorderedList>
                    <S.Fields>
                      {data.atual?.plano.Mensalidade.Valor ? (
                        'R$ ' + data.atual?.plano.Mensalidade.Valor.toFixed(2)
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Seller' })}:
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.plano.NomeVendedor ? (
                        data.atual?.plano.NomeVendedor
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Supervisor' })}:
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.plano.NomeSupervisor ? (
                        data.atual?.plano.NomeSupervisor
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Coordinator' })}:
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.plano.NomeCoordenador ? (
                        data.atual?.plano.NomeCoordenador
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                </>
                <>
                  <S.Subtitle>
                    {intl.formatMessage({ id: 'upSell.body.Associate' })}
                  </S.Subtitle>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.FormPayment' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.associado.AssTipoCobranca ? (
                        TipoCobranca.filter(
                          (e) =>
                            e.code === data.atual?.associado.AssTipoCobranca
                        )[0].pagamento
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.NextDueDate' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.associado.DiaVenc ? (
                        data.atual?.associado.DiaVenc
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.CellPhone' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.associado.Celular ? (
                        data.atual?.associado.Celular
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Telephone' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.associado.Telefone ? (
                        data.atual?.associado.Telefone
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Email' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.associado.AssEmail ? (
                        data.atual?.associado.AssEmail
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Address' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.associado.Residencia ? (
                        data.atual?.associado.Residencia
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Reference' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.associado.AssReferenciaEndereco ? (
                        data.atual?.associado.AssReferenciaEndereco
                      ) : (
                        <Skeleton width={150} height={15} />
                      )}
                    </S.Fields>
                  </S.ContainerGrow>
                </>
                <>
                  <S.Subtitle>
                    {intl.formatMessage({ id: 'upSell.body.Dependents' })}
                  </S.Subtitle>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.Amount' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.dependentes.length
                        ? data.atual?.dependentes.length
                        : 0}
                    </S.Fields>
                  </S.ContainerGrow>
                  <S.ContainerGrow>
                    <S.FieldsBold>
                      {intl.formatMessage({ id: 'upSell.body.PetTypeKinship' })}
                    </S.FieldsBold>
                    <S.Fields>
                      {data.atual?.dependentes[0] ? 'Sim' : 'Não'}
                    </S.Fields>
                  </S.ContainerGrow>
                  {data.atual?.dependentes ? (
                    data.atual?.dependentes.map((dependente: any) => (
                      <>
                        <Divider />
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Name' })}
                          </S.FieldsBold>
                          <S.Fields>{dependente.Nome}</S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Kinship' })}
                          </S.FieldsBold>
                          <S.Fields>{dependente.Parentesco}</S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Category' })}
                          </S.FieldsBold>
                          <S.Fields>
                            {dependente['CategoriaParentesco.Descricao']}
                          </S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Birth' })}
                          </S.FieldsBold>
                          <S.Fields>
                            {converteData(dependente.Nascimento)}
                          </S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Age' })}
                          </S.FieldsBold>
                          <S.Fields>{dependente.Idade}</S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Sex' })}
                          </S.FieldsBold>
                          <S.Fields>{dependente.DepSexo}</S.Fields>
                        </S.ContainerGrow>
                        <S.ContainerGrow>
                          <S.FieldsBold>
                            {intl.formatMessage({ id: 'upSell.body.Value' })}
                          </S.FieldsBold>
                          <S.Fields>
                            {'R$ ' + dependente.valor.toFixed(2)}
                          </S.Fields>
                        </S.ContainerGrow>
                      </>
                    ))
                  ) : (
                    <S.ContainerGrow>
                      <Skeleton width={150} height={15} />
                    </S.ContainerGrow>
                  )}
                </>
              </S.SectionContent>
            </S.FieldsGrow>
          </S.SectionContent>
        </S.FlexGrow>
      </G.Container>
    </HomeContent>
  );
};

export default UpSellAfterSales;
