import { Root } from '@radix-ui/react-alert-dialog';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { City } from 'types/City';
import { CityGroupCreation } from 'types/CityGroup';
import * as Yup from 'yup';

import { useFindCitiesByName, useFindCitiesByUF } from 'useCases/city';
import {
  useCreateCityGroup,
  useEditCityGroup,
  useFindAllCityGroup,
  useFindCityGroup,
} from 'useCases/cityGroup';

import ConfirmDialog, { AlertIcon } from 'components/shared/core/confirmDialog';
import InfiniteScroll from 'components/shared/core/infiniteScroll';
import LoadingSpinner from 'components/shared/core/loadingSpinner';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import CustomSelect, { SelectItem } from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';

import { brazilianStateOptions } from 'constants/brazilianStateOptions';
import { DEBOUNCE_INTERVAL } from 'constants/debounceInterval';

import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';
import { ReactComponent as CancelIcon } from 'assets/images/CancelGray.svg';
import { ReactComponent as GlassSearch } from 'assets/images/MagnifyingGlass.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icon-plus.svg';

import * as S from './ModalCityGroupPicker.styles';
import { sortCitiesByStateAndName } from './helpers';

interface ModalCityGroupPickerProps {
  open: boolean;
  onCancel: () => void;
  withGroups?: boolean;
  setAnonymousCreationId?: React.Dispatch<React.SetStateAction<string>>;
}

type formPhase = 'form' | 'loading' | 'success' | 'error';

const ModalCityGroupPicker = ({
  open,
  onCancel,
  withGroups = false,
  setAnonymousCreationId,
}: ModalCityGroupPickerProps) => {
  const intl = useIntl();

  const [selectedCities, setSelectedCities] = useState<City[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(open);
  const [isNewGroup, setIsNewGroup] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [currentPhase, setcurrentPhase] = useState<formPhase>('form');
  const [showConfirm, setShowConfirm] = useState(false);
  const { allCityGroups, rest: restCityGroups } = useFindAllCityGroup();

  const createCityGroupMutation = useCreateCityGroup();
  const editCityGroupMutation = useEditCityGroup();

  const formik = useFormik({
    initialValues: {
      searchType: 'city',
      searchCity: '',
      searchState: '',
      newCityGroup: '',
      cityGroupId: '',
      cityGroupName: '',
    },
    validationSchema: Yup.object().shape({
      newCityGroup:
        withGroups && isNewGroup
          ? Yup.string().required(
              intl.formatMessage({
                id: 'cityGroupPicker.validation.newCityGroup',
              })
            )
          : Yup.string().nullable(),
      cityGroupName:
        withGroups && !isNewGroup
          ? Yup.string().required(
              intl.formatMessage({
                id: 'cityGroupPicker.validation.cityGroup',
              })
            )
          : Yup.string().nullable(),
    }),
    onSubmit: () => {
      setShowConfirm(true);
    },
  });

  useEffect(() => {
    if (open === isOpen) {
      return;
    }
    setIsOpen(open);
    setcurrentPhase('form');

    if (!open) {
      setIsClosing(true);
      setTimeout(() => {
        setIsClosing(false);
      }, 200);
    }
  }, [open, isOpen]);

  const optionsFields: SelectItem[] = useMemo(
    () => [
      {
        value: 'state',
        label: intl
          .formatMessage({
            id: 'cityGroupPicker.modal.optionState',
          })
          .toUpperCase(),
      },
      {
        value: 'city',
        label: intl
          .formatMessage({
            id: 'cityGroupPicker.modal.optionCity',
          })
          .toUpperCase(),
      },
    ],
    []
  );

  const {
    citiesByName,
    refetch: refetchCitiesByName,
    isRefetching: isRefetchingCitiesByName,
    isLoading: isLoadingCityList,
    fetchNextPage: fetchNextPageCitiesByName,
    hasNextPage: hasNextPageCitiesByName,
  } = useFindCitiesByName({
    filterParam: formik.values.searchCity,
    enableOnMount: false,
  });

  const {
    citiesByUF,
    fetchNextPage: fetchNextPageCitiesByUF,
    hasNextPage: hasNextPageCitiesByUF,
  } = useFindCitiesByUF(formik.values.searchState);

  const { cityGroupData, isLoading: isModalLoading } = useFindCityGroup(
    formik.values.cityGroupId
  );

  useEffect(() => {
    setSelectedCities(cityGroupData?.cities ?? []);
    formik.setFieldValue('cityGroupName', cityGroupData?.name ?? '');
  }, [cityGroupData]);

  const debouncedSearch = useCallback(
    debounce(refetchCitiesByName, DEBOUNCE_INTERVAL),
    []
  );

  const handleOnChangeQueryType = (label: string, value: string) => {
    if (formik.values.searchType !== value) {
      formik.setFieldValue('searchValue', '');
    }

    formik.setFieldValue(label, value);
  };

  const handleOnChangeFilterState = (label: string, value: string) => {
    formik.setFieldValue('searchState', value);
  };

  const handleOnChangeCityGroup = (label: string, value: string) => {
    formik.setFieldValue('cityGroupId', value);
  };

  const handleClickOnCancelButton = () => {
    setIsNewGroup(false);
    formik.setFieldValue('newCityGroup', '');
  };

  const handleClickOnAddButton = () => {
    setIsNewGroup(true);
    formik.setFieldValue('cityGroupId', '');
  };

  const addressAvailableOptions = useMemo(() => {
    const cities =
      formik.values.searchType === 'city'
        ? citiesByName
        : formik.values.searchType === 'state'
        ? citiesByUF
        : [];

    return cities.filter(
      (option) =>
        !selectedCities.find(
          (selected) => selected.gsAdminId === option.gsAdminId
        )
    );
  }, [citiesByName, citiesByUF, selectedCities, formik.values]);

  const submitForm = async () => {
    setcurrentPhase('loading');
    setShowConfirm(false);
    setErrorMessage('');

    try {
      const cityGroupToCreateOrUpdate: CityGroupCreation = {
        anonymous: !withGroups ? true : false,
        name: !withGroups
          ? new Date().toISOString()
          : isNewGroup
          ? formik.values.newCityGroup
          : formik.values.cityGroupName,
        cities: selectedCities,
      };

      if (!formik.values.cityGroupId) {
        const responseCreation = await createCityGroupMutation.mutateAsync(
          cityGroupToCreateOrUpdate
        );

        if (setAnonymousCreationId) {
          setAnonymousCreationId(responseCreation.id);
        }
      } else {
        await editCityGroupMutation.mutateAsync({
          ...cityGroupToCreateOrUpdate,
          id: formik.values.cityGroupId,
        });
      }

      setcurrentPhase('success');
      setTimeout(() => {
        onCancel();
      }, 2000);
      formik.resetForm();
      setSelectedCities([]);
    } catch (e: any) {
      setErrorMessage(
        e?.response.data.message.replace(
          /-([a-z])/g,
          (_: string, group1: string) => group1.toUpperCase()
        )
      );
      setcurrentPhase('error');
      setTimeout(() => {
        setcurrentPhase('form');
      }, 2000);
    }
  };

  const handleClick = (action: 'add' | 'remove', city: City) => {
    if (
      action === 'add' &&
      !selectedCities.find((v) => v.gsAdminId === city.gsAdminId)
    ) {
      return setSelectedCities((prev) => [city, ...prev]);
    }

    if (action === 'remove') {
      return setSelectedCities((prev) =>
        prev.filter((v) => !(v.gsAdminId === city.gsAdminId))
      );
    }
  };

  const handleOnClose = () => {
    formik.resetForm();
    setSelectedCities([]);
    onCancel();
  };

  return (
    <Root open={open}>
      <S.Overlay />
      <S.Modal $isClosing={isClosing}>
        {currentPhase === 'loading' && (
          <S.ContainerLoader isLoading>
            <S.Loader isLoading />
          </S.ContainerLoader>
        )}

        {currentPhase === 'success' && (
          <S.Content $isClosing={isClosing}>
            <AlertIcon type={'success'} />
            <S.Title>
              {intl.formatMessage({
                id: 'cityGroupPicker.modal.success',
              })}
            </S.Title>
          </S.Content>
        )}

        {currentPhase === 'error' && (
          <S.Content $isClosing={isClosing}>
            <AlertIcon type={'error'} />
            <S.Title>
              {intl.formatMessage({
                id: errorMessage ? errorMessage : 'serverError',
              })}
            </S.Title>
          </S.Content>
        )}

        {currentPhase === 'form' && (
          <S.Content $isClosing={isClosing}>
            <S.Title>
              {intl
                .formatMessage({
                  id: 'cityGroupPicker.modal.title',
                })
                .toUpperCase()}
            </S.Title>

            <S.Form>
              <S.FormFields>
                {withGroups ? (
                  isNewGroup ? (
                    <>
                      <ButtonLink
                        onClick={handleClickOnCancelButton}
                        type="button"
                        variant="lighter"
                      >
                        <RoundedIcon>
                          <CancelIcon />
                        </RoundedIcon>
                      </ButtonLink>
                      <Input
                        required={isNewGroup}
                        id="newCityGroup"
                        type="string"
                        autoComplete="off"
                        variant="lighter"
                        hasBorder
                        label={intl.formatMessage({
                          id: 'cityGroupPicker.modal.newCityGroup',
                        })}
                        value={formik.values.newCityGroup}
                        onChange={formik.handleChange}
                        hasErrors={
                          formik.touched.newCityGroup &&
                          Boolean(formik.errors.newCityGroup)
                        }
                        maxLength={50}
                      />
                    </>
                  ) : (
                    <>
                      <ButtonLink
                        onClick={handleClickOnAddButton}
                        type="button"
                        variant="lighter"
                      >
                        <RoundedIcon>
                          <PlusIcon />
                        </RoundedIcon>
                      </ButtonLink>
                      <CustomSelect
                        id="cityGroup"
                        required={!isNewGroup}
                        inputName="cityGroup"
                        inputValue={formik.values.cityGroupName}
                        label={intl.formatMessage({
                          id: 'cityGroupPicker.modal.cityGroup',
                        })}
                        handlePagination={() => {
                          restCityGroups.fetchNextPage();
                        }}
                        hasPagination={restCityGroups.hasNextPage}
                        options={allCityGroups}
                        setValue={handleOnChangeCityGroup}
                        isBordered
                        hasErrors={
                          formik.touched.cityGroupName &&
                          Boolean(formik.errors.cityGroupName)
                        }
                      />
                    </>
                  )
                ) : null}
                <CustomSelect
                  id="searchType"
                  inputName="searchType"
                  inputValue={formik.values.searchType}
                  label={intl.formatMessage({
                    id: 'cityGroupPicker.modal.selectFilterLabel',
                  })}
                  options={optionsFields}
                  setValue={handleOnChangeQueryType}
                  isBordered
                />
                {formik.values.searchType === 'state' ? (
                  <CustomSelect
                    id="searchState"
                    inputName="searchState"
                    inputValue={formik.values.searchState}
                    label={intl.formatMessage({
                      id: 'cityGroupPicker.modal.selectFilter',
                    })}
                    options={brazilianStateOptions}
                    setValue={handleOnChangeFilterState}
                    isBordered
                  />
                ) : (
                  <Input
                    id="searchCity"
                    type="text"
                    variant="lighter"
                    autoComplete="off"
                    hasBorder
                    label={intl.formatMessage({
                      id: 'cityGroupPicker.modal.selectFilter',
                    })}
                    value={formik.values.searchCity}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue('searchCity', value);
                      if (value.length > 2) debouncedSearch();
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        refetchCitiesByName();
                      }
                    }}
                    endAdornment={
                      isRefetchingCitiesByName || isLoadingCityList ? (
                        <LoadingSpinner width={20} height={20} borderSize={3} />
                      ) : (
                        <GlassSearch />
                      )
                    }
                  />
                )}
              </S.FormFields>
              <S.FormCityList>
                <S.SecondFooterContainer>
                  <S.CityListTitle>
                    {intl.formatMessage({
                      id: 'cityGroupPicker.modal.cityListTitle',
                    })}
                  </S.CityListTitle>
                  <S.CityListArea>
                    <S.CityListHtmlList>
                      {addressAvailableOptions?.map(
                        ({ name, state, gsAdminId }) => (
                          <S.CityListHtmlListItem
                            key={`available_${gsAdminId}`}
                            onClick={() =>
                              handleClick('add', {
                                name,
                                state,
                                gsAdminId,
                              })
                            }
                          >
                            <S.CityListHtmlListElement>
                              {`${state} - ${name}`}
                            </S.CityListHtmlListElement>
                          </S.CityListHtmlListItem>
                        )
                      )}
                      {formik.values.searchType === 'state' &&
                        hasNextPageCitiesByUF && (
                          <>
                            <InfiniteScroll
                              loadMore={() => fetchNextPageCitiesByUF()}
                              refresh={() => fetchNextPageCitiesByUF}
                            />
                            <LoadingSpinner
                              width={20}
                              height={20}
                              borderSize={4}
                            />
                          </>
                        )}
                      {formik.values.searchType === 'city' &&
                        hasNextPageCitiesByName && (
                          <>
                            <InfiniteScroll
                              loadMore={() => fetchNextPageCitiesByName()}
                              refresh={() => fetchNextPageCitiesByName()}
                            />
                            <LoadingSpinner
                              width={20}
                              height={20}
                              borderSize={4}
                            />
                          </>
                        )}
                    </S.CityListHtmlList>
                  </S.CityListArea>
                </S.SecondFooterContainer>
                <S.SecondFooterContainer>
                  <S.CityListTitle>
                    {intl.formatMessage({
                      id: 'cityGroupPicker.modal.cityListTitleSelected',
                    })}
                  </S.CityListTitle>
                  <S.CityListArea>
                    <S.CityListHtmlList>
                      {selectedCities
                        ?.sort(sortCitiesByStateAndName)
                        .map(({ name, state, gsAdminId }) => (
                          <S.CityListHtmlListItem
                            key={`selected_${gsAdminId}`}
                            onClick={() =>
                              handleClick('remove', {
                                name,
                                state,
                                gsAdminId,
                              })
                            }
                          >
                            <S.CityListHtmlListElement>
                              {`${state} - ${name}`}
                            </S.CityListHtmlListElement>
                          </S.CityListHtmlListItem>
                        ))}
                    </S.CityListHtmlList>
                  </S.CityListArea>
                  <S.CityListTitle>
                    {intl.formatMessage(
                      {
                        id:
                          selectedCities.length < 2
                            ? 'cityGroupPicker.modal.selectedCity'
                            : 'cityGroupPicker.modal.selectedCities',
                      },
                      { count: selectedCities.length }
                    )}
                  </S.CityListTitle>
                </S.SecondFooterContainer>
              </S.FormCityList>

              <S.Footer>
                <ButtonLink onClick={handleOnClose} variant="lighter">
                  {intl.formatMessage({
                    id: 'cityGroupPicker.modal.cancel',
                  })}
                </ButtonLink>

                <ButtonLink
                  onClick={() => formik.handleSubmit()}
                  type="button"
                  variant="lighter"
                  disabled={selectedCities.length === 0}
                >
                  {isModalLoading
                    ? intl.formatMessage({
                        id: 'cashOutWithdrawls.modalConfirm.label.loading',
                      })
                    : intl.formatMessage({
                        id: 'cityGroupPicker.modal.confirm',
                      })}
                  <RoundedIcon>
                    <ArrowRight />
                  </RoundedIcon>
                </ButtonLink>
              </S.Footer>
            </S.Form>
          </S.Content>
        )}
      </S.Modal>
      <ConfirmDialog
        open={showConfirm}
        onConfirm={() => submitForm()}
        onOpenChange={(value) => setShowConfirm(value)}
        iconType="warn"
        onCancel={() => setShowConfirm(false)}
        confirmIcon={<ArrowRight />}
        title={intl.formatMessage(
          {
            id: 'cityGroupPicker.modal.confirmDialogTitle',
          },
          {
            numSelected: selectedCities.length,
          }
        )}
        confirmLabel={intl.formatMessage({
          id: 'cityGroupPicker.modal.confirmAdd',
        })}
        cancelLabel={intl.formatMessage({
          id: 'cityGroupPicker.modal.cancel',
        })}
      />
    </Root>
  );
};

export default ModalCityGroupPicker;
