import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  OrderServiceParams,
  OrdersServicesResponse,
} from 'types/orderServiceSisfuner';

import { useFindOrdersService } from 'useCases/sisfuner/funeralAgent/workOrders/useFindOrdersService';

import { useFuneralHomesUser } from './FuneralHomesUserContext';

type OrdersServiceSisfunerType = {
  children: ReactNode;
};

interface InitialValueProps {
  ordersServicesData: OrdersServicesResponse[];
  hasNextPage: any;
  fetchNextPage: any;
  isLoading: boolean;
  refetch: any;
  params: OrderServiceParams;
  setParams: (data: OrderServiceParams) => void;
}

const initialState: InitialValueProps = {
  ordersServicesData: [],
  hasNextPage: undefined,
  fetchNextPage: undefined,
  isLoading: false,
  refetch: undefined,
  params: {
    query: '',
    queryType: '',
    page: 1,
    limit: 10,
    funeralHomeId: '',
  },
  setParams: (data: OrderServiceParams) => {},
};

const OrdersServiceSisfunerContext =
  createContext<InitialValueProps>(initialState);

export const OrdersServiceSisfunerProvider = ({
  children,
}: OrdersServiceSisfunerType) => {
  const { funeralHomesUser } = useFuneralHomesUser();
  const [params, setParams] = useState<OrderServiceParams>({
    query: '',
    queryType: '',
    page: 1,
    limit: 10,
    funeralHomeId: funeralHomesUser.selectedFuneralHomesUser || '',
  });
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    setParams({
      query: '',
      queryType: '',
      page: 1,
      limit: 10,
      funeralHomeId: funeralHomesUser.selectedFuneralHomesUser,
    });
  }, [funeralHomesUser.selectedFuneralHomesUser]);

  const {
    ordersServicesData,
    isLoading: queryLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useFindOrdersService(params, false);

  useEffect(() => {
    if (queryLoading) {
      setInitialLoading(true);
    } else {
      setInitialLoading(false);
    }
  }, [queryLoading]);

  const queryClient = useQueryClient();

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      funeralHomeId: funeralHomesUser.selectedFuneralHomesUser,
      page: 1,
    }));
  }, [funeralHomesUser.selectedFuneralHomesUser]);

  useEffect(() => {
    if (params.funeralHomeId) {
      setInitialLoading(true);
      queryClient
        .invalidateQueries([
          `findOrdersServicesSisfuner-${params.funeralHomeId}`,
          `${params.queryType}-${params.query}`,
        ])
        .then(() => {
          refetch().finally(() => setInitialLoading(false));
        });
    }
  }, [params, queryClient, refetch]);

  return (
    <OrdersServiceSisfunerContext.Provider
      value={{
        ordersServicesData,
        isLoading: initialLoading,
        hasNextPage,
        fetchNextPage,
        refetch,
        params,
        setParams,
      }}
    >
      {children}
    </OrdersServiceSisfunerContext.Provider>
  );
};

export const useOrdersServices = () => useContext(OrdersServiceSisfunerContext);
