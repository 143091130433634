import { AxiosResponse } from 'axios';

import api from 'services/api';

import { getURLWithParams } from 'lib/contextual';

import {
  INSTALLMENTS,
  MONTHLYFEE,
  TUITION_READJUSTMENT,
  USERS,
} from 'constants/endpoints';

import {
  createTuitioApprovePayload,
  postNewReadjusmentSolicitationPayload,
  updateInstallmentsByReferencePayload,
  updateTuitionReadjustmentPayload,
} from './interfaces';
import {
  DataApproveFullResponse,
  GetPaginatedUserSyncProps,
  UsersResponse,
} from './types';

const patchReadjustmentApprover = (
  payload: updateTuitionReadjustmentPayload
): Promise<AxiosResponse<any>> =>
  api.patchRequest(
    TUITION_READJUSTMENT.TUITION_READJUSTMENT_UPDATE_STATUS_HISTORICAL_APPROVAL(
      String(payload.id)
    ),
    payload.approvalUpdateObject,
    {
      headers: {
        transactionId: 'patch-sync-status-historical-approval',
      },
    }
  );
const patchInstallmentByReferences = (
  payload: updateInstallmentsByReferencePayload
): Promise<AxiosResponse<any>> =>
  api.patchRequest(
    MONTHLYFEE.MONTHLYFEE_BY_REFERENCE_UPDATE(String(payload.subscriptionCode)),
    {
      references: payload.references,
      newValue: payload.newValue,
      paymentDateLimit: payload.paymentDateLimit,
    },
    {
      headers: {
        transactionId: 'patch-sync-installment-by-refference',
      },
    }
  );

const postNewReadjusmentSolicitation = (
  payload: Partial<postNewReadjusmentSolicitationPayload>
): Promise<AxiosResponse<any>> =>
  api.postRequest(INSTALLMENTS.FETCH_APPROVALS, payload, {
    headers: {
      transactionId: 'post-new-readjusment-solicitation',
    },
  });

type PaginatedUserResponse = {
  items: Array<UsersResponse>;
  lastEvaluatedKey: string;
  error?: Error | null;
};

const patchCreatApprover = (
  payload: createTuitioApprovePayload
): Promise<AxiosResponse<any>> =>
  api.postRequest(TUITION_READJUSTMENT.CREATE_BRANCH_APPROVER(), payload);
const getApprovalById = (
  id: string
): Promise<AxiosResponse<DataApproveFullResponse>> => {
  const url = getURLWithParams(INSTALLMENTS.FETCH_APPROVALS, { id });
  return api.getRequest(url);
};

const getPaginatedUserSync = async ({
  limit = 50,
  name,
  exclusiveStartKey = '',
}: GetPaginatedUserSyncProps): Promise<PaginatedUserResponse> => {
  let response: PaginatedUserResponse;
  try {
    const dynamicQueryParams = {
      limit,
      name,
      exclusiveStartKey,
    };
    if (name === '') {
      delete dynamicQueryParams.name;
    }
    const rawResponse = await api.getRequest(
      getURLWithParams(USERS.ALL_SYNC, dynamicQueryParams),
      {
        headers: {
          transactionId: 'list-users-sync',
        },
      }
    );
    //@ts-ignore
    if (rawResponse.isAxiosError) {
      response = {
        items: [],
        lastEvaluatedKey: '',
        error: new Error(),
      };
      return response;
    }
    response = {
      items: rawResponse.data.items,
      lastEvaluatedKey: rawResponse.data.lastEvaluatedKey,
      error: null,
    };
    return response;
  } catch (error) {
    throw error;
  }
};
const getFitApprover = async ({
  type,
  status,
}: {
  type: number;
  status: number;
}): Promise<{
  items: Array<any>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(TUITION_READJUSTMENT.GET_FIT_APPROVER, {
      type,
      status,
    })
  );

  const { items } = data;
  const cognitoId = items[0].approver;
  const response = await api.getRequest(
    getURLWithParams(USERS.ALL_SYNC, { cognitoId }),
    {
      headers: {
        transactionId: 'get-name-fit-approver-by-cognitoId',
      },
    }
  );
  return response.data;
};

export {
  getApprovalById,
  getFitApprover,
  getPaginatedUserSync,
  patchCreatApprover,
  patchInstallmentByReferences,
  patchReadjustmentApprover,
  postNewReadjusmentSolicitation,
};
