import * as XLSX from 'xlsx';

export const convertJsonToExcel = (
  jsonData: object[] | object[][],
  fileName: string,
  multipleSheets: boolean = false
) => {
  const workbook = XLSX.utils.book_new();
  if (multipleSheets) {
    jsonData.forEach((item, index: number) => {
      const worksheet = XLSX.utils.json_to_sheet(item as object[]);
      const sheetName = `Sheet${index + 1}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });
  } else {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  }
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  const dataBlob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const url = URL.createObjectURL(dataBlob);

  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();

  URL.revokeObjectURL(url);
};
