import styled from 'styled-components';

import { Grid } from 'components/shared';

import { device } from 'styles/tokens/devices';

export const Header = styled.div`
  margin: ${({ theme }) => theme.spacing.md} 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

export const ContainerButtons = styled.div`
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const CustomGrid = styled(Grid)`
  margin-top: 1rem;
`;

export const Form = styled.form`
  .hidden-separator {
    padding: 16px;
  }
  input[type='file'] {
  }
`;
export const BankTitle = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const ErrorText = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.contextual.error};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
