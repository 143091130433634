import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import OnlinePaymentSlipFilter from 'components/contextual/OnlinePaymentSlipFilter';
import HomeContent from 'components/contextual/homeContent';
import Divider from 'components/shared/core/divider';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import Breadcrumb, {
  BreadcrumbLink,
} from 'components/shared/newCore/Breadcrumb';
import { Urls } from 'constants/urls';
import { ReactComponent as ArrowRight } from 'assets/images/ArrowRight.svg';
import * as S from './onlinePaymentSlip.styles';
import OnlineBankSlipsTable from 'components/contextual/onlineBankSlipsTable'



const OnlinePaymentSlip = () => {
  const history = useNavigate();
  const [batch, setBatchId] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [date, setDate] = useState('');
  const [isMasterUser, setIsMasterUser] = useState(false);
  const intl = useIntl();
  const { user } = useSession();

  const breadcrumbLinks: BreadcrumbLink[] = [
    {
      label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
      redirectTo: Urls.HomePage,
    },
    {
      label: intl.formatMessage({ id: 'breadcrumb.paymentSlipMenu' }),
      redirectTo: Urls.menuPaymentSlip,
    },
    {
      label: intl.formatMessage({ id: 'breadcrumb.onlinePaymentSlip' }),
      redirectTo: Urls.onlinePaymentSlip,
    },
  ];


  useEffect(() => {
    setIsMasterUser(user.permissions.name === 'master');
  }, [user.permissions.name]);

  return (
    <HomeContent>
      <Breadcrumb path={breadcrumbLinks} />
      <S.Title>
        <S.MobileControl>
          {intl.formatMessage({
            id: 'paymentSlip.title.online',
          })}
        </S.MobileControl>
      </S.Title>

      <S.Header>
        <OnlinePaymentSlipFilter
          setBatchId={setBatchId}
          setDate={setDate}
          setSelectedStatus={setSelectedStatus}
          batch={batch}
          date={date}
          selectedStatus={selectedStatus}
          isMasterUser={isMasterUser}
        />
      </S.Header>
      <>
        <OnlineBankSlipsTable
          batch={batch}
          date={date}
          selectedStatus={selectedStatus}
          isMasterUser={isMasterUser}
        />

        <Divider />

        <S.Footer>
          <ButtonLink
            onClick={() => history(Urls.createOnlinePaymentSlip)}
            type="button"
            variant="lighter"
          >
            {intl.formatMessage({ id: 'onlineBatchList.button.newBatch' })}
            <RoundedIcon>
              <ArrowRight />
            </RoundedIcon>
          </ButtonLink>
        </S.Footer>
      </>
    </HomeContent>
  );
};

export default OnlinePaymentSlip;
