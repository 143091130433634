import styled, { css } from 'styled-components';

export const variants = {
  error: css`
    color: ${({ theme }) => theme.contextual.error};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: ${({ theme }) => theme.fontSizes.medium};
    font-weight: 600;
    text-transform: uppercase;
  `,
  muted: css`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: ${({ theme }) => theme.texts.muted};
    font-weight: 600;
  `,
  normal: css`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: ${({ theme }) => theme.texts.thinParagraph2};
    font-weight: 400;
  `,
  success: css`
    text-transform: uppercase;
    color: ${({ theme }) => theme.contextual.success};
    line-height: ${({ theme }) => theme.fontSizes.medium};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: 600;
  `,
  darkSecondary: css`
    color: ${({ theme }) => theme.texts.darkSecondary};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: 600;
  `,
  light: css`
    font-size: ${({ theme }) => theme.fontSizes.small1};
    color: ${({ theme }) => theme.texts.thinParagraph2};
  `,
} as const;

export type TypographyVariants = keyof typeof variants;

export type TypographyProps = {
  colorScheme: TypographyVariants;
};

export const Typography = styled.span<TypographyProps>`
  ${(props) => variants[props.colorScheme]}
`;
