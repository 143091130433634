import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Lots } from 'types/PaymentSlip';

import { formatHour, formatSimpleDate } from 'lib/core';

import EmptyState from 'components/shared/core/EmptyState';
import InfiniteScroll from 'components/shared/core/infiniteScroll';
import LoadingSpinner from 'components/shared/core/loadingSpinner';
import Table from 'components/shared/newCore/Table';

import { ReactComponent as AlertError } from 'assets/images/AlertError.svg';
import { ReactComponent as CancelIcon } from 'assets/images/CancelIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';

import * as S from './LotListTable.styles';

interface UserListTableProps {
  data: Lots[];
  isLoading: boolean;
  isCompleteLoad: boolean;
  handleLoadMore: () => void;
  isLoadingFilter?: boolean;
  isFilterRender?: boolean;
}

const LotListTable = ({
  data: lots,
  isLoading,
  isCompleteLoad,
  handleLoadMore,
  isLoadingFilter,
  isFilterRender,
}: UserListTableProps) => {
  const intl = useIntl();
  const listRef = useRef<any>([]);
  const isFirstRender = useRef(true);

  const batchStatsStyle = (state: string) => {
    if (
      [
        'monthly-payments-completed',
        'bank-slips-completed',
        'bank-slips-completed-shipping',
      ].includes(state)
    )
      return 'completed';
    if (
      [
        'monthly-payments-processing',
        'bank-slips-processing',
        'bank-slips-processing-shipping',
      ].includes(state)
    )
      return 'processing';
    if (
      [
        'monthly-payments-failed',
        'bank-slips-failed',
        'bank-slips-failed-shipping',
      ].includes(state)
    )
      return 'failed';
    return state;
  };

  const determineIndex = () => {
    if (listRef.current.length > 0) {
      const lastNumber = String(listRef.current.length).slice(-1);
      if (lastNumber === '0') {
        return listRef.current.length - (13 - Number(lastNumber));
      } else {
        return listRef.current.length - Number(lastNumber) - 3;
      }
    }
    return 0;
  };
  const executeScroll = () => {
    if (listRef.current.length > 0) {
      listRef.current[determineIndex()]?.scrollIntoView();
    }
  };

  const checkIfBatchFailed = (batchStatus: string) => {
    const errorStatuses = [
      'bank-slips-failed-shipping',
      'bank-slips-failed-conciliation',
      'bank-slips-failed',
      'monthly-payments-failed',
      'failed',
    ];

    if (batchStatus === 'finished') {
      return <CancelIcon width={50} cursor="auto" />;
    }

    if (errorStatuses.includes(batchStatus)) {
      return <AlertError width={50} height={34} cursor="auto" />;
    } else {
      return <LoadingSpinner width={34} height={34} borderSize={5} />;
    }
  };

  useEffect(() => {
    isFirstRender.current = false;
    if (!isFilterRender && isFirstRender.current) {
      executeScroll();
    }
  }, []);

  const loadMore = () => {
    listRef.current = [];
    handleLoadMore();
  };

  const addToRefs = (el: any) => {
    if (el && !listRef.current.includes(el)) {
      listRef.current.push(el);
    }

    if (lots.length < listRef.current.length) {
      listRef.current.shift();
    }
  };

  if (isLoading && !isLoadingFilter) {
    return (
      <>
        <S.ListSkeleton />
        <S.ListSkeleton />
        <S.ListSkeleton />
      </>
    );
  }

  if (lots.length === 0) {
    return (
      <S.EmptyStateContainer>
        <EmptyState />
      </S.EmptyStateContainer>
    );
  }

  return (
    <S.Container>
      <Table.Header screenXs>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({
            id: 'batchList.table.code',
          })}
        </Table.Column>
        <Table.Column isHeader contentPosition="center" columnSize={40}>
          {intl.formatMessage({
            id: 'batchList.table.interval',
          })}
        </Table.Column>
        <Table.Column isHeader contentPosition="center" columnSize={280}>
          {intl.formatMessage({
            id: 'batchList.table.date',
          })}
        </Table.Column>
        <Table.Column
          isHeader
          contentPosition="center"
          columnSize={30}
          paddingLeft={10}
        >
          {intl.formatMessage({
            id: 'batchList.table.generation',
          })}
        </Table.Column>
        <Table.Column
          isHeader
          contentPosition="center"
          columnSize={300}
          paddingLeft={25}
        >
          {intl.formatMessage({
            id: 'batchList.table.dataOrigin',
          })}
        </Table.Column>
        <Table.Column
          isHeader
          contentPosition="center"
          columnSize={60}
          paddingRight={10}
        >
          {intl.formatMessage({
            id: 'batchList.table.status',
          })}
        </Table.Column>
        <Table.Column
          isHeader
          contentPosition="center"
          columnSize={300}
          paddingRight={30}
        >
          {intl.formatMessage({
            id: 'batchList.table.details',
          })}
        </Table.Column>
      </Table.Header>

      <S.ContentTable>
        <Table resizeHeight={315}>
          {lots.map((batch: Lots) => (
            <Table.Row key={batch.id} screenXs ref={(el) => addToRefs(el)}>
              <Table.Column title={`${batch.code}`} contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.code',
                    })}
                    :
                  </span>
                  {batch.code}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column title={batch.dateStart} contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.interval',
                    })}
                    :
                  </span>
                  {formatSimpleDate(batch.dateStart)} -{' '}
                  {formatSimpleDate(batch.dateEnd)}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column title={batch.createdAt} contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.date',
                    })}
                    :
                  </span>
                  {`${formatSimpleDate(batch.createdAt)}-${formatHour(
                    new Date(batch.createdAt)
                  )}`}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.generation',
                    })}
                    :
                  </span>
                  {intl.formatMessage({
                    id: `bankSlipGeneration.${batch.associateInputType}`,
                  })}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.dataOrigin',
                    })}
                    :
                  </span>
                  <S.ButtonContainer>
                    <S.Button>
                      {batch.inputFile ? (
                        <a href={batch.inputFile}>
                          <DownloadIcon />
                        </a>
                      ) : (
                        <CancelIcon width={50} cursor="auto" />
                      )}
                    </S.Button>
                  </S.ButtonContainer>
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column contentPosition="center">
                <S.StatusColumn status={batchStatsStyle(batch.status)}>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.status',
                    })}
                    :
                  </span>
                  {intl.formatMessage({
                    id: `bankSlipStatus.${batch.status}`,
                  })}
                  {` - ${batch.currentStage}/${batch.totalStages}`}
                </S.StatusColumn>
              </Table.Column>
              <Table.Column contentPosition="center">
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.details',
                    })}
                    :
                  </span>
                  <S.ButtonContainer>
                    <S.Button>
                      {batch.processingResult ? (
                        <a href={batch.processingResult}>
                          <DownloadIcon />
                        </a>
                      ) : (
                        checkIfBatchFailed(batch.status)
                      )}
                    </S.Button>
                  </S.ButtonContainer>
                </S.ControlSpaceArea>
              </Table.Column>
            </Table.Row>
          ))}

          {!isCompleteLoad && (
            <>
              <InfiniteScroll loadMore={loadMore} />
              <LoadingSpinner width={20} height={20} borderSize={3} />
            </>
          )}
        </Table>
      </S.ContentTable>
    </S.Container>
  );
};

export default LotListTable;
