import { useState } from 'react';
import { OnlineBatchRequestType } from 'types/PaymentSlip';

import { onlinePaymentSlip } from 'services/onlinePaymentSlip';

export const useOnlineBatchCreator = (
  batch: OnlineBatchRequestType,
  archive: File
) => {
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const mutate = async () => {
    try {
      await onlinePaymentSlip().createOnlineBatch(batch, archive);
      setIsSuccess(true);
    } catch (error) {
      setHasError(true);
      setIsSuccess(false);
    }
  };
  return {
    isSuccess,
    hasError,
    mutate,
  };
};
