import * as AlertDialog from '@radix-ui/react-alert-dialog';
import styled, { css, Keyframes, keyframes } from 'styled-components';

const modalToValue = '-50%, -50%, 0';
const modalFromValue = '-50%, 0, 0';
const contentFromValue = '0, -25px, 0';
const contentToValue = '0, 0, 0';

const animationFrame = (isModal: boolean, close: boolean) =>
  close
    ? keyframes`
  0% {
    opacity: 1;
    -webkit-transform: translate3d(${isModal ? modalToValue : contentToValue});
    transform: translate3d(${isModal ? modalToValue : contentToValue});
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(${
      isModal ? modalFromValue : contentFromValue
    });
    transform: translate3d(${isModal ? modalFromValue : contentFromValue});
  }
`
    : keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(${
      isModal ? modalFromValue : contentFromValue
    });
    transform: translate3d(${isModal ? modalFromValue : contentFromValue});
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(${isModal ? modalToValue : contentToValue});
    transform: translate3d(${isModal ? modalToValue : contentToValue});
  }
`;

const openAnimation = (animation: Keyframes) => css`
  animation: ${animation} 0.3s ease-out 0s forwards;
  -webkit-animation: ${animation} 0.3s ease-out 0s forwards;
`;

const closeAnimation = (animation: Keyframes) => css`
  animation: ${animation} 0.3s ease-out 0.05s forwards;
  -webkit-animation: ${animation} 0.3s ease-out 0.05s forwards;
`;

export const Overlay = styled(AlertDialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Modal = styled(AlertDialog.Content)<{ $isClosing: boolean }>`
  ${({ theme, $isClosing }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min(800px, 90%);
    min-height: 361px;
    height: fit-content;
    background-color: #fff;
    border-radius: ${theme.borderRadius.md};
    box-shadow: ${theme.elevation.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    ${$isClosing
      ? closeAnimation(animationFrame(true, $isClosing))
      : openAnimation(animationFrame(true, $isClosing))};
  `}

  @media screen and (max-width: 585px) {
    width: 20rem;
  }
`;

export const Content = styled.div<{ $isClosing: boolean }>`
  ${({ theme, $isClosing }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    padding-left: ${theme.spacing.lg};
    padding-right: ${theme.spacing.lg};
    padding-bottom: ${theme.spacing.lg};
    padding-top: ${theme.spacing.lg};
    opacity: 0;

    ${$isClosing
      ? openAnimation(animationFrame(false, $isClosing))
      : closeAnimation(animationFrame(false, $isClosing))};
  `};
`;

export const Container = styled.div`
  width: 100%;
`;

export const List = styled.div`
  width: 100%;
  margin-top: 28px;
  border: 1px solid hsla(0, 0%, 84%, 1);
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

const columns = css`
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const ListItem = styled.article`
  width: calc(100% - 8px);
  display: grid;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.contextual.table.column};
  font-size: ${({ theme }) => theme.fontSizes.small1};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  padding-left: ${({ theme }) => theme.spacing.sm1};
  align-items: center;
  word-wrap: normal;
  ${columns}

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-block: 2vh;
  }
`;

export const ListItemColumn = styled.section`
  background: ${({ theme }) => theme.surface.main};
  color: ${({ theme }) => theme.texts.thinParagraph};
  font-size: ${({ theme }) => theme.fontSizes.small1};
  padding: 15px 0;
  overflow: hidden;
  word-wrap: break-all;
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;

  &:last-child {
    border-radius: ${({ theme }) => theme.borderRadius.md};
    width: 100%;
  }

  span {
    display: none;
  }

  @media screen and (max-width: 600px) {
    padding: 0.5rem 0.2rem;
    display: flex;
    flex-direction: row;
    span {
      font-size: 12px;
      line-height: 1.5rem;
      color: ${({ theme }) => theme.secondary.dark};
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;

      display: inline;
      font-weight: bold;
      margin-right: 0.5rem;
    }
  }
`;

export const ListBody = styled.div`
  max-height: 350px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 200px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  text-transform: uppercase;
  ${({ theme }) => css`
    color: ${theme.texts.lightSecondary};
  `}
`;

export const ButtonClose = styled.div`
  position: absolute;
  top: 0;
  margin-top: 15px;
  right: 26px;
  background: hsla(0, 0%, 100%, 1);
  border: none;
`;

export const ListHeader = styled.header`
  ${({ theme }) => css`
    display: grid;
    padding: ${theme.spacing.sm1};
    padding-bottom: ${theme.spacing.sm};
  `};
  ${columns}

  @media screen and (max-width: 600px) {
    padding: 1rem 0;
  }
`;

export const ColumnHeader = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.secondary.dark};
  text-transform: uppercase;
  font-weight: 600;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;
