export const typography = {
  family: {
    primary: 'Eina02',
    secondary: 'Dosis',
  },
  lineHeight: {
    xs: '18px',
    sm: '20px',
    default: '22px',
    xl: '24px',
    xl2: '28px',
    xl3: '44px',
    xl4: '64px',
  },
  size: {
    xs: '12px',
    sm: '14px',
    default: '16px',
    xl: '18px',
    xl2: '20px',
    xl3: '32px',
    xl4: '56px',
  },
  weight: {
    regular: 400,
    semibold: 600,
  },
} as const;
