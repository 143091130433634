import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useFindAllCollectors } from 'useCases/deliveryManager/useFindAllCollectors';

import SearchSelect from 'components/shared/forms/SearchSelect';

interface SelectCollectorProps {
  fieldValue: string;
  setFieldValue: (value: string, name?: string) => void;
  fieldTouched?: boolean;
  fieldError?: string;
  id?: string;
  multiple?: boolean;
  required?: boolean;
}

const SelectCollector = ({
  fieldValue,
  setFieldValue,
  fieldTouched,
  fieldError,
  id,
  multiple = false,
  required = true,
}: SelectCollectorProps) => {
  const intl = useIntl();

  const [collectorSearch, setCollectorSearch] = useState('');

  const {
    allCollectors,
    isFetching: isLoading,
    error,
    hasNextPage,
    fetchNextPage,
    refetch: refetchCollector,
  } = useFindAllCollectors(collectorSearch, !collectorSearch);

  const debouncedSearchCollectors = useCallback(
    debounce(refetchCollector, 800),
    []
  );

  const optionsFieldsCoordinators = useMemo(
    () =>
      allCollectors?.map((item) => ({
        label: `(${item.code}) ${item.name}`,
        value: item.code,
      })) ?? [],

    [required, allCollectors, collectorSearch, fieldValue]
  );

  const getNextPage = () => {
    if (!isLoading) {
      fetchNextPage();
    }
  };

  return (
    <SearchSelect
      asyncFilteredOptions
      id={id || ''}
      required={required}
      options={optionsFieldsCoordinators}
      label={intl.formatMessage({ id: 'forms.motoboy' })}
      placeholder={intl.formatMessage({
        id: 'installmentsDebts.select.collector.placeholder',
      })}
      valueLabel={fieldValue}
      setValue={(_label, value) => {
        setFieldValue(String(value));
      }}
      hasErrors={fieldTouched && Boolean(fieldError)}
      errorMessage={fieldError}
      isLoading={isLoading}
      onSearchChange={(value) => {
        setCollectorSearch(String(value));
        debouncedSearchCollectors();
      }}
      cancelSearch={() => {
        setCollectorSearch('');
        setFieldValue('');
      }}
      handlePagination={getNextPage}
      hasPagination={hasNextPage}
      hasErrorPagination={!!error}
      multiple={multiple}
      multipleProps={{
        flexColumn: true,
      }}
    />
  );
};

export default SelectCollector;
