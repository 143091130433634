import { useAlert } from 'contexts';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { ReportInfo } from 'types';
import { downloadArchive } from 'utils-general/downloadArchive';

import { financial } from 'services/financial';

export const useSyntheticalReport = () => {
  const intl = useIntl();
  const { postSyntheticReport, getReport } = financial();
  const { showAlert, setClear } = useAlert();
  const [fileName, setFileName] = useState('');
  const { mutateAsync } = useMutation(
    (body: ReportInfo) => postSyntheticReport(body),
    {
      onSuccess: (values) => {
        setFileName(values?.fileName);
      },
      onError: () => {
        setClear();
        process.nextTick(() => {
          showAlert({
            title: intl.formatMessage({ id: 'report' }),
            type: 'error',
          });
        });
      },
    }
  );

  const { refetch: catchReport } = useQuery(
    ['syntheticalReport', fileName],
    () => getReport(fileName),
    {
      enabled: !!fileName,
      onSuccess: (values) => {
        if (values.processingStatus === 'processing') {
          setTimeout(() => {
            catchReport();
          }, 15000);
          return;
        }
        if (values.processingStatus === 'error') {
          setClear();
          setTimeout(() => {
            showAlert({
              title: intl.formatMessage({ id: 'report' }),
              type: 'error',
            });
          }, 1);
          setFileName('');
          return;
        }
        if (
          values.processingStatus === 'done' &&
          values.metadata?.isReportEmpty
        ) {
          setClear();
          setTimeout(() => {
            showAlert({
              title: intl.formatMessage({ id: 'report.empty' }),
              type: 'warn',
            });
          }, 1);
          setFileName('');
          return;
        }
        setClear();
        setTimeout(() => {
          showAlert({
            type: 'success',
            title: intl.formatMessage({
              id: 'report.generated',
            }),
          });
        }, 1);
        setFileName('');
        downloadArchive(values.url);
      },
      onError: () => {
        setClear();
        setTimeout(() => {
          showAlert({
            title: intl.formatMessage({ id: 'report' }),
            type: 'error',
          });
        }, 2);
      },
    }
  );

  const generateSyntheticalReport = (reportInformation: ReportInfo) => {
    mutateAsync(reportInformation);

    showAlert({
      title: intl.formatMessage({
        id: 'electronicBillingReport.generateReport',
      }),
      type: 'loading',
    });
  };

  return {
    generateSyntheticalReport,
  };
};
