import {
  OrderServiceParams,
  OrdersServicesResponse,
  ResponseWithPagination,
} from 'types/orderServiceSisfuner';

import { getURLWithParams } from 'lib/contextual';

import { SISFUNER_FUNERAL_AGENT } from 'constants/endpoints';

import api from './api';

export const getOrdersService = async (
  params: OrderServiceParams
): Promise<ResponseWithPagination<OrdersServicesResponse>> => {
  const queryParams = Object.fromEntries(
    Object.entries({
      ...params,
    }).filter(([_key, value]) => value !== undefined)
  );

  const url = getURLWithParams(SISFUNER_FUNERAL_AGENT.WORK_ORDERS, {
    [params.queryType]: params.query,
    ...queryParams,
  });

  const result = await api.getRequest(url);
  return result.data;
};
