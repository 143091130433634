import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Text from 'components/shared/core/text';

export const Nav = styled.nav`
  a:last-child {
    color: ${(props) => props.theme.secondary.dark};
  }
`;

export const CustomLink = styled(Link)`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    color: ${theme.texts.breadcrumbInative};
  `}
  text-decoration: none;
  font-weight: 400;
`;

export const Separator = styled(Text)`
  color: ${(props) => props.theme.separatorColor};
`;
