import { useSession } from 'contexts';
import { useEffect, useRef, useState } from 'react';
import { getCookie } from 'utils-general/cookies';

import WebsocketNotification, {
  SocketConfiguration,
} from 'lib/core/WebsocketNotification';

import { Urls } from 'constants/urls';

import { useLocalStorage } from './useLocalStorage';

const SOCKET_NOTIFICATION_URL =
  process.env.REACT_APP_WEB_SOCKET_NOTIFICATION_URL!;
export const useNotificationWebsocket = () => {
  const [retries, setRetries] = useState(0);
  const { getItem } = useLocalStorage();
  const { user } = useSession();

  useEffect(() => {
    if (user.cognitoId === '') {
      setRetries((prev) => prev + 1);
    }
  }, [retries, user]);

  const config: SocketConfiguration = {
    socketUrl: SOCKET_NOTIFICATION_URL,
    host: 'trf-notification-ms-v1',
    userId: getItem('cognitoId'),
    authorization: getCookie('Authorization') ?? '',
  };

  const socket = new WebsocketNotification(config).getInstance();

  const { current } = useRef(socket);

  const subscribe = (event: string, callback: (data: any) => void) => {
    current.off(event);
    current.on(event, callback);
  };
  current.on('connect_error', () => {
    if (
      document.location.pathname === Urls.Login ||
      document.location.pathname.includes(Urls.RecoverPassword.replace('*', ''))
    ) {
      setTimeout(() => {
        current.disconnect();
      }, 1000);
      return;
    }

    if (retries < 3) {
      setTimeout(() => {
        setRetries((r) => r + 1);
        current.connect();
      }, 1000);
    }
  });
  current.on('connect', () => {
    setRetries(0);
  });
  return {
    subscribe,
    current,
    retries,
    loggedUser: config.userId,
  };
};
