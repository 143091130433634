import Skeleton from 'components/shared/newCore/Skeleton';

import * as S from './ToggleButton.styles';

export interface ToogleButtonItem {
  label: string;
  value: string;
}

interface ToggleButtonProps {
  options: ToogleButtonItem[];
  value: string;
  onChange: (value: string) => void;
  isLoading?: boolean;
  disabled?: boolean;
  showNonEditableOption?: boolean;
  activeNonEditable?: boolean;
  nonEditableButtonLabel?: string;
}

const ToggleButton = ({
  options,
  value,
  onChange,
  isLoading,
  disabled,
  showNonEditableOption,
  activeNonEditable,
  nonEditableButtonLabel,
}: ToggleButtonProps) => (
  <S.Container className="toggleButton">
    {options.map((option) =>
      isLoading ? (
        <Skeleton key={option.value} height="77px" width="260px" />
      ) : (
        <S.Button
          key={option.value}
          active={value === option.value}
          onClick={() => onChange(option.value)}
          disabled={disabled}
        >
          {option.label}
        </S.Button>
      )
    )}
    {showNonEditableOption &&
      (isLoading ? (
        <Skeleton height="77px" width="260px" />
      ) : (
        <S.Button active={activeNonEditable} disabled={true}>
          {nonEditableButtonLabel}
        </S.Button>
      ))}
  </S.Container>
);

export default ToggleButton;
