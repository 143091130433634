import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Skeleton from 'components/shared/newCore/Skeleton';
import Button from 'components/shared/newForms/Button';

import { ReactComponent as FaArrowLeft } from 'assets/images/FaArrowLeftBege.svg';

import * as S from './Breadcrumb.styles';

export type BreadcrumbLink = {
  label: string;
  redirectTo: string;
  isLoading?: boolean;
};
export interface BreadcrumbProps {
  path: BreadcrumbLink[];
  isLoading?: boolean;
  light?: boolean;
}

const Breadcrumb = ({ path, light }: BreadcrumbProps) => {
  const intl = useIntl();
  const history = useNavigate();
  return (
    <S.Container>
      <Button
        onClick={() => history(path[path.length - 2].redirectTo)}
        startIcon={<FaArrowLeft />}
        variants="cta"
      >
        <S.BreadcrumbText light={light}>
          {intl.formatMessage({ id: 'breadcrumb.back' })}
        </S.BreadcrumbText>
      </Button>
      <S.BreadcrumbSeparator />
      {path.map((item, index) => (
        <S.BreadcrumbItem key={index}>
          {index !== 0 && (
            <S.BreadcrumbLinkSeparator
              $lastItem={index === path.length - 1}
              light={light}
            >
              /
            </S.BreadcrumbLinkSeparator>
          )}
          {item.isLoading ? (
            <Skeleton height="20px" width="15vw" />
          ) : (
            <S.BreadcrumbLink
              to={item.redirectTo}
              $lastItem={index === path.length - 1}
              light={light}
            >
              {item.label}
            </S.BreadcrumbLink>
          )}
        </S.BreadcrumbItem>
      ))}
    </S.Container>
  );
};

export default Breadcrumb;
