import locales from 'locales';
import { createContext, useContext } from 'react';
import { IntlProvider as ReactIntlnProvider } from 'react-intl';

import { DEFAULT_LANGUAGE } from 'constants/enums';

export type Language = 'pt';

type ContextType = {
  language: Language;
};

export interface IntlProviderProps {
  children?: React.ReactChild | React.ReactChild[];
}

export const IntlContext = createContext<ContextType>({} as ContextType);

export const IntlProvider = (props: IntlProviderProps) => (
  <ReactIntlnProvider
    key={DEFAULT_LANGUAGE}
    messages={locales[DEFAULT_LANGUAGE]}
    locale={DEFAULT_LANGUAGE}
  >
    <IntlContext.Provider
      value={{
        language: DEFAULT_LANGUAGE,
      }}
    >
      {props.children}
    </IntlContext.Provider>
  </ReactIntlnProvider>
);

export const useIntlContext = () => useContext(IntlContext);
