import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useMySchedules } from 'useCases/billings/useMySchedules';

import FindMyScheduleTable from 'components/contextual/findMyScheduleTable';
import HomeContent from 'components/contextual/homeContent';
import { BreadcrumbLink } from 'components/shared';
import AlertDialog from 'components/shared/core/alertDialog';
import Divider from 'components/shared/core/divider';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import Input from 'components/shared/forms/Input';
import Breadcrumb from 'components/shared/newCore/Breadcrumb';
import PaginationButton from 'components/shared/newCore/PaginationButton';

import { Urls } from 'constants/urls';

import { ReactComponent as FaArrowRight } from 'assets/images/ArrowRight.svg';

import * as G from './../../styles/themes/global/mainContainer';
import { useMySchedulesSchema } from './findMySchedules.schema';
import * as S from './styles';

const FindMySchedules = () => {
  const intl = useIntl();
  const validationSchema = useMySchedulesSchema();
  const [page, setPage] = useState(1);
  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
    },
    validationSchema,
    validateOnMount: false,
    onSubmit: () => {
      refetch();
    },
  });
  const { refetch, mySchedules, isLoading, isFetching } = useMySchedules(
    page,
    formik.values.startDate,
    formik.values.endDate
  );
  const breadcrumbLinks: BreadcrumbLink[] = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
        redirectTo: Urls.HomePage,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.chargeAssociate' }),
        redirectTo: Urls.associateChargeMenu,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.reportAndResearchMenu' }),
        redirectTo: Urls.reportAndResearchMenu,
      },
      {
        label: intl.formatMessage({ id: 'breadcrumb.mySchedules' }),
        redirectTo: Urls.findMySchedules,
      },
    ],
    [intl]
  );
  return (
    <HomeContent>
      <Breadcrumb path={breadcrumbLinks} />
      <G.Container>
        <G.Nav>
          <header>
            <G.Title>
              {intl.formatMessage({ id: 'breadcrumb.mySchedules' })}
            </G.Title>
          </header>
        </G.Nav>
        <S.FiltersRow>
          <S.FiltersWrapper>
            <S.FiltersWidth>
              <Input
                required
                id={'startDate'}
                name={'startDate'}
                type={'date'}
                variant={'lighter'}
                label="Data inicial"
                onChange={formik.handleChange}
                value={formik.values.startDate}
                hasErrors={
                  !formik.isValid && formik.errors.startDate !== undefined
                }
                helperText={String(formik.errors.startDate ?? '')}
              />
            </S.FiltersWidth>
            <S.FiltersWidth>
              <Input
                required
                id={'endDate'}
                name={'endDate'}
                type={'date'}
                variant={'lighter'}
                label="Data Final"
                onChange={formik.handleChange}
                value={formik.values.endDate}
                helperText={String(formik.errors.endDate ?? '')}
                hasErrors={
                  !formik.isValid && formik.touched.endDate !== undefined
                }
              />
            </S.FiltersWidth>
          </S.FiltersWrapper>

          <ButtonLink
            onClick={() => {
              formik.handleSubmit();
            }}
            type="button"
            disabled={isLoading || isFetching}
          >
            {intl.formatMessage({ id: 'placeholder.search' })}
            <RoundedIcon>
              <FaArrowRight />
            </RoundedIcon>
          </ButtonLink>
        </S.FiltersRow>
        <Divider />
        <FindMyScheduleTable
          schedules={mySchedules.data}
          isLoading={isLoading || isFetching}
        />
        <PaginationButton
          pageSelected={(page) => setPage(page)}
          totalPages={1}
        />
      </G.Container>
      <AlertDialog delay={3000} />
    </HomeContent>
  );
};

export default FindMySchedules;
