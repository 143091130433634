import styled, { css } from 'styled-components';

import { Grid, Row } from 'components/shared';
import RoundedIcon from 'components/shared/core/roundedIcon';

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    line-height: ${theme.fontSizes.medium2};
    color: ${theme.texts.lightSecondary};
  `}
  font-weight: normal;
`;

export const CustomRoundedIcon = styled(RoundedIcon)`
  padding: ${({ theme }) => theme.spacing.sm};
  height: 56px;
  width: 56px;
`;

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;
export const QueryWrapper = styled(Grid)`
  margin-bottom: 1rem;
  @media screen and (max-width: 1000px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 350px) {
    grid-auto-flow: inherit;
  }
`;

export const StyledSearchFor = styled(Row)`
  margin-top: 5px;
`;

export const StyledButton = styled(Row)`
  margin-top: 1.5rem;
`;
