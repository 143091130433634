import styled, { css } from 'styled-components';

import { device } from 'styles/tokens/devices';

const columns = css`
  grid-template-columns: repeat(9, 1fr);
`;

export const SectionTitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small1};
    color: ${theme.texts.thinParagraph2};
    margin-top: 35px;
    margin-bottom: 35px;
  `}
`;

export const ListHeader = styled.header`
  ${({ theme }) => css`
    display: grid;
    padding: ${theme.spacing.sm1};
    padding-bottom: ${theme.spacing.sm};
  `};
  ${columns}

  @media screen and (max-width: 650px) {
    padding: 1rem 0;
  }
`;

export const ColumnHeader = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.secondary.dark};
  text-transform: uppercase;
  font-weight: 600;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const List = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

export const ListBody = styled.div`
  max-height: 320px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`;

export const ListItem = styled.article`
  width: calc(100% - 8px);
  display: grid;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.contextual.table.column};
  font-size: ${({ theme }) => theme.fontSizes.small1};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  padding-left: ${({ theme }) => theme.spacing.sm1};
  align-items: center;
  word-wrap: normal;
  ${columns}

  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-block: 2vh;
  }
`;

export const ListItemColumn = styled.section`
  background: ${({ theme }) => theme.surface.main};
  color: ${({ theme }) => theme.texts.thinParagraph};
  font-size: ${({ theme }) => theme.fontSizes.small1};
  padding: 25px 0;
  word-wrap: break-all;
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 4px;

  &:last-child {
    border-radius: ${({ theme }) => theme.borderRadius.md};
    width: 100%;
    display: flex;
    justify-content: center;
  }

  span {
    display: none;
  }

  @media screen and (max-width: 650px) {
    padding: 0.5rem 0.2rem;
    display: flex;
    flex-direction: row;
    span {
      font-size: 12px;
      line-height: 1.5rem;
      color: ${({ theme }) => theme.secondary.dark};
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;

      display: inline;
      font-weight: bold;
      margin-right: 0.5rem;
    }
  }
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  @media ${device.tabletL} {
    height: 1.5rem;
    svg {
      height: 100%;
    }
    div {
      div {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }
`;

export const ButtonStyled = styled.div`
  @media ${device.tabletL} {
    display: flex;
    flex-direction: row;
    display: inline-block;
  }
`;

export const InfiniteScrollContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ControlSpaceArea = styled.div`
  text-align: center;
  overflow: hidden;
  span {
    display: none;
  }
  @media ${device.tabletL} {
    text-align: left;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow-x: scroll;
    span {
      color: ${({ theme }) => theme.new.colors.primary.main};
      font-weight: 600;
      display: inline;
      padding-right: 0.2rem;
    }
  }
`;

export const LoadingContainer = styled.section`
  ${({ theme }) => css`
    margin-top: ${theme.new.spacing.xl6};
    display: flex;
    justify-content: center;
  `}
`;
