import {
  ReactNode,
  createContext,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';

import { useNotificationWebsocket } from 'hooks/useNotificationWebsocket';

export interface NotificationProps {
  content: any;
}
interface Notification {
  notifications: any;
  updateMessages: () => void;
  notificationLenght: number;
  isNotificationLoading: boolean;
  current: any;
}
const initialState: Notification = {
  notifications: [],
  updateMessages: () => {},
  notificationLenght: 0,
  isNotificationLoading: false,
  current: false,
};

const NotificationContext = createContext<Notification>(initialState);

interface NotificationProviderProps {
  children: ReactNode | ReactNode[];
}

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const [notifications, setNotifications] = useState<any>([]);
  const [isNotificationLoading] = useState(false);
  const { current, subscribe, loggedUser } = useNotificationWebsocket();

  if (current.disconnected) {
    current.connect();
  }
  subscribe('all-messages', (e) => {
    const messages = e.map(
      ({
        message,
        id,
        status,
      }: {
        message: any;
        id: string;
        status: string;
      }) => ({
        ...message,
        status,
        id,
      })
    );
    setNotifications((prevState: any) => [...prevState, ...messages]);
  });
  subscribe('new-message', ({ message, id, status }) => {
    setNotifications((prevState: any) => [
      { ...message, id, status },
      ...prevState,
    ]);
  });
  useLayoutEffect(() => {
    setNotifications([]);
    current.emit('all-messages', { userId: loggedUser });
  }, []);

  const updateMessages = () => {
    setTimeout(() => {
      setNotifications([]);
      current.emit('all-messages', { userId: loggedUser });
    }, 100);
  };
  return (
    <NotificationContext.Provider
      value={{
        notifications,
        notificationLenght: notifications.length,
        isNotificationLoading,
        current,
        updateMessages,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
