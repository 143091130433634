import React from 'react';

import Tooltip from 'components/shared/newCore/Tooltip';

import * as S from './Field.styles';

export interface FieldProps {
  label: string;
  type: string;
  helperText?: string;
  id?: string;
  name?: string;
  step?: string;
  min?: string;
  max?: string;
  hasBorder?: boolean;
  hasErrors?: boolean;
  variant: S.InputVariant;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value: string | number;
  testid?: string;
  required?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  uppercased?: boolean;
}

const Field = ({
  label,
  type,
  helperText,
  id,
  name,
  step,
  min,
  max,
  hasBorder,
  hasErrors,
  variant,
  disabled,
  startAdornment,
  endAdornment,
  onChange,
  value,
  testid,
  required,
  onBlur,
  placeholder,
  onKeyDown,
  uppercased = true,
}: FieldProps) => (
  <S.Container>
    <S.Label variant={variant} htmlFor={id}>
      {required && <span>*</span>} {label}
    </S.Label>
    <S.InputWrapper
      startAdornment={!!startAdornment}
      endAdornment={!!endAdornment}
      hasBorder={hasBorder}
      variant={variant}
      disabled={disabled}
      error={hasErrors}
    >
      {startAdornment && (
        <S.StartAdornmentWrapper>{startAdornment}</S.StartAdornmentWrapper>
      )}
      {endAdornment && (
        <S.EndAdornmentWrapper>{endAdornment}</S.EndAdornmentWrapper>
      )}
      <S.CustomInput
        step={step}
        hasStartAdornment={!!startAdornment}
        variant={variant}
        id={id ?? name}
        onBlur={onBlur}
        name={name}
        min={min}
        max={max}
        onChange={onChange}
        disabled={disabled}
        type={type}
        value={value}
        data-testid={testid}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        uppercased={uppercased}
      />
    </S.InputWrapper>
    {hasErrors && <Tooltip type="error" message={helperText as string} />}
  </S.Container>
);
export default Field;
