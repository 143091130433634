import styled from 'styled-components';

import { device } from 'styles/tokens/devices';

export const Header = styled.div`
  margin: ${({ theme }) => theme.spacing.md} 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

export const FlexLine = styled.div`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;

  span {
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: 600;
    color: ${({ theme }) => theme.texts.darkSecondary};
  }
`;

export const DownloadTemplateLink = styled.a`
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 0.5rem;
  text-decoration: none;
`;
