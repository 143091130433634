import { useState } from 'react';
import { useQuery } from 'react-query';
import { Branch, MutateListener } from 'types';

import { getBranch } from 'services/branch';

import { useLazyGateway } from 'hooks/useLazyGateway';

import { BRANCHES } from 'constants/endpoints';

export const useFetchBranchById = ({ onSuccess }: MutateListener) => {
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<Branch>();

  const { sendFor, isSuccess, isGatewayLoading, isValid } = useLazyGateway(
    'branch-by-id',
    {
      onSuccess: (response) => {
        setData(response);
        if (onSuccess) {
          onSuccess(response);
        }
      },
      onError: (err: any) => {
        setError(err?.response?.data);
      },
    }
  );

  const publish = async (id: string) => {
    await sendFor(BRANCHES.BY_ID(id));
  };

  return {
    isSuccess,
    isLoading: isGatewayLoading,
    error,
    isReady: isValid,
    publish,
    data,
  };
};

export const useBranch = (branchId: string) =>
  useQuery(['fetch-branch-by-id', branchId], () => getBranch(branchId), {
    enabled: Boolean(branchId),
  });
