import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { CreatePaymentSlipForm } from 'types/PaymentSlip';

import { useGetBanksWithParams } from 'useCases/paymentSlip/useGetBanksWithParams';

import { Grid, Row } from 'components/shared/core/grid';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';

import * as S from './CreateNewBatchNewAssociatesBySheet.styles';

interface CreateNewBatchNewAssociatesBySheetProps {
  formik: FormikProps<CreatePaymentSlipForm>;
  handleFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  withAssociates?: boolean;
}

const CreateNewBatchNewAssociatesBySheet = ({
  formik,
  handleFile,
  withAssociates = false,
}: CreateNewBatchNewAssociatesBySheetProps) => {
  const intl = useIntl();

  const { accounts } = useGetBanksWithParams({});

  return (
    <S.Form>
      <Grid>
        {withAssociates ? (
          <Row span="6">
            <Input
              required
              variant="lighter"
              name="associates"
              id="associatesNew"
              type="text"
              value={formik.values.associates}
              onChange={formik.handleChange}
              label={intl.formatMessage({
                id: 'createPaymentSlip.form.associateList',
              })}
              placeholder={intl.formatMessage({
                id: 'createPaymentSlip.form.associateListHelperText',
              })}
              hasErrors={
                formik.touched.associates && Boolean(formik.errors.associates)
              }
              helperText={formik.errors.associates}
              autoComplete="off"
            />
          </Row>
        ) : (
          <Row span="4">
            <Input
              required
              onChange={(e) => handleFile(e)}
              variant="lighter"
              id="inputFileNewAssociates"
              type="file"
              accept=".xlsx"
              inputFileLabel={intl.formatMessage({
                id: 'createPaymentSlip.form.label.buttonFile',
              })}
              label={intl.formatMessage({
                id: 'createPaymentSlip.form.label.file',
              })}
              hasErrors={
                formik.touched.archive && Boolean(formik.errors.archive)
              }
              helperText={formik.errors.archive}
            />
          </Row>
        )}
        <Row span="4" style={{ marginTop: '0.4rem' }}>
          <CustomSelect
            required
            id="bankAccountIdBySheet"
            inputName="bankAccountId"
            inputValue={formik.values.bankAccountId}
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.selectBank',
            })}
            placeholder={intl.formatMessage({
              id: 'createPaymentSlip.form.placeholder.selectBank',
            })}
            options={accounts}
            setValue={(_, v) => formik.setFieldValue('bankAccountId', v)}
            isBordered
            hasErrors={
              formik.touched.bankAccountId &&
              Boolean(formik.errors.bankAccountId)
            }
            helperText={formik.errors.bankAccountId}
          />
        </Row>
      </Grid>
    </S.Form>
  );
};

export default CreateNewBatchNewAssociatesBySheet;
