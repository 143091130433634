import { ReactNode } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { rules } from 'lib/contextual/Autorization';
import { hasSession, retrieveUserSession, Role } from 'lib/contextual/Session';

import { Permissions } from 'constants/enums';
import { Urls } from 'constants/urls';

interface PrivateRouteProps {
  children: ReactNode;
}

const permissions = {
  [Urls.reversal]: {
    permissionRedirectUrl: Urls.HomePage,
    permissionRequired: Permissions.REVERSAL_ACCESS,
  },
  [Urls.UserForm]: {
    permissionRedirectUrl: Urls.HomePage,
    permissionRequired: Permissions.USER_MANAGER_ACCESS,
  },
  [Urls.UserList]: {
    permissionRedirectUrl: Urls.HomePage,
    permissionRequired: Permissions.USER_MANAGER_ACCESS,
  },
  [Urls.CloseBox]: {
    permissionRedirectUrl: Urls.CashFlows,
    permissionRequired: Permissions.CLOSE_BOX_ACCESS,
  },
  [Urls.OpenBox]: {
    permissionRedirectUrl: Urls.CashFlows,
    permissionRequired: Permissions.OPEN_BOX_ACCESS,
  },
};

const canAccess = (permission: Permissions, roles: Role[]): boolean =>
  roles.some((role) => rules[permission](role));

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { pathname } = useLocation();
  const params = useParams();
  let actualRoute = pathname;

  for (const item of Object.entries(params)) {
    const key = item[0] || '';
    const value = item[1] || '';
    actualRoute = pathname.replace(value, `:${key}`);
  }

  const hasPermissionRule = Object.keys(permissions).find(
    (url) => url === actualRoute
  );
  const permissionRequired = hasPermissionRule
    ? permissions[hasPermissionRule].permissionRequired
    : undefined;
  const permissionRedirectUrl = hasPermissionRule
    ? permissions[hasPermissionRule].permissionRedirectUrl
    : undefined;

  if (!hasSession()) return <Navigate to={Urls.Login} />;

  const { roles } = retrieveUserSession();
  if (permissionRequired && !canAccess(permissionRequired, roles)) {
    return <Navigate to={permissionRedirectUrl ?? Urls.Login} />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
