import { useState } from 'react';
import { Machine, MutateListener } from 'types';

import { useLazyGateway } from 'hooks/useLazyGateway';

import { MACHINE_TERMINAL } from 'constants/endpoints';

export const useFetchMachines = ({ onSuccess }: MutateListener) => {
  const [error, setError] = useState<any>({});

  const { sendFor, isGatewayLoading, isValid } = useLazyGateway(
    'machines-all',
    {
      onSuccess: (response) => {
        onSuccess(response as Machine[]);
      },
      onError: (err: any) => {
        setError(err?.response?.data);
      },
    }
  );

  const publish = async (id: string) => {
    await sendFor(`${MACHINE_TERMINAL.ALL}/${id}`);
  };

  return {
    isLoading: isGatewayLoading,
    publish,
    isReady: isValid,
    error,
  };
};
