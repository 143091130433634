import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useBatchInformations, useDownloadPdf } from 'useCases/paymentSlip';

import HomeContent from 'components/contextual/homeContent';
import { Grid, Row } from 'components/shared';
import AlertDialog from 'components/shared/core/alertDialog';
import Divider from 'components/shared/core/divider';
import LoadingSpinner from 'components/shared/core/loadingSpinner';
import ButtonLink from 'components/shared/forms/ButtonLink';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';
import Textarea from 'components/shared/forms/Textarea';
import Breadcrumb from 'components/shared/newCore/Breadcrumb';
import Button from 'components/shared/newForms/Button';

import { ReactComponent as GlassSearch } from 'assets/images/MagnifyingGlass.svg';

import { useConstants } from './contants';
import * as S from './styles';
import { InputBatchCode } from './styles';
import { DocumentsOptionsType, searchForOptionsType } from './types';
import { usePrintPaymentSlipValidation } from './usePrintPaymentSlipValidation';

const PrintPaymentSlip = () => {
  const intl = useIntl();
  const [cardSelected, setCardSelected] = useState<string>();
  const { billPrinting, documents, model, searchFor, breadcrumbLinks } =
    useConstants();
  const validationSchema = usePrintPaymentSlipValidation();
  const formik = useFormik({
    initialValues: {
      documents: '',
      documentModel: '',
      printing: '',
      searchFor: '',
      registrations: '',
      batchCode: '',
    },
    validationSchema,
    onSubmit: (values) => {
      downloadBatch({
        documentModel: values.documentModel,
        printType: values.printing,
        searchType: values.searchFor,
        batch: cardSelected,
        subscriptions: values.registrations.replace(/\s/g, '').split(','),
      });
    },
  });
  const { batchInfos, fetchBatchInformation, fetchBatchInformationLoading } =
    useBatchInformations(formik.values.batchCode);
  const { downloadBatch, isLoading } = useDownloadPdf(formik.values.documents);
  const documentValue = formik.values.documents;
  useEffect(() => {
    if (documentValue !== DocumentsOptionsType.Carnet) {
      formik.setFieldValue('documentModel', '');
      formik.setFieldValue('printing', '');
      formik.setFieldValue('searchFor', '');
    } else {
      formik.setFieldValue('searchFor', '');
    }
  }, [documentValue]);
  const whenShowBatchCards = batchInfos && batchInfos.length > 0;

  return (
    <HomeContent>
      <Breadcrumb path={breadcrumbLinks} />
      <S.Title>
        {intl.formatMessage({
          id: 'paymentSlip.menu.print',
        })}
      </S.Title>
      <S.Form onSubmit={formik.handleSubmit}>
        <Grid>
          <Row span="3">
            <CustomSelect
              label={intl.formatMessage({
                id: 'paymentSlip.print.label.documents',
              })}
              required
              isBordered
              inputName="documents"
              id="documents"
              inputValue={formik.values.documents}
              options={documents}
              setValue={formik.setFieldValue}
              hasErrors={
                formik.touched.documents && Boolean(formik.errors.documents)
              }
              helperText={String(formik.errors.documents)}
            />
          </Row>
          <Row span="3">
            <CustomSelect
              label={intl.formatMessage({
                id: 'paymentSlip.print.label.model',
              })}
              required
              isBordered
              inputName="documentModel"
              id="documentModel"
              inputValue={formik.values.documentModel}
              options={model}
              disabled={formik.values.documents !== DocumentsOptionsType.Carnet}
              setValue={formik.setFieldValue}
              hasErrors={
                formik.touched.documentModel &&
                Boolean(formik.errors.documentModel)
              }
              helperText={String(formik.errors.documentModel)}
            />
          </Row>
          <Row span="3">
            <CustomSelect
              required
              isBordered
              label={intl.formatMessage({
                id: 'paymentSlip.print.label.billPrinting',
              })}
              inputValue={formik.values.printing}
              inputName="printing"
              id="printing"
              options={billPrinting}
              disabled={formik.values.documents !== DocumentsOptionsType.Carnet}
              setValue={formik.setFieldValue}
              hasErrors={
                formik.touched.printing && Boolean(formik.errors.printing)
              }
              helperText={String(formik.errors.printing)}
            />
          </Row>
          <Row span="3">
            <CustomSelect
              required
              isBordered
              label={intl.formatMessage({
                id: 'paymentSlip.print.label.searchFor',
              })}
              inputValue={formik.values.searchFor}
              inputName="searchFor"
              id="searchFor"
              options={searchFor}
              setValue={formik.setFieldValue}
              onBlur={() => setCardSelected('')}
              hasErrors={
                formik.touched.searchFor && Boolean(formik.errors.searchFor)
              }
              helperText={String(formik.errors.searchFor)}
            />
          </Row>
        </Grid>
        <Divider />
        {formik.values.searchFor === searchForOptionsType.Registrations && (
          <Grid>
            <Row span="6">
              <S.InputContainer>
                <S.Advise>
                  {intl.formatMessage({
                    id: 'paymentSlip.print.textArea.advice',
                  })}
                </S.Advise>
                <Textarea
                  rows={10}
                  required
                  label={intl.formatMessage({
                    id: 'paymentSlip.print.label.title',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'paymentSlip.print.placeholder',
                  })}
                  variant="lighter"
                  type="text"
                  name="registrations"
                  id="registrations"
                  value={formik.values.registrations}
                  onChange={formik.handleChange}
                  hasErrors={
                    formik.touched.registrations &&
                    Boolean(formik.errors.registrations)
                  }
                  helperText={String(formik.errors.registrations)}
                />
              </S.InputContainer>
            </Row>
          </Grid>
        )}

        {formik.values.searchFor === searchForOptionsType.BatchCode && (
          <Grid>
            <Row span="5">
              <InputBatchCode>
                <Input
                  required
                  value={formik.values.batchCode}
                  name="batchCode"
                  id="batchCode"
                  variant="lighter"
                  onChange={formik.handleChange}
                  label={intl.formatMessage({
                    id: 'paymentSlip.print.label.input',
                  })}
                  hasErrors={
                    formik.touched.batchCode && Boolean(formik.errors.batchCode)
                  }
                  helperText={String(formik.errors.batchCode)}
                />
              </InputBatchCode>
            </Row>
            <Row>
              <ButtonLink
                disabled={
                  formik.values.batchCode === '' || fetchBatchInformationLoading
                }
                style={{ marginTop: '1.5rem' }}
                type="button"
                onClick={() => fetchBatchInformation()}
                variant="lighter"
              >
                <S.CustomRoundedIcon>
                  {fetchBatchInformationLoading ? (
                    <LoadingSpinner width={20} height={20} borderSize={3} />
                  ) : (
                    <GlassSearch />
                  )}
                </S.CustomRoundedIcon>
              </ButtonLink>
            </Row>
          </Grid>
        )}
        {whenShowBatchCards &&
          formik.values.searchFor === searchForOptionsType.BatchCode &&
          batchInfos.map((info) => (
            <S.BatchWrapper key={info.batchId}>
              <S.BatchCard
                cardSelected={info.batch === cardSelected}
                onClick={() => setCardSelected(info.batch)}
              >
                <S.InscriptionTablePrint>
                  <span>
                    {intl.formatMessage({
                      id: 'paymentSlip.print.label.searchForBatch',
                    })}
                  </span>
                  {info.batch}
                </S.InscriptionTablePrint>
                <S.InscriptionTablePrint>
                  <span>
                    {intl.formatMessage({
                      id: 'paymentSlip.print.label.searchForRegistrations',
                    })}
                  </span>
                  {info.count}{' '}
                  {intl.formatMessage({
                    id: 'paymentSlip.print.batchCardText',
                  })}
                </S.InscriptionTablePrint>
              </S.BatchCard>
            </S.BatchWrapper>
          ))}
        {batchInfos &&
          batchInfos?.length === 0 &&
          formik.values.searchFor === searchForOptionsType.BatchCode && (
            <S.MessageNotFoundBatch>
              {intl.formatMessage({
                id: 'paymentSlip.print.batchCardText.error.notFoundBatch',
              })}
            </S.MessageNotFoundBatch>
          )}
        {formik.values.searchFor && (
          <S.ButtonContainer>
            <Button disabled={isLoading} type="submit">
              {intl.formatMessage({ id: 'paymentSlip.print.downloadPDF' })}
            </Button>
          </S.ButtonContainer>
        )}
      </S.Form>
      <AlertDialog delay={isLoading ? 20000 : 3000} />
    </HomeContent>
  );
};

export default PrintPaymentSlip;
