import { formatterIdInput } from 'lib/core/FormatterIdInput';

import * as S from './CheckboxInput.styles';

export type CheckboxInputProps = {
  id?: string;
  label?: string;
  checked: boolean;
  value?: any;
  onChange: (value: any) => void;
  disabled?: boolean;
  dark?: boolean;
  name?: string;
  paySlipPage?: boolean;
};

const CheckboxInput = ({
  id,
  label,
  checked,
  value,
  onChange,
  disabled,
  dark,
  name,
  paySlipPage,
}: CheckboxInputProps) => (
  <S.Container paySlipPage={paySlipPage} dark={dark}>
    <input
      id={id || formatterIdInput(label as string)}
      type="checkbox"
      checked={checked}
      onChange={() => onChange(value)}
      disabled={disabled}
      name={name}
    />
    <label
      aria-checked="false"
      role="checkbox"
      htmlFor={id || formatterIdInput(label as string)}
    >
      {label}
    </label>
  </S.Container>
);

export default CheckboxInput;
