import styled from 'styled-components';

import { Grid } from 'components/shared';

import { device } from 'styles/tokens/devices';

export const Header = styled(Grid)`
  margin: ${({ theme }) => theme.spacing.md} 0;
  align-items: flex-end;

  @media screen and (max-width: 820px) {
    align-items: normal;
  }
`;

export const MobileControl = styled.div`
  span {
    display: none;
  }
  @media ${device.tabletL} {
    font-size: 1.5rem;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    span {
      color: ${({ theme }) => theme.new.colors.primary.main};
      font-weight: 600;
      display: inline;
      padding-right: 0.2rem;
    }
  }
`;

export const FieldPosition = styled.div`
  margin-top: 16px;
`;

export const Title = styled.div`
  margin: ${({ theme }) => theme.spacing.md} 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;

  span {
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: 600;
    color: ${({ theme }) => theme.texts.darkSecondary};
  }
`;
