import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const UseCurrentCampaignValidation = () => {
  const intl = useIntl();

  return Yup.object().shape({
    searchFor: Yup.string().required(intl.formatMessage({ id: 'emptyField' })),
    subscription: Yup.string().when('searchFor', (searchFor) => {
      if (searchFor === 'subscription') {
        return Yup.string().required(intl.formatMessage({ id: 'emptyField' }));
      } else {
        return Yup.string();
      }
    }),
    associateName: Yup.string().when('searchFor', (searchFor) => {
      if (searchFor === 'associateName') {
        return Yup.string().required(intl.formatMessage({ id: 'emptyField' }));
      } else {
        return Yup.string();
      }
    }),
    userId: Yup.string().when('searchFor', (searchFor) => {
      if (searchFor === 'userId') {
        return Yup.string().required(intl.formatMessage({ id: 'emptyField' }));
      } else {
        return Yup.string();
      }
    }),
  });
};
