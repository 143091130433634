import { useInfiniteQuery } from 'react-query';

import { getURLWithParams } from 'lib/contextual/Url';

import { EMPLOYERS_MANAGER } from 'constants/endpoints';

import api from './api';

type MetaPagination = {
  currentPage: number;
  itemCount: number;
  nextPage: number;
  totalItems: number;
  totalPages: number;
};

type statusOptions = 'A' | 'I' | '';

export const getParams = (obj: Record<string, unknown>) => {
  const params: Record<string, unknown> = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      params[key] = obj[key];
    }
  });
  return params;
};

type coordinatorType = {
  id: string;
  name: string;
  cpf?: string | null;
  email?: string | null;
  status: 'ative' | 'inactive';
  manager?: string;
};

export const getOneCoordinator = async (
  id: string
): Promise<coordinatorType> => {
  const { data } = await api.getRequest(EMPLOYERS_MANAGER.COORDINATORS_ONE(id));
  return data;
};

export const getPaginatedCoordinators = async ({
  pageParam = 1,
  limit = 100,
  status = 'A' as statusOptions,
  name = '',
  cpf = '',
  email = '',
}): Promise<{
  meta: MetaPagination;
  data: Array<coordinatorType>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(EMPLOYERS_MANAGER.COORDINATORS_PAGINATED, {
      ...getParams({
        page: pageParam,
        limit,
        status,
        name,
        cpf,
        email,
      }),
    })
  );
  return data;
};

export const useFindAllCoordinators = (name: string, whenEnabled = true) =>
  useInfiniteQuery(
    ['findAllCoordinators', name],
    ({ pageParam }) => getPaginatedCoordinators({ pageParam, name }),
    {
      enabled: whenEnabled,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage >= lastPage.meta.totalPages
          ? undefined
          : lastPage.meta.currentPage + 1,
    }
  );

type sellerType = {
  id: string;
  name: string;
  cpf?: string | null;
  email?: string | null;
  status: 'ative' | 'inactive';
  coordinator?: string;
  supervisor?: string;
};

export const getOneSeller = async (id: string): Promise<sellerType> => {
  const response = await api.getRequest(EMPLOYERS_MANAGER.SELLERS_ONE(id));
  return response?.data ?? {};
};

export const getPaginatedSellers = async ({
  pageParam = 1,
  limit = 100,
  status = 'A' as statusOptions,
  name = '',
  cpf = '',
  email = '',
}): Promise<{
  meta: MetaPagination;
  data: Array<sellerType>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(EMPLOYERS_MANAGER.SELLERS_PAGINATED, {
      ...getParams({
        page: pageParam,
        limit,
        status,
        name,
        cpf,
        email,
      }),
    })
  );
  return data;
};

export const useFindAllSellers = (name: string, whenEnabled = true) =>
  useInfiniteQuery(
    ['findAllSellers', name],
    ({ pageParam }) => getPaginatedSellers({ pageParam, name }),
    {
      enabled: whenEnabled,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage >= lastPage.meta.totalPages
          ? undefined
          : lastPage.meta.currentPage + 1,
    }
  );

type supervisorType = {
  id: string;
  name: string;
  cpf?: string | null;
  email?: string | null;
  status: 'ative' | 'inactive';
  coordinator?: string;
  manager?: string;
};

export const getOneSupervisor = async (id: string): Promise<supervisorType> => {
  const response = await api.getRequest(EMPLOYERS_MANAGER.SUPERVISORS_ONE(id));
  return response?.data ?? {};
};

export const getPaginatedSupervisor = async ({
  pageParam = 1,
  limit = 100,
  status = 'A' as statusOptions,
  name = '',
  cpf = '',
  email = '',
}): Promise<{
  meta: MetaPagination;
  data: Array<supervisorType>;
}> => {
  const { data } = await api.getRequest(
    getURLWithParams(EMPLOYERS_MANAGER.SUPERVISORS_PAGINATED, {
      ...getParams({
        page: pageParam,
        limit,
        status,
        name,
        cpf,
        email,
      }),
    })
  );
  return data;
};

export const useFindAllSupervisors = (name: string, whenEnabled = true) =>
  useInfiniteQuery(
    ['findAllSupervisors', name],
    ({ pageParam }) => getPaginatedSupervisor({ pageParam, name }),
    {
      enabled: whenEnabled,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage >= lastPage.meta.totalPages
          ? undefined
          : lastPage.meta.currentPage + 1,
    }
  );
