import { IntlShape } from 'react-intl';

import { BreadcrumbLink } from 'components/shared/newCore/Breadcrumb';

import { Urls } from 'constants/urls';

export const clearFilterList = (list: string) =>
  list
    .split(',')
    .map((item) => item.trim())
    .filter((item) => item !== '');

export const getCurrentMonthFirstDay = () => {
  const currentDate = new Date();
  const currentMonthFirstDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  return currentMonthFirstDay.toISOString().split('T')[0];
};

export const getCurrentMonthLastDay = () => {
  const currentDate = new Date();

  const currentMonthLastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  return currentMonthLastDay.toISOString().split('T')[0];
};

export const createBreadcumbLinks = (intl: IntlShape): BreadcrumbLink[] => [
  {
    label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
    redirectTo: Urls.HomePage,
  },
  {
    label: intl.formatMessage({ id: 'breadcrumb.paymentSlipMenu' }),
    redirectTo: Urls.menuPaymentSlip,
  },
  {
    label: intl.formatMessage({ id: 'breadcrumb.paymentSlip' }),
    redirectTo: Urls.paymentSlip,
  },
  {
    label: intl.formatMessage({
      id: 'createPaymentSlip.breadcrumb.newPaymentSlip',
    }),
    redirectTo: Urls.createPaymentSlip,
  },
];
