import { useAlert } from 'contexts';
import { useIntl } from 'react-intl';
import { ScheduleOrderProps } from 'types/BillingAtHome';
import { downloadWithLink } from 'utils-general/downlodFileWithLink';

import { downloadReport } from 'services/billing_at_home_service';

export const useDownloadCollectorReport = () => {
  const intl = useIntl();
  const { showAlert, setClear } = useAlert();

  const downloadCollectorReport = async (
    values: Pick<
      ScheduleOrderProps,
      'afterOrEqualDueDate' | 'beforeOrEqualDueDate' | 'agents' | 'payedFee'
    >
  ) => {
    try {
      showAlert({
        type: 'loading',
        title: intl.formatMessage({
          id: 'deliveryManagement.manageDelivery.pdf.fetchingData',
        }),
      });

      const reportResponse = await downloadReport(
        {
          ...values,
          status: 'closed',
          orderBy: 'payment',
          reportType: 'pdf',
        },
        'billing'
      );
      if (reportResponse.processingStatus === 'done') {
        downloadWithLink(reportResponse.url);
        setClear();
      } else {
        setClear();
        process.nextTick(() => {
          showAlert({
            type: 'warn',
            title: intl.formatMessage({
              id: 'deliveryManagement.manageDelivery.pdf.emptyReport',
            }),
          });
        });
      }
    } catch (e) {
      throw e;
    }
  };

  return {
    downloadCollectorReport,
  };
};
