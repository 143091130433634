import { createContext, ReactNode, useContext } from 'react';

import { rules } from 'lib/contextual/Autorization';

import { useSession } from 'contexts/AuthContext';

import { Permissions } from 'constants/enums';

interface AbacContextProps {
  userHasPermission: (permision: Permissions, payload?: any) => boolean;
}

const AbacContext = createContext<AbacContextProps>({} as AbacContextProps);

interface AbacProviderProps {
  children?: ReactNode;
}

export const AbacProvider = ({ children }: AbacProviderProps) => {
  const {
    user: { roles = [] },
  } = useSession();

  const userHasPermission = (
    permission: Permissions,
    payload?: any
  ): boolean => {
    const result = roles.some((role) => rules[permission](role, payload));
    return result;
  };

  return (
    <AbacContext.Provider
      value={{
        userHasPermission,
      }}
    >
      {children}
    </AbacContext.Provider>
  );
};

export const useAbac = () => useContext(AbacContext);

interface AllowedWhenProps {
  noData?: ReactNode;
  permission: Permissions;
  children: ReactNode;
}

export const AllowedWhen = ({
  noData,
  children,
  permission,
}: AllowedWhenProps) => {
  const { userHasPermission } = useAbac();

  if (userHasPermission(permission)) return <>{children}</>;

  return <>{noData}</>;
};
