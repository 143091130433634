import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import * as S from './navigationCard.styles';

interface NavigationCardProps {
  redirectUrl: string;
  icon: string;
  alt: string;
  contentText: string;
}

const NavigationCard = ({
  redirectUrl,
  icon,
  alt,
  contentText,
}: NavigationCardProps) => {
  const history = useNavigate();
  const intl = useIntl();

  return (
    <S.CustomPaper
      onClick={() => {
        history(redirectUrl);
      }}
    >
      <S.Icon src={icon} alt={alt} />
      <S.Text as="span">{intl.formatMessage({ id: contentText })}</S.Text>
    </S.CustomPaper>
  );
};

export default NavigationCard;
