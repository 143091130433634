import { UseQueryOptions, useMutation } from 'react-query';
import { EquipmentsFormProps } from 'types/Orthopedic';

import { orthopedicLoan } from 'services/orthopedic';

export const useUpdateOrthopedicEquipment = (options: UseQueryOptions) => {
  const { updateOrthopedicEquipment } = orthopedicLoan();
  return useMutation({
    mutationFn: (data: EquipmentsFormProps) => updateOrthopedicEquipment(data),
  });
};
