import * as S from './CustomState.styles';

interface CustomFeedbackProps {
  iconUrl: string;
  title: string;
  description: string;
}

const CustomFeedback = ({
  iconUrl,
  title,
  description,
}: CustomFeedbackProps) => (
  <S.Container>
    <img src={iconUrl} alt="Custom Feedback icon" />
    <section>
      <S.Title>{title}</S.Title>
      <S.Subtitle>{description}</S.Subtitle>
    </section>
  </S.Container>
);

export default CustomFeedback;
