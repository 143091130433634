export default function FormatMonetaryValueFromMask(value: number | string) {
  if (String(value).length === 1) {
    return Number('0.0' + value);
  }

  return Number(
    String(value)?.substr(0, String(value)?.length - 2) +
      '.' +
      String(value)?.substr(-2)
  );
}
