import { AxiosError } from 'axios';
import { useAlert } from 'contexts';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { filterReport } from 'types/ReportAndInquiriesTypes';

import { currentCampaign } from 'services/billings';

export const useCurrentCampaign = (
  page: number,
  filter: filterReport,
  filterValue?: string
) => {
  const { showAlert } = useAlert();
  const intl = useIntl();
  const { data: currentCampaignData, ...props } = useQuery(
    ['currentCampaign', page],
    () => currentCampaign(page, filter, filterValue),
    {
      staleTime: 1000 * 60 * 5,
      keepPreviousData: true,
      onError: (e: AxiosError) => {
        if (e.response?.status === 404) {
          showAlert({
            type: 'error',
            title: intl.formatMessage({ id: 'notFound' }),
          });
          return;
        }
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'serverError',
          }),
        });
      },
    }
  );

  return {
    currentCampaignData: currentCampaignData ?? [],
    totalPages: currentCampaignData?.meta?.totalPages ?? 0,
    ...props,
  };
};
