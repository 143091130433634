import { useIntl } from 'react-intl';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { EquipmentResponse } from 'types/EquipmentMovement';
import { EquipmentsProps, MetaPagination } from 'types/Orthopedic';

import { Grid, Row } from 'components/shared';
import InfiniteScroll from 'components/shared/core/infiniteScroll';
import LoadingSpinner from 'components/shared/core/loadingSpinner';
import Button from 'components/shared/newForms/Button';

import { ReactComponent as Edit } from 'assets/images/PencilLine.svg';
import { ReactComponent as Delete } from 'assets/images/trashBlue.svg';

import * as S from './orthopedicEquipmentTable';

interface OrthopedicEquipmentTableProps {
  equipments: EquipmentsProps[];
  handleChangeModalEquipment: (
    equipment: EquipmentsProps,
    type: string
  ) => void;
  handleOpenConfirmDialog: (id: number) => void;
  hasNextPageEquipment?: boolean;
  fetchNextPageEquipment: (options?: FetchNextPageOptions) => Promise<
    InfiniteQueryObserverResult<
      {
        meta: MetaPagination;
        items: EquipmentResponse[];
      },
      unknown
    >
  >;
  refreshField: number;
  isLoading: boolean;
}

const OrthopedicEquipmentTable = (props: OrthopedicEquipmentTableProps) => {
  const {
    equipments,
    handleChangeModalEquipment,
    handleOpenConfirmDialog,
    hasNextPageEquipment,
    fetchNextPageEquipment,

    isLoading,
  } = props;
  const intl = useIntl();

  const formatValueEquipment = (value: number) => {
    const formattedValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formattedValue;
  };

  return (
    <>
      <Row span="12">
        <S.SectionTitle>
          {intl.formatMessage({
            id: 'orthopedicEquipment.section.equipmentData',
          })}
        </S.SectionTitle>
      </Row>

      <Grid>
        <Row span="12">
          <S.ListHeader>
            <S.ColumnHeader>
              {intl.formatMessage({
                id: 'orthopedicEquipment.table.description.title',
              })}
            </S.ColumnHeader>
            <S.ColumnHeader>
              {intl.formatMessage({
                id: 'orthopedicEquipment.table.assetNumber.title',
              })}
            </S.ColumnHeader>
            <S.ColumnHeader>
              {intl.formatMessage({
                id: 'orthopedicEquipment.table.comment.title',
              })}
            </S.ColumnHeader>
            <S.ColumnHeader>
              {intl.formatMessage({
                id: 'orthopedicEquipment.table.branch.title',
              })}
            </S.ColumnHeader>
            <S.ColumnHeader>
              {intl.formatMessage({
                id: 'orthopedicEquipment.table.value.title',
              })}
            </S.ColumnHeader>
            <S.ColumnHeader>
              {intl.formatMessage({
                id: 'orthopedicEquipment.table.acquisitionValue.title',
              })}
            </S.ColumnHeader>
            <S.ColumnHeader>
              {intl.formatMessage({
                id: 'orthopedicEquipment.table.status.title',
              })}
            </S.ColumnHeader>
            <S.ColumnHeader>
              {intl.formatMessage({
                id: 'orthopedicEquipment.table.toEdit.title',
              })}
            </S.ColumnHeader>
            <S.ColumnHeader>
              {intl.formatMessage({
                id: 'orthopedicEquipment.table.delete.title',
              })}
            </S.ColumnHeader>
          </S.ListHeader>
        </Row>
      </Grid>
      <S.List>
        <S.ListBody>
          {equipments.map((item, index) => (
            <Row span="12" key={index + '-' + item.id}>
              <S.ListItem id="ListItem">
                <S.ListItemColumn>
                  <span>
                    {intl.formatMessage({
                      id: 'orthopedicEquipment.table.description.title',
                    })}
                    :
                  </span>{' '}
                  {item.description}
                </S.ListItemColumn>
                <S.ListItemColumn>
                  <span>
                    {intl.formatMessage({
                      id: 'orthopedicEquipment.table.assetNumber.title',
                    })}
                    :
                  </span>{' '}
                  {item.id}
                </S.ListItemColumn>
                <S.ListItemColumn>
                  <span>
                    {intl.formatMessage({
                      id: 'orthopedicEquipment.table.comment.title',
                    })}
                    :
                  </span>{' '}
                  {item.comment}
                </S.ListItemColumn>
                <S.ListItemColumn>
                  <span>
                    {intl.formatMessage({
                      id: 'orthopedicEquipment.table.branch.title',
                    })}
                    :
                  </span>{' '}
                  {item.branch.name}
                </S.ListItemColumn>
                <S.ListItemColumn>
                  <span>
                    {intl.formatMessage({
                      id: 'orthopedicEquipment.table.value.title',
                    })}
                    :
                  </span>{' '}
                  {formatValueEquipment(Number(item.value!))}
                </S.ListItemColumn>
                <S.ListItemColumn>
                  <span>
                    {intl.formatMessage({
                      id: 'orthopedicEquipment.table.acquisitionValue.title',
                    })}
                    :
                  </span>{' '}
                  {formatValueEquipment(Number(item.aquisitionValue!))}
                </S.ListItemColumn>
                <S.ListItemColumn>
                  <span>
                    {intl.formatMessage({
                      id: 'orthopedicEquipment.table.status.title',
                    })}
                    :
                  </span>{' '}
                  {item?.status
                    ? intl.formatMessage({
                        id: `orthopedicEquipment.status.${item?.status}`,
                      })
                    : '-'}
                </S.ListItemColumn>
                <S.ListItemColumn>
                  <span>
                    {intl.formatMessage({
                      id: 'orthopedicEquipment.table.toEdit.title',
                    })}
                    :
                  </span>{' '}
                  <S.ButtonStyled>
                    <Button
                      variants="ghost"
                      disabled={item.status !== 'available'}
                      onClick={() => handleChangeModalEquipment(item, 'edit')}
                    >
                      <Edit />
                    </Button>
                  </S.ButtonStyled>
                </S.ListItemColumn>
                <S.ListItemColumn>
                  <span>
                    {intl.formatMessage({
                      id: 'orthopedicEquipment.table.delete.title',
                    })}
                    :
                  </span>{' '}
                  <S.ButtonStyled>
                    <Button
                      variants="ghost"
                      disabled={item.status !== 'available'}
                      onClick={() => handleOpenConfirmDialog(item.id!)}
                    >
                      <Delete />
                    </Button>
                  </S.ButtonStyled>
                </S.ListItemColumn>
              </S.ListItem>
            </Row>
          ))}
          {!isLoading && hasNextPageEquipment && (
            <S.InfiniteScrollContainer>
              <InfiniteScroll
                loadMore={fetchNextPageEquipment}
                refresh={equipments?.length}
              />
              <LoadingSpinner width={20} height={20} borderSize={3} />
            </S.InfiniteScrollContainer>
          )}
        </S.ListBody>
      </S.List>
    </>
  );
};

export default OrthopedicEquipmentTable;
