import { FormikErrors, FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { BankPercentageTypes, CreatePaymentSlipForm } from 'types/PaymentSlip';

import { useGetBanks } from 'useCases/paymentSlip';

import { Grid, Row } from 'components/shared/core/grid';
import RoundedIcon from 'components/shared/core/roundedIcon';
import ButtonLink from 'components/shared/forms/ButtonLink';
import CheckboxInput from 'components/shared/forms/CheckboxInput';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';

import { ReactComponent as PlusIcon } from 'assets/images/icon-plus.svg';
import { ReactComponent as TrashBlue } from 'assets/images/trashBlue.svg';

import * as S from './CreateNewBatchReadjustments.styles';

interface CreateNewBatchReadjustmentsProps {
  formik: FormikProps<CreatePaymentSlipForm>;
  handleFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CreateNewBatchReadjustments = ({
  formik,
  handleFile,
}: CreateNewBatchReadjustmentsProps) => {
  const intl = useIntl();

  const { accounts } = useGetBanks();

  const handleAddRow = () => {
    formik.setValues({
      ...formik.values,
      bankPercentages: [
        ...formik.values.bankPercentages,
        {
          bankAccountId: '',
          percentage: 0,
          acceptsNordeste: false,
          enablesApiIntegration: false,
        },
      ],
    });
  };

  const handleRemoveRow = (index: number) => {
    if (formik.values.bankPercentages.length === 1) {
      return;
    }
    const updatedBankPercentages = [...formik.values.bankPercentages];
    updatedBankPercentages.splice(index, 1);
    formik.setValues({
      ...formik.values,
      bankPercentages: updatedBankPercentages,
    });
  };

  return (
    <S.Form>
      <Grid>
        <Row span="4">
          <Input
            onChange={(e) => handleFile(e)}
            variant="lighter"
            required
            id="inputFileReadjustments"
            type="file"
            accept=".xlsx"
            inputFileLabel={intl.formatMessage({
              id: 'createPaymentSlip.form.label.buttonFile',
            })}
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.file',
            })}
            hasErrors={formik.touched.archive && Boolean(formik.errors.archive)}
            helperText={formik.errors.archive}
          />
        </Row>
        <Row span="4">
          <Input
            required
            id="dateStartReadjustments"
            name="dateStart"
            value={formik.values.dateStart}
            hasErrors={
              formik.touched.dateStart && Boolean(formik.errors.dateStart)
            }
            helperText={formik.errors.dateStart}
            onChange={formik.handleChange}
            variant="lighter"
            type="date"
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.initialDate',
            })}
          />
        </Row>
        <Row span="4">
          <Input
            required
            id="dateEndReadjustments"
            name="dateEnd"
            type="date"
            value={formik.values.dateEnd}
            hasErrors={formik.touched.dateEnd && Boolean(formik.errors.dateEnd)}
            helperText={formik.errors.dateEnd}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="lighter"
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.label.finalDate',
            })}
          />
        </Row>
      </Grid>
      <Grid>
        <Row span="12">
          <Row>
            <S.Header>
              <S.BankTitle>
                {intl.formatMessage({
                  id: 'createPaymentSlip.form.label.bank',
                })}
              </S.BankTitle>
              {formik.touched.bankPercentages &&
              formik.errors.bankPercentagesError ? (
                <S.ErrorText>{formik.errors.bankPercentagesError}</S.ErrorText>
              ) : null}
            </S.Header>
          </Row>
          {formik.values.bankPercentages.map((item, index) => (
            <S.CustomGrid
              id={`firstGridPercentages${index}`}
              key={`firstGridPercentages${index}`}
            >
              <Row
                span="4"
                id={`firstRowPercentages${index}`}
                key={`firstRowPercentages${index}`}
                style={{ marginTop: '0.4rem' }}
              >
                <CustomSelect
                  required
                  id={`bankPercentages[${index}].bankAccountId`}
                  key={`bankPercentages[${index}].bankAccountId`}
                  inputName={`bankPercentages[${index}].bankAccountId`}
                  inputValue={item.bankAccountId}
                  label={intl.formatMessage({
                    id: 'createPaymentSlip.form.label.selectBank',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'createPaymentSlip.form.placeholder.selectBank',
                  })}
                  options={accounts}
                  setValue={(_, v) =>
                    formik.setFieldValue(
                      `bankPercentages[${index}].bankAccountId`,
                      v
                    )
                  }
                  isBordered
                  hasErrors={
                    formik.touched.bankPercentages &&
                    Boolean(
                      (
                        formik.errors.bankPercentages?.[
                          index
                        ] as FormikErrors<BankPercentageTypes>
                      )?.bankAccountId
                    )
                  }
                  helperText={
                    (
                      formik.errors.bankPercentages?.[
                        index
                      ] as FormikErrors<BankPercentageTypes>
                    )?.bankAccountId
                  }
                />
              </Row>
              <Row
                span="2"
                id={`secondRowPercentages${index}`}
                key={`secondRowPercentages${index}`}
              >
                <Input
                  id={`bankPercentages[${index}].percentage`}
                  key={`bankPercentages[${index}].percentage`}
                  name={`bankPercentages[${index}].percentage`}
                  type={'number'}
                  value={item.percentage}
                  onChange={formik.handleChange}
                  variant="lighter"
                  required
                  label={intl.formatMessage({
                    id: 'createPaymentSlip.form.label.percentage',
                  })}
                  hasErrors={
                    formik.touched.bankPercentages &&
                    Boolean(
                      (
                        formik.errors.bankPercentages?.[
                          index
                        ] as FormikErrors<BankPercentageTypes>
                      )?.percentage
                    )
                  }
                  helperText={
                    (
                      formik.errors.bankPercentages?.[
                        index
                      ] as FormikErrors<BankPercentageTypes>
                    )?.percentage
                  }
                  maxLength={3}
                />
              </Row>

              <Row
                span="2"
                id={`thirdRowPercentages${index}`}
                key={`thirdRowPercentages${index}`}
              >
                <CheckboxInput
                  name={`bankPercentages[${index}].acceptsNordeste`}
                  id={`bankPercentages[${index}].acceptsNordeste`}
                  key={`bankPercentages[${index}].acceptsNordeste`}
                  paySlipPage
                  checked={item.acceptsNordeste}
                  value={item.acceptsNordeste}
                  label={intl.formatMessage({
                    id: 'createPaymentSlip.form.label.acceptsNordeste',
                  })}
                  onChange={(value) =>
                    formik.setFieldValue(
                      `bankPercentages[${index}].acceptsNordeste`,
                      !value
                    )
                  }
                />
              </Row>

              <Row
                span="2"
                id={`fourthRowPercentages${index}`}
                key={`fourthRowPercentages${index}`}
              >
                <CheckboxInput
                  name={`bankPercentages[${index}].enablesApiIntegration`}
                  id={`bankPercentages[${index}].enablesApiIntegration`}
                  key={`bankPercentages[${index}].enablesApiIntegration`}
                  paySlipPage
                  checked={item.enablesApiIntegration}
                  value={item.enablesApiIntegration}
                  label={intl.formatMessage({
                    id: 'createPaymentSlip.form.label.enablesApiIntegration',
                  })}
                  onChange={(value) =>
                    formik.setFieldValue(
                      `bankPercentages[${index}].enablesApiIntegration`,
                      !value
                    )
                  }
                />
              </Row>

              <Row
                span="2"
                id={`fifthRowPercentages${index}`}
                key={`fifthRowPercentages${index}`}
              >
                <S.ContainerButtons
                  id={`secondGridPercentages${index}`}
                  key={`secondGridPercentages${index}`}
                >
                  {formik.values.bankPercentages.length > 1 && (
                    <ButtonLink
                      id={`removeRowButton${index}`}
                      key={`removeRowButton${index}`}
                      onClick={() => handleRemoveRow(index)}
                      type="button"
                      variant="lighter"
                    >
                      <RoundedIcon newPaySlipPage>
                        <TrashBlue />
                      </RoundedIcon>
                    </ButtonLink>
                  )}
                  {index === formik.values.bankPercentages.length - 1 && (
                    <ButtonLink
                      id={`addRowButton${index}`}
                      key={`addRowButton${index}`}
                      onClick={() => {
                        handleAddRow();
                      }}
                      type="button"
                      variant="lighter"
                    >
                      <RoundedIcon newPaySlipPage>
                        <PlusIcon />
                      </RoundedIcon>
                    </ButtonLink>
                  )}
                </S.ContainerButtons>
              </Row>
            </S.CustomGrid>
          ))}
        </Row>
      </Grid>
      <Grid>
        <Row span="12">
          <S.Header>
            <S.BankTitle>
              {intl.formatMessage({
                id: 'createPaymentSlip.form.batchFilters',
              })}
            </S.BankTitle>
          </S.Header>
        </Row>
      </Grid>
      <Grid>
        <Row span="6">
          <Input
            variant="lighter"
            name="associates"
            id="associatesReadjustments"
            type="text"
            value={formik.values.associates}
            onChange={formik.handleChange}
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.associateList',
            })}
            placeholder={intl.formatMessage({
              id: 'createPaymentSlip.form.associateListHelperText',
            })}
            autoComplete="off"
          />
        </Row>
        <Row span="6">
          <Input
            variant="lighter"
            name="groups"
            id="groupsReadjustments"
            type="text"
            value={formik.values.groups}
            onChange={formik.handleChange}
            label={intl.formatMessage({
              id: 'createPaymentSlip.form.groupList',
            })}
            placeholder={intl.formatMessage({
              id: 'createPaymentSlip.form.groupListHelperText',
            })}
            autoComplete="off"
          />
        </Row>
      </Grid>
    </S.Form>
  );
};

export default CreateNewBatchReadjustments;
