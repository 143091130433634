import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

import EmptyState from 'components/shared/core/EmptyState';
import Table from 'components/shared/newCore/Table';

import * as S from './findMyScheduleTable.styles';

interface Schedule {
  id: number;
  associateName: string;
  subscription: string;
  createdAt: string;
  openingTime: string;
  phoneNumber: string;
  feesReferences: string;
  feesTotalValue: string;
}

interface findMyScheduleTableProps {
  schedules: Schedule[];
  isLoading: Boolean;
}

const FindMyScheduleTable = ({
  schedules,
  isLoading,
}: findMyScheduleTableProps) => {
  const intl = useIntl();

  const changeDate = (date: string) =>
    new Date(date).toLocaleDateString('pt-BR');

  if (isLoading) {
    return (
      <S.Container>
        <Skeleton height={105}></Skeleton>
        <Skeleton height={105}></Skeleton>
        <Skeleton height={105}></Skeleton>
      </S.Container>
    );
  }

  if (!schedules) {
    return null;
  }

  if (schedules.length === 0) {
    return (
      <S.EmptyStateContainer>
        <EmptyState />
      </S.EmptyStateContainer>
    );
  }

  if (isLoading) {
    return (
      <S.Container>
        <Skeleton height={105}></Skeleton>
        <Skeleton height={105}></Skeleton>
        <Skeleton height={105}></Skeleton>
      </S.Container>
    );
  }
  return (
    <S.Container>
      <Table.Header>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({ id: 'forms.subscription' })}
        </Table.Column>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({ id: 'forms.name' })}
        </Table.Column>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({ id: 'forms.dateSchedule' })}
        </Table.Column>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({ id: 'forms.hourSchedule' })}
        </Table.Column>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({ id: 'forms.phone' })}
        </Table.Column>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({ id: 'forms.phone1' })}
        </Table.Column>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({ id: 'forms.monthlyFees' })}
        </Table.Column>
        <Table.Column isHeader contentPosition="center">
          {intl.formatMessage({ id: 'forms.totalFeesValue' })}
        </Table.Column>
      </Table.Header>

      <S.ContentTable>
        <Table resizeHeight={315}>
          {schedules.map((schedule: Schedule) => (
            <Table.Row key={schedule.subscription}>
              <Table.Column
                title={`${schedule.subscription}`}
                contentPosition="center"
              >
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.code',
                    })}
                  </span>
                  {schedule.subscription ?? '-'}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column
                title={schedule.associateName ?? '-'}
                contentPosition="center"
              >
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.interval',
                    })}
                  </span>
                  {schedule.associateName ?? '-'}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column
                title={changeDate(schedule.createdAt)}
                contentPosition="center"
              >
                <S.ControlSpaceArea>
                  <span>
                    {intl.formatMessage({
                      id: 'batchList.table.date',
                    })}
                  </span>
                  {changeDate(schedule.createdAt) ?? '-'}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column contentPosition={'center'}>
                <S.ControlSpaceArea>
                  <span>{'Hora Agenda'}</span>
                  {schedule.openingTime ?? '-'}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column contentPosition={'center'}>
                <S.ControlSpaceArea>
                  <span>{'Telefone 1'}</span>
                  {schedule.phoneNumber ?? '-'}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column contentPosition={'center'}>
                <S.ControlSpaceArea>
                  <span>{'Whatsapp'}</span>
                  {schedule.phoneNumber ?? '-'}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column contentPosition={'center'}>
                <S.ControlSpaceArea marginLeft={30}>
                  <span>{'Parcelas'}</span>
                  {schedule.feesReferences.length}
                </S.ControlSpaceArea>
              </Table.Column>
              <Table.Column contentPosition={'center'}>
                <S.ControlSpaceArea marginLeft={35}>
                  <span>{'Valor'}</span>
                  {schedule.feesTotalValue ?? '-'}
                </S.ControlSpaceArea>
              </Table.Column>
            </Table.Row>
          ))}
        </Table>
      </S.ContentTable>
    </S.Container>
  );
};

export default FindMyScheduleTable;
