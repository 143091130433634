import * as S from './Tooltip.styles';

type TooltipProps = {
  type: 'error' | 'default';
  position?: 'top' | 'bottom';
  message: string;
  size?: number;
  fontSize?: 'xs' | 'sm';
  fontWeight?: 'regular' | 'semibold';
  textAlign?: 'left' | 'right' | 'center';
  className?: string;
  positionContainer?: 'absolute' | 'relative';
  moveArrowHorizontally?: string;
};

const Tooltip = ({
  type,
  message,
  position = 'bottom',
  size,
  fontSize = 'xs',
  fontWeight = 'regular',
  textAlign = 'left',
  className,
  positionContainer,
  moveArrowHorizontally,
}: TooltipProps) => (
  <S.Container
    position={positionContainer}
    moveArrowHorizontally={moveArrowHorizontally}
    className={className}
  >
    <S.Content type={type} position={position} size={size}>
      <S.Message
        fontSize={fontSize}
        fontWeight={fontWeight}
        textAlign={textAlign}
      >
        {message}
      </S.Message>
    </S.Content>
  </S.Container>
);
export default Tooltip;
