import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';

export type InputVariant = 'lighter' | 'darken' | 'lighter_in_darken';
interface InputWrapperProps {
  error?: boolean;
  disabled?: boolean;
  hasBorder?: boolean;
  startAdornment: boolean;
  endAdornment: boolean;
  file?: boolean;
}

const LabelVariants = {
  lighter: css`
    color: ${(props) => props.theme.input.primary.label};
    font-weight: 600;
  `,
  darken: css``,
  lighter_in_darken: css``,
};

interface LabelProps {
  variant: InputVariant;
  file?: boolean;
  isAccordionForm?: boolean;
}

interface ContainerProps {
  disabled: boolean;
  isAccordionRegistrationDataForm?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
  ${(props) =>
    props.disabled &&
    props.isAccordionRegistrationDataForm &&
    css`
      opacity: 1;
    `}
`;

export const Label = styled.label<LabelProps>`
  text-transform: uppercase;

  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.input.outlined.label};
    span {
      color: ${theme.contextual.error};
    }
  `}
  ${(props) =>
    props.file &&
    css`
      display: flex;
      width: 130px;
      height: 35px;
      padding-top: 10.3px;
      margin-top: 15.5px;
      margin-left: 8px;
      border-radius-left-top: ${({ theme }) =>
        css`
          ${theme.borderRadius.md}
        `};
      border-radius-left-bottom: ${({ theme }) =>
        css`
          ${theme.borderRadius.md}
        `};
      font-size: 11px;
      background-color: white;
      color: #fff;
      align-item: center;
      text-align: center;
      display: block;
      cursor: pointer;
      position: absolute;
      z-index: 999999;
    `}

  ${({ variant }) => LabelVariants[variant]}
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  margin-top: ${({ theme }) => theme.spacing.xs};
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.input.outlined.background};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.contextual.error}` : 'none'};

  &.lighter,
  &.lighter_in_darken {
    background-color: ${({ theme }) => theme.input.primary.background};
    ${({ hasBorder, error }) =>
      hasBorder &&
      !error &&
      css`
        border: ${({ theme }) => theme.input.primary.optionalBorder};
      `}
    ${(props) =>
      props.disabled &&
      css`
        background-color: ${({ theme }) => theme.input.primary.background};
      `}
  }

  &.lighter:focus-within {
    border: 1px solid ${({ theme }) => theme.input.primary.label};
    /* ${(props) =>
      props.file &&
      css`
        border: none;
      `} */
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${({ theme }) => theme.surface.transparent};
      border: ${({ theme }) => theme.input.disabled.border};
    `}

  &:focus-within, &:focus-visible {
    border: 1px solid ${({ theme }) => theme.surface.main};
  }

  ${(props) =>
    props.startAdornment &&
    css`
      padding-left: ${({ theme }) => theme.spacing.md};
    `}

  ${(props) =>
    props.endAdornment &&
    css`
      padding-right: ${({ theme }) => theme.spacing.md};
    `}


  overflow: hidden;
`;

export const SkeletonWrapper = styled(Skeleton)`
  margin-top: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  height: 48px;
`;

interface CustomInputProps {
  hasStartAdornment: boolean;
  file?: boolean;
  isAccordionRegistrationDataForm?: boolean;
  upperCased?: boolean;
}

export const CustomInput = styled.input<CustomInputProps>`
  ${({ theme, upperCased }) => css`
    width: 100%;
    padding: 14px ${theme.spacing.sm};
    font-size: ${theme.fontSizes.small};
    border: none;
    outline: none;
    background-color: ${theme.surface.transparent};
    color: ${theme.input.outlined.textColor};
    letter-spacing: -0.02em;
    line-height: 100%;
    text-transform: ${() => (upperCased ? 'uppercase' : 'none')};
  `}

  &.lighter, &.lighter_in_darken {
    color: ${({ theme }) => theme.input.primary.textColor};

    ${({ hasStartAdornment }) =>
      hasStartAdornment &&
      css`
        font-weight: 600;
      `}

    ${(props) =>
      props.disabled &&
      css`
        color: ${({ theme }) => theme.input.primary.disabledTextColor};
      `}
      
      

    &::placeholder {
      color: ${({ theme }) => theme.input.placeholder.textColor};
      opacity: 0.6;
    }
    ${(props) =>
      props.file &&
      css`
        position: relative;
        cursor: pointer;
      `}
  }

  ${(props) =>
    props.disabled &&
    props.isAccordionRegistrationDataForm &&
    css`
      color: ${({ theme }) => theme.new.colors.status.subtext};
      font-weight: 600;
      background-color: #dddff1;
    `}

  &.lighter:-webkit-autofill,
  &.lighter_in_darken:-webkit-autofill {
    -webkit-text-fill-color: text;
    -webkit-text-fill-color: ${({ theme }) => theme.input.primary.textColor};
  }

  &:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme }) => theme.input.outlined.textColor};
  }
`;

export const StartAdornmentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.spacing.sm};
  transform: translateY(-50%);
`;

export const EndAdornmentWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing.sm};
  transform: translateY(-50%);
`;

export const ErrorLabel = styled.small`
  color: ${(props) => props.theme.contextual.error};
  font-size: ${(props) => props.theme.fontSizes.small};
`;
