import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useOrthopedicModalEquipmentValidation = () => {
  const intl = useIntl();
  return Yup.object().shape({
    comment: Yup.string().max(50, intl.formatMessage({ id: 'maximum50Chars' })),
    description: Yup.string()
      .required(intl.formatMessage({ id: 'emptyField' }))
      .min(3, intl.formatMessage({ id: 'minimum3Chars' })),
    id: Yup.number()
      .min(1, intl.formatMessage({ id: 'emptyField' }))
      .required(intl.formatMessage({ id: 'emptyField' })),
    branchId: Yup.number()
      .required(intl.formatMessage({ id: 'emptyField' }))
      .min(1, intl.formatMessage({ id: 'emptyField' })),
    aquisitionValue: Yup.string(),
    equipmentGroupId: Yup.string().required(
      intl.formatMessage({ id: 'emptyField' })
    ),
  });
};
