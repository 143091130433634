import styled, { css } from 'styled-components';

export const Title = styled.div`
  margin: ${({ theme }) => theme.spacing.md} 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.texts.lightSecondary};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.large};
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;

  span {
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: 600;
    color: ${({ theme }) => theme.texts.darkSecondary};
  }
`;

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;

export const QueryWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 2rem;
  flex-wrap: wrap;
  @media screen and (max-width: 1000px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const ContainerWithMarginTop = styled.div<{ marginTop: number }>`
  width: 100%;
  ${({ marginTop }) => css`
    margin-top: ${`${marginTop}px`};
  `}
`;
