import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { paymentSlip } from 'services/paymentSlip';

export const useGetBanksWithParams = ({
  page = 1,
  limit = 100,
  enablesApiIntegration = true,
  numericId = false,
}) => {
  const { banksWithParams } = paymentSlip();
  const intl = useIntl();
  const { data: apiBanksNames } = useQuery('apiBanksNames', () =>
    banksWithParams(page, limit, enablesApiIntegration)
  );

  const accounts = useMemo(() => {
    const items = apiBanksNames?.map((item) => ({
      label: `${item.bankCode} - ${item.account} - ${item.assignor} (${item.bank.name})`,
      value: numericId && item.gsAdminId ? item.gsAdminId : item.id,
    }));
    if (items)
      return [
        {
          label: 'GERENCIANET - CONTA PADRÃO',
          value: 0,
        },
        ...items,
      ];
    return [
      {
        label: intl.formatMessage({
          id: 'paymentSlip.selectOption.statusBanksFailed',
        }),
        value: undefined,
      },
    ];
  }, [apiBanksNames]);

  const ticketAccounts = useMemo(() => {
    const items = apiBanksNames?.map((item) => ({
      label: `${item.bankCode} - ${item.account} - ${item.assignor} (${item.bank.name})`,
      value: item?.gsAdminId,
    }));
    if (items) return items;
    return [
      {
        label: intl.formatMessage({
          id: 'paymentSlip.selectOption.statusBanksFailed',
        }),
        value: undefined,
      },
    ];
  }, [apiBanksNames]);
  return {
    accounts,
    ticketAccounts,
  };
};
