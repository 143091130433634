import styled from 'styled-components';
import {
  layout,
  LayoutProps,
  padding,
  PaddingProps,
  display,
  DisplayProps,
  background,
  BackgroundProps,
  shadow,
  ShadowProps,
  position,
  PositionProps,
  border,
  color,
  TypographyProps,
  typography,
  BorderProps,
  ColorProps,
  grid,
  GridProps,
} from 'styled-system';

export type BoxProps = React.HTMLAttributes<HTMLDivElement> &
  DisplayProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  BorderProps &
  PaddingProps &
  BackgroundProps &
  ColorProps &
  GridProps &
  TypographyProps;

export const Box = styled.div<BoxProps>`
  ${layout}
  ${display}
  ${padding}
  ${background}
  ${shadow}
  ${position}
  ${border}
  ${color}
  ${typography}
  ${grid}
`;

export default Box;
