import styled from 'styled-components';

type DiviperProps = {
  isInsideTable?: boolean;
};

const Divider = styled.hr<DiviperProps>`
  border-top: 1px solid
    ${({ theme, isInsideTable }) =>
      isInsideTable
        ? theme.new.colors.neutrals[100]
        : theme.new.colors.neutrals[300]};
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin: 32px 0;
  width: 100%;
  height: 0;
`;

export default Divider;
