import React, { InputHTMLAttributes, useRef } from 'react';

import Tooltip from 'components/shared/newCore/Tooltip';

import * as S from './Input.styles';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  helperText?: string;
  step?: string;
  hasBorder?: boolean;
  hasErrors?: boolean;
  variant: S.InputVariant;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  testid?: string;
  isLoading?: boolean;
  upperCased?: boolean;
  inputFileLabel?: string;
  isAccordionRegistrationDataForm?: boolean;
  hasCustomDate?: boolean;
  onlyNumbers?: boolean;
  noSpecialCharacters?: boolean;
}

const Input = ({
  label,
  type,
  helperText,
  id,
  name,
  step,
  min,
  max,
  hasBorder,
  hasErrors,
  variant,
  disabled = false,
  startAdornment,
  endAdornment,
  value,
  testid,
  required,
  onBlur,
  onChange,
  placeholder,
  autoComplete,
  maxLength,
  isLoading,
  inputFileLabel,
  isAccordionRegistrationDataForm,
  hasCustomDate,
  upperCased = true,
  onlyNumbers = false,
  noSpecialCharacters = false,
  ...props
}: InputProps) => {
  const isUpperCased = upperCased && (type === 'text' || type === 'textarea');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef?.current?.focus();
  };

  const onBlurInput = (ev: React.FocusEvent<HTMLInputElement>) => {
    if (isUpperCased && onChange) {
      ev.target.value = ev.target.value.toUpperCase();
      onChange(ev);
    }

    if (onBlur) {
      onBlur(ev);
    }
  };

  return (
    <S.Container
      disabled={disabled}
      isAccordionRegistrationDataForm={isAccordionRegistrationDataForm}
    >
      {type === 'file' && (
        <S.Label variant={variant} htmlFor={id}>
          {required && <span>*</span>} {inputFileLabel}
        </S.Label>
      )}
      <S.Label file={type === 'file'} variant={variant} htmlFor={id}>
        {required && type !== 'file' && <span>*</span>} {label}
      </S.Label>
      {isLoading ? (
        <S.SkeletonWrapper />
      ) : (
        <S.InputWrapper
          startAdornment={!!startAdornment}
          endAdornment={!!endAdornment}
          hasBorder={hasBorder}
          className={variant}
          disabled={disabled}
          error={hasErrors}
          file={type === 'file'}
        >
          {startAdornment && (
            <S.StartAdornmentWrapper onClick={handleClick}>
              {startAdornment}
            </S.StartAdornmentWrapper>
          )}
          {endAdornment && (
            <S.EndAdornmentWrapper onClick={handleClick}>
              {endAdornment}
            </S.EndAdornmentWrapper>
          )}
          <S.CustomInput
            isAccordionRegistrationDataForm={isAccordionRegistrationDataForm}
            autoComplete={autoComplete || 'on'}
            step={step}
            hasStartAdornment={!!startAdornment}
            className={variant}
            id={id ?? name}
            onBlur={onBlurInput}
            name={name}
            min={min}
            max={max}
            onChange={onChange}
            disabled={disabled}
            type={type}
            value={value}
            data-testid={testid}
            placeholder={placeholder}
            upperCased={isUpperCased}
            maxLength={maxLength}
            ref={inputRef}
            file={type === 'file'}
            {...props}
          />
        </S.InputWrapper>
      )}
      {hasErrors && helperText && (
        <Tooltip type="error" message={helperText as string} />
      )}
    </S.Container>
  );
};

export default Input;
