import messages from 'locales/contexts/serviceOrder';
import { createIntl, createIntlCache } from 'react-intl';

import { Language } from 'contexts/IntlContext';

export const DEFAULT_LANGUAGE: Language = 'pt';
const cache = createIntlCache();
const intl = createIntl(
  {
    locale: DEFAULT_LANGUAGE,
    messages: messages[DEFAULT_LANGUAGE],
  },
  cache
);

export enum Masks {
  CPF = '999.999.999-99',
  CEP = '99999-999',
  Phone = '(99) 9999-9999',
  CellPhone = '(99) 99999-9999',
  Subscription = '999.999-9',
  CreditCard = '9999-9999-9999-9999',
  CVV = '999',
  DueMonth = '99/9999',
  CNPJ = '99.999.999/9999-99',
  SMS = '(99)99999-9999',
  DeathCertificateNumber = '99999999-9',
}

export enum Profiles {
  AGENTE_FUNERARIO = 'AGENTE FUNERARIO',
  ATENDENTE = 'ATENDENTE',
  ADMINISTRADOR = 'MASTER',
}

export enum SocketEvents {
  CONNECTED = 'connected',
}

export enum Permissions {
  USER_MANAGER_ACCESS = 'USER_MANAGER_ACCESS',
  OPEN_BOX_ACCESS = 'OPEN_BOX_ACCESS',
  CLOSE_BOX_ACCESS = 'CLOSE_BOX_ACCESS',
  REVERSAL_ACCESS = 'REVERSAL_ACCCESS',
}

export enum RoutesPermissions {
  CASH = 'cashFlow',
  ASSOCIATES = 'associates',
  MANAGEMENT = 'management',
  OBITUARY = 'obituary',
  PAYMENTSLIP = 'paymentSlip',
  DELIVERY_MANAGEMENT = 'deliveryManagement',
  NEW_CLIENTS = 'newClient',
  ADJUSTMENT_TUITION = 'tuitionAdjustment',
  HOME_CHARGE = 'charge',
  BATCH_PRODUCTION = 'batchProduction',
  TEAM_CHARGE = 'associateCharge',
  POST_SELLING = 'postSelling',
  FINANCIAL = 'financial',
  GRAVES = 'graves',
  MANAGEMENT_REPORTS = 'managementReports',
  ORTHOPEDIC_SUPPORT = 'orthopedicSupport',
  DOCUMENTS_TO_SIGN = 'documentsToSign',
  REGISTRATIONS = 'registrations',
  ADMIN_ORTHOPEDIC_SUPPORT = 'adminOrthopedicSupport',
  ORDER_SERVICE_SISFUNER = 'orderServiceSisfuner',
  NEW_SERVICE_ORDER_SISFUNER_DETAILS = 'newServiceOrderSisfunerDetails',
  UNIT_MANAGER_SISFUNER = 'sisfunerUnitManager',
  FUNERAL_AGENT_SISFUNER = 'sisfunerFuneralAgent',
  FUNERAL_SERVICES_SISFUNER = 'sisfunerFuneralServices',
  SISFUNER_CASH = 'sisfunerCashFlow',
}

export const DebitAccountStatus: {
  [key: string]: string;
} = {
  1: 'Pedido pendente de inclusão',
  2: 'Pedido enviado para o banco',
  3: 'Pedido recebido pelo banco',
  4: 'Pedido aceito pelo banco',
  5: 'Pedido recusado pelo banco',
  6: 'Erro no processamento do pedido',
  7: 'Pedido concluído com sucesso',
  8: 'Pedido cancelado',
  9: 'Pedido pendente de cancelamento',
};
export enum ComponentsPermissions {
  HOME_CARDS_CASHFLOW = 'home.cards.cashFlow',
  HOME_CARDS_ASSOCIATES = 'home.cards.associates',
  HOME_CARDS_MANAGEMENT = 'home.cards.management',
  HOME_CARDS_PAYMENT_SLIP = 'home.cards.paymentSlip',
  HOME_CARDS_DELIVERY_MANAGEMENT = 'home.cards.deliveryManagement',
  HOME_CARDS_ORTHOPEDIC_SUPPORT = 'home.cards.orthopedicSupport',
  ORTHOPEDIC_SUPPORT_CARDS_ADMIN = 'orthopedicSupport.cards.adminOrthopedicSupport',
  HOME_CARDS_OBITUARY = 'home.cards.obituary',
  HOME_CARDS_NEW_CLIENTS = 'home.cards.newClient',
  HOME_CARDS_GRAVES = 'home.cards.graves',
  HOME_CARDS_MANAGEMENT_REPORTS = 'home.cards.managementReports',
  HOME_CARDS_FINANCIAL = 'home.cards.financial',
  HOME_CARDS_REGISTRATIONS = 'home.cards.registrations',
  HOME_CARDS_SISFUNER = 'home.cards.sisfuner',

  ASSOCIATES_DELETE_PAYMENT = 'associates.installmentDebts.components.deletePayments',
  ASSOCIATES_OBSERVATIONS = 'associates.observations',
  ASSOCIATES_OBSERVATIONS_NEW = 'associates.observations.components.new',
  ASSOCIATES_CHANGE_HOLDER = 'associates.changeHolder',
  ASSOCIATES_DEPENDENTS_MANAGEMENT = 'associates.dependentsManagement',
  ASSOCIATE_PERIODIC_UPDATE = 'associates.form.components.periodicUpdate',
  ASSOCIATES_FORM_EDIT = 'associates.form.components.edit',
  ASSOCIATES_DEPENDENTS_MANAGEMENT_NEW = 'associates.dependentsManagement.components.new',
  ASSOCIATES_DEPENDENTS_MANAGEMENT_EDIT = 'associates.dependentsManagement.components.edit',
  ASSOCIATES_INSTALLMENTS_AND_DEBITS = 'associates.installmentDebts',
  ASSOCIATES_MAKING_OF_CARDS = 'associates.makingOfCards',
  ASSOCIATES_CHANGE_PAYMENT = 'associates.changePayment',
  ASSOCIATES_DEATH_HISTORY = 'associates.deathHistory',
  ASSOCIATES_ADDENDUM_CREMATION = 'associates.addendumCremation',
  ASSOCIATES_RENEGOTIATE_GRACE = 'associates.renegotiateGrace',
  ASSOCIATES_CANCEL_PLAN = 'associates.cancelPlan',
  ASSOCIATES_DELIVERY = 'associates.delivery',
  ASSOCIATES_FEES = 'associates.fees',
  ASSOCIATES_FEES_NEW = 'associates.fees.components.new',
  ASSOCIATES_FEES_EDIT = 'associates.fees.components.edit',
  ASSOCIATES_FEES_DELETE = 'associates.fees.components.delete',
  ASSOCIATES_CONTRACT = 'associates.contract',
  ASSOCIATES_CONTRACT_CHANGE_FORM = 'associates.contractChangeForm',
  ASSOCIATES_VAULTS = 'associates.vaults',
  ASSOCIATES_VAULTS_NEW = 'associates.vaults.components.new',
  ASSOCIATES_VAULTS_EDIT = 'associates.vaults.components.edit',
  ASSOCIATES_VAULTS_DELETE = 'associates.vaults.components.delete',

  DOCUMENT_ACCORDION_COMPONENT = 'newClient.partnerDetail.components.accordion',
  COMPLETE_CHANGES = 'newClient.partnerDetail.components.completeChanges',
  DOCUMENTS_TO_SIGN = 'newClient.partnerDetail.components.documentsToSign',
  INSTALLMENTANDDEBTS_CREATE_PARCEL_COMPONENT = 'newClient.installmentsAndDebts.components.createParcel',
  INSTALLMENTANDDEBTS_RECEIVE_PARCEL_COMPONENT = 'newClient.installmentsAndDebts.components.receiveParcel',
  INSTALLMENTANDDEBTS_DELETEPARCEL_COMPONENT = 'newClient.installmentsAndDebts.components.deleteParcel',
  INSTALLMENTANDDEBTS_EDITPARCEL_COMPONENT = 'newClient.installmentsAndDebts.components.editParcel',
  INSTALLMENTANDDEBTS_GENERATE_BANK_SLIP_PARCEL_COMPONENT = 'newClient.installmentsAndDebts.components.generateBankSlips',
  INSTALLMENTANDDEBTS_SEND_CHARGE = 'newClient.installmentsAndDebts.components.sendCharge',
  INSTALLMENTANDDEBTS_RECEIVE_EMPLOYEE_PAYMENT = 'newClient.installmentsAndDebts.components.receiveEmployeePayment',
  EDITASSOCIATE_NEW_COMPONENTS = 'newClient.editAssociate.components.new',
  EDITASSOCIATE_READ_ONLY_COMPONENTS = 'newClient.editAssociate.components.readOnly',
  EDITASSOCIATE_EDIT_COMPLEMENTAR_DATA_COMPONENTS = 'newClient.editAssociate.components.edit',
  NEW_CLIENT_REPORT = 'newClient.components.newClientReport',
  HOME_CARDS_ADJUSTMENT_TUITION = 'home.cards.tuitionAdjustment',
  HIDDEN = 'hidden.component.secret',
  HOME_CHARGE = 'cashFlow.cards.homeCharge',
  BANK_SLIP_BATCH_MANAGEMENT = 'paymentSlip.cards.management',
  BANK_SLIP_BATCH_PRODUCTION = 'paymentSlip.cards.print',
  BANK_SLIP_BATCH_ONLINE_BILLING = 'paymentSlip.cards.online',
  TEAM_CHARGE = 'home.cards.associateCharge',

  BILLING_SCHEDULE = 'associateCharge.components.billingSchedule',
  CURRENT_CAMPAIGN = 'associateCharge.components.currentCampaign',
  MY_SCHEDULES = 'associateCharge.components.mySchedules',
  COMISSION_REPORT = 'associateCharge.components.comissionReport',
  ELECTRONIC_BILLING = 'associateCharge.components.electronicBilling',

  POST_SELLING = 'home.cards.postSelling',
  PERMISSION_CHANGE_PAYMENT_METHOD = 'associates.changePaymentMethod.components.changePayment',
  PERMISSION_CONFIRM_PAYMENT = 'associates.installmentDebts.components.confirmPayment',
  PERMISSION_CONFIRM_PAYMENT_RESTRICTED = 'associates.installmentDebts.components.confirmPaymentRestricted',
  FINANCIAL_INSTALLMENT_TRANSACTIONS = 'financial.components.installmentTransactions',
  FINANCIAL_CASH_REPORT = 'financial.components.cashReport',
  DEPOSIT_RECEIPT = 'depositReceipt',
  ORDER_SERVICE_SISFUNER = 'home.cards.orderServiceSisfuner',
  NEW_SERVICE_ORDER_SISFUNER_DETAILS = 'newServiceOrderSisfunerDetails',
  FUNERAL_AGENT_SISFUNER = 'sisfunerFuneralAgent.cards.sisfunerFuneralAgent',
  UNIT_MANAGER_SISFUNER = 'sisfunerUnitManager.cards.sisfunerUnitManager',
  PERMISSION_VALIDATES_UPSELL = 'associates.upsell.components.validatesUpsell',
  SISFUNER_MASTER = 'sisfuner.cards.master',
}

export enum ComponentsManagementPermissions {
  EDITCEMETERIES = 'management.plans.editCemeteries',
}

export enum CashRegisterStatus {
  ABERTO = 'ABERTO',
  PENDENTE = 'PENDENTE',
  FECHADO = 'FECHADO',
}

export enum PaymentMethods {
  CARD = '7',
  TICKET = '1',
  ESCELSA = '8',
  DEBITACCOUNT = '5',
}

export enum SCHEDULE_PAYMENT_METHOD_OPTIONS {
  JUST_SCHEDULE_PAYMENT = 'justSchedulePayment',
  PIX = 'pix',
  TICKET = 'ticket',
  LINK_CREDIT_CARD_OR_PIX = 'linkCreditCardOrPix',
}

export enum ServicesValues {
  ChangeOwner = 10,
  PlanCard = 1.5,
  AddDependent = 10,
  EditDependent = 10,
}

export enum Services {
  LittleWallet = 'CRT',
  ChangeOwner = 'TIT',
  AddDependent = 'ADE',
  EditDependent = 'EDE',
  AdicionalPriceCategory = 'ADP',
}

export enum StatusLog {
  skip = 'DESCONSIDERADO',
  updated = 'ATUALIZADO',
  confirmed = 'CONFIRMADO',
}

export enum TransactionEnum {
  skip = 'DESCONSIDERADO',
  updated = 'ATUALIZADO',
  confirmed = 'CONFIRMADO',
  edited = 'EDITADO',
}

export enum StockTransferEnumStatus {
  ANALISED = 'ANALISADO',
  PENDING_ANALYSIS = 'ANALISE PENDENTE',
}

export enum TransactionStatusEnum {
  PAIDOUT = 'PAGO',
  REVERSED = 'ESTORNADO',
}

export enum StatusApprovalEnum {
  approve = 2,
  disapprove = 3,
}
export const readjustmentNotificationTypeEnum: {
  [key: string]: string;
} = {
  REQUIREMENT_READJUSTMENT: 'Solicitação de desconto',
  ADJUSTMENT_APPROVED: 'Desconto Aprovado',
  ADJUSTMENT_DISAPPROVED: 'Desconto Reprovado',
};

export enum EquipmentStatusEnumTypes {
  AVAILABLE = 'available',
  LOCATED = 'located',
  MAINTENANCE = 'maintenance',
  INVALID = 'invalid',
  SOLD = 'sold',
}

export const StatusOrthopedicEquipmentsEnum: {
  [key: string | EquipmentStatusEnumTypes]: string;
} = {
  available: 'Disponível',
  located: 'Locado',
  maintenance: 'Em manutenção',
  invalid: 'Inválido',
  sold: 'Vendido',
};

export enum LabelMaintenanceTypeEnum {
  MAINTENANCE = 'maintenance',
  RETURN = 'return',
  SALE = 'sale',
  INVALID = 'invalid',
}

export enum LabelMaintenanceTypeInOutEnum {
  ENTRY = 'entry',
  EXIT = 'exit',
  UNUSABLE = 'invalid',
}

export enum SearchForEquipmentsTypeEnum {
  AssetNumber = 'assetNumber',
  Description = 'description',
  Status = 'status',
}

export enum AssociateQueryTypes {
  BYSUBSCRIPTION = 'subscriptionCode',
  BYNAME = 'name',
  BYSOCIALSECURITYNUMBER = 'socialSecurityNumber',
}

export enum DepositEnum {
  PENDING = 'PENDENTE',
  PAYED = 'PAGO',
  CANCELED = 'CANCELADO',
}

export enum BatchBankSlipMailingStatusEnum {
  Pending = 'pending',
  FileProcessing = 'file-processing',
  FileCompleted = 'file-completed',
  FileFailed = 'file-failed',
  MailingProcessing = 'bank-slips-processing',
  MailingShippingFailed = 'bank-slips-failed-shipping',
  MailingCompleted = 'bank-slips-completed',
  MailingFailed = 'bank-slips-failed',
  Finished = 'finished',
}

type StageType = 'pending' | 'failed' | 'completed' | 'finished';
export const BatchBankSlipMailingStatusByStatus: Record<
  BatchBankSlipMailingStatusEnum,
  StageType
> = {
  [BatchBankSlipMailingStatusEnum.Pending]: 'pending',
  [BatchBankSlipMailingStatusEnum.FileProcessing]: 'pending',
  [BatchBankSlipMailingStatusEnum.FileCompleted]: 'completed',
  [BatchBankSlipMailingStatusEnum.FileFailed]: 'failed',
  [BatchBankSlipMailingStatusEnum.MailingProcessing]: 'pending',
  [BatchBankSlipMailingStatusEnum.MailingShippingFailed]: 'failed',
  [BatchBankSlipMailingStatusEnum.MailingCompleted]: 'completed',
  [BatchBankSlipMailingStatusEnum.MailingFailed]: 'failed',
  [BatchBankSlipMailingStatusEnum.Finished]: 'finished',
};

export const SacProfiles = ['sac', 'gerente sac'] as const;

export const TypeAcceptanceEnum: {
  [key: string]: string;
} = {
  1: 'partnerDetails.accordion.document.acceptanceType.acceptance',
  2: 'partnerDetails.accordion.document.acceptanceType.acceptanceSubmition',
  3: 'partnerDetails.accordion.document.acceptanceType.acceptanceSubmition.charge',
  4: 'partnerDetails.accordion.document.acceptanceType.screenAcceptance',
  5: 'partnerDetails.accordion.document.acceptanceType.screenAcceptance.pixCharge',
  6: 'partnerDetails.accordion.document.acceptanceType.screenAcceptance.pixCharge.debit',
  7: 'partnerDetails.accordion.document.acceptanceType.screenAcceptance.debit.billet',
};

export enum WorkOrderType {
  FUNERAL = 'FUN',
  SALE = 'VEN',
  COMPLEMENTARY = 'COM',
  OUTSOURCED = 'TER',
  IND = 'IND',
  ORC = 'ORC',
  ALL = 'ALL',
}

export enum WorkOrderTypeId {
  'FUN' = '2',
  'PARTICULAR' = '5',
  'SEGURADORA' = '14',
  'SEGURADORA_SAO_PAULO' = '15',
  'NOTA_DEBITO' = '16',
  'PARTICULAR_CLIENTE' = '1',
  'PARTICULAR_EMPRESA' = '9',
}

export type ServiceTypes = 'ASSOCIATE' | 'PARTICULAR' | 'INSURER' | 'DEBT_NOTE';

export const ServiceTypeDescription: { [key in ServiceTypes]: string } = {
  ASSOCIATE: intl.formatMessage({ id: 'serviceOrder.serviceType.associate' }),
  PARTICULAR: intl.formatMessage({ id: 'serviceOrder.serviceType.particular' }),
  INSURER: intl.formatMessage({ id: 'serviceOrder.serviceType.insurer' }),
  DEBT_NOTE: intl.formatMessage({ id: 'serviceOrder.serviceType.debtNote' }),
} as const;

export enum ServiceTypesIds {
  ASSOCIATE = '2',
  PARTICULAR = '5',
  INSURER = '14',
  DEBT_NOTE = '16',
}

export const WorkOrderTypeDescription: {
  [key in WorkOrderType]: string;
} = {
  FUN: intl.formatMessage({ id: 'serviceOrder.type.funeral' }),
  VEN: intl.formatMessage({ id: 'serviceOrder.type.sale' }),
  COM: intl.formatMessage({ id: 'serviceOrder.type.complementary' }),
  TER: intl.formatMessage({ id: 'serviceOrder.type.outsourced' }),
  ORC: intl.formatMessage({ id: 'serviceOrder.type.orc' }),
  IND: intl.formatMessage({ id: 'serviceOrder.type.ind' }),
  ALL: intl.formatMessage({ id: 'serviceOrder.type.all' }),
} as const;

export enum WorkOrderDocumentTypeEnum {
  SERVICE_ORDER = 'ORDEM DE SERVIÇO',
  MEMBER_SERVICE_AUTHORIZATION = 'AUTORIZAÇÃO DE SERVIÇO DE ASSOCIADO',
  BODY_CONSERVATION_AND_RESTORATION_AUTHORIZATION = 'AUTORIZAÇÃO PARA CONSERVAÇÃO E RESTAURAÇÃO DO CORPO',
  ATTENDANCE_BOOK = 'LIVRO DE PRESENÇA',
  SERVICE_CANCELLATION = 'CANCELAMENTO DE SERVIÇOS',
  REMOVAL_AUTHORIZATION = 'AUTORIZAÇÃO DE REMOÇÃO',
  BODY_STORAGE_AUTHORIZATION = 'AUTORIZAÇÃO DE GUARDA DO CORPO',
  CADAVER_RECOGNITION = 'RECONHECIMENTO DE CADÁVER',
  THANATO_AUTHORIZATION = 'AUTORIZAÇÃO DE TANATO',
  THANATO_MINUTES = 'ATA DE TANATOPRAXIA',
  EMBALMING_AUTHORIZATION = 'AUTORIZAÇÃO PARA EMBALSAMENTO',
  URN_CHANGE_AUTHORIZATION = 'AUTORIZAÇÃO TROCA DE URNA',
  DEATH_REGISTRATION_STATEMENT = 'DECLARAÇÃO DE REGISTRO DE ÓBITO',
}

export enum WorkOrderPaymentReceiptTypeEnum {
  DEBIT = 'DEBITO',
  CREDIT = 'CREDITO',
  PIX = 'PIX',
  MONEY = 'DINHEIRO',
}
