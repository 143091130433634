import { formatterIdInput } from 'lib/core/FormatterIdInput';

import * as S from './RadioInputGroup.styles';

export type RadioInputOption = {
  label: string;
  value: string | number;
  disabled?: boolean;
};

type RadioInputOptionsItem = {
  disabled?: boolean;
} & RadioInputOption;

export interface RadioInputGroupProps {
  selected?: RadioInputOption;
  options: RadioInputOptionsItem[];
  onChange: (selected: RadioInputOption) => void;
  column?: boolean;
  name?: string;
  greaterMaxWidth?: boolean;
}

const RadioInputGroup = ({
  selected,
  options,
  onChange,
  column,
  name,
  greaterMaxWidth,
}: RadioInputGroupProps) => (
  <S.Container
    className="radioInputGroup"
    column={column}
    greaterMaxWidth={greaterMaxWidth}
  >
    {options.map((option) => (
      <div key={option.label}>
        <input
          id={formatterIdInput(option.label)}
          type="radio"
          checked={selected?.value === option.value}
          value={option.value}
          name={name || 'radio-input-group'}
          disabled={option.disabled}
          onChange={() => onChange({ ...option })}
        />
        <label htmlFor={formatterIdInput(option.label)}>{option.label}</label>
      </div>
    ))}
  </S.Container>
);

export default RadioInputGroup;
