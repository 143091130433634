import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useLazyGateway } from 'hooks/useLazyGateway';

import { CART } from 'constants/endpoints';

import { useSession } from './AuthContext';

export interface CartProps {
  id: string;
  name: string;
  installmentId: string;
  subscriptionCode: string;
  socialSecurityNumber: string;
  quantityItems: number;
}

interface Cart {
  carts: CartProps[];
  update: () => Promise<void>;
  cartLength: number;
  isCartLoading: boolean;
}

const initialState: Cart = {
  carts: [],
  update: async () => {
    await new Promise<void>(() => {});
  },
  cartLength: 0,
  isCartLoading: false,
};

const CartContext = createContext<Cart>(initialState);

interface CartProviderProps {
  children: ReactNode | ReactNode[];
}

export const CartProvider = ({ children }: CartProviderProps) => {
  const [carts, setCarts] = useState<CartProps[]>([]);
  const [isCartLoading, setIsCartLoading] = useState(true);
  const { user } = useSession();
  const isFirstRender = useRef(true);

  const headerCart = useLazyGateway('header-cart', {
    onSuccess: (data: CartProps[]) => setCarts(data),
    onError: () => setCarts([]),
  });

  const update = useCallback(async () => {
    const url = `${CART.LIST.replace(':id', user.cognitoId)}`;
    await headerCart.sendFor(url);
  }, [headerCart.sendFor, user.cognitoId]);

  useEffect(() => {
    const fetch = async () => {
      await update();
    };
    if (headerCart.isValid && isFirstRender.current) {
      isFirstRender.current = false;
      fetch();
    }
  }, [headerCart.isValid, update]);

  useEffect(
    () => setIsCartLoading(headerCart.isGatewayLoading),
    [headerCart.isGatewayLoading]
  );

  return (
    <CartContext.Provider
      value={{
        carts,
        update,
        cartLength: carts.length,
        isCartLoading,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
