import { MutateListener } from 'types';
import { Branch } from 'types/Branch';

import api from 'services/api';

import { useMutationGateway } from 'hooks/useMutationGateway';

import { BRANCHES } from 'constants/endpoints';

export const useEditBranch = ({ onError, onSuccess }: MutateListener) => {
  const fetchEditBranch = async (
    transactionId: string,
    body: Branch
  ): Promise<void> => {
    await api.putRequest(BRANCHES.UPDATE(body.id), body, {
      headers: {
        transactionId,
      },
    });
  };

  return useMutationGateway(fetchEditBranch, {
    transactionId: 'edit-branch',
    onError,
    onSuccess,
  });
};
