export const colors = {
  primary: {
    main: '#0D1E78',
    light: '#11279C',
  },
  secondary: {
    main: '#F2BA6F',
    dark: '#E58817',
  },
  neutrals: {
    '0': '#ffffff',
    '50': '#dddff1',
    '100': '#E8E9F3',
    '200': '#A7A7A7',
    '300': '#C9CCEE',
    '500': '#E4E5F1',
    '400': '#9191B7',
    '700': '#55557D',
    '900': '#393953',
  },
  status: {
    alert: '#EA6F3B',
    error: '#E7225E',
    success: '#42CC61',
    information: '#1171FF',
    subtext: '#686781',
    texts: '#575861',
  },
  transparent: {
    '400': 'rgba(145, 145, 183, 0.12)',
    '500': 'rgba(145, 145, 183, 0.3)',
    '550': 'rgba(231, 232, 243, 0.49)',
    '600': 'rgba(145, 145, 183, 0.5)',
  },
};
