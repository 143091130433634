import { useReducer } from 'react';

export type LoadingStatus = 'idle' | 'pending' | 'success' | 'error';

interface LoadingState {
  status: LoadingStatus;
}

export type LoadingActionTypes = { type: 'SET_STATUS'; payload: LoadingStatus };

const loadingReducer = (state: LoadingState, action: LoadingActionTypes) => {
  switch (action.type) {
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    default:
      return state;
  }
};

const initialState: LoadingState = {
  status: 'pending',
};

export const useLoading = () => {
  const [state, dispatch] = useReducer(loadingReducer, initialState);

  const isIdle = state.status === 'idle';
  const isLoading = state.status === 'pending';
  const isSuccess = state.status === 'success';

  return {
    state,
    isIdle,
    isLoading,
    isSuccess,
    dispatch,
  };
};
