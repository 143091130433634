import { useSession } from 'contexts';
import RouteGate from 'permissions/RouteGate';
import { lazy, Suspense, useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { retriveUserPermissions } from 'lib/contextual';

import { AssociateProvider } from 'contexts/AssociateContext';
import { NotificationProvider } from 'contexts/NotificationContext';
import { OrdersServiceSisfunerProvider } from 'contexts/OrdersServiceSisfunerContext';

import TuitionReadjustmentModal from 'components/contextual/modalTuitionReadjustment/ModalTuitionReadjustment';
import LoadingState from 'components/shared/core/LoadingState';
import AlertDialog from 'components/shared/core/alertDialog';
import HomeLayout from 'components/shared/core/homeLayout';
import PrivateRoute from 'components/shared/core/privateRoute';

import { ComponentsPermissions, RoutesPermissions } from 'constants/enums';
import { Urls } from 'constants/urls';

import CollectionCommissionReport from './collectionCommissionReport';
import CreateOnlinePaymentSlip from './createOnlinePaymentSlip';
import CreatePaymentSlip from './createPaymentSlip';
import CurrentCampaign from './currentCampaign';
import ElectronicBillingReport from './electronicBillingReport';
import FinancialCashReport from './financialCashReport';
import FindMySchedules from './findMySchedules';
import OnlinePaymentSlip from './onlinePaymentSlip';
import OrthopedicEquipment from './orthopedicEquipment';
import PaymentSlip from './paymentSlip';
import PostSellingReport from './postSellingReport';
import PrintPaymentSlip from './printPaymentSlip';
import ReportAndResearchMenu from './reportAndResearchMenu';
import UpSellAfterSales from './upSellAfterSales';

const LazyLogin = lazy(() => import('./login'));
const LazyRecoverPassword = lazy(() => import('./recoverPassword'));
const LazyHomePage = lazy(() => import('./home'));
const LazyCashFlows = lazy(() => import('./cashFlows'));
const LazyOpenBox = lazy(() => import('./openBox'));
const LazySearchPartners = lazy(() => import('./searchPartners'));
const LazyPartnersDetails = lazy(() => import('./partnersDetails'));
const LazyDependentsOverview = lazy(
  () => import('./associates/dependents/dependentsOverview')
);
const LazyNewDependent = lazy(
  () => import('./associates/dependents/newDependent')
);
const LazyAssociateVaults = lazy(() => import('./associates/vaults'));
const LazyInstallmentsDebts = lazy(() => import('./installmentsDebts'));
const LazyEditDependent = lazy(
  () => import('./associates/dependents/editDependent')
);
const LazyPlanCard = lazy(() => import('./planCard'));
const LazyChangeOwnership = lazy(() => import('./changeOwnership'));
const LazyNewOwnership = lazy(() => import('./changeOwnership/newOwnership'));
const LazyNewOwnershipFromDepedent = lazy(
  () => import('./changeOwnership/newOwnershipFromDependent')
);
const LazyCancelPlan = lazy(() => import('./cancelPlan'));
const LazyPaymentMethod = lazy(() => import('./paymentMethod'));
const LazyDeathHistory = lazy(() => import('./deathHistory'));
const LazyCremationAddition = lazy(() => import('./cremationAddition'));
const LazyObservations = lazy(() => import('./observations'));
const LazyNegociateGracePeriod = lazy(() => import('./negociateGracePeriod'));
const LazyUserList = lazy(() => import('./users/userList'));
const LazyUserForm = lazy(() => import('./users/userForm'));
const LazyEditPartners = lazy(() => import('./editPartners'));
const LazyCashOutWithdrawls = lazy(
  () => import('./cashFlows/cashOutWithdrawals')
);
const LazyCloseBox = lazy(() => import('./closeBox'));
const LazyReversal = lazy(() => import('./reversal'));
const LazyShoppingCart = lazy(() => import('./shoppingCart'));
const LazyManagement = lazy(() => import('./management'));
const LazyOrthopedicSupport = lazy(() => import('./orthopedicSupport'));
const LazyOrthopedicMovement = lazy(() => import('./orthopedicMovement'));
const LazyOrthopedicMovementConsult = lazy(
  () => import('./orthopedicMovement/movementConsult')
);
const LazyOrthopedicGroups = lazy(() => import('./orthopedicGroup'));
const LazyOrthopedicMovementLoans = lazy(
  () => import('./orthopedicMovement/movementLoans')
);
const LazyOrthopedicMovementDevolution = lazy(
  () => import('./orthopedicMovement/movementDevolution')
);
const LazyOrthopedicMovementMaintenance = lazy(
  () => import('./orthopedicMaintenance')
);
const LazyOrthopedicMaintenanceUpdate = lazy(
  () => import('./orthopedicMaintenance/MaintenanceUpdate')
);
const LazyBranchList = lazy(() => import('./branches/branchList'));
const LazyBranchForm = lazy(() => import('./branches/branchForm'));
const LazySisFunerBranchlist = lazy(
  () => import('./sisFuner/unitManager/branches/branchList')
);
const LazySisFunerEmployeelist = lazy(
  () => import('./sisFuner/unitManager/employees/employeeList')
);

const LazyReportUpdateAssociates = lazy(
  () => import('./reportUpdateAssociates')
);
const LazyDelivery = lazy(() => import('./delivery'));
const LazyCashReport = lazy(() => import('./cashReport'));
const LazyMembershipFee = lazy(() => import('./membershipFee'));
const LazyMembershipNewFee = lazy(() => import('./membershipNewFee'));
const LazyMembershipEditFee = lazy(() => import('./membershipEditFee'));
const LazyAssociateLoan = lazy(() => import('./associateLoan'));

const LazyDeathForm = lazy(() => import('./death'));
const LazyDeathRecord = lazy(() => import('./deathRecord'));
const LazyDeathServiceOrder = lazy(() => import('./deathServiceOrder/'));
const LazyDeathNewServiceOrder = lazy(
  () => import('./deathServiceOrder/NewServiceOrder')
);
const LazyDeathDeclarantForm = lazy(() => import('./deathDeclarant'));
const LazyDeathRequiredDocuments = lazy(
  () => import('./deathRequiredDocuments')
);

const LazyDeliveryManagement = lazy(() => import('./deliveryManagement'));
const LazySearchDelivery = lazy(() => import('./searchDelivery'));
const LazyNewDelivery = lazy(() => import('./newDelivery'));
const LazyManageDelivery = lazy(() => import('./manageDelivery'));
const LazyManageDeliveryBatch = lazy(
  () => import('./manageDelivery/DeliveryBatch')
);
const LazyMenuNewClient = lazy(() => import('./menuNewClient'));
const LazyReportNewClients = lazy(() => import('./printReportNewClient'));
const LazyReportReportUpsell = lazy(() => import('./printReportUpsell'));
const LazyDeliveryReport = lazy(() => import('./printDeliveryReport'));
const LazyCrateNewClient = lazy(() => import('./createPartners'));
const LazyTuitionAdjustment = lazy(() => import('./tuitionAdjustment'));
const LazyTuitionLimitsParameterization = lazy(
  () => import('./tuitionLimitsParameterization')
);
const LazyTuitionFitApprover = lazy(() => import('./tuitionFitApprover'));
const MonthlyApprovalHistory = lazy(() => import('./monthlyApprovalHistory'));
const LazyPaymentSlipMenu = lazy(() => import('./PaymentSlipMenu'));
const LazyChargeAssociateMenu = lazy(() => import('./chargeAssociateMenu'));
const LazyMenuManagerMotoboy = lazy(() => import('./MenuManagerMotoboy'));
const LazySchedulesPage = lazy(() => import('./schedulesAndDelays'));
const LazySchedulesByCollectorPage = lazy(
  () => import('./schedulesByCollector')
);
const LazyReportOfBillingPage = lazy(() => import('./reportOfBillings'));
const LazySyncCalendarMotoboy = lazy(() => import('./syncCalendar'));
const LazySchedulePayment = lazy(() => import('./schedulePayment'));
const LazyFinancial = lazy(() => import('./financial'));
const LazyDepositOfReceipts = lazy(() => import('./depositOfReceipts'));

const LazySearchInstallmentTransactions = lazy(
  () => import('./searchInstallmentTransactions')
);
const LazyInstallmentTransactions = lazy(
  () => import('./installmentTransactions')
);
const LazyGraves = lazy(() => import('./graves'));
const LazyManageGrave = lazy(() => import('./graves/manageGrave'));
const LazyManagementReports = lazy(() => import('./managementReports'));
const LazySalesReport = lazy(() => import('./salesReport'));
const LazyMissingBalletBoxReport = lazy(
  () => import('./sisFuner/unitManager/missingBalletBoxReport')
);

const LazyProductHistoryReport = lazy(
  () => import('./sisFuner/unitManager/productHistoryReport')
);

const LazyPlanCompanyReport = lazy(() => import('./planCompanyReport'));
const LazyCanceledSlipsReport = lazy(() => import('./canceledSlipsReport'));
const LazyAssociatesByStatusReport = lazy(() => import('./associatesReport'));
const LazyRegistrations = lazy(
  () => import('./sisFuner/unitManager/registrations')
);
const LazySisfunerDeclarant = lazy(
  () => import('./sisFuner/funeralAgent/declarant')
);
const LazySisfunerDeclarantEdit = lazy(
  () => import('./sisFuner/funeralAgent/declarant/editDeclarant')
);
const LazyDiscounts = lazy(() => import('./sisFuner/unitManager/discounts'));
const LazyBilletExpirationLimit = lazy(
  () => import('./sisFuner/unitManager/billetExpirationLimit')
);
const LazyStockTransfer = lazy(
  () => import('./sisFuner/unitManager/stockTransfer')
);
const LazyBranchFuneralForm = lazy(
  () => import('./sisFuner/unitManager/branchFuneralForm')
);
const LazySisfunerEmployeeForm = lazy(
  () => import('./sisFuner/unitManager/employees/employeeForm')
);
const LazyServiceOrderSisfuner = lazy(
  () => import('./sisFuner/funeralAgent/serviceOrder')
);
const LazyServiceOrderSisfunerSummary = lazy(
  () => import('./sisFuner/funeralAgent/serviceOrderSummary')
);
const LazyServiceOrderSisfunerDocuments = lazy(
  () => import('./sisFuner/funeralAgent/serviceOrderDocuments')
);
const LazyNewServiceOrderSisfunerDetails = lazy(
  () => import('./sisFuner/funeralAgent/newServiceOrderSisfunerDetails')
);
const LazyNewDeathRecordSisfuner = lazy(
  () => import('./sisFuner/funeralAgent/createDeathRecord')
);
const LazyWorkOrderObservations = lazy(
  () => import('./sisFuner/funeralAgent/serviceOrderObservations')
);

const LazyServiceOrderListItems = lazy(
  () => import('./sisFuner/funeralAgent/serviceOrderItemsList')
);

const LazyFuneralServices = lazy(() => import('./sisFuner/funeralServices'));
const LazyAddServicesForm = lazy(
  () => import('./sisFuner/funeralAgent/addServicesForm')
);
const LazyUnitManagement = lazy(
  () => import('./sisFuner/unitManager/unitManagement')
);
const LazyUnitManagementReports = lazy(
  () => import('./sisFuner/unitManager/unitManagerReports')
);
const LazySolicitationCenterSisfuner = lazy(
  () => import('./sisFuner/unitManager/solicitationCenter')
);
const LazyAddNewItemSisfuner = lazy(
  () => import('./sisFuner/funeralAgent/addNewItem')
);
const LazyCreateStockTransferSolicitation = lazy(
  () => import('./sisFuner/unitManager/createStockTransferSolicitation')
);
const LazyServiceOrderReport = lazy(
  () => import('./sisFuner/funeralAgent/reports/serviceOrderReport')
);
const LazyInvoices = lazy(() => import('./sisFuner/unitManager/invoices'));
const LazySisfunerPayment = lazy(
  () => import('./sisFuner/funeralAgent/sisfunerPayment')
);
const LazySisfunerInvoiceReport = lazy(
  () => import('./sisFuner/unitManager/invoiceReport')
);
const LazySisfunerCashFlows = lazy(
  () => import('./sisFuner/cashFlowsSisfuner')
);
const LazySisFunerCashOutWithdrawals = lazy(
  () => import('./sisFuner/cashFlowsSisfuner/cashOutWithdrawals')
);
const LazySisfunerOpenBox = lazy(
  () => import('./sisFuner/cashFlowsSisfuner/openBoxSisfuner')
);
const LazySisfunerCashReport = lazy(
  () => import('./sisFuner/cashFlowsSisfuner/cashReport')
);
const LazySisfunerCloseBox = lazy(
  () => import('./sisFuner/cashFlowsSisfuner/closeBoxSisfuner')
);

const LazyStockConferenceReports = lazy(
  () => import('./sisFuner/unitManager/stockConferenceReports')
);

const LazySisfunerReversal = lazy(
  () => import('./sisFuner/cashFlowsSisfuner/reversal')
);

const AppRoutes = () => {
  const session = useSession();
  const userPermissions = useMemo(
    () => retriveUserPermissions() ?? session.user.permissions,
    [session]
  );

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingState spin />}>
        <Routes>
          <Route path={Urls.Login} element={<LazyLogin />} />
          <Route
            path={Urls.RecoverPassword}
            element={<LazyRecoverPassword />}
          />
          <Route
            element={
              <PrivateRoute>
                <NotificationProvider>
                  <HomeLayout />
                </NotificationProvider>
              </PrivateRoute>
            }
          >
            <Route path={Urls.HomePage} element={<LazyHomePage />} />

            {/* CASH */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.CASH}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route path={Urls.CashFlows} element={<LazyCashFlows />} />
              <Route path={Urls.OpenBox} element={<LazyOpenBox />} />
              <Route path={Urls.CloseBox} element={<LazyCloseBox />} />
              <Route
                element={<LazyCashOutWithdrawls />}
                path={Urls.CashOutWithdrawals}
              />
              <Route element={<LazyReversal />} path={Urls.reversal} />
              <Route element={<LazyCashReport />} path={Urls.CashReport} />
              <Route
                element={<LazyMenuManagerMotoboy />}
                path={Urls.subMenuMotoboy}
              />
              <Route
                element={<LazySchedulesPage />}
                path={Urls.schedulesAndDelays}
              />
              <Route
                element={<LazySchedulesByCollectorPage />}
                path={Urls.schedulesLookUpByCollector}
              />
              <Route
                element={<LazyReportOfBillingPage />}
                path={Urls.reportsOfBillings}
              />
            </Route>
            <Route
              element={<LazySyncCalendarMotoboy />}
              path={Urls.syncCalendar}
            />

            {/* ASSOCIATES */}
            <Route
              element={
                <AssociateProvider>
                  <RouteGate
                    permission={RoutesPermissions.ASSOCIATES}
                    userPermissions={userPermissions}
                  />
                </AssociateProvider>
              }
            >
              <Route path={Urls.Attendance} element={<LazySearchPartners />} />
              <Route
                path={Urls.partnersDetails}
                element={<LazyPartnersDetails />}
              />
              <Route path={Urls.Observation} element={<LazyObservations />} />
              <Route
                element={
                  <RouteGate
                    permission={
                      ComponentsPermissions.ASSOCIATES_INSTALLMENTS_AND_DEBITS
                    }
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route
                  path={Urls.InstallmentsDebts}
                  element={<LazyInstallmentsDebts />}
                />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={
                      ComponentsPermissions.ASSOCIATES_DEPENDENTS_MANAGEMENT
                    }
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route
                  path={Urls.DependentsOverview}
                  element={<LazyDependentsOverview />}
                />
                <Route
                  path={Urls.EditDependent}
                  element={<LazyEditDependent />}
                />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={
                      ComponentsPermissions.ASSOCIATES_DEPENDENTS_MANAGEMENT_NEW
                    }
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route
                  path={Urls.NewDependents}
                  element={<LazyNewDependent />}
                />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={
                      ComponentsPermissions.ASSOCIATES_MAKING_OF_CARDS
                    }
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route path={Urls.PlanCard} element={<LazyPlanCard />} />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={ComponentsPermissions.ASSOCIATES_CHANGE_HOLDER}
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route
                  path={Urls.ChangeOwnership}
                  element={<LazyChangeOwnership />}
                />
                <Route
                  path={Urls.NewOwnership}
                  element={<LazyNewOwnership />}
                />
                <Route
                  path={Urls.NewOwnershipFromDependant}
                  element={<LazyNewOwnershipFromDepedent />}
                />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={ComponentsPermissions.ASSOCIATES_CANCEL_PLAN}
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route path={Urls.CancelPlan} element={<LazyCancelPlan />} />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={ComponentsPermissions.ASSOCIATES_CHANGE_PAYMENT}
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route
                  path={Urls.PaymentMethod}
                  element={<LazyPaymentMethod />}
                />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={ComponentsPermissions.ASSOCIATES_DEATH_HISTORY}
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route
                  path={Urls.DeathHistory}
                  element={<LazyDeathHistory />}
                />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={
                      ComponentsPermissions.ASSOCIATES_ADDENDUM_CREMATION
                    }
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route
                  path={Urls.CremationAddition}
                  element={<LazyCremationAddition />}
                />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={ComponentsPermissions.ASSOCIATES_OBSERVATIONS}
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route path={Urls.Observation} element={<LazyObservations />} />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={
                      ComponentsPermissions.ASSOCIATES_RENEGOTIATE_GRACE
                    }
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route
                  path={Urls.NegociateGracePeriod}
                  element={<LazyNegociateGracePeriod />}
                />
              </Route>
              <Route element={<LazyEditPartners />} path={Urls.editPartners} />

              <Route element={<LazyShoppingCart />} path={Urls.ShoppingCart} />
              <Route
                element={
                  <RouteGate
                    permission={ComponentsPermissions.ASSOCIATES_DELIVERY}
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route element={<LazyDelivery />} path={Urls.Delivery} />
              </Route>

              <Route
                element={
                  <RouteGate
                    permission={ComponentsPermissions.ASSOCIATES_FEES}
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route
                  element={<LazyMembershipFee />}
                  path={Urls.MembershipFee}
                />
                <Route
                  element={
                    <RouteGate
                      permission={ComponentsPermissions.ASSOCIATES_FEES_NEW}
                      userPermissions={userPermissions}
                    />
                  }
                >
                  <Route
                    element={<LazyMembershipNewFee />}
                    path={Urls.MembershipNewFee}
                  />
                </Route>
                <Route
                  element={<LazyMembershipEditFee />}
                  path={Urls.MembershipFeeEdit}
                />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={ComponentsPermissions.ASSOCIATES_VAULTS}
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route element={<LazyAssociateVaults />} path={Urls.Vaults} />
              </Route>
              <Route
                element={
                  <RouteGate
                    permission={RoutesPermissions.ORTHOPEDIC_SUPPORT}
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route element={<LazyAssociateLoan />} path={Urls.LoanInfo} />
              </Route>
            </Route>

            {/* MANAGEMENT */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.MANAGEMENT}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route element={<LazyManagement />} path={Urls.Management} />
              <Route element={<LazyUserList />} path={Urls.UserList} />
              <Route element={<LazyUserForm />} path={Urls.UserForm} />
              <Route element={<LazyBranchList />} path={Urls.BranchList} />
              <Route element={<LazyBranchForm />} path={Urls.BranchForm} />
              <Route
                element={<LazyReportUpdateAssociates />}
                path={Urls.AssociateUpdateReport}
              />
              <Route
                path={Urls.tuitionFitApprover}
                element={<LazyTuitionFitApprover />}
              />
              <Route
                path={Urls.monthlyApprovalHistory}
                element={<MonthlyApprovalHistory />}
              >
                <Route
                  path={Urls.monthlyApprovalHistoryRemoteRequirementAdjusment}
                  element={
                    <>
                      <TuitionReadjustmentModal />
                      <AlertDialog delay={2000} />
                    </>
                  }
                />
              </Route>

              <Route
                path={Urls.tuitionReadjustment}
                element={<LazyTuitionAdjustment />}
              />
              <Route
                path={Urls.tuitionLimitsParameterization}
                element={<LazyTuitionLimitsParameterization />}
              />
            </Route>

            {/* OBITUARY */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.OBITUARY}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route element={<LazyDeathRecord />} path={Urls.Obituary} />
              <Route element={<LazyDeathForm />} path={Urls.DeathForm} />
              <Route
                element={<LazyDeathDeclarantForm />}
                path={Urls.DeclarantForm}
              />
              <Route
                element={<LazyDeathServiceOrder />}
                path={Urls.ServiceOrder}
              />
              <Route
                element={<LazyDeathNewServiceOrder />}
                path={Urls.NewServiceOrder}
              />
              <Route
                element={<LazyDeathRequiredDocuments />}
                path={Urls.RequiredDocuments}
              />
            </Route>

            {/* PAYMENTSLIP */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.PAYMENTSLIP}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                element={<LazyPaymentSlipMenu />}
                path={Urls.menuPaymentSlip}
              />
              <Route element={<PaymentSlip />} path={Urls.paymentSlip} />
              <Route
                element={<PrintPaymentSlip />}
                path={Urls.printPaymentSlip}
              />
              <Route
                element={<CreatePaymentSlip />}
                path={Urls.createPaymentSlip}
              />
              <Route
                element={<OnlinePaymentSlip />}
                path={Urls.onlinePaymentSlip}
              />
              <Route
                element={<CreateOnlinePaymentSlip />}
                path={Urls.createOnlinePaymentSlip}
              />
            </Route>

            {/* DELIVERY MANAGEMENT */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.DELIVERY_MANAGEMENT}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                path={Urls.deliveryManagement}
                element={<LazyDeliveryManagement />}
              />

              <Route
                path={Urls.searchDeliveries}
                element={<LazySearchDelivery />}
              />
              <Route path={Urls.newDelivery} element={<LazyNewDelivery />} />
              <Route
                path={Urls.manageDelivery}
                element={<LazyManageDelivery />}
              />
              <Route
                path={Urls.manageDeliveryBatches}
                element={<LazyManageDeliveryBatch />}
              />
              <Route
                path={Urls.deliveriesReport}
                element={<LazyDeliveryReport />}
              />
              {/* NEW CLIENTS */}
              <Route
                element={
                  <RouteGate
                    permission={RoutesPermissions.NEW_CLIENTS}
                    userPermissions={userPermissions}
                  />
                }
              >
                <Route
                  path={Urls.newClientMenu}
                  element={<LazyMenuNewClient />}
                />
                <Route
                  path={Urls.printReport}
                  element={<LazyReportNewClients />}
                />
                <Route
                  path={Urls.printReportUpsell}
                  element={<LazyReportReportUpsell />}
                />
                <Route
                  path={Urls.UpSellAfterSales}
                  element={<UpSellAfterSales />}
                />
              </Route>
              <Route
                path={Urls.newClientForm}
                element={<LazyCrateNewClient />}
              />
            </Route>
            {/* ADJUSTMENT_TUITION */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.ADJUSTMENT_TUITION}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                path={Urls.tuitionFitApprover}
                element={<LazyTuitionFitApprover />}
              />
              <Route
                path={Urls.monthlyApprovalHistory}
                element={<MonthlyApprovalHistory />}
              >
                <Route
                  path={Urls.monthlyApprovalHistoryRemoteRequirementAdjusment}
                  element={
                    <>
                      <TuitionReadjustmentModal />
                      <AlertDialog delay={2000} />
                    </>
                  }
                />
              </Route>
              <Route
                path={Urls.tuitionReadjustment}
                element={<LazyTuitionAdjustment />}
              />
              <Route
                path={Urls.tuitionLimitsParameterization}
                element={<LazyTuitionLimitsParameterization />}
              />
            </Route>
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.TEAM_CHARGE}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                path={Urls.associateChargeMenu}
                element={<LazyChargeAssociateMenu />}
              />
              <Route
                path={Urls.schedulePayment}
                element={<LazySchedulePayment />}
              />
              <Route
                path={Urls.reportAndResearchMenu}
                element={<ReportAndResearchMenu />}
              />
              <Route
                path={Urls.findMySchedules}
                element={<FindMySchedules />}
              />
              <Route
                path={Urls.currentCampaign}
                element={<CurrentCampaign />}
              />
              <Route
                path={Urls.collectionCommissionReport}
                element={<CollectionCommissionReport />}
              />
              <Route
                path={Urls.electronicBillingReport}
                element={<ElectronicBillingReport />}
              />
            </Route>
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.POST_SELLING}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                path={Urls.postSellingReport}
                element={<PostSellingReport />}
              />
            </Route>

            {/* FINANCIAL */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.FINANCIAL}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route element={<LazyFinancial />} path={Urls.financial} />
              <Route
                element={<LazyDepositOfReceipts />}
                path={Urls.depositOfReceipts}
              />
              <Route
                element={<LazySearchInstallmentTransactions />}
                path={Urls.installmentTransactions}
              />
              <Route
                element={<LazyInstallmentTransactions />}
                path={Urls.installmentTransactionsList}
              />
              <Route element={<FinancialCashReport />} path={Urls.cashReport} />
            </Route>
            {/* GRAVES */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.GRAVES}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route path={Urls.graves} element={<LazyGraves />} />
              <Route path={Urls.newGrave} element={<LazyManageGrave />} />
              <Route
                path={Urls.editGrave}
                element={<LazyManageGrave isEditing />}
              />
            </Route>

            {/* MANAGEMENT REPORTS */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.MANAGEMENT_REPORTS}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                path={Urls.managementReports}
                element={<LazyManagementReports />}
              />
              <Route path={Urls.salesReport} element={<LazySalesReport />} />

              <Route
                path={Urls.associatesByStatusReport}
                element={<LazyAssociatesByStatusReport />}
              />
              <Route
                path={Urls.companiesReport}
                element={<LazyPlanCompanyReport />}
              />
              <Route
                path={Urls.canceledSlipsReport}
                element={<LazyCanceledSlipsReport />}
              />
            </Route>

            {/* ORTHOPEDIC SUPPORT */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.ORTHOPEDIC_SUPPORT}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                element={<LazyOrthopedicSupport />}
                path={Urls.orthopedicSupport}
              />
              <Route
                element={<LazyOrthopedicMovement />}
                path={Urls.orthopedicMovements}
              />
              <Route
                element={<LazyOrthopedicMovementMaintenance />}
                path={Urls.orthopedicMaintenance}
              />
              <Route
                element={<LazyOrthopedicMaintenanceUpdate />}
                path={Urls.orthopedicMaintenanceUpdate}
              />
              <Route
                element={<LazyOrthopedicMovementLoans />}
                path={Urls.movementsLoans}
              />
              <Route
                element={<LazyOrthopedicMovementDevolution />}
                path={Urls.movementsDevolution}
              />
              <Route
                element={<LazyOrthopedicMovementDevolution />}
                path={Urls.movementsDevolutionId}
              />
              <Route
                element={<LazyOrthopedicMovementConsult />}
                path={Urls.movementsConsult}
              />
            </Route>

            {/* ADMIN ORTHOPEDIC SUPPORT */}
            <Route
              element={
                <RouteGate
                  permission={
                    ComponentsPermissions.ORTHOPEDIC_SUPPORT_CARDS_ADMIN
                  }
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                element={<LazyOrthopedicSupport />}
                path={Urls.orthopedicSupport}
              />
              <Route
                element={<LazyOrthopedicGroups />}
                path={Urls.orthopedicGroups}
              />
              <Route
                element={<OrthopedicEquipment />}
                path={Urls.orthopedicEquipments}
              />
            </Route>

            {/* GERENTE DE UNIDADE SISFUNER */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.UNIT_MANAGER_SISFUNER}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                element={<LazyStockTransfer />}
                path={Urls.managementStockTransfer}
              />
              <Route element={<LazyInvoices />} path={Urls.invoices} />
              <Route
                element={<LazyUnitManagementReports />}
                path={Urls.unitManagementReports}
              />
              <Route
                element={<LazySolicitationCenterSisfuner />}
                path={Urls.SisFunerSolicitationCenterList}
              />
              <Route
                element={<LazyCreateStockTransferSolicitation />}
                path={Urls.SisFunerCreateStockTransferSolicitation}
              />
              <Route
                element={<LazySisfunerInvoiceReport />}
                path={Urls.SisFunerInvoicesReport}
              />
              <Route
                path={Urls.missingBallotBoxReport}
                element={<LazyMissingBalletBoxReport />}
              />
              <Route
                path={Urls.SisFunerProductHistoryReport}
                element={<LazyProductHistoryReport />}
              />
              <Route
                path={Urls.stockConferenceReport}
                element={<LazyStockConferenceReports />}
              />
            </Route>

            {/* SISFUNER - MASTER */}
            <Route
              element={
                <RouteGate
                  permission={ComponentsPermissions.SISFUNER_MASTER}
                  userPermissions={userPermissions}
                />
              }
            >
              {/* GERENCIAMENTO */}
              <Route
                element={<LazyUnitManagement />}
                path={Urls.unitManagement}
              />
              <Route
                element={<LazyBilletExpirationLimit />}
                path={Urls.billetExpirationLimit}
              />
              <Route element={<LazyDiscounts />} path={Urls.discounts} />

              {/* CADASTROS */}
              <Route
                element={<LazySisFunerBranchlist />}
                path={Urls.SisFunerBranchList}
              />
              <Route
                path={Urls.missingBallotBoxReport}
                element={<LazyMissingBalletBoxReport />}
              />
              <Route
                path={Urls.missingBallotBoxReport}
                element={<LazyMissingBalletBoxReport />}
              />
              <Route
                element={<LazyBranchFuneralForm />}
                path={Urls.SisFunerBranchForm}
              />
              <Route
                element={<LazySisFunerEmployeelist />}
                path={Urls.SisFunerEmployeeList}
              />
              <Route
                element={<LazySisfunerEmployeeForm />}
                path={Urls.SisFunerNewEmployeeForm}
              />
            </Route>

            {/* ORDEM DE SERVIÇO */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.ORDER_SERVICE_SISFUNER}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                element={<LazyFuneralServices />}
                path={Urls.funeralServices}
              />
              <Route
                element={
                  <OrdersServiceSisfunerProvider>
                    {' '}
                    <LazyServiceOrderSisfuner />
                  </OrdersServiceSisfunerProvider>
                }
                path={Urls.orderServiceSisfuner}
              />
              <Route
                element={<LazyServiceOrderSisfunerSummary />}
                path={Urls.orderServiceSummary}
              />
              <Route
                element={<LazyServiceOrderSisfunerDocuments />}
                path={Urls.orderServiceDocuments}
              />
              <Route
                element={<LazySisfunerDeclarant />}
                path={Urls.orderServiceDeclarantSisfuner}
              />
              <Route
                element={<LazySisfunerDeclarantEdit />}
                path={Urls.orderServiceDeclarantSisfunerDetails}
              />
              <Route
                element={<LazyNewServiceOrderSisfunerDetails />}
                path={Urls.newServiceOrderSisfunerDetails}
              />
              <Route
                element={<LazyAddServicesForm />}
                path={Urls.addServicesToServiceOrder}
              />
              <Route
                element={<LazyNewDeathRecordSisfuner />}
                path={Urls.newDeathRecordSisfuner}
              />
              <Route
                element={<LazyWorkOrderObservations />}
                path={Urls.serviceOrderObservations}
              />
              <Route
                element={<LazyServiceOrderListItems />}
                path={Urls.orderServiceItemsList}
              />
            </Route>
            <Route
              element={<LazyAddNewItemSisfuner />}
              path={Urls.addNewItemSisfuner}
            ></Route>
            <Route
              element={<LazyServiceOrderReport />}
              path={Urls.workOrdersReports}
            ></Route>
            <Route
              element={<LazySisfunerPayment />}
              path={Urls.sisfunerPayment}
            ></Route>

            {/* CASH SISFUNER */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.SISFUNER_CASH}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                path={Urls.SisFunerCashFlows}
                element={<LazySisfunerCashFlows />}
              />
              <Route
                path={Urls.SisFunerCashOutWithdrawals}
                element={<LazySisFunerCashOutWithdrawals />}
              />
              <Route
                path={Urls.SisFunerOpenBox}
                element={<LazySisfunerOpenBox />}
              />
              <Route
                path={Urls.reversalSisfuner}
                element={<LazySisfunerReversal />}
              />
              <Route
                path={Urls.SisFunerCashReport}
                element={<LazySisfunerCashReport />}
              />
              <Route
                path={Urls.SisfunerCloseBox}
                element={<LazySisfunerCloseBox />}
              />
            </Route>

            {/* REGISTRATIONS */}
            <Route
              element={
                <RouteGate
                  permission={RoutesPermissions.REGISTRATIONS}
                  userPermissions={userPermissions}
                />
              }
            >
              <Route
                element={<LazyRegistrations />}
                path={Urls.registrations}
              />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to={Urls.HomePage} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
