import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class GenericApiClient {
  private api: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    this.api = axios.create(config);
  }

  async get<T>(
    endpoint: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.api.get(endpoint, config);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async post<T>(
    endpoint: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.api.post(
        endpoint,
        data,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async patch<T>(
    endpoint: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.api.patch(
        endpoint,
        data,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async put<T>(
    endpoint: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.api.put(
        endpoint,
        data,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async delete<T>(
    endpoint: string,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.api.delete(
        endpoint,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}
