import { RadioInputOption } from 'components/shared/forms/RadioInputGroup';

export type Payment = {
  id: string;
  method: string;
  cardNumber?: string;
  registrationDate: string;
  lastFourCardDigits?: string;
};

export interface CardPayment {
  cardNumber?: string;
  dueDate?: string;
  cardFourLastDigits?: string;
  cvv?: string;
  type: RadioInputOption;
  description: string;
  name: string;
  cpf: string;
  encryptedCardNumber?: string;
  encryptedExpiryMonth?: string;
  encryptedExpiryYear?: string;
  encryptedSecurityCode?: string;
}

export interface CardPaymentPartial {
  cashRegisterId?: string;
  installmentId?: string;
  receiptType: string;
  authorization: string;
  NSU: string;
  status: string;
  title: string;
  paymentValue: number;
  subscriptionCode: string;
  paymentReference: string[];
  total: number;
  userId: string;
  titularName: string;
  secondCopy: boolean;
  terminalId: string;
  receiptDate: string | Date;
  paymentInPix?: boolean;
  card: {
    totalNumberOfPayments?: number;
    paymentInDebit: boolean;
  };
  collectorId?: string;
}

export interface TotalPayment {
  cashRegisterId?: string;
  installmentId?: string;
  receiptType: string;
  authorization: string;
  NSU: string;
  status: string;
  title: string;
  paymentValue: number;
  subscriptionCode: string;
  paymentReference: string[];
  total: number;
  userId: string;
  terminalId?: string;
  receiptDate: string | Date;
  paymentInPix?: boolean;
  isPaymentWithoutTerminal?: boolean;
  card: {
    totalNumberOfPayments?: number;
    paymentInDebit: boolean;
  };
  forDeposit?: boolean;
  collectorId?: string;
}

export interface TicketPaymentDTO {
  type?: string;
  cep: string;
  state: string;
  city: string;
  street: string;
  district: string;
  number: string;
  email: string;
  name: string;
  cpf: string;
  chargeDay: string;
  description: string;
  cityId?: string;
}

export type PaymentDTO = Omit<CardPayment, 'type'> & {
  type: string;
};

export type PaymentHistory = Payment[];

export type PaymentHistoryResponse = {
  id: string;
  lastFourCardDigits?: number;
  lastUpdateDate?: string;
  namePaymentMethod: string;
  status: string;
  subscriptionCode: number;
  transactionReference: string;
};

export type PartialPaymentResponse = {
  createdAt: string;
  id: string;
  paymentValue: number;
  paymentReference: string;
  receiptType: string;
  status: string;
  totalValue: string;
  NSU?: string;
  authorization: '';
  installmentId?: string;
  operationType?: string;
  paymentReferences: [
    {
      paymentReference: string;
      type: string;
    }
  ];
  receiptDate?: string;
  receiptValue: number;
  subscriptionCode?: number;
  title?: string;
  userId?: string;
};

export enum SENDING_METHOD_ID {
  SMS = 1,
  email = 2,
  whatsapp = 3,
}

export const SendingMethodOptions = {
  '1': 'SMS',
  '2': 'email',
  '3': 'whatsapp',
};

export type SendingMethods = keyof typeof SENDING_METHOD_ID;

interface DataPaymentMethodBase {
  feesReferences: string[];
}

export interface DataPaymentMethodPIX extends DataPaymentMethodBase {
  dueDate: string;
}

export interface DataPaymentMethodSLIP extends DataPaymentMethodBase {}

export interface DataPaymentMethodURL extends DataPaymentMethodBase {
  sendingMethod: number;
  sendingMail?: string;
  sendingPhone?: string;
}

export interface DataSendSlipByEmail extends DataPaymentMethodBase {
  email: string;
}

export interface NewSchedule {
  openingDate: string;
  openingTime: string;
  closingDate: string;
  paymentPromiseDate: string;
  observation: string;
  campaignCallStatus: number;
  billingChannel: string;
  ticket: string;
  references: string[];
}

export type BankDetails = {
  id: number;
  nome: string;
  codigo: string;
};
export interface DebitAccount {
  id: number;
  subscriptionCode: number;
  bank: number;
  account: string;
  dv_account: string;
  agency: string;
  start_date: string;
  end_date: string;
  status: string;
  created_at: string;
  user_updated: string;
  updated_at: string;
  user_created: string;
  bankDetails: BankDetails;
}

export interface SendLinkRequestBody {
  FormaEnvio: number;
  TipoAceite: number;
  EmailEnvio: string;
  TelefoneEnvio: string;
  Inscricao: string;
  ReenvioAtualizaConta: boolean;
}
export interface SendLinkRequestResponse {
  mensagem: string;
}

export interface DebitAccountPayload {
  inscricao: number;
  banco: number;
  conta: string;
  dv: string;
  agencia: string;
  status: string;
}

export interface StatusShipment {
  id: number;
  inscricao: number;
  status: number;
  ultimaTransacao: string;
  observacao: string;
  urlRemessa: string;
  urlRetorno: string;
  contaBancaria: string;
  userCreated: string;
  userUpdated: string;
  createdAt: string;
  updatedAt: string;
  StatusTransacao: {
    id: number;
    descricao: string;
  };
}
