import { IntlShape } from 'react-intl';

import { BreadcrumbLink } from 'components/shared/newCore/Breadcrumb';

import { Urls } from 'constants/urls';

export const createBreadcumbLinks = (intl: IntlShape): BreadcrumbLink[] => [
  {
    label: intl.formatMessage({ id: 'breadcrumb.homePage' }),
    redirectTo: Urls.HomePage,
  },
  {
    label: intl.formatMessage({ id: 'breadcrumb.paymentSlipMenu' }),
    redirectTo: Urls.menuPaymentSlip,
  },
  {
    label: intl.formatMessage({ id: 'breadcrumb.onlinePaymentSlip' }),
    redirectTo: Urls.onlinePaymentSlip,
  },
  {
    label: intl.formatMessage({
      id: 'createOnlinePaymentSlip.breadcrumb.newOnlinePaymentSlip',
    }),
    redirectTo: Urls.createOnlinePaymentSlip,
  },
];
