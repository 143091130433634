import styled from 'styled-components';

import RoundedIcon from 'components/shared/core/roundedIcon';

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;
export const Container = styled.div`
  width: 100%;
  max-height: 650px;
`;

export const CustomRoundedIcon = styled(RoundedIcon)`
  padding: ${({ theme }) => theme.spacing.sm};
  height: 56px;
  width: 56px;
`;
