import { MutateListener } from 'types';

import api from 'services/api';

import { useMutationGateway } from 'hooks/useMutationGateway';

import { MACHINE_TERMINAL } from 'constants/endpoints';

type RequestBody = {
  idMachine: string;
};

export const useDeleteMachine = ({ onError, onSuccess }: MutateListener) => {
  const deleteItem = async (
    transactionId: string,
    body: RequestBody
  ): Promise<void> => {
    await api.deleteRequest(
      MACHINE_TERMINAL.DELETE(body.idMachine),
      {},
      {
        headers: {
          transactionId,
        },
      }
    );
  };

  const { mutate, isMutateLoading } = useMutationGateway(deleteItem, {
    transactionId: 'delete-machine ',
    onError,
    onSuccess,
  });

  return {
    mutate,
    isLoading: isMutateLoading,
  };
};
