import { useMemo } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { CityGroup, CityGroupCreation } from 'types/CityGroup';

import { cityGroup } from 'services/cityGroup';

export const useFindAllCityGroup = () => {
  const { getAllCityGroups } = cityGroup();

  const { data: allCityGroups, ...rest } = useInfiniteQuery(
    'cityGroups',
    (page) => getAllCityGroups({ ...page }),
    {
      getNextPageParam: (data) => {
        if (data.items.length === 0) return;
        return data.meta.currentPage + 1;
      },
    }
  );

  const allValues = useMemo(
    () => allCityGroups?.pages.flatMap(({ items }) => items),
    [allCityGroups]
  );

  const allLabelsWithValue = useMemo(
    () => allValues?.map((item) => ({ label: item.name, value: item.id })),
    [allValues]
  );

  return {
    allCityGroups: allLabelsWithValue?.length ? allLabelsWithValue : [],
    rest,
  };
};

export const useFindCityGroup = (id: string) => {
  const { getCityGroupById } = cityGroup();

  const { data: cityGroupData, ...rest } = useQuery(['cityGroupById', id], () =>
    getCityGroupById(id)
  );

  return {
    cityGroupData,
    ...rest,
  };
};

export const useCreateCityGroup = () => {
  const { postCityGroup } = cityGroup();

  const createCityGroupMutation = useMutation((newGroup: CityGroupCreation) =>
    postCityGroup(newGroup)
  );

  return createCityGroupMutation;
};

export const useEditCityGroup = () => {
  const { patchCityGroup } = cityGroup();

  const editCityGroupMutation = useMutation((group: CityGroup) =>
    patchCityGroup(group)
  );

  return editCityGroupMutation;
};
