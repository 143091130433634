export const generateMultipleFilter = (
  filterArray: Array<string | number>,
  queryString: string
) => {
  if (filterArray.length === 0) return '';
  const transformInMultidimensionalArray = Object.entries({ ...filterArray });
  const changeKeyValue = transformInMultidimensionalArray.map((arr) => [
    queryString,
    arr[1],
  ]);
  const trasnformInAObject = Object.fromEntries(changeKeyValue);
  return trasnformInAObject;
};
