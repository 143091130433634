export const dataColectionTable = [
  {
    id: 1,
    startDate: '-9999',
    endDate: '-121',
    campaign: '0',
    receptive: '0',
  },
  {
    id: 2,
    startDate: '-120',
    endDate: '-91',
    campaign: '9,5',
    receptive: '5',
  },
  {
    id: 3,
    startDate: '-90',
    endDate: '-61',
    campaign: '6,5',
    receptive: '2.5',
  },
  {
    id: 4,
    startDate: '-60',
    endDate: '-1',
    campaign: '3,5',
    receptive: '2',
  },
  {
    id: 5,
    startDate: '0',
    endDate: '60',
    campaign: '3',
    receptive: '1,5',
  },
  {
    id: 6,
    startDate: '61',
    endDate: '90',
    campaign: '6',
    receptive: '2',
  },
];
