import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface BreadcrumbLinkProps {
  $lastItem: boolean;
}

interface BreadcrumbLight {
  light?: boolean;
}

type BreadcrumbLinkSeparatorProps = BreadcrumbLinkProps & BreadcrumbLight;

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 1rem;
  button {
    svg {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 703px) {
    span {
      display: none;
    }
    div {
      display: none;
    }
  }
`;

export const BreadcrumbSeparator = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.new.colors.neutrals['400']};
    width: 1px;
    height: 16px;
    margin: 0 ${theme.new.spacing.sm};
  `};
`;

export const BreadcrumbText = styled.p<BreadcrumbLight>`
  ${({ light, theme }) =>
    light &&
    css`
      color: ${theme.new.colors.neutrals[0]};
    `}
`;

export const BreadcrumbItem = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.new.typography.size.sm};
    display: flex;
    align-items: center;
    justify-items: center;
  `};
`;

export const BreadcrumbLink = styled(Link)<
  BreadcrumbLinkProps & BreadcrumbLight
>`
  ${({ theme, $lastItem, light }) => css`
    text-decoration: none;
    color: ${$lastItem
      ? theme.new.colors.primary.light
      : theme.new.colors.neutrals['700']};

    ${light &&
    css`
      color: ${$lastItem
        ? theme.new.colors.neutrals[0]
        : theme.new.colors.neutrals['400']};
    `}
  `};
`;

export const BreadcrumbLinkSeparator = styled.span<BreadcrumbLinkSeparatorProps>`
  ${({ theme, $lastItem, light }) => css`
    margin: 0 ${theme.new.spacing.xs};
    color: ${$lastItem
      ? theme.new.colors.primary.light
      : theme.new.colors.neutrals['700']};
    ${light &&
    css`
      color: ${theme.new.colors.neutrals['400']};
    `}
  `};
`;
