import { AxiosPromise } from 'axios';
import {
  ApplicationUsers,
  BanksForFinanceReport,
  Branches,
  FilterTypeFinancial,
  FinancialPaymentRequest,
  FinancialPaymentResponse,
  FinancialTransaction,
  FinancialTransactionPayload,
  FinancialTransactionResponse,
  FinancialTransactionsRequest,
  InstallmentTransactionsVariation,
  ReportInfo,
  TransactionsUser,
  TransactionValuesFinancial,
  Users,
} from 'types';
import { generateMultipleFilter } from 'utils-general/generateMultipleFilters';

import { getURLWithParams } from 'lib/contextual';

import { FINANCIAL } from 'constants/endpoints';

import api from './api';

export const financial = () => {
  const getPaymentHistory = async ({
    subscriptionCode,
    page = 1,
    limit = 20,
    status = '',
    reference = '',
  }: FinancialPaymentRequest): Promise<FinancialPaymentResponse> => {
    let params: FinancialPaymentRequest = {
      subscriptionCode,
      page,
      limit,
    };

    if (status) {
      params.status = status;
    }

    if (reference) {
      params.reference = reference;
    }

    const url = getURLWithParams(FINANCIAL.INSTALLMENTS_LIST, params);

    const { data } = await api.getRequest(url);

    return data;
  };

  const getTransactionsHistory = async ({
    subscriptionCode,
    page = 1,
    limit = 20,
    filter,
  }: FinancialTransactionsRequest): Promise<FinancialTransactionResponse> => {
    if (filter?.value === TransactionValuesFinancial.All) {
      filter = undefined;
    }

    const filterParam = !filter?.type
      ? {}
      : filter.type === FilterTypeFinancial.TransactionDate
      ? filter.value
      : { [filter.type]: filter.value };

    let params: FinancialTransactionsRequest = {
      subscriptionCode,
      page,
      limit,
      ...filterParam,
    };

    const url = getURLWithParams(FINANCIAL.TRANSACTIONS_HISTORY, params);

    const { data } = await api.getRequest(url);

    return data;
  };

  const getTransactionsUsers = async (
    subscriptionCode: number
  ): Promise<TransactionsUser[]> => {
    const url = FINANCIAL.TRANSACTIONS_USERS(subscriptionCode);

    const { data } = await api.getRequest(url);
    return data;
  };

  const postInstallmentTransaction = (
    payload: FinancialTransactionPayload
  ): AxiosPromise<FinancialTransaction> => {
    const transactionsEndpoints = {
      [InstallmentTransactionsVariation.Bonus]: FINANCIAL.POST_DEBT_WRITE_OFF,
      [InstallmentTransactionsVariation.Refund]: FINANCIAL.REVERSAL,
      [InstallmentTransactionsVariation.WriteOff]: FINANCIAL.RECEIVE_PAY_ON,
    };

    const url = transactionsEndpoints[payload.variation];

    return api.postRequest(url, payload.data);
  };

  const getBranches = async (
    page: number,
    itemsPerPage: number
  ): Promise<Branches> => {
    const url = FINANCIAL.GET_BRANCHES(page, itemsPerPage);
    try {
      const response = await api.getRequest(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const getUsersSystem = async (
    page = 1,
    itemsPerPage: number,
    userIds?: Array<number | string>,
    branchIds?: Array<string | number>,
    userName?: string,
    userNick?: string
  ): Promise<Users> => {
    const url = FINANCIAL.GET_USER_SYSTEM;
    const params = {
      page,
      itemsPerPage,
      ...(userName ? { userName } : ''),
      ...(userNick ? { userNick } : ''),
      ...(userIds ? generateMultipleFilter(userIds, 'userIds') : ''),
      ...(branchIds ? generateMultipleFilter(branchIds, 'branchIds') : ''),
    };
    const urlWithPageParams = getURLWithParams(url, params);

    try {
      const response = await api.getRequest(urlWithPageParams);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const postAnalyticReport = async (body: ReportInfo) => {
    const url = FINANCIAL.POST_ANALYTIC_REPORT;
    try {
      const response = await api.postRequest(url, body);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const postSyntheticReport = async (body: ReportInfo) => {
    const url = FINANCIAL.POST_SYNTHETIC_REPORT;
    try {
      const response = await api.postRequest(url, body);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const getReport = async (fileName: string) => {
    const url = FINANCIAL.GET_REPORT(fileName);
    try {
      const response = await api.getRequest(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  const getApplicationUsers = async (): Promise<ApplicationUsers> => {
    const url = FINANCIAL.GET_APP_USERS;
    try {
      const response = await api.getRequest(url);
      return response.data;
    } catch (e) {
      throw e;
    }
  };
  const getBankFinanceReport = async (
    page: number,
    limit: number
  ): Promise<BanksForFinanceReport> => {
    const url = FINANCIAL.GET_BANKS_FINANCE_REPORT;
    const params = {
      page,
      limit,
    };
    const urlWithParams = getURLWithParams(url, params);
    try {
      const response = await api.getRequest(urlWithParams);
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  return {
    getPaymentHistory,
    getTransactionsHistory,
    getTransactionsUsers,
    postInstallmentTransaction,
    getBranches,
    getUsersSystem,
    postAnalyticReport,
    postSyntheticReport,
    getReport,
    getApplicationUsers,
    getBankFinanceReport,
  };
};
