import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div``;
export const Header = styled.header`
  display: flex;
  cursor: pointer;
  height: auto;

  svg {
    margin-left: ${({ theme }) => theme.spacing.xs};
  }
`;
export const Title = styled.h3`
  ${({ theme }) => css`
    color: ${theme.texts.darkSecondary};
    font-weight: 600;
    font-size: ${theme.fontSizes.medium};
    line-height: ${theme.spacing.md};
  `};

  @media screen and (max-width: 700px) {
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.small};
    `};
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.span`
  ${({ theme }) => css`
    width: 25px;
    height: 25px;
    border-radius: ${theme.new.shapes.borderRadius.full};
    border: ${theme.new.shapes.borderWidth.xl} solid
      ${theme.new.colors.secondary.dark};
    border-bottom: ${theme.new.shapes.borderWidth.xl} solid transparent;
    -webkit-animation: ${spin} 1s linear infinite;
    animation: ${spin} 1s linear infinite;
    margin-left: ${theme.new.spacing.sm};
  `}
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.main``;
