import styled, { css } from 'styled-components';

interface ContainerProps {
  dark?: boolean;
  paySlipPage?: boolean;
}

export const Container = styled.div<ContainerProps>`
  input {
    display: none;
  }

  input + label:before {
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: ${({ theme, dark }) =>
      dark
        ? `${theme.input.checkbox.backgroundDisabled}`
        : `${theme.input.checkbox.background}`};
    box-shadow: inset 0px 0px 0px 2px
      ${({ theme, dark }) =>
        dark
          ? `${theme.input.checkbox.dark}`
          : `${theme.input.checkbox.border}`};
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    margin-bottom: 3px;
  }

  input:checked + label:before {
    box-shadow: inset 0px 0px 0px 8px
      ${({ theme }) => theme.input.checkbox.borderChecked};
  }

  input:checked + label {
    color: ${({ theme }) => theme.input.checkbox.labelChecked};
    ${(props) =>
      props.paySlipPage &&
      css`
        color: ${({ theme }) => theme.link.light.textColor};
      `}
  }

  label {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: 600;
    line-height: 100%;
    color: ${({ theme }) => theme.input.checkbox.label};
    cursor: pointer;
  }

  input:disabled + label:before {
    opacity: 0.1;
    cursor: not-allowed;
  }
  ${(props) =>
    props.paySlipPage &&
    css`
      font-size: ${(props) => props.theme.fontSizes.small};
      justify-content: flex-start;
      align-content: flex-start;
      margin-top: 2.5rem;
      color: black;
    `}
`;
