import styled, { css } from 'styled-components';

export const MenuConainer = styled.div`
  width: 100vw;
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    line-height: ${theme.fontSizes.medium2};
    color: ${theme.texts.lightSecondary};
  `}
  font-weight: normal;
`;
export const CardsWrapper = styled.div`
  padding-left: 5%;
  padding-top: 20px;
  display: flex;
  width: 70%;
  height: 100%;
  gap: 10px;
  flex-wrap: wrap;
`;

export const TitleContainer = styled.div`
  width: 100%;
  padding-top: 3%;
  padding-left: 5%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
`;
export const Container = styled.div`
  width: 100%;
  padding-top: 3%;
  padding-left: 5%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
`;
