export const convertJsonToCsv = <T, K>(
  jsonData: Array<T | K>,
  headers: K,
  fileName: string
) => {
  if (headers) {
    jsonData.unshift(headers);
  }

  const resultCsv = convertToCSV(jsonData);
  const dataBlob = new Blob([resultCsv], { type: 'text/csv;charset=utf-8;' });
  const exportedFileName = fileName + '.csv' || 'export.csv';
  const url = URL.createObjectURL(dataBlob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = exportedFileName;
  anchor.click();

  URL.revokeObjectURL(url);
};

function convertToCSV<T>(objArray: T[]) {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ';';
      if (array[i][index]) {
        line += array[i][index];
      }
    }

    str += line + '\r\n';
  }

  return str;
}

export const isCsvFile = (file: File): boolean => file.type === 'text/csv';
