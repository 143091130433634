export const spacing = {
  xxs: '1px',
  xs: '8px',
  xs0: '11px',
  xs1: '12px',
  xs2: '14px',
  s: '15px',
  sm: '16px',
  'sm0.5': '18px',
  'sm0.7': '20px',
  sm0: '22px',
  sm1: '24px',
  'sm1.1': '26px',
  sm2: '28px',
  md: '32px',
  lg: '40px',
  lg1: '44px',
  lg2: '46px',
  'lg2.1': '48px',
  'lg2.5': '56px',
  lg3: '60px',
  xl: '64px',
  xxxl: '112px',
  big: '189px',
} as const;
