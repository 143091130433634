import io, { Socket } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL!;

class Websocket {
  private static instance?: Socket;
  private constructor() {}

  static getInstance(host: string = 'websocket') {
    if (Websocket.instance) {
      return Websocket.instance;
    }

    const socket = io(SOCKET_URL, {
      transports: ['websocket'],
      withCredentials: true,
      extraHeaders: {
        'Content-Host': host,
      },
    });

    Websocket.instance = socket;

    return Websocket.instance;
  }
}

export default Websocket;
