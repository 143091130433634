import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useBranchs } from 'useCases/financial/useBranchs';

import SearchSelect from 'components/shared/forms/SearchSelect';

interface SelectBranchesProps {
  fieldValue: string;
  setFieldValue: (value: string) => void;
  fieldTouched?: boolean;
  fieldError?: string;
  required?: boolean;
  cancelSearch?: () => void;
}

const SelectBranches = ({
  fieldValue,
  setFieldValue,
  fieldTouched,
  fieldError,
  required = false,
  cancelSearch,
}: SelectBranchesProps) => {
  const intl = useIntl();
  const emptyOption = {
    label: '',
    value: '',
  };
  const [branchSearch, setBranchSearch] = useState('');

  const { branches, branchesLoading } = useBranchs(1, 1000, !branchSearch);

  const optionsFieldsCoordinators = useMemo(() => {
    const branchesChangedToSelect = branches?.items.map((item) => ({
      label: `${item.id} - ${item.description}`,
      value: item.id,
    })) ?? [emptyOption];

    return branchesChangedToSelect.filter(
      (v) =>
        v.label.toLowerCase().includes(branchSearch.toLowerCase()) ||
        v.value === branchSearch ||
        v.value === fieldValue
    );
  }, [required, branches, branchSearch, fieldValue]);

  return (
    <SearchSelect
      id="branches"
      required={required}
      options={optionsFieldsCoordinators}
      label={intl.formatMessage({ id: 'forms.branches' })}
      placeholder={intl.formatMessage({ id: 'forms.branches.placeholder' })}
      valueLabel={fieldValue}
      setValue={(_label, value) => setFieldValue(String(value))}
      hasErrors={fieldTouched && Boolean(fieldError)}
      errorMessage={fieldError}
      isLoading={branchesLoading}
      onSearchChange={(value) => setBranchSearch(String(value))}
      cancelSearch={() => {
        setBranchSearch('');
        setFieldValue('');
        cancelSearch && cancelSearch();
      }}
    />
  );
};

export default SelectBranches;
