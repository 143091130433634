import React from 'react';

import { InputVariant } from 'components/shared/forms/Input/Input.styles';

import { ReactComponent as EyeClosed } from 'assets/images/EyeClosed.svg';
import { ReactComponent as EyeClosedLighter } from 'assets/images/EyeClosedLighter.svg';
import { ReactComponent as EyeOpenLighter } from 'assets/images/EyeLighter.svg';
import { ReactComponent as EyeOpen } from 'assets/images/EyeOpen.svg';

export const EyeClosedSchema: React.FC<{ variant: InputVariant }> = (props) =>
  props.variant === 'darken' ? <EyeClosed /> : <EyeClosedLighter />;
export const EyeOpenedSchema: React.FC<{ variant: InputVariant }> = (props) =>
  props.variant === 'darken' ? <EyeOpen /> : <EyeOpenLighter />;

const toggleEyeschemas = {
  EyeClosedSchema,
  EyeOpenedSchema,
};

export default toggleEyeschemas;
