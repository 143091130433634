import {
  BatchAssociateInputType,
  CreateOnlinePaymentSlipForm,
  OnlineBatchRequestType,
} from 'types/PaymentSlip';

export const getInitialValues = (): CreateOnlinePaymentSlipForm => ({
  archive: null,
  dateEnd: '',
  dateStart: '',
});

export const mapToApi = (
  formValues: CreateOnlinePaymentSlipForm
): OnlineBatchRequestType => {
  const data = {
    associateInputType: BatchAssociateInputType.Sheets,
    dateStart: formValues.dateStart,
    dateEnd: formValues.dateEnd,
  };

  return data;
};
