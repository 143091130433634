import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { filterReport } from 'types/ReportAndInquiriesTypes';

import { useCurrentCampaign } from 'useCases/billings';

import ListCurrentCampaign from 'components/contextual/ListCurrentCampaign';
import SelectUsers from 'components/contextual/SelectUser';
import HomeContent from 'components/contextual/homeContent';
import { Row } from 'components/shared';
import AlertDialog from 'components/shared/core/alertDialog';
import Divider from 'components/shared/core/divider';
import ButtonLink from 'components/shared/forms/ButtonLink';
import CustomSelect from 'components/shared/forms/CustomSelect';
import Input from 'components/shared/forms/Input';
import Breadcrumb from 'components/shared/newCore/Breadcrumb';
import PaginationButton from 'components/shared/newCore/PaginationButton';

import { ReactComponent as GlassSearch } from 'assets/images/MagnifyingGlass.svg';

import * as G from './../../styles/themes/global/mainContainer';
import { UseCurrentCampaignValidation } from './UseCurrentCampaignValidation';
import { useCurrentCampaignConstants } from './currentCampaign.constants';
import * as S from './styles';
import { OptionsSearchFor } from './types';

const CurrentCampaign = () => {
  const intl = useIntl();
  const { breadcrumbLinks, searchFor, resultSearchFor } =
    useCurrentCampaignConstants();
  const [dynamicInputLabel, setDynamicInputLabel] = useState(false);
  const [labelOption, setLabelOption] = useState('');
  const validationSchema = UseCurrentCampaignValidation();
  const [fetchPage, setFetchPage] = useState(1);
  const formik = useFormik({
    initialValues: {
      searchFor: '' as filterReport,
      subscription: '',
      userId: '',
      associateName: '',
    },
    validateOnMount: false,
    validationSchema,
    onSubmit: () => {
      if (fetchPage === 1) {
        refetch();
      } else {
        setFetchPage(1);
      }
    },
  });
  const { currentCampaignData, isFetching, refetch, isLoading, totalPages } =
    useCurrentCampaign(
      fetchPage,
      formik.values.searchFor,
      formik.values[formik.values.searchFor]
    );

  const labelOptionsMap = {
    [OptionsSearchFor.Subscription]: {
      label: resultSearchFor[0].label,
      id: 'subscription',
      name: 'subscription',
      value: formik.values.subscription,
      error: formik.errors.subscription,
    },
    [OptionsSearchFor.AssociateName]: {
      label: resultSearchFor[1].label,
      id: 'associateName',
      name: 'associateName',
      value: formik.values.associateName,
      error: formik.errors.associateName,
    },
  };

  const selectedLabelOption = labelOption as OptionsSearchFor;

  const selectedSearchFor = formik.values.searchFor;

  const handleClear = () => {
    formik.setFieldValue(selectedLabelOption, '');
  };

  useEffect(() => {
    handleClear();
    if (selectedSearchFor === OptionsSearchFor.Subscription) {
      setDynamicInputLabel(true);
      setLabelOption(OptionsSearchFor.Subscription);
    } else if (selectedSearchFor === OptionsSearchFor.AssociateName) {
      setDynamicInputLabel(true);
      setLabelOption(OptionsSearchFor.AssociateName);
    } else {
      setDynamicInputLabel(false);
    }
  }, [selectedSearchFor]);

  return (
    <HomeContent>
      <Breadcrumb path={breadcrumbLinks} />
      <G.Container>
        <G.Nav>
          <header>
            <G.Title>
              {intl.formatMessage({ id: 'breadcrumb.currentCampaign' })}
            </G.Title>
          </header>
        </G.Nav>
        <S.Form onSubmit={formik.handleSubmit}>
          <S.QueryWrapper>
            <S.StyledSearchFor span="4">
              <CustomSelect
                label={intl.formatMessage({
                  id: 'currentCampaign.SearchFor',
                })}
                required
                isBordered
                inputName="searchFor"
                id="searchFor"
                inputValue={formik.values.searchFor}
                setValue={formik.setFieldValue}
                options={searchFor}
                hasErrors={
                  Boolean(formik.errors.searchFor) && formik.touched.searchFor
                }
                helperText={String(formik.errors.searchFor)}
              />
            </S.StyledSearchFor>
            {dynamicInputLabel && (
              <Row span="4">
                <Input
                  type={
                    formik.values.searchFor === 'subscription'
                      ? 'number'
                      : 'text'
                  }
                  required
                  placeholder={labelOptionsMap[selectedLabelOption].label}
                  id={labelOptionsMap[selectedLabelOption].id}
                  name={labelOptionsMap[selectedLabelOption].name}
                  onChange={formik.handleChange}
                  value={labelOptionsMap[selectedLabelOption].value}
                  variant="lighter"
                  label={labelOptionsMap[selectedLabelOption].label}
                  hasErrors={Boolean(
                    labelOptionsMap[selectedLabelOption].error
                  )}
                  helperText={String(
                    labelOptionsMap[selectedLabelOption].error
                  )}
                />
              </Row>
            )}

            {formik.values.searchFor === 'userId' && (
              <Row span="4">
                <SelectUsers
                  required={true}
                  fieldError={formik.errors.userId}
                  fieldValue={formik.values.userId}
                  setFieldValue={(value) =>
                    formik.setFieldValue('userId', value)
                  }
                  fieldTouched={!!formik.errors.userId}
                />
              </Row>
            )}
            {formik.values.searchFor && (
              <S.StyledButton>
                <ButtonLink type="submit" variant="lighter">
                  <S.CustomRoundedIcon>
                    <GlassSearch />
                  </S.CustomRoundedIcon>
                </ButtonLink>
              </S.StyledButton>
            )}
          </S.QueryWrapper>
        </S.Form>
        <Divider />
        <ListCurrentCampaign
          isLoading={isFetching || isLoading}
          campaignData={currentCampaignData}
        />

        <PaginationButton
          totalPages={totalPages}
          pageSelected={(page) => {
            setFetchPage(page);
          }}
        />
        <AlertDialog delay={2000} />
      </G.Container>
    </HomeContent>
  );
};

export default CurrentCampaign;
