import styled, { css } from 'styled-components';

interface PaginationProps {
  selected?: boolean;
}

export const PaginationArea = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
  overflow-x: auto;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.new.spacing.xl7};
  ::-webkit-scrollbar {
    width: 5px;
    height: 15px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`;

export const IntervalWrapper = styled.div`
  width: 20px;
`;

export const NumberPageCheck = styled.div<PaginationProps>`
  ${({ theme, selected }) => css`
    min-width: ${theme.new.spacing.xl2};
    min-height: ${theme.new.spacing.xl2};
    background: ${selected
      ? theme.new.colors.neutrals[300]
      : theme.new.colors.neutrals[100]};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    color: ${theme.texts.darkSecondary};
    font-size: ${theme.fontSizes.small};
    margin-right: ${theme.new.spacing.sm};
    align-self: center;
  `}
`;
