import { City, CityRequest } from 'types/City';

import api from 'services/api';

import { getURLWithParams } from 'lib/contextual/Url';

import { CITY } from 'constants/endpoints';

export const city = () => {
  const getByUF = async ({
    filterParam,
    pageParam = 1,
  }: CityRequest): Promise<{
    data: City[];
    meta: {
      totalItems: number;
      itemCount: number;
      currentPage: number;
      totalPages: number;
    };
  }> => {
    const params = { page: pageParam, limit: 30, state: filterParam };
    const url = getURLWithParams(CITY.GET_BY_UF, params);
    const getByUFResponse = await api.getRequest(url);
    const data = getByUFResponse.data.data.map((city: any) => ({
      gsAdminId: city.id,
      name: city.name,
      state: city.state,
    }));

    return {
      data,
      meta: getByUFResponse.data.meta,
    };
  };

  const getByName = async ({
    filterParam,
    pageParam = 1,
    limitParam = 30,
    orderBy = 'state',
  }: CityRequest): Promise<{
    data: City[];
    meta: {
      totalItems: number;
      itemCount: number;
      currentPage: number;
      totalPages: number;
    };
  }> => {
    const params = {
      page: pageParam,
      limit: limitParam,
      name: filterParam,
      orderBy: orderBy,
    };
    const url = getURLWithParams(CITY.GET_BY_NAME, params);
    const getByNameResponse = await api.getRequest(url);
    const data = getByNameResponse.data.data.map((city: any) => ({
      gsAdminId: city.id,
      name: city.name,
      state: city.state,
    }));

    return {
      data,
      meta: getByNameResponse.data.meta,
    };
  };

  return {
    getByUF,
    getByName,
  };
};
