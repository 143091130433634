import * as XLSX from 'xlsx';

export type ExcelRow = Array<string | number | null | undefined>;

export const convertExceltoJson = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData: ExcelRow[] = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        blankrows: false,
      });
      resolve(jsonData);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });

export const isExcelFile = (file: File): boolean =>
  file.type ===
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
