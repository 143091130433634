import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { SelectItem } from 'components/shared';

import { StatusApprovalEnum } from 'constants/enums';

const TuitionReadjustmentsSharedResources = () => {
  const { formatMessage } = useIntl();
  const optionsStatus: SelectItem[] = [
    {
      label: formatMessage({
        id: 'tuitonReadjustment.modal.field.option.approve',
      }),
      value: StatusApprovalEnum.approve,
    },
    {
      label: formatMessage({
        id: 'tuitonReadjustment.modal.field.option.disapprove',
      }),
      value: StatusApprovalEnum.disapprove,
    },
  ];

  const tuitionReadjustmentSchema = Yup.object().shape({
    status: Yup.string().required(
      formatMessage({ id: 'tuitonReadjustment.modal.field.approve.validation' })
    ),
    reason: Yup.string()
      .min(
        1,
        formatMessage({
          id: 'tuitonReadjustment.modal.field.reason.validation.min',
        })
      )
      .trim()
      .max(
        150,
        formatMessage({
          id: 'tuitonReadjustment.modal.field.reason.validation.max',
        })
      )
      .required(
        formatMessage({
          id: 'tuitonReadjustment.modal.field.reason.validation',
        })
      ),
  });
  return { optionsStatus, tuitionReadjustmentSchema };
};
export default TuitionReadjustmentsSharedResources;
