export function transformB64InAB(b64: string) {
  const binary = atob(b64);
  const buf = new ArrayBuffer(binary.length);
  const view = new Uint8Array(buf);
  for (var i = 0; i !== binary.length; ++i)
    view[i] = binary.charCodeAt(i) & 0xff;
  const blob = new Blob([buf], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
  });
  const downloadUrl = URL.createObjectURL(blob);
  return downloadUrl;
}
