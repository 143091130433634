import styled from 'styled-components';

import { device } from 'styles/tokens/devices';

export const FiltersRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media ${device.tabletL} {
    flex-direction: column;
    gap: 20px;
  }
`;

export const FiltersWidth = styled.div`
  width: 20rem;
  gap: 20px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media ${device.tabletL} {
    flex-direction: column;
  }
`;
