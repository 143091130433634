import * as AlertDialog from '@radix-ui/react-alert-dialog';
import styled, { css, Keyframes, keyframes } from 'styled-components';

const modalToValue = '-50%, -50%, 0';
const modalFromValue = '-50%, 0, 0';
const contentFromValue = '0, -25px, 0';
const contentToValue = '0, 0, 0';

const animationFrame = (isModal: boolean, close: boolean) =>
  close
    ? keyframes`
  0% {
    opacity: 1;
    -webkit-transform: translate3d(${isModal ? modalToValue : contentToValue});
    transform: translate3d(${isModal ? modalToValue : contentToValue});
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(${
      isModal ? modalFromValue : contentFromValue
    });
    transform: translate3d(${isModal ? modalFromValue : contentFromValue});
  }
`
    : keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(${
      isModal ? modalFromValue : contentFromValue
    });
    transform: translate3d(${isModal ? modalFromValue : contentFromValue});
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(${isModal ? modalToValue : contentToValue});
    transform: translate3d(${isModal ? modalToValue : contentToValue});
  }
`;

const openAnimation = (animation: Keyframes) => css`
  animation: ${animation} 0.3s ease-out 0s forwards;
  -webkit-animation: ${animation} 0.3s ease-out 0s forwards;
`;

const closeAnimation = (animation: Keyframes) => css`
  animation: ${animation} 0.3s ease-out 0.05s forwards;
  -webkit-animation: ${animation} 0.3s ease-out 0.05s forwards;
`;

type LoaderProps = {
  isLoading?: boolean;
};

export const Overlay = styled(AlertDialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Modal = styled(AlertDialog.Content)<{ $isClosing: boolean }>`
  ${({ theme, $isClosing }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min(800px, 90%);
    min-height: 361px;
    height: fit-content;
    background-color: #fff;
    border-radius: ${theme.borderRadius.md};
    box-shadow: ${theme.elevation.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    ${$isClosing
      ? closeAnimation(animationFrame(true, $isClosing))
      : openAnimation(animationFrame(true, $isClosing))};
  `}

  @media screen and (max-width: 585px) {
    width: 20rem;
  }
`;

export const Content = styled.div<{ $isClosing: boolean }>`
  ${({ theme, $isClosing }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    padding-left: ${theme.spacing.lg};
    padding-right: ${theme.spacing.lg};
    padding-top: ${theme.spacing.lg};
    opacity: 0;

    ${$isClosing
      ? openAnimation(animationFrame(false, $isClosing))
      : closeAnimation(animationFrame(false, $isClosing))};
  `};
`;

export const Title = styled.span`
  ${({ theme }) => css`
    color: ${theme.texts.darkSecondary};
    font-size: ${theme.fontSizes.large};
  `}
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 585px) {
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.medium2};
    `}
  }
`;

export const Text = styled.p`
  margin-top: 18px;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.cards.text};
  text-align: center;
`;

export const Form = styled.form`
  flex: 1;
  width: 100%;
`;

export const FormFields = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-top: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.sm};

  & > div {
    width: 100%;
  }

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`;

export const FormCityList = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-top: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.sm};

  & > div {
    width: 100%;
  }

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.spacing.sm1};
    padding-bottom: ${theme.spacing.sm1};
    justify-content: space-between;
    width: 100%;
  `}
`;

export const ContainerLoader = styled.div<LoaderProps>`
  ${({ theme, isLoading }) =>
    isLoading &&
    css`
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      opacity: 0.5;
      border-radius: ${theme.borderRadius.md};
    `}
`;

export const Loader = styled.div<LoaderProps>`
  ${({ theme, isLoading }) =>
    isLoading &&
    css`
      position: absolute;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: ${theme.spacing.xs} solid transparent;
      border-top: ${theme.spacing.xs} solid ${theme.texts.darkSecondary};
      border-bottom: ${theme.spacing.xs} solid ${theme.texts.darkSecondary};
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;

      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}
`;

export const SecondFooterContainer = styled.div`
  width: 50%;
`;

export const CityListTitle = styled.span`
  text-transform: uppercase;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  display: block;
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    color: ${theme.input.primary.label};
    font-weight: 600;
    span {
      color: ${theme.contextual.error};
    }
  `}
`;

export const CityListArea = styled.div`
  ${({ theme }) => css`
    padding: ${theme.new.spacing.lg};
  `}
  background-color: rgba(231, 232, 243, 0.49);
  max-height: 300px;
  height: 220px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 200px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(145, 145, 183, 0.3);
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(145, 145, 183, 0.5);
    border-radius: 100px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

export const CityListHtmlList = styled.ul`
  list-style-type: none;
`;

export const CityListHtmlListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.new.spacing.sm};
`;

export const CityListHtmlListElement = styled.span`
  text-transform: uppercase;
  display: block;
  ${({ theme }) => css`
    color: ${theme.input.primary.textColor};
  `}
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.input.primary.focusColor};
  }
`;
