const isDigitSequence = (value: string): boolean => {
  for (let i = 0; i < value.length - 1; i++) {
    for (let j = i + 1; j < value.length; j++) {
      if (value[i] !== value[j]) {
        return false;
      }
    }
  }
  return true;
};

export const hasCPFValid = (cpf: string): boolean => {
  let sum: number;
  let rest: number;

  if (!cpf) {
    return false;
  }

  const valueSanitized = cpf.replace('.', '').replace('.', '').replace('-', '');

  sum = 0;

  if (isDigitSequence(valueSanitized)) {
    return false;
  }
  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(valueSanitized.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(valueSanitized.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(valueSanitized.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(valueSanitized.substring(10, 11), 10)) {
    return false;
  }

  return true;
};
