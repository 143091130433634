import { Machine, MutateListener } from 'types';

import api from 'services/api';

import { useMutationGateway } from 'hooks/useMutationGateway';

import { MACHINE_TERMINAL } from 'constants/endpoints';

export const useRegisterMachine = ({ onSuccess, onError }: MutateListener) => {
  const create = async (
    transactionId: string,
    body: Machine
  ): Promise<void> => {
    await api.postRequest(MACHINE_TERMINAL.POST, body, {
      headers: {
        transactionId,
      },
    });
  };

  const { mutate, isMutateLoading } = useMutationGateway(create, {
    transactionId: 'register-machine',
    onError: onError,
    onSuccess: onSuccess,
  });

  return {
    mutate,
    isLoading: isMutateLoading,
  };
};
