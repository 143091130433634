import { DataMailingItem } from 'pages/postSellingReport';

import { ASSOCIATES, MAILING_EXPORT } from 'constants/endpoints';

import api from './api';

export const postSellingReport = () => {
  const cardRegister = async (subscription: string, data: any) => {
    const url = `${ASSOCIATES.CARD_REGISTER(subscription)}`;
    const result = await api.patchRequest(url, data);

    return result;
  };

  const exportMailing = async (
    startDate: string,
    endDate: string,
    selectAll: boolean
  ) => {
    const url = `${MAILING_EXPORT.GET_BATCHES_ARCHIVE(
      startDate,
      endDate,
      selectAll
    )}`;
    const result = await api.patchRequest(url);
    return result;
  };
  return { cardRegister, exportMailing };
};

export const postSellingAssignmentStatus = (data: DataMailingItem[]) => {
  const subscriptions = data
    .filter((item) => item.checked)
    .map(({ subscription }) => subscription);
  const url = `${MAILING_EXPORT.POST_BATCHES_ARCHIVE}/status/confirm-all`;
  return api.patchRequest(url, { subscriptions });
};
