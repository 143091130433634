import styled, { css } from 'styled-components';

interface Props {
  borderSize?: number;
  width?: number;
  height?: number;
}
export const Container = styled.div<Props>`
  ${({ theme, borderSize, width, height }) => css`
    animation: is-rotating 1s infinite;
    border: ${borderSize ? borderSize : 6}px solid
      ${theme.new.colors.neutrals[300]};
    border-radius: 50%;
    border-top-color: ${theme.new.colors.neutrals[400]};
    height: ${height ? height : 50}px;
    width: ${width ? width : 50}px;
  `}

  @keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin: ${({ theme }) => theme.new.spacing.sm};
`;
