export const downloadWithLink = (url: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.click();
};

export const downloadWithLinkAndId = (url: string, id: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `file_${id}.pdf`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
