import styled, { css } from 'styled-components';

export const Form = styled.form`
  .hidden-separator {
    padding: 16px;
  }
  input[type='file'] {
  }
`;

export const ContainerSubTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1.35rem;
`;

export const ContainerButton = styled.div`
  margin-top: 1.35rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  height: 100%;
  width: 100%;
`;

export const Subtitle = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small1};
    line-height: ${theme.fontSizes.small1};
    color: ${theme.texts.thinParagraph2};
  `}
  font-weight: 400;
`;

export const Paragraph = styled.p`
  ${({ theme }) => css`
    color: ${theme.texts.thinParagraph};
    margin-top: ${theme.spacing.sm};
    font-size: ${theme.fontSizes.small};
  `}
  line-height: 20px;
  text-align: justify;
`;

export const FooterText = styled.p`
  ${({ theme }) => css`
    color: ${theme.texts.darkSecondary};
    margin-top: ${theme.spacing.sm};
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.fontSizes.small};
  `}
  text-align: end;
  text-transform: uppercase;
  font-weight: bold;
`;

export const Button = styled.button`
  ${({ theme }) => css`
    border: 2px solid ${theme.texts.lightSecondary};
    color: ${theme.texts.lightSecondary};
    font-size: ${theme.fontSizes.small};
    line-height: ${theme.fontSizes.small1};
  `}

  font-weight: 600;
  padding: 16px;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 25px;
  cursor: pointer;
  transition: all 250ms;

  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.texts.lightSecondary};
  }

  @media screen and (max-width: 400px) {
    padding: 0.4rem 0.8rem;
  }
`;

export const SelectWrapper = styled.div`
  margin-top: 0.4rem;
`;

export const ErrorText = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.contextual.error};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
