import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FuneralHomesResponse } from 'types/Sisfuner';

import { sisfuner } from 'services/sisfuner';

import { useLocalStorage } from 'hooks/useLocalStorage';

import { SelectItem } from 'components/shared';

import { useSession } from './AuthContext';

type funeralHomesUserType = {
  selectedFuneralHomesUser: string;
  funeralHomesOptions: SelectItem[];
  selectedFuneralData: FuneralHomesResponse;
  allFuneralHomesData: FuneralHomesResponse[];
};

interface InitialValueProps {
  funeralHomesUser: funeralHomesUserType;
  setFuneralHomesUser: (data: funeralHomesUserType) => void;
  cleanFuneralHomes: () => void;
  refreshFuneralHomes: () => void;
}

const initialState: InitialValueProps = {
  funeralHomesUser: {
    selectedFuneralHomesUser: '',
    funeralHomesOptions: [],
    selectedFuneralData: {} as FuneralHomesResponse,
    allFuneralHomesData: [],
  },
  setFuneralHomesUser: (data: funeralHomesUserType) => {},
  cleanFuneralHomes: () => {},
  refreshFuneralHomes: () => {},
};

const FuneralHomesUserContext = createContext<InitialValueProps>(initialState);

interface FuneralHomesProviderProps {
  children: ReactNode;
}

type FuneralHomeUserParam = {
  selectedFuneralHomesUser: string;
  selectedFuneralData: FuneralHomesResponse;
  allFuneralHomesData: FuneralHomesResponse[];
  funeralHomesOptions: {
    label: string;
    value: string;
  }[];
};

export const FuneralHomesUserProvider = ({
  children,
}: FuneralHomesProviderProps) => {
  const { setItem, getItem } = useLocalStorage();
  const { user } = useSession();
  const selectedFuneralHome = getItem('selectedFuneralHomesUser');
  const [funeralHomesUser, setFuneralHomesUser] =
    useState<FuneralHomeUserParam>({
      selectedFuneralHomesUser: selectedFuneralHome ? selectedFuneralHome : '',
      funeralHomesOptions: [],
      selectedFuneralData: {} as FuneralHomesResponse,
      allFuneralHomesData: [],
    });
  const { getEmployeesFuneralHomes } = sisfuner();

  const handleEmployeesFuneralHomes = async () => {
    try {
      const data = await getEmployeesFuneralHomes(user.email);

      if (data['funeral-homes'].length) {
        const primary = data['funeral-homes'].filter((p) => p.isPrimary);

        const normalized = data['funeral-homes'].map((item) => ({
          label: item.name,
          value: item.id,
        }));

        setFuneralHomesUser({
          ...funeralHomesUser,
          funeralHomesOptions: normalized,
          selectedFuneralHomesUser: primary[0].id,
          selectedFuneralData: primary[0],
          allFuneralHomesData: data['funeral-homes'],
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const cleanFuneralHomes = () => {
    setFuneralHomesUser({
      selectedFuneralHomesUser: '',
      funeralHomesOptions: [],
      selectedFuneralData: {} as FuneralHomesResponse,
      allFuneralHomesData: [],
    });
    setItem('selectedFuneralHomesUser', '');
  };

  useEffect(() => {
    if (funeralHomesUser.selectedFuneralHomesUser) {
      setItem(
        'selectedFuneralHomesUser',
        funeralHomesUser.selectedFuneralHomesUser
      );

      const selected = funeralHomesUser.allFuneralHomesData.find(
        (p) => p.id === funeralHomesUser.selectedFuneralHomesUser
      );

      if (selected) {
        setFuneralHomesUser({
          ...funeralHomesUser,
          selectedFuneralData: selected,
        });
      }
    }
  }, [funeralHomesUser.selectedFuneralHomesUser]);

  useEffect(() => {
    if (user.email) {
      handleEmployeesFuneralHomes();
    }
  }, [user.email]);

  return (
    <FuneralHomesUserContext.Provider
      value={{
        funeralHomesUser,
        setFuneralHomesUser,
        cleanFuneralHomes,
        refreshFuneralHomes: handleEmployeesFuneralHomes,
      }}
    >
      {children}
    </FuneralHomesUserContext.Provider>
  );
};

export const useFuneralHomesUser = () => useContext(FuneralHomesUserContext);
