import { Installments } from 'types';

export type StatusFinancial = 'PAGO' | 'PENDENTE' | '';

export enum TransactionValuesFinancial {
  Bonus = 'BONIFICAÇÃO',
  Refund = 'ESTORNO',
  WriteOff = 'BAIXA',
  All = 'TODAS',
}

export enum FilterTypeFinancial {
  TransactionType = 'transactionType',
  Username = 'username',
  TransactionDate = 'transactionDate',
}

export enum InstallmentTransactionsVariation {
  Refund = 'refund',
  WriteOff = 'writeOff',
  Bonus = 'bonus',
}

export type TransactionTypeFinancial =
  typeof TransactionValuesFinancial[keyof typeof TransactionValuesFinancial];

export type InstallmentTransactionsVariationType =
  typeof InstallmentTransactionsVariation[keyof typeof InstallmentTransactionsVariation];

export type FilterFinancialHistory =
  | {
      type: FilterTypeFinancial.TransactionType;
      value: TransactionTypeFinancial;
    }
  | {
      type: FilterTypeFinancial.Username;
      value: string;
    }
  | {
      type: FilterTypeFinancial.TransactionDate;
      value: { initialTransactionDate: string; finalTransactionDate: string };
    };

interface FetchFinancialProps {
  subscriptionCode: number;
  enableOnMount?: boolean;
}

interface FinancialRequest {
  subscriptionCode: number;
  page?: number;
  limit?: number;
}

export interface FinancialPaymentRequest extends FinancialRequest {
  status?: StatusFinancial;
  reference?: string;
}

export interface FinancialTransactionsRequest extends FinancialRequest {
  filter?: FilterFinancialHistory;
}

export interface FinancialRefundRequest {
  subscriptionCode: number;
  references: Array<string>;
  userId: string;
  branchId?: string;
}
export interface FinancialBonusRequest extends FinancialRefundRequest {
  writeOffReason: string;
}

export interface FinancialWriteOffRequest extends FinancialRefundRequest {
  paidValue: number;
  paymentDate: string;
  bankAccountId?: number;
}

export type FinancialTransactionPayload =
  | {
      variation: InstallmentTransactionsVariation.Refund;
      data: FinancialRefundRequest;
    }
  | {
      variation: InstallmentTransactionsVariation.Bonus;
      data: FinancialBonusRequest;
    }
  | {
      variation: InstallmentTransactionsVariation.WriteOff;
      data: FinancialWriteOffRequest;
    };

export interface FinancialTransaction {
  subscriptionCode: number;
  transactionType: string;
  reference: string;
  portionValue: number;
  paidValue: number;
  description?: string;
  transactionDate: string;
  username: string;
  payOnType?: string;
  ticket?: number;
}
export interface FinancialPaymentResponse {
  meta: {
    currentPage: number;
    itemCount: number;
    totalItems: number;
    totalPages: number;
  };
  items: Array<Installments>;
}

export interface FinancialTransactionResponse {
  meta: {
    currentPage: number;
    itemCount: number;
    totalItems: number;
    totalPages: number;
  };
  items: Array<FinancialTransaction>;
}
export interface ApplicationUsers {
  meta: {
    currentPage: number;
    itemCount: number;
    totalItems: number;
    totalPages: number;
  };
  items: Array<{ id: string; name: string; group: string; nick: string }>;
}
export interface BanksForFinanceReport {
  meta: {
    currentPage: number;
    itemCount: number;
    totalItems: number;
    totalPages: number;
  };
  items: Array<{
    name: string;
    id: string;
    nick: string;
  }>;
}

export interface BrancheInfo {
  id: string;
  description: string;
  address: string;
  neighbourhood: string;
  city: string;
  cep: string;
  phoneNumber: string;
  cgc: string;
}

export interface Meta {
  currentPage: number;
  itemCount: number;
  totalItems: number;
  totalPages: number;
}

export interface Branches {
  meta: Meta;
  items: Array<BrancheInfo>;
}

export interface UsersItems {
  id: number;
  nick: string;
  name: string;
  group: number;
}

export interface Users {
  meta: Meta;
  items: Array<UsersItems>;
}

export interface ReportInfo {
  startDate: string;
  endDate: string;
  userIds?: Array<number>;
  collectors?: string[];
}

export interface FetchFinancialInstallmentsProps extends FetchFinancialProps {
  reference?: string;
  status?: StatusFinancial;
}

export interface FetchFinancialTransactionsProps extends FetchFinancialProps {
  filter?: FilterFinancialHistory;
}

export interface TransactionsUser {
  Codigo: number;
  Nome: string;
  Nick: string;
}
