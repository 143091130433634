import { breakpoints } from 'styles/tokens/breakpoints';
import { colors } from 'styles/tokens/colors';
import { elevation } from 'styles/tokens/elevation';
import { shapes } from 'styles/tokens/shapes';
import { spacing } from 'styles/tokens/spacing';
import { typography } from 'styles/tokens/typography';

import { newTheme } from './new';

export const theme = {
  new: newTheme,
  primary: {
    main: colors.beges[500],
    dark: colors.beges[600],
    darken: colors.beges[700],
  },
  paymentGridColumns: '375px 1fr',
  secondary: {
    main: colors.blues[500],
    dark: colors.blues[600],
  },
  contextual: {
    error: colors.reds[500],
    success: colors.greens[500],
    suspense: colors.orange[500],
    tuitionSummary: {
      monthlyFees: colors.grays[710],
      tuitionPaid: colors.blues[600],
      openTuition: colors.blues[500],
      overdueTuition: colors.reds[500],
    },
    statusInstallments: {
      opened: colors.blues[500],
      expired: colors.reds[500],
      paidout: colors.greens[400],
    },
    listPoint: colors.grays[650],
    separatorSummaryModal: colors.grays[350],
    status: {
      active: colors.greens[400],
      inactive: colors.reds[500],
    },
    table: {
      column: colors.whites[500],
    },
    cardCashResult: {
      total: colors.greens[400],
      cashOut: colors.reds[500],
    },
  },
  cards: {
    maintenceInfo: {
      backgroundColor: colors.whites[500],
      borderBottom: `1px solid ${colors.grays[400]}`,
      borderBottom2: `1px solid ${colors.grays[500]}`,
    },
    title: colors.blues[560],
    text: colors.grays[647],
  },
  texts: {
    fadedText: colors.grays[300],
    largeTitle: colors.beges[600],
    secondary: colors.blues[500],
    lightSecondary: colors.blues[550],
    lightSecondary2: colors.blues[560],
    lightBlue: colors.blues[450],
    darkSecondary: colors.blues[600],
    descriptionBlue: colors.blues[560],
    breadcrumbInative: colors.grays[643],
    muted: colors.grays[700],
    options: colors.grays[641],
    description: colors.grays[710],
    thinParagraph: colors.grays[643],
    thinParagraph2: colors.grays[647],
  },
  switch: {
    primary: {
      offColor: colors.grays[640],
    },
  },
  input: {
    outlined: {
      label: colors.grays[600],
      textColor: colors.whites[500],
      background: colors.grays[800],
    },
    bordered: {
      lightBorder: colors.whites[450],
      border: `1px solid ${colors.grays[641]}`,
      textColor: colors.grays[643],
    },
    primary: {
      label: colors.blues[600],
      textColor: colors.grays[647],
      optionalBorder: `1px solid ${colors.grays[600]}`,
      background: colors.whites[500],
      disabledBorder: `1px solid ${colors.grays[600]}`,
      disabledTextColor: colors.grays[700],
      focusColor: colors.grays[700],
    },
    disabled: {
      border: `1px solid ${colors.grays[800]}`,
    },
    placeholder: {
      textColor: colors.grays[710],
    },
    radio: {
      background: colors.whites[500],
      backgroundDisabled: colors.grays[400],
      border: colors.grays[600],
      borderChecked: colors.beges[500],
      label: colors.grays[710],
      labelChecked: colors.blues[600],
    },
    checkbox: {
      background: colors.whites[500],
      backgroundDisabled: colors.grays[400],
      border: colors.grays[641],
      borderChecked: colors.beges[500],
      label: colors.grays[710],
      labelChecked: colors.whites[500],
      dark: colors.blues[550],
    },
    darken: {
      label: colors.grays[641],
      color: colors.grays[641],
    },
  },
  link: {
    secondary: {
      textColor: colors.whites[500],
      hover: colors.beges[600],
    },
    primary: {
      textColor: colors.blues[600],
    },
    light: {
      textColor: colors.blues[560],
    },
  },
  accordion: {
    separatorItem: `1px solid ${colors.grays[719]}`,
  },
  separatorColorDarker: `1px solid ${colors.grays[719]}`,
  separatorColor: colors.grays[700],
  separatorColorLight: colors.grays[600],
  fontSizes: {
    xxs: '10px',
    xs: '12px',
    small: '14px',
    small0: '15px',
    small1: '16px',
    medium: '18px',
    medium1: '20px',
    medium2: '24px',
    large0: '30px',
    large: '32px',
  },
  surface: {
    background: colors.grays[400],
    backgroundDark: colors.grays[600],
    transparent: colors.transparent,
    main: colors.whites[500],
    header: colors.blues[600],
    emptyState: colors.grays[550],
    accordion: colors.grays[720],
  },
  button: {
    disabled: {
      textColor: colors.grays[700],
      backgroundColor: colors.grays[720],
    },
  },
  elevation,
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints.small})`,
    medium: `@media screen and (min-width: ${breakpoints.medium})`,
    large: `@media screen and (min-width: ${breakpoints.large})`,
  },
  ...shapes,
  spacing,
  typography,
} as const;
