import { useEffect } from 'react';

export const useOutsideClick = (
  ref: React.MutableRefObject<null | HTMLElement>,
  outsideClickCallback: () => void
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref && ref.current && !ref?.current?.contains(event.target as Node)) {
        outsideClickCallback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, outsideClickCallback]);
};
